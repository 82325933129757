import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnyIfEmpty, useDispatch, useSelector } from "react-redux";

import { Container } from "@material-ui/core";
import * as api from "../../api";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import RepairReason from "../../compositions/RepairReason/index";
import { userChosenHighLevelDescription, userChosenRepairLocation, userChosenRepairLocationId } from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps } from "react-router-dom";

import axios from 'axios';
import {
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion
} from "../../ducks/redux/actions/userChoices";
import HeaderJourney from "../../storybook/Header/HeaderJourney";

interface RepairLocationProps extends RouteComponentProps<any> { }

const RepairLocation: React.FC<RepairLocationProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const array: any[] = [];

  const [addresses, setAddresses] = useState(array);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setdisabled] = useState(false);

  const [repairLocation, setRepairLocation] = useState("");
  const [change, setChange] = useState(0);

  const userChosenPropertyType = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenRepairLocationIdBackButton = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );

  const resumeDraftRepair = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  useEffect(() => {
    if (userChosenRepairLocationIdBackButton) {
      setRepairLocation(userChosenRepairLocationIdBackButton);
      setdisabled(true);
    }
  }, []);

  useEffect(() => {
    let referenceDataresponsed;
    (async function () {
      try {
        //call reference data api
        const namespacenames = userChosenPropertyType;

        const premiseType =
          namespacenames === "Estate" ? "estate" :
            namespacenames === "Divided Block" || namespacenames === "Divided block" ? "divided-block" : namespacenames;

        referenceDataresponsed = await api.getReferenceData(
          premiseType as string
        );


        let keyValuePairs: any = "";
        if (referenceDataresponsed) {
          keyValuePairs =
            referenceDataresponsed[0]?.namespaces[0]?.namespace?.keyValues;
          setAddresses(keyValuePairs);
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/genericerror");
      }
    })();
  }, []);


  let fieldsArray: any[] = [];
  let arrayObject = Object.entries(addresses);
  arrayObject.sort((a, b) => a[1].localeCompare(b[1]))
  arrayObject.map((eachLocation, key) => {

    fieldsArray.push(
      <option key={eachLocation[0]} className="block mt-1 width-css mt-1 text-black global-txt" value={eachLocation[0]}>
        {eachLocation[1]}
      </option>
    );
  });


 


  const handleChange = (e: any) => {
    setdisabled(true);
    if (repairLocation != e.target.value) {
      if (EditState === true) {
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
        dispatch(userChosenRelationDetails(""));
        dispatch(userChosenEmergencyQuestion(""));
        dispatch(isEditState(false));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenAdditionalDescription(""));
        dispatch(userChosenDuplicateQuestion(""));
      }
      setRepairLocation(e.target.value);
      dispatch(userChosenRepairLocationId(e.target.value));
      const selectedvalues = addresses[e.target.value];
      dispatch(userChosenRepairLocation(selectedvalues));
      setdisabled(true);
    }
  };


  return (
    <>
    <HeaderJourney></HeaderJourney>
    
    <Container id="reploc-container" className="parent-footer" role="main">
      
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={50}
          timeout={7000} //3 secs
        />
      ) : (
        <div id="reploc-main-div" className="padding-repair">
          <h1 className="pt-40 header-size" id="reploc-div1">
            {t("repair_location_label")}
          </h1>
          
          <div className="pt-30">
            <h6 className="pb-12">Location</h6>
            <select id="reploc-sel"
              data-testid="dropDownLocation"
              name="dropDownLocation"
              placeholder="dropDownLocation"
              value={repairLocation} aria-label="reploc-sel"
              onChange={handleChange} className="input-text-select height-44"
            >
              <option value="No option selected global-txt" disabled={disabled} >
                Select
              </option>
              {fieldsArray}
            </select>
          </div>
          
        </div>
      )}

{<RepairReason {...props} />}
      
         
          
          
    </Container>
    </>
  );
};

export default RepairLocation;
