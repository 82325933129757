import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import {
  ApplicationInsights,
  IPageViewTelemetry,
} from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'

const reactPlugin = new ReactPlugin()
const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY

const browserHistory = createBrowserHistory({ basename: '' })
export const getBrowserHistory = () => browserHistory

let debug = false

if (process.env.REACT_APP_ENV !== 'prod') {
  debug = true
}

let appInsights: ApplicationInsights

export function setUpAppInsights() {
  const config = {
    instrumentationKey,
    maxBatchInterval: 0,
    disableFetchTracking: false,
    enableUnhandledPromiseRejectionTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        debug,
        history: browserHistory,
      },
    },
  }

  appInsights = new ApplicationInsights({
    config,
  })
  appInsights.loadAppInsights()



}

export function trackPageView(page: IPageViewTelemetry): void {
  if (appInsights) {
    appInsights.trackPageView(page)
    appInsights.flush()
  }
}

export function trackEvent(eventName: string, channel?:string,customerId?:string,corelationId?:string,sessionId?: string) {
  if (appInsights && eventName) {
    appInsights.trackEvent({
      name: `${eventName.replace('/', '')}`,
      properties: { channel_identifier: channel,customer_identifier: customerId,correlation_identifier: corelationId,session_identifier: sessionId },
    })
    appInsights.flush()
  }
}

export function trackException(error: Error, channel?:string,customerId?:string,corelationId?:string,sessionId?: string) {
  if (appInsights) {
    appInsights.trackException({
      error,
      properties: { channel_identifier: channel,customer_identifier: customerId,correlation_identifier: corelationId,session_identifier: sessionId },
    })
    appInsights.flush()
  }
}
