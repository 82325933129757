import { NameDetails } from '../Customer/CustomerName'
import DigitalAddress from './Address/DigitalAddress'
import { Person } from './Person'

type addressField = {
  digitalAddresses: DigitalAddress[]
}

export class RaisedBy {
  public person: Person
  public address: addressField
  constructor(name?: NameDetails, addresses?: DigitalAddress[]) {
    this.person = {
      name: {
        title: name?.title || '',
        givenName: name?.givenName || '',
        surname: name?.surname || '',
      },
    }
    this.address = {
      digitalAddresses: addresses || [],
    }
  }
}
