import React, { useCallback, useEffect, useRef, useState } from "react";
import { CaseStatusField } from "../../../models/Case/Case";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import staticSortableFields from "../../../models/Case/CaseSortableFields";
import { PremisesType } from "../../../models/Customer/Premises";
import * as api from "../../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../HistoricRepairs/index.scss"
import filtericon from "../../../assets/img/filtericon.png";
import Clear from "../../../assets/img/Clear.png";
import {
    MobilePagination,
    Pagination,
    Paginationbox,
    Search,
    WebPagination,
} from "./components";

import { RouteComponentProps } from "react-router-dom";
import { formatDate, isNullOrUndefined } from "../../../utils/formatting";

import { Offcanvas } from "react-bootstrap";
import { CustomerResponse } from "../../../models/Customer";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import CaseStatusOptions from "../../../models/Case/CaseStatusOptions";
import CaseSearchData, { Links } from "../../../models/Case/CaseSearch";
import {
    AssocitaedAssetsType,
    RelatedPremisesType,
} from "../../../models/Property/AdminUnits";
import Tenancies from "../../../models/Customer/Tenancies";
import { trackException } from "../../../appInsights/TelemetryService";
import { useHistory } from "react-router-dom";
import { CaseOwnerMatrix } from "../../../models/Case/CaseOwnerMatrix";
import { showMenuTabs, userChosenPropertyId } from "../../../ducks/redux/actions/userChoices";
import { repairHistoryData } from "../../../ducks/redux/actions/historicRepair";

interface CaseSearchProps extends RouteComponentProps<any> { }
const CaseSearch: React.FC<CaseSearchProps> = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [defaultSortOrder, setdefaultSortOrder] = useState("createdDate desc");
    const [defaultCaseStatus, setdefaultCaseStatus] = useState("");
    const [defaultassocitaedAsset, setdefaultassocitaedAsset] = useState("");
    const [associtaedAsset, setassocitaedAsset] = useState("");

    const [searchTerm, setsearchTerm] = useState("");
    const [priority, setpriority] = useState("");

    const [selectedArea, setselectedArea] = useState("All");
    const [Area, setArea] = useState(false);
    const [totalCount, settotalCount] = useState(0);
    const [currentPage, setcurrentPage] = useState(0);
    const [resultsPerPage, setresultsPerPage] = useState(10);
    const [skip, setskip] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const customerID = useSelector((state: any) => state.addresses?.customerid);
    const [isMoreThanThousand, setIsMoreThanThousand] = useState(false);
    const [hasMoreCases, setHasMoreCases] = useState(true);
    const arrayCases: CaseSearchData = {
        cases: [],
        "total-count": 0,
        skip: 0,
        limit: 0,
        _links: new Links(),
    };
    const [CustResponse, setCustomerResponse] = useState<CustomerResponse>();
    const arrayArea: Array<{ key: string; value: string }> = [];
    const [premisesListArea, setpremisesListArea] = useState(arrayArea);
    const [associtaedAssetList, setassocitaedAssetsList] = useState(arrayArea);
    const [caseDescriptionTxt, setCaseDescriptionTxt] = useState("");
    const [isActiveDescription, setIsActiveDescription] = useState(false);
    const [activeCase, setActiveCase] = useState<string[]>([]);
    const [assetIdentifiersAll, setassetIdentifiersAll] = useState("");
    const history = useHistory();

    let ownerName: any;

    const propertyId = useSelector(
        (state: any) => state.userChoicesReducer.userChosenPropertyId
    );

    let totalRecords: any = [];

    let assetBlockPropertyIds: any = [];
    let PropertyId = useSelector(
        (state: any) => state.userChoicesReducer.userChosenPropertyId
    );

    const assetType = useSelector(
        (state: any) => state?.caseCreationReducer?.caseAssetType
    );

    const casestatusSelectOptions = CaseStatusOptions.map((f) => ({
        key: f.key,
        value: f.value,
    }));

    const [caseListData, setCaseSearchData] = useState(arrayCases);

  enum CaseOwnerName {
    MyPeabodyHubComplaints = "MyPeabody Hub Complaints"
   }
 

   const mappedOwnerName = new Map();
    mappedOwnerName.set('Estate Services Enquiries', CaseOwnerMatrix["Estate Services Enquiries"])
        .set('Homeowner Sales Services', CaseOwnerMatrix["Homeowner Sales Services"])
        .set('NM', CaseOwnerMatrix["NM"])
        .set('Homeownership Team', CaseOwnerMatrix["Homeownership Team"])
        .set('Incomes officer', CaseOwnerMatrix["Incomes officer"])
        .set('Leasehold Compliance Team', CaseOwnerMatrix["Leasehold Compliance Team"])
        .set('Leasehold Team', CaseOwnerMatrix["Leasehold Team"])
        .set('New Homes (Development)', CaseOwnerMatrix["New Homes (Development)"])
        .set('Private Rent Team', CaseOwnerMatrix["Private Rent Team"])
        .set('Rehousing Services', CaseOwnerMatrix["Rehousing Services"])
        .set('Rehousing and Lettings', CaseOwnerMatrix["Rehousing and Lettings"])
        .set('Resale And Staircasing Team', CaseOwnerMatrix["Resale And Staircasing Team"])
        .set('SAR Team', CaseOwnerMatrix["SAR Team"])
        .set('Specialist Case Management', CaseOwnerMatrix["Specialist Case Management"])
        .set('Lettings Team', CaseOwnerMatrix["Lettings Team"])
        .set('Property Accounts', CaseOwnerMatrix["Property Accounts"])
        .set('New Homes Team', CaseOwnerMatrix["New Homes Team"])
        .set('Business Support', CaseOwnerMatrix["Business Support"])
        .set('Allocations and Lettings', CaseOwnerMatrix["Allocations and Lettings"])
        .set('Property Accounts Team', CaseOwnerMatrix["Property Accounts Team"]);


    let associtaedAssets: any[] = [];
    useEffect(() => {
        dispatch(showMenuTabs(true));
        (async () => {
            try {
                setIsLoading(true);
                const customerResponse = await api.getCustomer(customerID);
                setCustomerResponse(customerResponse);

                const activeTenanciesSummaries =
                    customerResponse.tenanciesSummaries.filter(
                        (ts) =>
                            ts.tenancySummary.statuses.find(
                                (s) => s.type === "isTenancyActive"
                            )?.value === true
                    );
                const activeTenanciesPremisesIds: Tenancies[] = activeTenanciesSummaries
                    .map((ts: any) => ts.tenancySummary.premisesIds)
                    .flat();
                const promises = activeTenanciesPremisesIds.map((item: any) => {
                    return api.getAssociatedAssets(item);
                });
                Promise.all(promises).then((results) => {
                    //Filter results as per premisesid
                    activeTenanciesPremisesIds?.map(async (assetid: any) => {
                        results.forEach((element) => {
                            const resultF = element.filter(
                                (addressdata) => addressdata.id === assetid
                            );

                            if (resultF !== null) associtaedAssets.push(resultF);
                        });
                    });
                    const associtaedAssetsList: AssocitaedAssetsType[] = [];

                    associtaedAssets?.map((addressdata: any) => {
                        addressdata?.map((address: any) => {
                            const associtaedAssetsTypeEntry: AssocitaedAssetsType =
                                new AssocitaedAssetsType();

                            associtaedAssetsTypeEntry.key = address?.id;
                            associtaedAssetsTypeEntry.value =
                                address?.addresses?.postalAddresses[0]?.formattedAddress;
                            associtaedAssetsList.push(associtaedAssetsTypeEntry);
                        });
                    });
                    if (associtaedAssetsList?.length > 1) {
                        setArea(true);
                    } else {
                        setArea(false);
                    }
                    if (PropertyId != "") {
                        setdefaultassocitaedAsset(PropertyId);
                        setassocitaedAsset(PropertyId);
                    } else {
                        PropertyId = associtaedAssetsList[0].key;
                        setdefaultassocitaedAsset(associtaedAssetsList[0].key);
                        setassocitaedAsset(associtaedAssetsList[0].key);
                    }

                    setassocitaedAssetsList(associtaedAssetsList);
                });

                const premisesList: RelatedPremisesType[] = [];
                const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
                let propertiesIds: string[] = [];
                let addressIds: string[] = [];
                premisesEntry.premisesType = PremisesType.Default;
                premisesEntry.premisesTypeId = PremisesType.Default;
                premisesList.push(premisesEntry);
                const addressesData = await api.getAssociatedAssets(PropertyId);

                addressesData.forEach((element) => {
                    const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
                    premisesEntry.premisesType = element.assetType as PremisesType;
                    premisesEntry.premisesTypeId = element.id;
                    premisesList.push(premisesEntry);
                    propertiesIds.push(element.id);
                });
                const areaOptions = premisesList.map((f) => ({
                    key: f.premisesType,
                    value: f.premisesTypeId,
                }));

                setpremisesListArea(areaOptions);

                let tenancyStartDate =
                    activeTenanciesSummaries[0]?.tenancySummary.tenancyStartDate || "";

                areaOptions.forEach((element) => {
                    if (element.key === "Property" || element.key === "Block") {
                        assetBlockPropertyIds.push(element.value);
                    }
                });
                let assetIDsIntTxt = "assetIdentifiers=";
                let assetIDsRemTxt = assetBlockPropertyIds.join("&assetIdentifiers=");
                let assetIDsAll = assetIDsIntTxt.concat(assetIDsRemTxt);
                setassetIdentifiersAll(assetIDsAll);

                const casequeryParams = {
                    searchText: "",
                    orderBy: "createdDate desc",
                    status: "",
                    limit: 10,
                    skip: 0,
                    defaultassocitaedAsset: assetIDsAll,
                    customerId: customerID,
                };

                const casesResponse = await api.getCasesList(casequeryParams);
                setCaseSearchData(casesResponse);
                settotalCount(casesResponse["total-count"]);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                history.push("/genericerror");
            }
        })();
    }, [propertyId]);

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setIsLoading(true);
        setresultsPerPage((e.target as any).value);
        setskip(0);
        setcurrentPage(0);
        fetchCasesData(
            (e.target as any).value,
            defaultSortOrder,
            defaultCaseStatus,
            0,
            searchTerm,
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID,
            false
        );
    };

    const fetchData = () => {
        setresultsPerPage(50);
        fetchCasesData(
            totalCount,
            defaultSortOrder,
            defaultCaseStatus,
            skip,
            searchTerm,
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID,
            true
        );
    };

    const handleSubmit = (s: string): void => {
        setresultsPerPage(10);
        setskip(0);
        setcurrentPage(0);
        if (s !== "") {
            setIsLoading(true);
        }
        setsearchTerm(s);

        fetchCasesData(
            resultsPerPage,
            defaultSortOrder,
            defaultCaseStatus,
            0,
            s,
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID
        );
    };
    const handleResetSubmit = (s: string): void => {
        setcurrentPage(0);
        setsearchTerm("");
        fetchCasesData(
            resultsPerPage,
            defaultSortOrder,
            defaultCaseStatus,
            skip,
            "",
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID
        );
    };

    const handleChangeSortOrder = (e: { target: { value: any } }) => {
        setdefaultSortOrder(e.target.value);
        setIsLoading(true);
        fetchCasesData(
            resultsPerPage,
            e.target.value,
            defaultCaseStatus,
            skip,
            searchTerm,
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID
        );
    };
    const handleChangeAssocitae = (e: { target: { value: any } }) => {
        setselectedArea("All");
        handleReset();
        setdefaultassocitaedAsset(e.target.value);
        dispatch(userChosenPropertyId(e.target.value));
        fetchCasesData(
            resultsPerPage,
            defaultSortOrder,
            defaultCaseStatus,
            skip,
            searchTerm,
            priority,
            selectedArea,
            e.target.value,
            customerID
        );
    };
    const handleChangeRepairStatus = (e: { target: { value: any } }) => {
        setdefaultCaseStatus(e.target.value);
    };

    const caseDetailsHandler = (el: any) => {

        if (activeCase.indexOf(el.identifiers.caseIdentifier) >= 0) {
            var filteredArray = activeCase.filter(e => e !== el.identifiers.caseIdentifier);
            setActiveCase(filteredArray);
            setIsActiveDescription(false);
        } else {
            activeCase.push(el.identifiers.caseIdentifier);
            setIsActiveDescription(true);
        }
        setCaseDescriptionTxt(el.description);

        const details = viewCaseDetail(el.identifiers.caseIdentifier, el.description)
        const historyData = CreateCasesHistoryData(el, details)

        dispatch(repairHistoryData(historyData));
        dispatch(showMenuTabs(false));
        props.history.push("/casedetails");
    };

    const CreateCasesHistoryData = (e: any, details: any) => {
        const ownerName = mappedOwnerName.get(e.assignee?.trim())      
        return {
            caseRefNo: e.caseReferenceNumber,
            caseStatus: e.status,
            caseOwner: ownerName,
            dateCreated: isNullOrUndefined(e.createdDate)
                ? "-"
                : formatDate(e.createdDate),
                requsetType :e.caseTypeDescription,
            caseDetailsData: details
        };
    };



    const handleReset = () => {
        setIsLoading(true);
        setresultsPerPage(10);
        setdefaultSortOrder("createdDate desc");
        setdefaultCaseStatus("All");
        setskip(0);
        setcurrentPage(0);
        setsearchTerm("");
        setselectedArea("All");
        setdefaultassocitaedAsset(associtaedAsset);
        fetchCasesData(
            10,
            "createdDate desc",
            "All",
            0,
            "",
            "",
            "All",
            associtaedAsset,
            customerID
        );
    };
    const handleFilterSubmit = () => {
        setIsLoading(true);
        setresultsPerPage(10);
        setskip(0);
        setcurrentPage(0);
        fetchCasesData(
            resultsPerPage,
            defaultSortOrder,
            defaultCaseStatus,
            0,
            searchTerm,
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID
        );
        setShow(false);
    };
    const handleChangePage = (page: number) => {
        setIsLoading(true);
        setcurrentPage(page);
        setskip(resultsPerPage * page);
        fetchCasesData(
            resultsPerPage,
            defaultSortOrder,
            defaultCaseStatus,
            resultsPerPage * page,
            searchTerm,
            priority,
            selectedArea,
            defaultassocitaedAsset,
            customerID
        );
    };

    const fetchCasesData = async (
        resultsPerPage: number,
        defaultSortOrder: string,
        defaultCaseStatus: string,
        skip: number,
        searchTerm: string,
        priority: string,
        selectedArea: string,
        defaultassocitaedAsset: string,
        customerId: string,
        isMobileView: boolean = false
    ) => {
        let status = "";
        const propertiesIds: string[] = [];
        if (defaultCaseStatus == "Active") {
            status = CaseStatusField.Active;
        } else if (defaultCaseStatus == "Cancelled") {
            status = CaseStatusField.Cancelled;
        } else if (defaultCaseStatus == "All") {
            status = "";
        } else {
            const CaseStatusEnum = defaultCaseStatus as CaseStatusField;
            status = CaseStatusEnum;
        }
        try {
            const casequeryParams = {
                searchText: searchTerm,
                orderBy: defaultSortOrder,
                limit: resultsPerPage,
                skip: skip,
                status,
                defaultassocitaedAsset: assetIdentifiersAll,
                customerId,
            };

            const caseList = await api.getCasesList(casequeryParams);
            settotalCount(caseList["total-count"]);

            if (isMobileView && skip != 1000) {
                totalRecords = caseList;
            }
            if (isMobileView && skip == 1000) {
                const totalCases = totalRecords.cases.concat(caseList.cases);
                caseList.cases = totalCases;
                setCaseSearchData(caseList);
            } else {
                setCaseSearchData(caseList);
            }

            if (isMobileView && caseList?.cases?.length < caseList["total-count"]) {
                setIsMoreThanThousand(true)
                //fetch next data again
                fetchCasesData(
                    totalCount,
                    defaultSortOrder,
                    defaultCaseStatus,
                    1000,
                    searchTerm,
                    priority,
                    selectedArea,
                    defaultassocitaedAsset,
                    customerID,
                    true
                );
            } else {
                setHasMoreCases(false);
            }


            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            history.push("/genericerror");
        }
    };
    const backButtonHandler = () => {
        history.push("/customer360View");
    };


    const getStatusClassName = (status) => {
        if (status === "Resolved") {
            return "status-green";
        } else if (status === "Cancelled") {
            return "status-red";
        } else {
            return "status-yellow";
        }
    };


    const displayCaseList = useCallback(
        (caseListData) => {
            return caseListData.cases.map((el: any) => (
                <>
                    <tr data-testid="btn_details" onClick={() => caseDetailsHandler(el)} onKeyDown={() => caseDetailsHandler(el)}>
                        <td>
                            {isNullOrUndefined(el.caseReferenceNumber)
                                ? " "
                                : el.caseReferenceNumber}
                        </td>
                        <td>
                            {el.caseTypeDescription}
                        </td>
                        <td>
                            {mappedOwnerName.get(el.assignee?.trim())}
                            {isNullOrUndefined(el.assignee?.trim()) ? "-" : ownerName?.trim()}
                        </td>
                        <td>
                            {isNullOrUndefined(el.createdDate)
                                ? "-"
                                : formatDate(el.createdDate)}
                        </td>
                        <td>
                            {el.status}
                        </td>
                    </tr>
                </>
            ));
        },
        [caseDescriptionTxt, activeCase]
    );

    const displayCaseListMobView = useCallback(
        (caseListData) => {
            return caseListData.cases.map((el: any, key: any) => (
                <div id={key} className={getStatusClassName(el.status)}>
                    <div className="row">
                        <div className="col-8 pe-0">
                            <h5>
                                {isNullOrUndefined(el.caseReferenceNumber) ? " " : el.caseReferenceNumber}
                            </h5>
                        </div>
                        <div className="col pe-0 text-end pt-1">
                            {el.status}
                        </div>
                    </div>
                    <div>
                        <h6>{t("Case_Request_Type")}</h6>
                        <p>{el.caseTypeDescription}</p>

                        <h6>{t("Case_created_date")}</h6>
                        <p>{isNullOrUndefined(el.createdDate)
                            ? "-"
                            : formatDate(el.createdDate)}
                        </p>
                        <h6>{t("Case_Owner")}</h6>
                        <p>{mappedOwnerName.get(el.assignee)}
                            {isNullOrUndefined(el.assignee) ? "-" : ownerName}
                        </p>
                    </div>
                    <button data-testid={"btn_view" + key} onClick={() => caseDetailsHandler(el)} className="secondary-ghost-btn mt-24 w-100" id={"Repair_Id_button_" + el.id} >{t("View_details")}</button>
                </div>

            ));
        },
        [caseDescriptionTxt, activeCase]
    );

    const handleChange = (s: string): void => {
        const re = /^(?!-)(?!.*--)[\s A-Za-z0-9 .,_@+!()?'=&-]*$/;
        // if value is not blank, then test the regex
        if (s === "" || re.test(s)) {
            setsearchTerm(s);
        } else {
            setsearchTerm(s);
        }
    };

    const getCaseDescriptionDetails = (caseReferenceNumber: string, caseDescription: string) => {
        let caseDesItems = [""];
        if (caseReferenceNumber != undefined) {
            let caseDesCollection = caseDescription;
            if (caseDesCollection != undefined && caseDesCollection != null) {
                caseDesItems = caseDesCollection
                    .split(/\r?\n/)
                    .filter((element) => element);
            }
        }
        return caseDesItems;
    }

    const viewCaseDetail = (caseReferenceNumber: string, caseDescription: string) => {
        let caseDescriptionDetails = [""];
        if (activeCase.includes(caseReferenceNumber)) {
            caseDescriptionDetails = getCaseDescriptionDetails(caseReferenceNumber, caseDescription);
        }
        return caseDescriptionDetails
    }


    return (
        <>
            <WebPagination>
                <div className="container-fluid p-0" role="main">
                    {!isLoading ? (
                        <div className="padding-repair position-relative">
                            <h1 className="rephistory-heading">{t("Case_Search_Requests_CC020a")}</h1>
                            <div className="row">
                                {Area ? (
                                    <div className="col-md-6 pe-0 history-dd">
                                        <select
                                            data-testid="cases-search-address1"
                                            value={defaultassocitaedAsset}
                                            id="cases-search-address1"
                                            onChange={handleChangeAssocitae}
                                            className="text-select repair-dd"
                                        >
                                            {associtaedAssetList.map((e, key) => {
                                                return (
                                                    <option key={key} value={e.key}>
                                                        {e.value}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                ) : (
                                    <div
                                        className="col-md-6 pe-0 history-dd"
                                        data-testid="cases-search-address1"
                                        id="cases-search-address1"
                                    >
                                        {associtaedAssetList.map((e, key) => {
                                            return (
                                                <div className="text-tenancy tenancy-padding">
                                                    {e.value}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <div className="row search-field">
                                    <div className="col-md-6 pe-0">
                                        <div id="custom-search-input">
                                            <div id="searchId">
                                                <label id="searchLabel" className="w-100">
                                                    <Search
                                                        defaultValue={searchTerm}
                                                        data-testid="cases-search"
                                                        onSubmit={handleSubmit}
                                                        placeholder="Search"
                                                        onClear={handleResetSubmit}
                                                        onChange={handleChange}
                                                        className="search-input"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-1">
                                        <select
                                            data-testid="cases-search-sort1"
                                            value={defaultSortOrder}
                                            id="cases-search-sort1"
                                            onChange={handleChangeSortOrder}
                                            title="Sort Cases"
                                            aria-label="Sort Cases"
                                            className="sorting-dd"
                                        >
                                            {staticSortableFields.map((e, key) => {
                                                return (
                                                    <option key={key} value={e.sortableKey}>
                                                        {e.displayName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <button
                                            data-testid="cases-search-Filter"
                                            onClick={handleShow}
                                            className="filter-dd cursor-pointer"
                                        >
                                            {t("Filter")}
                                            <img src={filtericon} alt="Filter" className="repair-filtericon" />
                                        </button>
                                    </div>
                                    <div className="col-3">
                                        <button
                                            onClick={handleReset}
                                            data-testid="cases-search-Reset"
                                            className="filter-dd clear-filter cursor-pointer"
                                        >
                                            {t("Clear_filters")}
                                        </button>
                                    </div>
                                </div>
                                {/* < -- case details start web view --> */}
                                <div className="row history-table">
                                    {!caseListData?.cases?.length ? (
                                        <div>
                                            {t("Case_CC021_no_results")}
                                        </div>
                                    ) : (

                                        <div className="col-md-12">
                                            <div className="history-result">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">{t("Case_Ref_No")}</th>
                                                            <th scope="col" className="req-type">{t("Case_Request_Type")}</th>
                                                            <th scope="col" className="owner-case">{t("Case_Owner")}</th>
                                                            <th scope="col">{t("Case_created_date")}</th>
                                                            <th scope="col" className="status-case">{t("historic_repairs_status")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{
                                                        displayCaseList(caseListData)
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    )}
                                </div>

                                {/* < -- Side Filter view --> */}
                                <Offcanvas placement="end" show={show} onHide={handleClose}>
                                    <div id="mySidenav">
                                        <Offcanvas.Header>
                                            <h4>
                                                {t("Filter")}
                                                <span>
                                                    <img src={Clear} alt="Close" className="cursor-pointer" onClick={handleClose} onKeyDown={handleClose} />
                                                </span>
                                            </h4>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                            <h6 className="pt-30">{t("historic_repairs_status")}</h6>
                                            <div>
                                                <select
                                                    data-testid="cases-search-status1"
                                                    value={defaultCaseStatus}
                                                    id="cases-search-status1"
                                                    onChange={handleChangeRepairStatus}
                                                >
                                                    {casestatusSelectOptions.map((e, key) => {
                                                        return (
                                                            <option key={key} value={e.value}>
                                                                {e.key}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            <div className="pt-20 text-end">
                                                <button
                                                    onClick={handleFilterSubmit}
                                                    data-testid="cases-search-button"
                                                    className="primary-btn"
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </Offcanvas.Body>
                                    </div>
                                </Offcanvas>

                                {/* < -- Pagination view --> */}
                                <Paginationbox className="text-end">
                                    <label>
                                        <Pagination
                                            colSpan={7}
                                            count={totalCount}
                                            page={currentPage}
                                            rowsPerPage={resultsPerPage}
                                            rowsPerPageOptions={[10, 20, 50]}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </label>
                                </Paginationbox>
                            </div>
                        </div>
                    ) : (
                        <div className="div-loader">
                            <div className="SpinnerWrapper">
                                <LoadingSpinner />
                            </div>
                        </div>
                    )}
                </div>
            </WebPagination>
            {/* mobile */}
            <MobilePagination>
                <div
                    id="scrollableDiv"
                    data-testid="scrollableDiv"
                    className="mob-view-only padding-repair"
                    style={{
                        height: 800,
                        overflowY: "scroll",
                        overflow: "auto",
                        flexDirection: "column",
                    }}
                >
                    <InfiniteScroll
                        dataLength={caseListData?.cases?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={hasMoreCases}
                        loader={""}
                        scrollableTarget="scrollableDiv"
                    >
                        <div role="main">
                            {!isLoading ? (
                                <div>
                                    <p id="backmodal-p" className="d-none">
                                        <a onClick={backButtonHandler} onKeyDown={backButtonHandler}>
                                            {t("Back")}
                                        </a>
                                    </p>

                                    <h1 className="rephistory-heading">{t("Case_Search_Requests_CC020a")}</h1>

                                    <div>
                                        <div className="position-relative">
                                            {Area ? (
                                                <select
                                                    data-testid="cases-search-address"
                                                    value={defaultassocitaedAsset}
                                                    id="cases-search-address"
                                                    className="text-select repair-dd mt-24"
                                                    onChange={handleChangeAssocitae}
                                                >
                                                    {associtaedAssetList.map((e, key) => {
                                                        return (
                                                            <option key={key} value={e.key}>
                                                                {e.value}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            ) : (
                                                <div
                                                    data-testid="cases-search-address1"
                                                    id="cases-search-address1"
                                                >
                                                    {associtaedAssetList.map((e, key) => {
                                                        return (
                                                            <div className="text-tenancy pt-4">
                                                                {e.value}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>

                                        {/* ====== <!-- search dropdown and filter --> ==== */}
                                        {/*  =======  search input  ======= */}

                                        <div id="custom-search-input">
                                            <div id="searchId">
                                                <label id="searchLabel" className="w-100">
                                                    <Search
                                                        defaultValue={searchTerm}
                                                        onSubmit={handleSubmit}
                                                        placeholder="Search"
                                                        onClear={handleResetSubmit}
                                                        onChange={handleChange}
                                                        className="search-input"
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        {/* ======= Sort by dropdown ========= */}

                                        <div className="pb-24">
                                            <select
                                                data-testid="cases-search-sort"
                                                value={defaultSortOrder}
                                                id="cases-search-sort"
                                                onChange={handleChangeSortOrder}
                                                title="Sort Cases"
                                                aria-label="Sort Cases"
                                                className="sorting-dd"
                                            >
                                                {staticSortableFields.map((e, key) => {
                                                    return (
                                                        <option key={key} value={e.sortableKey}>
                                                            {e.displayName}
                                                        </option>
                                                    );
                                                })}
                                            </select>

                                            {/*  =======  Filter & Reset view  =======   */}
                                            <a
                                                data-testid="cases-search-Filter1"
                                                onClick={handleShow}
                                                onKeyDown={handleShow}
                                                className="mobile-filtericon"
                                            >
                                                <img src={filtericon} alt="Filter" className="cursor-pointer" />
                                            </a>
                                        </div>

                                        {/* < -- case details start mobile view --> */}

                                        {!caseListData?.cases?.length ? (
                                            <div>
                                                {t("Case_CC021_no_results")}
                                            </div>
                                        ) : (
                                            displayCaseListMobView(caseListData)
                                        )}

                                        {/* < -- Side Filter view --> */}

                                        <Offcanvas placement="end" show={show} onHide={handleClose}>
                                            <div id="mySidenav" className="filter-section">
                                                <Offcanvas.Header>
                                                    <h4>
                                                        {t("Filter")}
                                                        <span>
                                                            <img src={Clear} alt="Close" className="cursor-pointer" onClick={handleClose} onKeyDown={handleClose} />
                                                        </span>
                                                    </h4>
                                                </Offcanvas.Header>
                                                <Offcanvas.Body>
                                                    <h6 className="pt-30">{t("historic_repairs_status")}</h6>
                                                    <div>
                                                        <select
                                                            data-testid="cases-search-status"
                                                            value={defaultCaseStatus}
                                                            id="cases-search-status"
                                                            onChange={handleChangeRepairStatus}
                                                            className="mt-12 sorting-dd"
                                                        >
                                                            {casestatusSelectOptions.map((e, key) => {
                                                                return (
                                                                    <option key={key} value={e.value}>
                                                                        {e.key}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="pt-20 text-end">
                                                        <button
                                                            onClick={handleFilterSubmit}
                                                            data-testid="cases-search-button1"
                                                            className="primary-btn"
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                </Offcanvas.Body>
                                            </div>
                                        </Offcanvas>
                                    </div>
                                </div>
                            ) : (
                                <div className="div-loader">
                                    <div className="SpinnerWrapper">
                                        <LoadingSpinner />
                                    </div>
                                </div>
                            )}
                        </div>
                    </InfiniteScroll>
                </div>
            </MobilePagination>
        </>
    );
};

export default CaseSearch;
