import * as api from '../../../api/index'
import {
  USERCHOSEN_PROPERTY_ID,
  USERCHOSEN_REPORTER_ID,
  USERCHOSEN_PROPERTY_TYPE,
  USERCHOSEN_PROPERTY_ADDRESS,
  USERCHOSEN_REPAIR_LOCATION,
  USERCHOSEN_HIGH_LEVEL_DESCRIPTION,
  USERCHOSEN_SUPPLEMENTARY_QUESTIONS,
  USERCHOSEN_EMERGENCY_QUESTION,
  USERCHOSEN_DUPLICATE_QUESTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT,
  USERCHOSEN_VANDALISM_QUESTION,
  USERCHOSEN_COVID_QUESTION,
  USERCHOSEN_VULNERABILITY_QUESTION,
  USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION,
  USERCHOSEN_CONTACT_DETAILS,
  USERCHOSEN_UPRN,
  USERCHOSEN_SOR,
  USERCHOSEN_STATUS,
  USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION,
  USERCHOSEN_CONTACT_NAME,
  USERCHOSEN_CONTACT_PHONE,
  USERCHOSEN_RELATION_DETAILS,
  USERCHOSEN_REPAIR_LOCATION_ID,
  LOGIN_STATUS,
  USERCHOSEN_ELIGIBILITY_ERRORCODE,
  LOGIN_TIME,
  REFRESH_TOKEN,
  REFRESS_TOKEN_EXPIRE_TIME,
  ACCESS_TOKEN_EXPIRE_TIME,
  INITIAL_LOGIN_TIME,
  UPDATE_PREV_CONFIRMED_STATE,
  UPDATE_ROUTE_HISTORY,
  UPDATE_USER_CHOICES_WITH_NEW_CHANGE,
  Is_Edit_State,
  USERCHOSEN_BANLANCE_TYPE,
  USERCHOSEN_ACCOUNT_NUMBER,
  USERCHOSEN_BALANCE,
  USERCHOSEN_ACCOUNT_Type,
  USERCHOSEN_Other_BALANCE,
  USER_PROFILE_ETAG,
  USER_PROFILE_DATA,
  Allpay_Conformation_Message,
  USERCHOSEN_ERROR_CODE,
  USERCHOSEN_ISELIGIBLE,
  RESUME_DRAFT_REPAIR,
  USER_REPAIR_WORKORDER,
  USER_RESUME_DATA,
  USER_RESUME_DRAFT_DATA_CACHE,
  USERCHOSEN_ISREPORTER,
  USERCHOSEN_ISERROR,
  VIEW_STATEMENT_DATA,
  USERCHOSEN_ACCOUNT_Identifier,
  IS_CATALYST_REPAIR_DISABLED_AREA,
  CLEAR_ALL_STATE,
  USERCHOSEN_UPLOADED_MEDIA,
  MY_MESSAGE_DATA,
  USERCHOSEN_RESUME_REPAIR,
  LOAD_DUPLICATE_REPAIR_PAGE,
  USER_CREATED_NEW_REPAIR,
  UPLOADED_MEDIA_ITEMS,
  DELETE_MEDIA_ITEM,
  CLEAR_MEDIA_FILES,
  USERCHOSEN_DELETE_MEDIA,
  HAS_MEDIA_ATTACHED,
  FROM_MEDIA_PAGE,
  SELECTED_HISTORY_MEDIA,
  MEDIA_RESUME,
  ENERGY_ADVICE_MAX_COUNT,
  CASES_USERCHOSEN_PROPERTY_ID,
  ASSET_REGION,
  ASSET_IDENTIFIERS,
  IS_CATALYST_CUSTOMER,
  IS_WORKORDER_TOGGLE,
  IS_EDIT_PROFILE_TOGGLE,
  TAB_SELECTED_VALUE,
  SHOW_MENU_TAB,
  NAV_HEADER_TEXT,
  BLOCK_IDENTIFIER,
  USER_SELECTED_MESSAGE_DETAILS,
  USER_SELECTED_MESSAGE_SUBJECT,
  USER_SELECTED_MESSAGE_DATE,
  USER_SELECTED_MESSAGE_SENDER,
  USER_SELECTED_MESSAGE_NOTIFICATION_TYPE,
  USER_SELECTED_MESSAGE_DOCUMENT_IDENTIFIER,
  SINGLE_TENANCY,
  SHOW_FOOTER,
  NAVIGATED_FROM,
  USER_SELECTED_MESSAGE_STATUS,
  IS_CASE_CATALYST_TOGGLE,
  BOROUGH_NAME,
  USER_SELECTED_TENANCY_GROUP,
  IS_ON_HOLD_REPAIR,
  DEMOBILIZED_CONTRACTOR_SITES,
  IS_MYPB_REPAIRS_MAINTENANCE_VALUE
} from "../actionTypes";

export const clearAllState = (payload:any)=> ({
  type: CLEAR_ALL_STATE,
  payload,
})

export const catalystEditProfileToggleValue = (payload: any) => ({
  type: IS_EDIT_PROFILE_TOGGLE,
  payload,
});

export const catalystWorkOrderToggleValue = (payload: any) => ({
  type: IS_WORKORDER_TOGGLE,
  payload,
});

export const validateCatalystCustomer = (payload: any) => ({
  type: IS_CATALYST_CUSTOMER,
  payload,
});

export const validateCaseCatalystToggle = (payload: any) => ({
  type: IS_CASE_CATALYST_TOGGLE,
  payload,
});

export const validateCatalystDisabledArea = (payload: any) => ({
  type: IS_CATALYST_REPAIR_DISABLED_AREA,
  payload,
});

export const userChosenPropertyId = (payload: any) => ({
  type: USERCHOSEN_PROPERTY_ID,
  payload,
});

export const userChosenPropertyType = (payload: any) => ({
  type: USERCHOSEN_PROPERTY_TYPE,
  payload,
});

export const userChosenPropertyAddress = (payload: any) => ({
  type: USERCHOSEN_PROPERTY_ADDRESS,
  payload,
});

export const userChosenRepairLocation = (payload: any) => ({
  type: USERCHOSEN_REPAIR_LOCATION,
  payload,
});

export const userChosenHighLevelDescription = (payload: any) => ({
  type: USERCHOSEN_HIGH_LEVEL_DESCRIPTION,
  payload,
});

export const userChosenSupplementaryQuestions = (payload: any) => ({
  type: USERCHOSEN_SUPPLEMENTARY_QUESTIONS,
  payload,
});

export const userChosenEmergencyQuestion = (payload: any) => ({
  type: USERCHOSEN_EMERGENCY_QUESTION,
  payload,
});

export const userChosenDuplicateQuestion = (payload: any) => ({
  type: USERCHOSEN_DUPLICATE_QUESTION,
  payload,
});

export const userChosenAdditionalDescription = (payload: any) => ({
  type: USERCHOSEN_ADDITIONAL_DESCRIPTION,
  payload,
});

export const userChosenAdditionalDescriptionCOUNT = (payload: any) => ({
  type: USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT,
  payload,
});

export const userChosenVandalismQuestion = (payload: any) => ({
  type: USERCHOSEN_VANDALISM_QUESTION,
  payload,
});
export const userChosenVandalismAdditionalInformation = (payload: any) => ({
  type: USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION,
  payload,
});
export const userChosenCovidQuestion = (payload: any) => ({
  type: USERCHOSEN_COVID_QUESTION,
  payload,
});

export const userChosenVulnerabilityQuestion = (payload: any) => ({
  type: USERCHOSEN_VULNERABILITY_QUESTION,
  payload,
});

export const userChosenVulnerabilityAdditionalInformation = (payload: any) => ({
  type: USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION,
  payload,
});

export const userChosenReporterId = (payload: any) => ({
  type: USERCHOSEN_REPORTER_ID,
  payload,
});

export const userChosenContactDetails = (payload: any) => ({
  type: USERCHOSEN_CONTACT_DETAILS,
  payload,
});

export const userChosenContactName = (payload: any) => ({
  type: USERCHOSEN_CONTACT_NAME,
  payload,
});

export const userChosenContactPhone = (payload: any) => ({
  type: USERCHOSEN_CONTACT_PHONE,
  payload,
});

export const userChosenRelationDetails = (payload: any) => ({
  type: USERCHOSEN_RELATION_DETAILS,
  payload,
});

export const userChosenUPRN = (payload: any) => ({
  type: USERCHOSEN_UPRN,
  payload,
});

export const userChosenSOR = (payload: any) => ({
  type: USERCHOSEN_SOR,
  payload,
});

export const userChosenSTATUS = (payload: any) => ({
  type: USERCHOSEN_STATUS,
  payload,
});

export const userChosenEligibilityErrorCode = (payload: any) => ({
  type: USERCHOSEN_ELIGIBILITY_ERRORCODE,
  payload,
});

export const userChosenRepairLocationId = (payload: any) => ({
  type: USERCHOSEN_REPAIR_LOCATION_ID,
  payload,
});
export const loginTime = (payload: any) => ({
  type: LOGIN_TIME,
  payload,
});
export const initialLoginTime = (payload: any) => ({
  type: INITIAL_LOGIN_TIME,
  payload,
});
export const refershToken = (payload: any) => ({
  type: REFRESH_TOKEN,
  payload,
});
export const loginStatus = (payload: any) => ({
  type: LOGIN_STATUS,
  payload,
});

export const accessTokenExpirationTime = (payload: any) => ({
  type: ACCESS_TOKEN_EXPIRE_TIME,
  payload,
});
export const refreshTokenExpirationTime = (payload: any) => ({
  type: REFRESS_TOKEN_EXPIRE_TIME,
  payload,
});

export const updatePrevConfirmedState = (payload: any) => ({
  type: UPDATE_PREV_CONFIRMED_STATE,
  payload,
});

export const isEditState = (payload: any) => ({
  type: Is_Edit_State,
  payload,
});

export const updateRouteHistory = (payload: any) => ({
  type: UPDATE_ROUTE_HISTORY,
  payload,
});

export const resetUserChoicesWithNewChange = (payload: any) => ({
  type: UPDATE_USER_CHOICES_WITH_NEW_CHANGE,
  payload,
});

export const userChosenBalanceType = (payload: any) => ({
  type: USERCHOSEN_BANLANCE_TYPE,
  payload,
});
export const userChosenAccountNumber = (payload: any) => ({
  type: USERCHOSEN_ACCOUNT_NUMBER,
  payload,
});
export const userChosenAccountType = (payload: any) => ({
  type: USERCHOSEN_ACCOUNT_Type,
  payload,
});
export const userChosenBalance = (payload: any) => ({
  type: USERCHOSEN_BALANCE,
  payload,
});
export const userChosenOtherBalance = (payload: any) => ({
  type: USERCHOSEN_Other_BALANCE,
  payload,
});
export const userProfileETag = (payload: any) => ({
  type: USER_PROFILE_ETAG,
  payload,
});
export const userProfileData = (payload: any) => ({
  type: USER_PROFILE_DATA,
  payload,
});
export const AllpayConformationMessage = (payload: any) => ({
  type: Allpay_Conformation_Message,
  payload,
});
export const userChosenErrorCode = (payload: any) => ({
  type: USERCHOSEN_ERROR_CODE,
  payload,
});
export const userChosenIseligible = (payload: any) => ({
  type: USERCHOSEN_ISELIGIBLE,
  payload,
});
export const resumeDraftRepair = (payload: any) => ({
  type: RESUME_DRAFT_REPAIR,
  payload,
});
export const userRepairWorkOrder = (payload: any) => ({
  type: USER_REPAIR_WORKORDER,
  payload,
});
export const userResumeData = (payload: any) => ({
  type: USER_RESUME_DATA,
  payload,
});
export const userResumeDraftDataCache = (payload: any) => ({
  type: USER_RESUME_DRAFT_DATA_CACHE,
  payload,
});
export const userChosenIsReporter = (payload: any) => ({
  type: USERCHOSEN_ISREPORTER,
  payload,
});
export const userChosenIsError = (payload: any) => ({
  type: USERCHOSEN_ISERROR,
  payload,
});

export const userChosenResumeRepair = (payload: any) => ({
  type: USERCHOSEN_RESUME_REPAIR,
  payload,
});
export const loadDuplicateRepairPage = (payload: any) => ({
  type: LOAD_DUPLICATE_REPAIR_PAGE,
  payload,
});
export const userCreatedNewRepair = (payload: any) => ({
  type: USER_CREATED_NEW_REPAIR,
  payload,
});

export const viewStatementData = (payload: any) => ({
  type: VIEW_STATEMENT_DATA,
  payload,
});
export const userChosenAccountIdentifier = (payload: any) => ({
  type: USERCHOSEN_ACCOUNT_Identifier,
  payload,
});
export const userChosenUploadedMedia = (payload: any) => ({
  type: USERCHOSEN_UPLOADED_MEDIA,
  payload,
});
export const myMessageData = (payload: any) => ({
  type: MY_MESSAGE_DATA,
  payload,
});

export const getAllAssetIdentifiers = (payload: any) => ({
  type: ASSET_IDENTIFIERS,
  payload
});

export const storeMediaFilesId = (payload: any) => ({
  type: UPLOADED_MEDIA_ITEMS,
  payload,
});

export const deleteMediaItem = (payload: any) => ({
  type: DELETE_MEDIA_ITEM,
  payload,
});

export const clearMediaFiles = (payload: any) => ({
  type: CLEAR_MEDIA_FILES,
  payload,
});

export const isMediaResume = (payload: any) => ({
  type: MEDIA_RESUME,
  payload,
});

export const userChosenDeletedMedia = (payload: any) => ({
  type: USERCHOSEN_DELETE_MEDIA,
  payload,
});

export const selectedWorkorderForMedia = (payload: any) => ({
  type: SELECTED_HISTORY_MEDIA,
  payload,
});

export const fromMediaUpload = (payload: any) => ({
  type: FROM_MEDIA_PAGE,
  payload,
});
export const hasMediaAttached = (payload: any) => ({
  type: HAS_MEDIA_ATTACHED,
  payload,
});
export const casesUserChosenPropertyId = (payload: any) => ({
  type: CASES_USERCHOSEN_PROPERTY_ID,
  payload,
});
export const assetRegion = (payload: any) => ({
  type: ASSET_REGION,
  payload,
});

export const boroughName = (payload: any) => ({
  type: BOROUGH_NAME,
  payload,
});

export const selectedTabValue = (payload: any) => ({
  type: TAB_SELECTED_VALUE,
  payload,
});

export const showMenuTabs = (payload: any) => ({
  type: SHOW_MENU_TAB,
  payload,
});

export const showFooter = (payload: any) => ({
  type: SHOW_FOOTER,
  payload
})

export const singleTenancy = (payload: any) => ({
  type: SINGLE_TENANCY,
  payload,
});

export const setNavHeaderText = (payload: any) => ({
  type: NAV_HEADER_TEXT,
  payload
});

export const getBlockIdentifier = (payload:any) => ({
  type: BLOCK_IDENTIFIER,
  payload
});

export const setUserSelectedMessageDetails = (payload:any) => ({
  type: USER_SELECTED_MESSAGE_DETAILS,
  payload
});

export const setUserSelectedMessageSubject = (payload:any) => ({
  type: USER_SELECTED_MESSAGE_SUBJECT,
  payload
});

export const setUserSelectedMessageDate = (payload:any) => ({
  type: USER_SELECTED_MESSAGE_DATE,
  payload
});

export const setUserSelectedMessageSender = (payload:any) => ({
  type:USER_SELECTED_MESSAGE_SENDER,
  payload
});

export const setUserSelectedMessageNotificationType = (payload:any) => ({
  type:USER_SELECTED_MESSAGE_NOTIFICATION_TYPE,
  payload
});

export const setUserSelectedMessageDocumentIdentifier = (payload:any) => ({
  type: USER_SELECTED_MESSAGE_DOCUMENT_IDENTIFIER,
  payload
});

export const setUserSelectedMessageStatus = (payload:any) => ({
  type: USER_SELECTED_MESSAGE_STATUS,
  payload
});


export const userNavigatedFrom = (payload:any) => ({
  type: NAVIGATED_FROM,
  payload
});

export const isOnHoldRepair = (payload: boolean) => ({
  type: IS_ON_HOLD_REPAIR,
  payload
});
export const demobilizedContractorSites = (payload: any): any => {
  return { type: DEMOBILIZED_CONTRACTOR_SITES, payload };
};

export const selectedTenancyGroup = (payload:any) => ({
  type: USER_SELECTED_TENANCY_GROUP,
  payload
});

export const isMyPbRepairsMaintainence = (payload: any): any => {
  return { type: IS_MYPB_REPAIRS_MAINTENANCE_VALUE, payload };
};




export const requestCustomStatement = (customerID: string, requestData: object, eTag: string, otherData: any) => {
  return (dispatch: any) => {
    (async () => {
      await api.postRequestCustomStatement(customerID, requestData, eTag)
        .then((response) => {
          otherData.setYellowAlertMessage('');
          otherData.setPurpleAlertMessage('SSC010');

          setTimeout(() => {
            otherData.setFromDate('');
            otherData.setToDate('');
            otherData.setRadioValue('');
            otherData.setOtherEmailVal('');
            otherData.setShowOtherEmail(false);
            otherData.setYellowAlertMessage('');
            otherData.setPurpleAlertMessage('');
            otherData.setIsOpen(false);
          }, 5000);
        })
        .catch(e => {
          otherData.setPurpleAlertMessage('');
          otherData.setYellowAlertMessage('VS_Failure_To_Retrieve_Statement');
          setTimeout(() => {
            otherData.setYellowAlertMessage('');
          }, 4000);
        })
    })()
  }
};


export const fetchChosenStatement = (data: any) => {
  return () => {
    (async () => {
      data.setYellowAlertMessage('');
      data.setIsLoading(true);
      let documentPDFTitle = `Statement ${data.MonthYear}`;
      const documentResponse = await api.getDocumentByIdentifier(data.customerID, data.documentID);
      if (!documentResponse.data) {
        data.setIsLoading(false);
        data.setYellowAlertMessage(`VS_Failure_To_Retrieve_Statement`);
        return
      } else {
        data.setYellowAlertMessage('');
        data.setIsLoading(false);
        const file = new Blob([documentResponse.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const newWindow: any = window.open(fileURL, "_blank");

        if (newWindow?.document) {
          setTimeout(() => newWindow.document.title = documentPDFTitle, 1000);
        }
      }
    })()
  }
}
