export const CaseStatusOptions = [
    {
      key: "All",
      value: "All",
    },
    {
      key: "Active",
      value: "Active",
    },
    {
      key: "Cancelled",
      value: "Cancelled",
    },
    {
      key: "Resolved",
      value: "Resolved",
    },    
  ];
  export default CaseStatusOptions;
  