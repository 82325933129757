import { AnyAction } from "redux";

import {
    CASE_CREATION_MASTER_DATA,
    CASE_CREATION_ID,
    CASE_CREATION_ADDITINALINFO,
    CASE_CREATION_DESCRIPTION,
    CASE_SELECTED_ANSWERS,
    CASE_CREATION_QUESTIONS,
    CLEAR_CASE_STATE,
    CASE_QUESTION_ANSWERS_DETAILS,
    CASE_DESCRIPTION_DATA_INFO,
    CASE_PROPERTY_TENANCY_GROUP,
    CASE_IDENTIFIER,
    CASE_SUBTYPE,
    CASE_SUBTYPE_CATEGORY,
    CASE_OWNING_TEAM,
    CASE_EDIT,
    CASE_ELIGIBILITY_RESPONSE,
    CASE_ADDITIONAL_CASE_FIELD,
    CASE_CATEGORY,
    CASE_COUNTER,
    CASE_ASSET_TYPE,
    CASE_OWNER,
    CASE_ASSET_IDENTIFIER,
    CASE_SELECTED_TENANCY,
} from "../actionTypes";

const initialState = {
    caseIdentifier: "",
    caseTenancyGroup: "",
    caseSelectedTenancyAddress: [],
    caseCreationMasterData: [],
    caseCreationId: "",
    caseCreationDescription: "",
    caseCreationAdditionalInfo: [],
    caseSelectedAnswers: [],
    caseAdditionalFields: [],
    caseSelectedQuestions: [],
    caseDescriptionDataInfo: [],
    caseQuestionAnswerDetails: {
        caseTypeId: "",
        identifiers: {
            assetIdentifier: "",
            customerIdentifier: "",
            tenancyIdentifier: "",
        },
        additionalInfo: [
            {
                questionId: "",
                responseId: "",
                responseText: "",
            },
        ],
    },
    caseSubType: "",
    caseSubTypeCategory: "",
    caseOwningTeam: "",
    isCaseEdit: false,
    caseCategory: "",
    counter: 0,
    caseAssetType: "",
    caseAssetIdentifier: "",
};

export const caseCreationReducer = (
    state: any = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case CASE_SELECTED_TENANCY:
            return {
                ...state,
                caseSelectedTenancyAddress: action.payload,
            };
        case CASE_ASSET_IDENTIFIER:
            return {
                ...state,
                caseAssetIdentifier: action.payload,
            };
        case CASE_ASSET_TYPE:
            return {
                ...state,
                caseAssetType: action.payload,
            };
        case CASE_COUNTER:
            return {
                ...state,
                counter: action.payload,
            };

        case CASE_CATEGORY:
            return {
                ...state,
                caseCategory: action.payload,
            };
        case CASE_ADDITIONAL_CASE_FIELD:
            return {
                ...state,
                caseAdditionalFields: action.payload,
            };
        case CASE_EDIT:
            return {
                ...state,
                isCaseEdit: action.payload || false,
            };
        case CASE_OWNING_TEAM:
            return {
                ...state,
                caseOwningTeam: action.payload,
            };
        case CASE_SUBTYPE:
            return {
                ...state,
                caseSubType: action.payload,
            };
        case CASE_SUBTYPE_CATEGORY:
            return {
                ...state,
                caseSubTypeCategory: action.payload,
            };
        case CASE_PROPERTY_TENANCY_GROUP:
            return {
                ...state,
                caseTenancyGroup: action.payload,
            };
        case CASE_IDENTIFIER:
            return {
                ...state,
                caseIdentifier: action.payload,
            };
        case CASE_CREATION_MASTER_DATA:
            return {
                ...state,
                caseCreationMasterData: action.payload,
            };
        case CASE_DESCRIPTION_DATA_INFO:
            return {
                ...state,
                caseDescriptionDataInfo: action.payload,
            };
        case CASE_CREATION_ID:
            return {
                ...state,
                caseCreationId: action.payload,
            };
        case CASE_CREATION_DESCRIPTION:
            return {
                ...state,
                caseCreationDescription: action.payload,
            };
        case CASE_CREATION_ADDITINALINFO:
            return {
                ...state,
                caseCreationAdditionalInfo: action.payload,
            };
        case CASE_CREATION_QUESTIONS:
            return {
                ...state,
                caseSelectedQuestions: action.payload,
            };
        case CASE_SELECTED_ANSWERS:
            return {
                ...state,
                caseSelectedAnswers: action.payload,
            };
        case CLEAR_CASE_STATE:
            return {
                ...state,
                caseCreationMasterData: [],
                caseCreationId: "",
                caseCreationDescription: "",
                caseCreationAdditionalInfo: [],
                caseSelectedAnswers: [],
                caseSelectedQuestions: [],
                caseDescriptionDataInfo: [],
                caseOwningTeam: "",
                caseSubType: "",
                caseSubTypeCategory: "",
                isCaseEdit: false,
                caseCategory: "",
                caseAdditionalFields: [],
                counter: 0,
            };
        case CASE_QUESTION_ANSWERS_DETAILS:
            return {
                ...state,
                caseQuestionAnswerDetails: {
                    ...state.caseQuestionAnswerDetails, // Need to save question answer details
                },
            };
        case CASE_ELIGIBILITY_RESPONSE:
            return {
                ...state,
                caseEligibilityResponseData: action.payload,
            };
        case CASE_OWNER:
            return {
                ...state,
                caseOwnerName: action.payload,
            };
        default:
            return state;
    }
};

export default caseCreationReducer;
