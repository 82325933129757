export const isUndefinedNullOrEmpty = (value: string): boolean => {
    if (hasScriptTag(value)) {
      return true;
    }
  
    if (typeof value === 'undefined' || value === null) {
      return true;
    } else if (value === '') {
      return true;
    } else if (!value) {
      return true;
    }
    return false;
  };

  export const hasScriptTag = (value: string): boolean => {
    if (hasTag(value)) {
      return true;
    }
    const html = document.createElement('html');
    html.innerHTML = value?.toString()?.toLowerCase();
    return Boolean(html.getElementsByTagName('script').length);
  };

  const hasTag = (value: any): boolean => {
    return (
      (value && value?.toString()?.includes('<')) ||
      value?.toString()?.includes('>') ||
      value?.toString()?.includes(';') ||
      value?.toString()?.includes('&#60;') ||
      value?.toString()?.toLowerCase()?.includes('&#x3c;') ||
      value?.toString()?.toLowerCase()?.includes('&lt;') ||
      value?.toString()?.toLowerCase()?.includes('u+00c') ||
      value?.toString()?.toLowerCase()?.includes('0x3c') ||
      value?.toString()?.toLowerCase()?.includes('0x003c') ||
      value?.toString()?.toLowerCase()?.includes('script') ||
      value?.toString()?.toLowerCase()?.includes('alert')
    );
  };