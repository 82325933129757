import { combineReducers } from "redux";
import addressReducer from "./addressReducer";
import { dashboardReducer } from "./dashboardReducer";
import userChoices from "./userChoices";
import historicRepairReducer from "./historicRepairReducer";
import caseCreationReducer from "./caseCreationReducer";
import { FinancialReferralReducer } from "./FinancialReferral";

const rootReducer = combineReducers({
  addresses: addressReducer,
  dashboardReducer: dashboardReducer,
  userChoicesReducer: userChoices,
  historicRepairReducer: historicRepairReducer,
  financialReferralReducer: FinancialReferralReducer,
  caseCreationReducer: caseCreationReducer,
});

export default rootReducer;
