import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDateToSlash } from '../../../utils/formatting';
import React, { useEffect, useState } from 'react';
import { selectedTabValue, setUserSelectedMessageDate, setUserSelectedMessageDetails, setUserSelectedMessageDocumentIdentifier, setUserSelectedMessageNotificationType, setUserSelectedMessageSender, setUserSelectedMessageStatus, setUserSelectedMessageSubject } from '../../../ducks/redux/actions/userChoices';
import { useDispatch } from 'react-redux';

interface MyMessageCardRowProps extends RouteComponentProps { eachMsgData: any, msgArrayNum: number }

const MyMessageCardRow: React.FC<MyMessageCardRowProps> = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isCasesMsgNotification, setisCasesMsgNotification] = useState(true);
    enum CommunicationCategoryType {
        CaseCreation = "CaseCreation",
    }


    const { title, givenName, surname } = props.eachMsgData?.message?.sender?.organisation?.personName || ({} as any);

    const titleVal = title ? title : '';
    const givenNameVal = givenName ? givenName : '';
    const surnameVal = surname ? surname : '';
    const messageSender = titleVal + " " + givenNameVal + " " + surnameVal;

    const checkCasesMsgNotification = (communicationCategoryType: any) => {
        if (communicationCategoryType === CommunicationCategoryType.CaseCreation) {
          setisCasesMsgNotification(false);
        }
    };
    
    useEffect(()=>{
        checkCasesMsgNotification(props.eachMsgData.communicationCategoryType);
    }, [props.eachMsgData])

    const callMyMessageHistory = (message: any) => {
        dispatch(selectedTabValue("mymessageshistory"))
        console.log("props.eachMsgData",props.eachMsgData);
        dispatch(setUserSelectedMessageDetails(props.eachMsgData.message.content[1].value));
        dispatch(setUserSelectedMessageSubject(props.eachMsgData.message.content[0].value));
        dispatch(setUserSelectedMessageDate(props.eachMsgData.createdDate));
        dispatch(setUserSelectedMessageSender(messageSender));
        dispatch(setUserSelectedMessageNotificationType(isCasesMsgNotification));
        dispatch(
          setUserSelectedMessageDocumentIdentifier(props.eachMsgData.message.content[2]?.value)
        );
        dispatch(setUserSelectedMessageStatus(props.eachMsgData.status));

        props.history.push("/messagedetails");
    };


    return (
        <div className='recent-msg '>
            <div onKeyDown={() => callMyMessageHistory(props.eachMsgData)} onClick={() => callMyMessageHistory(props.eachMsgData)}>
                <div>
                    <div>
                        <div>
                            <div className="d-flex justify-between">
                                {
                                    props.eachMsgData?.status === 'UNREAD' ? (
                                        <><h6 className={props.eachMsgData?.status === 'UNREAD' ? ` ` : 'fade-m'}>{`${titleVal} ${givenNameVal} ${surnameVal}`}</h6><h6 className={props.eachMsgData?.status === 'UNREAD' ? `` : 'fade-m'}>{formatDateToSlash(props.eachMsgData.createdDate)}</h6></>
                                    ) : (
                                        <><p className={props.eachMsgData?.status === 'UNREAD' ? ` ` : 'fade-m'}>{`${titleVal} ${givenNameVal} ${surnameVal}`}</p><p className={props.eachMsgData?.status === 'UNREAD' ? `` : 'fade-m'}>{formatDateToSlash(props.eachMsgData.createdDate)}</p></>
                                    )
                                }

                            </div>
                        </div>

                        <div className='pt-16'>
                            {
                                props.eachMsgData?.status === 'UNREAD' ? (
                                    <h6 className={props.eachMsgData?.status === 'UNREAD' ? ` ` : 'fade-m'}>{props.eachMsgData.message.content[0].value}</h6>

                                ) : (
                                    <p className={props.eachMsgData?.status === 'UNREAD' ? ` ` : 'fade-m'}>{props.eachMsgData.message.content[0].value}</p>
                                )
                            }

                        </div>
                        <div  data-testid="view-link" className={`link pt-16 cursor-pointer`} onClick={() => callMyMessageHistory(props.eachMsgData)} onKeyDown={() => callMyMessageHistory(props.eachMsgData)}>{t("View_Message")}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyMessageCardRow;