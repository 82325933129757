
export enum CaseOwnerMatrix {
    'New Homes (Development)' = 'New Homes Team',
    'NM' = 'Neighbourhood Manager',
    'Estate Services Enquiries' = 'Estate Services Team',
    'Specialist Case Management' = 'Specialist Case Management Team',
    'Lettings Team' = 'Lettings Team',
    'Leasehold Team' = 'Leasehold Team',
    'Private Rent Team' = 'Private Rent Team',
    'Homeownership Team' = 'Homeownership Team',
    'Leasehold Compliance Team' = 'Leasehold Compliance Team',
    'Resale And Staircasing Team' = 'Resale and Staircasing Team',
    'Homeowner Sales Services' = 'Homeownership Services Team',
    'Incomes officer' = 'Incomes officer',
    'Rehousing Services' = 'Rehousing Team',
    'Rehousing and Lettings' = 'Rehousing and Lettings Team',
    'SAR Team' = 'Subject Access Request Team',
    'Property Accounts' = 'Property Accounts',
    'New Homes Team' = 'New Homes Team',
    'Business Support'= 'Business Support',
    'Allocations and Lettings' = 'Allocations & Lettings Team',
    'Property Accounts Team' = 'Property Accounts',
}






