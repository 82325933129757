import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import * as api from "../../api";
import {
  saveAssociatedAssests,
  saveCustomerTenacies, 
  saveCustomerDetails,
  saveCustomerphoneNumber,
  getCustomerId,  
  selectedTenacyType,
} from "../../ducks/redux/actions/getAddress";

import Loader from "react-loader-spinner";
import {
  resetUserChoicesWithNewChange,
  updateRouteHistory,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  userChosenRepairLocationId,
  userChosenHighLevelDescription,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  singleTenancy,
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../storybook/Button";

import ExitModal from "../Modal/ExitModal";
import BackModal from "../Modal/BackModal";
import {
  userChosenPropertyAddress,  
} from "../../ducks/redux/actions/userChoices";

import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { defaultSearchArea, defaultSearchRepairStatus, defaultSearchSortByOrder, repairSearchTerm } from "../../ducks/redux/actions/historicRepair";
import HeaderJourney from "../../storybook/Header/HeaderJourney";

import Message from "../../storybook/Message";
interface RepairPropertyProps extends RouteComponentProps<any> { }

const RepairProperty: React.FC<RepairPropertyProps> = (props) => {
  const dispatch = useDispatch();

  //To track radio button selection for RepairProperty--Start here---
  const presentTrackSelection = useSelector(
    // (state: any) => state.dashboardReducer.formState.tracks[0]?.selected
    (state: any) => state.dashboardReducer.formState.customerID
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenPropertyId = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenPropertyId
  );
  //-----------Ends here-----------//
  const id = "btn_continue";
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const array: any[] = [];
   const [AllAsset, SetAllAssetData] = useState(array);
  
  const [isError, setError] = useState("none");
  const [buttondisable, setsetButtonDisable] = useState(false); 

  const radiovalue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyAddress
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);
 
   const [activeTenacyPremisesIds, setActiveTenacyPremisesIds] = useState<any>(
    []
  );
  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true)
        ClearReduxStore();
        let residentialProperies: {
          premisesid: string;
          tenancygroup: string;
          startdate: string | null;
        }[] = [{ premisesid: "", tenancygroup: "", startdate: "" }];

        let nonResidentialProperies: {
          premisesid: string;
          tenancygroup: string;
          startdate: string | null;
        }[] = [{ premisesid: "", tenancygroup: "", startdate: "" }];
        // set work order to null for create new work order
        const customerResponse = api.getCustomer(customerID);
        const tenancyResponse = api.getTenancies(customerID);
        Promise.all([customerResponse, tenancyResponse]).then(
          async (results) => {
            dispatch(
              saveCustomerphoneNumber(results[0].addresses.telecomsAddresses)
            );
            dispatch(getCustomerId(customerID));
            const tenancySummries = results[0].tenanciesSummaries;

            const activeTenanciesSummaries = tenancySummries.filter(
              (ts) =>
                ts.tenancySummary.statuses.find(
                  (s) => s.type === "isTenancyActive"
                )?.value === true
            );
            const activeTenanciesPremisesIds = activeTenanciesSummaries.map(
              (ts) => ts.tenancySummary.premisesIds
            );
            dispatch(saveCustomerTenacies(activeTenanciesSummaries));

            dispatch(saveCustomerDetails(results[0]));

            if (results[1]?.length > 0) {
              results[1].map((itemTenancy) => {
                activeTenanciesSummaries.map((itemActiveTrenancy) => {
                  if (
                    itemTenancy.tenancyIdentifier ==
                    itemActiveTrenancy.tenancySummary.tenancyId
                  ) {
                    const premiseid =
                      itemActiveTrenancy?.tenancySummary?.premisesIds[0];

                    const startdateValue =
                      itemActiveTrenancy?.tenancySummary.tenancyStartDate;

                    const groupTenancy = itemTenancy.tenancyGroup;

                    if (
                      itemTenancy.tenancyGroup == "Non-residential" ||
                      itemTenancy.tenancyGroup == "Commercial"
                    ) {
                      nonResidentialProperies.push({
                        premisesid: premiseid,
                        tenancygroup: groupTenancy,
                        startdate: startdateValue,
                      });
                    } else {
                      residentialProperies.push({
                        premisesid: premiseid,
                        tenancygroup: groupTenancy,
                        startdate: startdateValue,
                      });
                    }
                  }
                });
              });
            }
            const resAddAssestsArray: any = [];
            const promisesResidential = residentialProperies.map(
              async (itemdata) => {
                if (itemdata.premisesid !== "") {
                  const responseResidential: any =
                    await api.getAssociatedAssets(itemdata.premisesid);
                  resAddAssestsArray.push(responseResidential);

                  return (
                    responseResidential
                      .filter((data: any) => {
                        // push data in AddAssetArray
                        //resAddAssestsArray.push(data);
                        return data.id === itemdata.premisesid;
                      })
                      // @ts-ignore
                      .map((eData: any) => {
                        return {
                          ...itemdata,
                          residential: true,
                          formattedAddress:
                            eData.addresses.postalAddresses[0].formattedAddress,
                        };
                      })
                  );
                }
              }
            );
            const promisesNonResidential = nonResidentialProperies.map(
              async (itemdata) => {
                if (itemdata.premisesid !== "") {
                  const responseNonResidential: any =
                    await api.getAssociatedAssets(itemdata.premisesid);
                  resAddAssestsArray.push(responseNonResidential);
                  // push data in AddAssetArray
                  return (
                    responseNonResidential
                      .filter((data: any) => {
                        // resAddAssestsArray.push(data);
                        return data.id === itemdata.premisesid;
                      })
                      // @ts-ignore
                      .map((eData: any) => {
                        return {
                          ...itemdata,
                          residential: false,
                          formattedAddress:
                            eData.addresses.postalAddresses[0].formattedAddress,
                        };
                      })
                  );
                }
              }
            );

            SetAllAssetData(resAddAssestsArray);

            const parsedData = await Promise.all([
              ...promisesResidential,
              ...promisesNonResidential,
            ]);

            const resData = parsedData
              .filter((e: any) => (e && e[0] ? true : false))
              .flat()
              .sort(function (a: any, b: any) {
                const dateA: any = Date.parse(a.startdate);
                const dateB: any = Date.parse(b.startdate);
                return dateB - dateA;
              });

            setActiveTenacyPremisesIds(resData);
           

            const residentialBool = resData.filter((e: any) => {
              return e.residential;
            });

            if (activeTenacyPremisesIds?.length === 1) {
              dispatch(saveAssociatedAssests(results));
            }

            const chosenAdd = resData.filter((eachData: any) => {
              return eachData.premisesid === userChosenPropertyId
            })

            const defaultData = chosenAdd[0];

            if (!isUndefinedNullOrEmpty(radiovalue)) {
              const selectedtData = resData.filter((data: any) => {
                return data.formattedAddress === radiovalue;
              });
              callSelection(
                selectedtData[0]?.formattedAddress,
                selectedtData[0]?.premisesid,
                resAddAssestsArray
              );
            } else {
              callSelection(
                defaultData?.formattedAddress,
                defaultData?.premisesid,
                resAddAssestsArray
              );
            }
            //redirect to next page if single tenancy
            if (resData.length === 1) {
              dispatch(singleTenancy(true))
              setIsLoading(false)
              props.history.push("/repairasset");
            }
            else {
              dispatch(singleTenancy(false))
              setIsLoading(false)
            }
          }
        );
        setError("none");
        setsetButtonDisable(false);

      } catch (e) {
        setError("block");
        setsetButtonDisable(true);
        console.log("catch", e);
      }
    })();
  }, []);

 
  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const callPageHandler = () => {
    props.history.push("/repairasset");
  };

  const updateSelection = (
    value: string,
    premiseId: any,
    arr: any = AllAsset
  ) => {
    if (EditState === true) {
      dispatch(userChosenRepairLocationId(0));
      dispatch(userChosenHighLevelDescription(""));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
      dispatch(loadDuplicateRepairPage(false));
      dispatch(userCreatedNewRepair(null));
    }
    let associtaedAssets: any[] = [];
    arr.forEach((element: any) => {
      const resultF = element.filter((data: any) => data.id === premiseId);
      if (resultF !== null && resultF.length > 0) {
        associtaedAssets.push(element);

        dispatch(saveAssociatedAssests(associtaedAssets));
      }
    });
    dispatch(userChosenPropertyAddress(value));
  };
  const callSelection = (
    value: string,
    premiseId: any,
    arr: any = AllAsset
  ) => {
    let associtaedAssets: any[] = [];
    arr.forEach((element: any) => {
      const resultF = element.filter((data: any) => data.id === premiseId);
      if (resultF !== null && resultF.length > 0) {
        associtaedAssets.push(element);

        dispatch(saveAssociatedAssests(associtaedAssets));
      }
    });
    dispatch(userChosenPropertyAddress(value));
  };
  const ClearReduxStore = () => {
    dispatch(selectedTenacyType(""));
    dispatch(repairSearchTerm(""));
    dispatch(defaultSearchRepairStatus(""));
    dispatch(defaultSearchArea(""));
    dispatch(defaultSearchSortByOrder(""));
  }
  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer repair-request p-0" id="repair-container">
        <div className="padding-repair">
          <h1
            id="repair-asset-h1"
            className="header-size"
          >
            {t("repair_property_label")}
          </h1>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={50}
              width={50}
              timeout={5000} />
          ) : (
            <div
              className="pt-44"
              id="repair-main-div"
            >
              <RadioGroup
                id="repair-radiogroup"
                name="radio-buttons-group"
                value={radiovalue}
              >
                {activeTenacyPremisesIds
                  .filter((e: any) => {
                    return e.residential;
                  })
                  .map((e: any) => {
                    return (
                      <FormControlLabel
                        value={e.formattedAddress}
                        key={e.premisesid}
                        control={<Radio
                          key={e.premisesid}
                          id={"radio-id1"}
                          name={e.formattedAddress}
                          onChange={(
                            v: React.ChangeEvent<HTMLInputElement>
                          ): void => {
                            updateSelection(v.target.value, e.premisesid);
                          }} />}
                        label={e.formattedAddress} />
                    );
                  })}
                {activeTenacyPremisesIds
                  .filter((e: any) => {
                    return !e.residential;
                  })
                  .map((e: any) => {
                    return (
                      <FormControlLabel
                        value={e.formattedAddress}
                        key={e.premisesid}
                        control={<Radio
                          key={e.premisesid}
                          id="radio-id"
                          name={e.formattedAddress}
                          onChange={(
                            v: React.ChangeEvent<HTMLInputElement>
                          ): void => {
                            updateSelection(v.target.value, e.premisesid);
                          }} />}
                        label={e.formattedAddress} />
                    );
                  })}
              </RadioGroup>
            </div>
          )}
        </div>
        <div
          style={{ display: isError }}
        >
          <Message className="error-msg">
            {t("SSR001")}
          </Message>
        </div>
        <div className="col-md-12 marginTop-auto">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0">
             
              <ExitModal
                id="repairproperty-exitmodal"
                exit={exitButtonHandler} />
            </div>
            <div className="col text-end padding-repair">
              <Button
                onClick={callPageHandler}
                disabled={buttondisable}
                id="btn_repairproperty"
                className="primary-btn"
              >
                {t("continuen_button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairProperty;
