import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Button/Button";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { clearReferralInformation, comingFromReferralPage, financialReferralFor } from "../../../ducks/redux/actions/FinancialReferral";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import ExitModal from "../../Modal/ExitModal";
import { showFooter, showMenuTabs } from "../../../ducks/redux/actions/userChoices";
import Message from "../../../storybook/Message";


interface FinancialInclusion extends RouteComponentProps<any> {
  
}

const EnquiryConsent: React.FC<FinancialInclusion> = (props:any) => {
  const RadioOption1 = [{"option":"Myself","id":"Myself"}, {"option":"Other member of household","id":"Other"} ]  
  
  const dispatch = useDispatch()
  const [buttondisable] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)

  const [referralFor, setReferralFor] = useState(data?.referralFor || "");
  
  useEffect(()=>{
    window.scrollTo(0, 0);
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
  },[])
 


  const exitButtonHandler = () => {
    dispatch(clearReferralInformation({}))
    props.history.push('/Customer360View');
  }
  
  const callPageHandler = () => {
    if(!referralFor){
      setError("Financial_FI017a")
      
    }else {
      dispatch(comingFromReferralPage(true))
      props.history.push('/enquiryreason')
    }
  }

  const handleRadioChange = (e:any) => {
      setError("")
      setReferralFor(e.target.value)
      dispatch(financialReferralFor(e.target.value))
  }

  
  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer p-0" id="back-modal-container">
    <div className="padding-repair">
        {/* Main Page Content */}
        <div className="sm:mx-1 px-2">
            <div className="h5" id="repreason-div1">
              <h1 className="header-size pb-30">
                 {t("Financial_FI001d")} 
              </h1>
            </div> 
        </div>
        {/* Yellow Alert */}
        {  error && <Message className="warning-msg"> {t(error)}</Message>}
        <RadioGroup
            data-testid="radioGroup"
            className="question-btn mb-4 px-2"
            value={referralFor}
            onChange={handleRadioChange}
          >
            {RadioOption1?.map((data:any)=>{
            return(<FormControlLabel
                id={data.id}
                key={data.id}
                value={data.option}
                data-testid="formControl"
                className="mb-1 global-txt card-text margin-lt"
                
                control={<Radio id={`covidQues-radio1`} placeholder="radioLabel"
                name={data.id} />}
                label={data.option}
            />)})}
          </RadioGroup>
         </div>    
        {/* Exit and Continue SMS button */}
        <div className="col-md-12 marginTop-auto">
        <div className="row m-0 footer-actionbtn case-footer">
          <div className="col btn-top padding-repair mb-0">
          <ExitModal
          id="repair-asset-exitmodal"
          exit={exitButtonHandler}
        />
          </div>
          <div className="col text-end padding-repair">
          <Button
          id="btn_repairasset"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="primary-btn"
        >
          {t("continuen_button")}{" "}
        </Button>
          </div>
        </div>
      </div>
       
    
  </div>
</>
)};
export default EnquiryConsent;