import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import "./index.scss"; 
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    CASE_SELECTED_ANSWERS,
    CLEAR_CASE_STATE,
  } from "../../ducks/redux/actionTypes";
import { CaseOwnerMatrix } from "../../models/Case/CaseOwnerMatrix";
import { capitalizeName, formatDateWithFullMonthWeekInLetters } from "../../utils/formatting";
import * as api from "../../api";
import { CatalistTriageContractorType } from '../../models/Repair/Contractor'
import Modal from '../Modal/index';


interface JourneySuccess extends RouteComponentProps<any> { }

const SuccessMessage: React.FC<JourneySuccess> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
const slotDate = useSelector( (state: any) => state?.financialReferralReducer?.selectedDateSlot)
const isComingFromCaseJourney = useSelector( (state: any) => state?.financialReferralReducer?.isCaseJourneyMessage)
const isComingFromRepairJourney = useSelector( (state: any) => state?.financialReferralReducer?.isRepairJourneyMessage);
const isTriageRepairMsg = useSelector( (state: any) => state?.financialReferralReducer?.isTriageRepair);
const [assigneeDetails, setAssigneeDetails] = useState("");
const [triageMessageValue, setTriageMessageValue] = useState("");
const [isDefectTriageRepair, setIsDefectTriageRepair] = useState(false);
const [isTier2TriageRepair, setIsTier2TriageRepair] = useState(false);
const [isEmaTriageRepair, setIsEmaTriageRepair] = useState(false);
const [popup, setPopup] = useState(true)
const [emergencyRepairMsg, setEmergencyRepairMsg] = useState("");
const [normalAppointment, setNormalAppointment] = useState(false)
const [emergencyOOH, setEmergencyOOH] = useState("");

  const caseOwnerName = useSelector(
    (state: any) => state.caseCreationReducer?.caseOwnerName
  );
  
  const isPreferenceAppointment = useSelector(
    (state: any) => state.addresses?.preferenceno
  );

  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const caseTypeDescription = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  let assetIdentifier = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );
  
  const _userChosenDuplicateQuestion =  useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );

  const _userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenVulnerabilityQuestion
  );

  const heatinghotwatercase = useSelector(
    (state: any) => state?.addresses?.heatinghotwatercase
  );

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );

  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );

  const determinedcontractordetails = useSelector(
    (state: any) => state.addresses?.determinedcontractordetails
  );
  const emergencyRepair = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion || ""
  );

  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );

  const contractor = useSelector(
    (state: any) => state.addresses?.contractorname ?? state.historicRepairReducer?.repairHistoryData?.RepairHistoryContractor
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );
  const isOnHoldRepair = useSelector(
    (state: any) => state.userChoicesReducer?.isOnHoldRepair
  );

enum AssigneeName {
    NeighbourhoodManager = "Your Neighbourhood Manager, ",
    IncomesOfficer = "Your Incomes officer, ",
    NeighbourhoodManagerTeam = "Your Neighbourhood Manager Team"
  };

  enum CaseOwnerName {
    NM = "NM",
    IncomesOfficer = "Incomes officer"
  }

  enum OwnerTeamTitle {
    Title = "The "
  }

  const mappedOwnerName = new Map();
  mappedOwnerName.set('Estate Services Enquiries',CaseOwnerMatrix["Estate Services Enquiries"])
    .set('Homeowner Sales Services', CaseOwnerMatrix["Homeowner Sales Services"])
    .set('NM', CaseOwnerMatrix["NM"])
    .set('Homeownership Team', CaseOwnerMatrix["Homeownership Team"])
    .set('Incomes officer', CaseOwnerMatrix["Incomes officer"])
    .set('Leasehold Compliance Team', CaseOwnerMatrix["Leasehold Compliance Team"])
    .set('Leasehold Team', CaseOwnerMatrix["Leasehold Team"])
    .set('New Homes (Development)', CaseOwnerMatrix["New Homes (Development)"])
    .set('Private Rent Team', CaseOwnerMatrix["Private Rent Team"])
    .set('Rehousing Services', CaseOwnerMatrix["Rehousing Services"])
    .set('Rehousing and Lettings', CaseOwnerMatrix["Rehousing and Lettings"])
    .set('Resale And Staircasing Team', CaseOwnerMatrix["Resale And Staircasing Team"])
    .set('SAR Team', CaseOwnerMatrix["SAR Team"])
    .set('Specialist Case Management', CaseOwnerMatrix["Specialist Case Management"])
    .set('Lettings Team', CaseOwnerMatrix["Lettings Team"])
    .set('Business Support', CaseOwnerMatrix["Business Support"])
    .set('Property Accounts', CaseOwnerMatrix["Property Accounts"])
    .set('New Homes Team', CaseOwnerMatrix["New Homes Team"])
    .set('Business Support', CaseOwnerMatrix["Business Support"])
    .set('Allocations and Lettings', CaseOwnerMatrix["Allocations and Lettings"])
    .set('Property Accounts Team', CaseOwnerMatrix["Property Accounts Team"]);

  let messageTriage = t("");
  
  const setTriageMessages = () => {
      if (isCatalyst) {
        const contractorDetail = determinedcontractordetails?.[0];
        let siteIdentifier = contractorDetail?.contractorSites;
        
        if (eligibilityResponseObject?.statuses?.isInDefects) {
          setIsDefectTriageRepair(true);
          setTriageMessageValue("");
        }
        else if (siteIdentifier && siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_TIER2) {
          setIsTier2TriageRepair(true);
          setTriageMessageValue("");
        }
        else if (contractorDetail?.statuses?.isEMA) {
          setIsEmaTriageRepair(true);
          setTriageMessageValue("");
        }
      }
     
  
      if (
        (_userChosenDuplicateQuestion != "" &&
          heatinghotwatercase != undefined &&
          _userChosenVulnerabilityQuestion == "Yes") ||
        (_userChosenDuplicateQuestion != "" &&
          heatinghotwatercase != undefined) ||
        (_userChosenDuplicateQuestion != "" &&
          _userChosenVulnerabilityQuestion == "Yes") ||
        (heatinghotwatercase != undefined &&
          _userChosenVulnerabilityQuestion == "Yes")
      ) {
        messageTriage = t("SSR050");
        setTriageMessageValue(messageTriage);
        setIsDefectTriageRepair(false);
        setIsTier2TriageRepair(false);
        setIsEmaTriageRepair(false);
      } //all combinations
      else if (_userChosenDuplicateQuestion != "") {
        messageTriage = t("SSR042");
        setTriageMessageValue(messageTriage);
        setIsDefectTriageRepair(false);
        setIsTier2TriageRepair(false);
        setIsEmaTriageRepair(false);
      } //duplicate
      else if (heatinghotwatercase != undefined) {
        messageTriage = t("SSR046");
        setTriageMessageValue(messageTriage);
        setIsDefectTriageRepair(false);
        setIsTier2TriageRepair(false);
        setIsEmaTriageRepair(false);
      } //Vuln
      else if (_userChosenVulnerabilityQuestion == "Yes") {
        messageTriage = t("SSR040");
        setTriageMessageValue(messageTriage);
        setIsDefectTriageRepair(false);
        setIsTier2TriageRepair(false);
        setIsEmaTriageRepair(false);
      }
  };
    
  const fetchCaseAssetAssociates = async () => {
    try {
      const response = await api.getCaseAssetAssociate(assetIdentifier);
      if (caseOwnerName === CaseOwnerName.NM) {
          if (response?.associates?.neighbourhoodManager === null) {
            let Nmdetails = AssigneeName.NeighbourhoodManagerTeam;
            setAssigneeDetails(Nmdetails);
          } else {
            let NmFirstName = capitalizeName(response?.associates?.neighbourhoodManager?.person?.name?.givenName);
            let NmSecondName = response?.associates?.neighbourhoodManager?.person?.name?.surname;
            let Nmdetails = AssigneeName.NeighbourhoodManager + ' ' + NmFirstName + ' ' + NmSecondName;
            setAssigneeDetails(Nmdetails);
          }
        
      } else {
          let incomesOfficerFirstName = capitalizeName(response?.associates?.incomesOfficer?.person?.name?.givenName);
          let incomesOfficerSecondName = capitalizeName(response?.associates?.incomesOfficer?.person?.name?.surname);
          let incomesOfficerDetails = AssigneeName.IncomesOfficer + ' ' + incomesOfficerFirstName + ' ' + incomesOfficerSecondName;
          setAssigneeDetails(incomesOfficerDetails);
      }
    } catch (e: any) {
      props.history.push("/genericerror");
      dispatch({ type: CLEAR_CASE_STATE });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    } 

  };

const setAppointmentMessages = () => {
    if (contractor === 'Thamesmead Environmental Services') {
      setEmergencyRepairMsg(t("Emergency_SSR035"));
              setEmergencyOOH("repair history.");
     }
    
        if (selectedproperty === "Property") {
          if ((priority === "Emergency" || priority === "Emergency (OOH)") && emergencyRepair === "Yes, I can provide access") {
              setEmergencyRepairMsg(t("Emergency_OOH"));
              setEmergencyOOH("repair history.");
            } else if (emergencyRepair === "Yes, I can provide access") {
              setEmergencyRepairMsg(t("SSR033"));
            } else {
              setEmergencyRepairMsg(t("Emergency_SSR035"));
              setEmergencyOOH("repair history.");
            }
        } else 
        {
          setEmergencyRepairMsg(t("Emergency_Communal2"));
          setEmergencyOOH("repair history.");
        }
         
     };

useEffect(()=>{
    if(isComingFromCaseJourney){
        if (caseOwnerName === CaseOwnerName.NM || caseOwnerName === CaseOwnerName.IncomesOfficer) {
            fetchCaseAssetAssociates();
          } else {
            let teamDetails = OwnerTeamTitle.Title + mappedOwnerName.get(caseOwnerName);
            setAssigneeDetails(teamDetails);
          }
    }

    if(isComingFromRepairJourney && isTriageRepairMsg){
      setTriageMessages();
    }
    if(isComingFromRepairJourney && !isTriageRepairMsg){
      setAppointmentMessages();
    }
    
    if (isPreferenceAppointment) {
      setNormalAppointment(false);
    }else {
      setNormalAppointment(true);
    }
},[])



  
  return (
    <>
        { (isComingFromCaseJourney) &&
        (<div data-testid="alert-warning"
        className="successBg mb-4 py-2 mt-4">
            <div className='flex'>
                <div className="iconChecked">
                    <i className="fa fa-check-circle fa-lg" aria-hidden="true"></i>
                </div>
                {isComingFromCaseJourney && <div className="px-4">
                    {t("Case_final_msg")}  
                    <strong>{caseTypeDescription}</strong> {t("Case_final_msg1")}  <strong>{assigneeDetails}</strong>
                </div>}

            </div>
        </div>)
      }

        {/* Display duplicate/triage message on pop-up */}
        {
          (isComingFromRepairJourney && (isDefectTriageRepair || triageMessageValue || isTier2TriageRepair || isEmaTriageRepair)) && isTriageRepairMsg &&
         
          <Modal open={popup} cssClasses="modalPopUp">
            <div className="py-4 px-4">
              <div
                className="close"
                onClick={() => setPopup(false)}
                onKeyPress={() => setPopup(false)}
              >
               <h4>X</h4> 
              </div>
              {(isTier2TriageRepair || isEmaTriageRepair || isDefectTriageRepair)? <h3>{t("CatalystTriageThanksMessage")}</h3> : <h3>{t("thankyou_savedrepair_message")}</h3>}
              <p className='mt-4'>
                
                 {(isTier2TriageRepair || isEmaTriageRepair || isDefectTriageRepair) &&
                  (<>
                    <em>{t('CatalystDefectTrageMessage1')}</em>
                  
                  </>
                  )}
                {triageMessageValue && 
                  <em> {triageMessageValue}</em>
                }   
              </p>
            </div>
          </Modal>     

        }
      {/* Display On Hold message on pop-up */}
      {
        isComingFromRepairJourney && isOnHoldRepair &&
        <Modal open={popup} cssClasses="modalPopUp">
          <div className="py-4 px-4" style={{ textAlign: 'center' }}>
            <div
              className="close"
              onClick={() => setPopup(false)}
              onKeyPress={() => setPopup(false)}
            >
              <h4>X</h4>
            </div>
            {<h3>{t('CatalystTriageThanksMessage')}</h3>}
            <p className='mt-4'>
              {<><em>{t('onHoldPopUpMessage')}</em></>}
            </p>
          </div>
        </Modal>
      }
          {/* Display emergency message on green status bar */}
          {
          (isComingFromRepairJourney &&  emergencyRepair === "Yes, I can provide access" && !isTriageRepairMsg &&
            <div data-testid="alert-warning"
            className="successBg mb-4 py-2 mt-4">
            <div className='flex'>
                <div className="iconChecked">
                    <i className="fa fa-check-circle fa-lg" aria-hidden="true"></i>
                </div>
                <div className="px-4">
                    <p>
                    <h6>{t("Emergency_msg")}  {`${contractor}.`}</h6>
                    </p> 
                    <p  className='mt-2'>{emergencyRepairMsg} <Link to = "/historicRepairs">{emergencyOOH}</Link></p>
                </div>
                
              </div>
           </div>
          )}

        {/* Display Available/Preferred work order appoinment message */}
        {isComingFromRepairJourney && (isPreferenceAppointment || normalAppointment) && (!isDefectTriageRepair && !triageMessageValue && !isTier2TriageRepair && !isEmaTriageRepair) && !isTriageRepairMsg && emergencyRepair!=="Yes, I can provide access" &&
        (
          <div data-testid="alert-warning"
            className="successBg mb-4 py-2 mt-4">
            <div className='flex'>
                <div className="iconChecked">
                    <i className="fa fa-check-circle fa-lg" aria-hidden="true"></i>
                </div>
                <div className="px-4">
                {/* This is for normal appoinment */}
                {normalAppointment && <h6>{t("Normal_repair")} {`${contractor}.`}</h6>}
                {/* This is for preferred appoinment */}
                {isPreferenceAppointment && <h6>{t("Normal_repair")} {`${contractor}.`} {t("Preffered_repair")}</h6>}
                {slotDate? <p>{`Your reference is ${referenceNumberCode} for your appointment on ${slotDate && formatDateWithFullMonthWeekInLetters(new Date(slotDate?.date).toDateString())}, ${slotDate?.availabilityTypeLabel} (${slotDate?.start} to ${slotDate?.end}). Details about the repair is in the `}<Link to='/historicRepairs'>repair history.</Link></p>:  <p  className='mt-2'>{emergencyRepairMsg} <Link to = "/historicRepairs">{emergencyOOH}</Link></p>} 
                </div>
              </div>
           </div>
        )

        }

    </>  
  )
}

export default SuccessMessage