import { createTheme } from '@material-ui/core/styles'

// to be used only for margin and padding to keep layout spacing consistent
export const SMALL_SPACING = '1rem'
export const SPACING = '2rem'

export const FIELD_WIDTH = '248'
export const FIELD_WIDTH_LARGE_WIDE = '300'
export const FIELD_WIDTH_EXTRA_LARGE_WIDE = '450'

export const FONT_SIZE = {
  xsmall: '.7rem',
  small: '.8rem',
  regular: '.9rem',
  large: '1rem',
}

export const colors = {
  primaryColor: '#00A3AD',
  secondaryColor: '#efefef',
  primaryText: '#565656',
  secondaryText: '#efefef',
  tertiaryText: '#888888',
  headerText: '#171717',
  errorText: '#D9001B',
  primaryWarning: {
    light: 'rgb(254, 250, 242)',
    main: 'rgb(247, 202, 119)',
    dark: 'rgb(241, 149, 55)',
  },
  successAlert: {
    light: 'rgba(51, 186, 124, 0.095)',
    main: 'rgba(12, 160, 110, 1)',
    dark: 'rgba(12, 160, 110, 1)',
  },
  disabledButton: 'rgba(0, 0, 0, 0.38)',
}

const PeabodyTheme = createTheme({
  typography: {
    fontFamily: 'Poppings',
    h1: {
      fontFamily: 'Poppings-Bold',
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.4rem',
      color: colors.headerText,
      lineHeight: '.95',
      padding: 0,
      margin: 0,
    },
    h3: {
      fontSize: '1.2rem',
      color: colors.headerText,
      lineHeight: '.95',
      padding: 0,
      margin: 0,
    },
    body1: {
      fontSize: FONT_SIZE.regular,
    },
    subtitle1: {
      fontSize: FONT_SIZE.regular,
    },
  },
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor,
    },
    text: {
      primary: colors.primaryText,
      secondary: colors.secondaryText,
    },
    warning: colors.primaryWarning,
    success: colors.successAlert,
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: FONT_SIZE.regular,
      },
      multiline: {
        padding: '0px',
        lineHeight: '17px',
      },
    },
    MuiOutlinedInput: {
      multiline: {
        padding: '0px',
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: colors.primaryText,
        },
      },
    },
    MuiChip: {
      root: {
        margin: '0px 8px 14px 0px!important',
      },
    },
    MuiButton: {
      outlined: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      contained: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
        marginBottom: SPACING,
      },
    },
    MuiBreadcrumbs: {
      separator: {
        marginLeft: '0px',
        marginRight: '0px',
        color: colors.primaryText,
      },
    },
    MuiTabs: {
      flexContainer: {
        paddingTop: '25px',
        paddingBottom: '25px',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: FONT_SIZE.large,
      },
    },
    MuiExpansionPanel: {
      root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
      expanded: {},
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '15px',
        alignItems: 'center',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '700px',
      },
    },
  },
})

export default PeabodyTheme
