import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as api from "../../api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RequestStatement from "../RequestStatement";
import {
  MobilePagination,
  WebPagination,
} from "./components";
import MonthYearName from "../../models/MonthYearName/MonthYearName";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewStatementDetails from "../ViewStatementDetails";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import "./index.scss";
import Message from "../../storybook/Message";

interface ViewStatementProps extends RouteComponentProps<any> {
  Payref: string | null;
  accountType: string | null;
  accountnumber: string | null;
}
const ViewStatement: React.FC<ViewStatementProps> = ({
  Payref,
  accountType,
  accountnumber,
}) => {
  const { t } = useTranslation();
  const swtichOffAlert = React.useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const array: MonthYearName[] = [];
  const [monthYear, setmonthYear] = useState(array);
  const [totalCount, settotalCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [resultsPerPage, setresultsPerPage] = useState(6);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isRequestCustomStatement, setIsRequestCustomStatement] =
    React.useState("");
  const [availableStatements, setAvailableStatements] = useState<boolean>();

  const userChosenPropertyAddress = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenPropertyAddress
  );

  const viewRequestStatementData = useSelector(
    (state: any) => state.userChoicesReducer?.viewStatementData
  );
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  let allMonths: string[] = [];

  const tenancyStartDate = new Date(viewRequestStatementData?.tenancyStartDate);
  allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let currentDate = new Date();

  const twoYearsAgoFromNow = new Date();
  twoYearsAgoFromNow.setFullYear(twoYearsAgoFromNow.getFullYear() - 2);

  const data = {
    yearMonthFrom: `${twoYearsAgoFromNow.getFullYear()}/${String(
      twoYearsAgoFromNow.getMonth() + 1
    ).padStart(2, "0")}`,
    yearMonthTo: `${currentDate.getFullYear()}/${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}`,
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await api
        .GetAppConfigToggleValue("IsRequestCustomStatement")
        .then((response) => {
          setIsRequestCustomStatement(response?.value);
        })
        .catch((error) => {
          console.log("error", error);
        });

      await api
        .getDocumentsByCustomer(customerID, data)
        .then((response) => {
          if (response?.documents.length === 0) {
            setAvailableStatements(false);
            setIsLoading(false);
          } else {
            setAvailableStatements(true);
            if (currentDate.getUTCDate() < tenancyStartDate.getUTCDate()) {
              getMonthsAndYears(1, 7);
            } else {
              getMonthsAndYears(1, 24);
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    })();
  }, []);

  const getMonthsAndYears = (startLimit: number, endLimit: number) => {
    let filteredMonthYearNameList: MonthYearName[] = [];
    setIsLoading(true);
    const monthYearNameList: MonthYearName[] = [];
    const output =
      new Date().getMonth() -
      tenancyStartDate.getMonth() +
      12 * (new Date().getFullYear() - tenancyStartDate.getFullYear());
    if (output > 24) {
      settotalCount(24);
    } else {
      let todayDate = new Date().getDate();
      if (tenancyStartDate.getDate() > todayDate) {
        settotalCount(output - 1);
      } else {
        settotalCount(output);
      }
      if (output <= endLimit) {
        if (tenancyStartDate.getDate() > todayDate) {
          endLimit = output - 1;
        } else {
          endLimit = output;
        }
      }
    }

    for (var i = startLimit; i <= endLimit; i++) {
      let currentDate = new Date();
      const monthYearName: MonthYearName = new MonthYearName();
      let prevdate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - (i - 1),
        1
      );

      const thisMonthYear =
        allMonths[currentDate.getMonth()] + " " + currentDate.getFullYear();
      monthYearName.name =
        allMonths[prevdate.getMonth()] + " " + prevdate.getFullYear();
      monthYearNameList.push(monthYearName);
      if (currentDate.getUTCDate() < tenancyStartDate.getUTCDate()) {
        filteredMonthYearNameList = monthYearNameList.filter(
          (el) => el.name !== thisMonthYear
        );
      } else {
        filteredMonthYearNameList = monthYearNameList;
      }
    }
    setmonthYear(filteredMonthYearNameList);
    setIsLoading(false);
  };

  const fetchData = () => {
    setcurrentPage(0);
    setresultsPerPage(24);
    getMonthsAndYears(1, 24);
  };
  return (
    <>
      <WebPagination>
        {!isLoading ? (
          <>
            <h1>{t("Account_Statements")}</h1>
            <div>
              <div className="padding-repair container-fluid">
                <div className="px-4 lg:px-0">
                  <div className="w-full text-lg flex flex-col md:flex-row md:justify-between">
                    {isRequestCustomStatement === "true" && (
                      <div className="pr-4">
                        <div>
                          <a className="cursor-pointer no-underline hover:underline">
                            <RequestStatement
                              accountType={accountType}
                              accountnumber={accountnumber}
                              isDisabled={isDisabled}
                              customerAddress={
                                viewRequestStatementData?.getCustomerResponse
                                  ?.addresses?.digitalAddresses[0]?.address
                              }
                              tenancyStartDate={tenancyStartDate}
                              customerName={
                                viewRequestStatementData?.getCustomerResponse
                                  ?.person?.name
                              }
                              eTag={
                                viewRequestStatementData?.getCustomerResponse
                                  ?.eTag
                              }
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {!availableStatements ? (
                <div className="no-statement">
                  <Message className="info-msg">
                    {t("VS_No_Statement_Exit")}
                  </Message>
                </div>
              ) : (
                <div className="pb-12 ">
                  <div className="my-2">
                    <div className="flex d-flex flex-column">
                      {monthYear?.length ? (
                        <div>
                          <Message className="info-msg">
                            {t("VS_Initial_Notification")}
                          </Message>
                          <Message className="info-msg">
                            {t("VS_Initial_Notification2")}
                          </Message>
                        </div>
                      ) : (
                        <Message className="info-msg">
                          {t("VS_No_Statement_Found")}
                        </Message>
                      )}
                    </div>
                  </div>
                  <ViewStatementDetails
                    monthYear={monthYear}
                    swtichOffAlert={swtichOffAlert}
                    accountnumber={accountnumber}
                    payRef={Payref}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="div-loader">
            <div className="SpinnerWrapper">
              <LoadingSpinner />
            </div>
          </div>
        )}
      </WebPagination>
      <MobilePagination>
        <div
          id="scrollableDiv"
          data-testid="scrollableDiv"
          style={{
            height: "auto",
            overflowY: "scroll",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <InfiniteScroll
            dataLength={monthYear?.length}
            next={fetchData}
            style={{ display: "flex", flexDirection: "column" }}
            hasMore={true}
            loader={<h4></h4>}
            scrollableTarget="scrollableDiv"
          >
              <h1>{t("Account_Statements")}</h1>
              {isRequestCustomStatement === "true" && (
                <div className="pr-4">
                  <div>
                    <a className="text-teal cursor-pointer no-underline hover:underline">
                      <RequestStatement
                        accountType={accountType}
                        accountnumber={accountnumber}
                        isDisabled={isDisabled}
                        customerAddress={
                          viewRequestStatementData?.getCustomerResponse
                            ?.addresses?.digitalAddresses[0]?.address
                        }
                        tenancyStartDate={tenancyStartDate}
                        customerName={
                          viewRequestStatementData?.getCustomerResponse?.person
                            ?.name
                        }
                        eTag={
                          viewRequestStatementData?.getCustomerResponse?.eTag
                        }
                      />
                    </a>
                  </div>
                </div>
              )}

            {!availableStatements ? (
              <Message className="info-msg">
                {t("VS_No_Statement_Exit")}
              </Message>
            ) : (
              <div>
                {monthYear?.length ? (
                  <div>
                    <Message className="info-msg">
                      {t("VS_Initial_Notification")}
                    </Message>
                    <Message className="info-msg">
                      {t("VS_Initial_Notification2")}
                    </Message>
                  </div>
                ) : (
                  <Message>{t("VS_No_Statement_Found")}</Message>
                )}
                <ViewStatementDetails
                  monthYear={monthYear}
                  swtichOffAlert={swtichOffAlert}
                  accountnumber={accountnumber}
                  payRef={Payref}
                />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </MobilePagination>
    </>
  );
};

export default ViewStatement;
