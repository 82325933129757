export default class CaseData {
  public caseTypeId: number = 0;
  public caseTypeDescription: string = "";
  public assignee: string = "";
  public assigneeType: string = "";
  public identifiers: any = {};
  public contactDetails: any = {};
  public additionalInfo: additionalInfo[] = [];
  public asset: any = {};
}

export class asset {
  type: any = {};
}

export class type {
  public assetTypeId: number = 1;
  public assetTypeName: string = "";
}

export class Identifiers {
  public assetIdentifier: string = "";
  public customerIdentifier: string = "";
  public tenancyIdentifier: string = "";
}

export class contactDetails {
  public phoneNumber: number = 0;
  public name: string = "";
  public relationId: number = 0;
  public relation: string = "";
}

export class additionalInfo {
  public questionId: string = "";
  public responseId: string = "";
  public responseText: string = "";
}

export enum CaseReporterId {
  Customer = 1,
  "Friend or Family" = 2,
  Other = 3,
}

export class caseResponseData {
  public caseIdentifier: string = "";
}

export enum CaseStatusField {
  Active = "Active",
  Cancelled = "Cancelled",
  Resolved = "Resolved",
}
