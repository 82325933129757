import { RouteComponentProps } from "react-router-dom";
import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import { imgData } from "./StaticIconName";
import cancel from "../../assets/img/cancel.png";

interface UploadMediaRowProps extends RouteComponentProps {
  mediaFileName: string;
  bgColour: string;
  type: string;
  deleteMediaFile: () => void;
}

const UploadMediaRow: React.FC<UploadMediaRowProps> = (props) => {
  const mediaType = props?.type?.split("/");

  if (mediaType && mediaType[1] === "quicktime") {
    mediaType[1] = "mov";
  }

  if (mediaType && mediaType[1] === "x-matroska") {
    mediaType[1] = "mkv";
  }

  return (
    <>
      <ReactBootstrap.Table className="container-new file-upload">
        <TableBody id="confirmation-table">
            <TableRow id={"confirmation-tr1"} className="file-row">
              <TableCell id={"confirmation-tr1-td1"} className="file-name">
                {imgData.map((item: any, index: number) => {
                  if (
                    (mediaType && mediaType[1] === item.id) ||
                    (mediaType && mediaType[1] === item.id.toUpperCase())
                  ) {
                    return (
                      <div key={index} className="d-flex">
                        <span>
                          <img
                            src={item.src}
                            alt={`thumbnail icon${Math.floor(
                              Math.random() * 20
                            )}`}
                            className="file-img"
                          />
                        </span>
                        <p className="pl-8 text-break">
                          {props.mediaFileName}
                        </p>
                      </div>
                    );
                  }
                })}
              </TableCell>

              <TableCell id={"confirmation-tr1-td3"} className="file-name text-end">
                  <a
                    onClick={props.deleteMediaFile}
                    data-testid="Delete_Button"
                  >
                    <img src={cancel} className="cursor-pointer" alt="Remove" />
                  </a>
              </TableCell>
            </TableRow>
        </TableBody>
      </ReactBootstrap.Table>
    </>
  );
};

export default UploadMediaRow;
