export class Statuses {
    isCovidSelfIsolated?: boolean | null
    isDraft?: boolean
    isEmergency?: boolean
    isOutOfHours?: boolean
    isRiskToHumanLife?: boolean | null
    Status?: string
    isRecall?: boolean | null
    isEMA?: boolean
    isCommunal?: boolean 
    isPotentialDuplicate? : boolean
  }
  export class UpdateRepair {
    statuses?: Statuses | null
    asset?: Asset | null
  }

  export class Asset {
    type?: AssetType | null
  }

  export class AssetType {
    assetTypeId?: number
  }