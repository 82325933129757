import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as api from "../../../api";
import { formatDate } from "../../../utils/formatting";
import { RouteComponentProps } from "react-router-dom";
import Tenancies from "../../../models/Customer/Tenancies";
import { CustomerResponse, PremisesType } from "../../../models/Customer";
import { AssocitaedAssetsType, RelatedPremisesType } from "../../../models/Property/AdminUnits";
import Button from "../../../storybook/Button";
import { selectedTabValue, showMenuTabs, showFooter, userNavigatedFrom} from "../../../ducks/redux/actions/userChoices";
import { comingFromCasePage } from "../../../ducks/redux/actions/FinancialReferral";
const Loader = React.lazy(() => import("react-loader-spinner"));

interface CaseCardProps extends RouteComponentProps<any> {}

const CaseCard: React.FC<CaseCardProps> = (props) => {
  let status: any = {
    Active: 1,
    Resolved: 2,
    Cancelled: 3,
  };
  const caseData: any = [];
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [caseSearchData, setCaseSearchData] = useState(caseData);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const propertyId = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyId
  );

 
  const customerId = useSelector((state: any) => state.addresses?.customerid);

  const ViewCasesHandler = () => {
    history.push("/casesearch");
    dispatch(userNavigatedFrom(""));
    dispatch(selectedTabValue("requests"));   

  };

  const CreateCaseHandler = () => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    dispatch(userNavigatedFrom(""));
    dispatch(comingFromCasePage(true))
    history.push("/selectTenancy");
  };

  let PropertyId = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyId
  );

  let assetBlockPropertyIds: any = [];
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  let associtaedAssets: any[] = [];

  const getSearchApi = async (assetIDsAll: string) => {
    try {
      setIsLoading(true);
      const casequeryParams = {
        searchText: "",
        orderBy: "createdDate desc",
        status: "",
        limit: 10,
        skip: 0,
        defaultassocitaedAsset: assetIDsAll,
        customerId: customerId,
      };

      const caseSearchResponse = await api.getCasesList(casequeryParams);

      // === Sorting cases according to status ===
      const sortedData = caseSearchResponse.cases;
      sortedData.sort((a, b) => status[a.status] - status[b.status]);

      setCaseSearchData(sortedData);
    } catch (e) {
      history.push("/genericerror");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const customerResponse = await api.getCustomer(customerID);
        const activeTenanciesSummaries =
          customerResponse.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );
        const activeTenanciesPremisesIds: Tenancies[] = activeTenanciesSummaries
          .map((ts: any) => ts.tenancySummary.premisesIds)
          .flat();

        const promises = activeTenanciesPremisesIds.map((item: any) => {
          return api.getAssociatedAssets(item);
        });
        Promise.all(promises).then((results) => {
          //Filter results as per premisesid
          activeTenanciesPremisesIds?.map(async (assetid: any) => {
            results.forEach((element) => {
              const resultF = element.filter(
                (addressdata) => addressdata.id === assetid
              );

              if (resultF !== null) associtaedAssets.push(resultF);
            });
          });

          const associtaedAssetsList: AssocitaedAssetsType[] = [];

          associtaedAssets?.map((addressdata: any) => {
            addressdata?.map((address: any) => {
              const associtaedAssetsTypeEntry: AssocitaedAssetsType =
                new AssocitaedAssetsType();

              associtaedAssetsTypeEntry.key = address?.id;
              associtaedAssetsTypeEntry.value =
                address?.addresses?.postalAddresses[0]?.formattedAddress;
              associtaedAssetsList.push(associtaedAssetsTypeEntry);
            });
          });
        });

        const premisesList: RelatedPremisesType[] = [];
        const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
        let propertiesIds: string[] = [];
        premisesEntry.premisesType = PremisesType.Default;
        premisesEntry.premisesTypeId = PremisesType.Default;
        premisesList.push(premisesEntry);
        const addressesData = await api.getAssociatedAssets(PropertyId);

        addressesData.forEach((element) => {
          const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
          premisesEntry.premisesType = element.assetType as PremisesType;
          premisesEntry.premisesTypeId = element.id;
          premisesList.push(premisesEntry);
          propertiesIds.push(element.id);
        });

        const areaOptions = premisesList.map((f) => ({
          key: f.premisesType,
          value: f.premisesTypeId,
        }));

        areaOptions.forEach((element) => {
          if (element.key === "Property" || element.key === "Block") {
            assetBlockPropertyIds.push(element.value);
          }
        });

        let assetIDsIntTxt = "assetIdentifiers=";
        let assetIDsRemTxt = assetBlockPropertyIds.join("&assetIdentifiers=");
        let assetIDsAll = assetIDsIntTxt.concat(assetIDsRemTxt);

        getSearchApi(assetIDsAll);

      } catch (e) {
        setIsLoading(false);
        history.push("/genericerror");
      }
    })();
  }, [propertyId]);


  return (
      <div className="body-card row m-0 border-teal display-column">
        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        ) : (
          <>
                <h4
                  data-testid="caseCardTitle"
                >
                  {t("Case_Card_Title")}{" "}
                </h4>
                
              {!caseSearchData.length ? (
                <div className="col-md-12 p-0">
                  <p className="pt-20 pb-20">
                  {t("Case_Card_0027")}
                 </p>
                </div>
              ) : (
                caseSearchData.slice(0, 2).map((el: any, key: any) => (
                  <div key={key} className="col-md-12 p-0 repair-divider">
                    
                    <h5 className="pt-24 pb-20">
                      <strong data-testid="caseCardSubTitle">
                        {el.caseTypeDescription}
                      </strong>
                    </h5>

                    
                    <div className="record-repair justify-between">
                      <h6>{t("Case_Card_Ref")}</h6>
                      <p> {el.caseReferenceNumber}</p>
                    </div>

                    <div className="record-repair justify-between pt-16 pb-16">
                      <h6>{t("Case_Card_Status")}</h6>
                      <p> {el.status}</p>
                    </div>

                    <div className="record-repair justify-between">
                      <h6>{t("Case_Card_CreatedDate")} </h6>
                      <p>{formatDate(el.createdDate)}</p>
                    </div>
                  
                  </div>
                ))
              )}
              <div className="col-md-12 marginTop-auto">
            <div className="row pe-0 repair-btn">
                <div className="col ps-0 pt-30">
                    {caseSearchData.length > 0 && (     
                    <Button id="cust360-btn1" data-testid="viewCases" onClick={ViewCasesHandler} className="secondary-ghost-btn">{t("View_Cases")}</Button>                               
                    )}
                    </div>
                    <div className="col text-end pt-30 pe-0">
                    <Button id="repairs-Create1"data-testid="cases-Create" onClick={CreateCaseHandler} className="secondary-ghost-btn"> {t("Create_Case")}</Button>                    
                    </div>
                  
                </div>
                </div>
              </>
        )}
      </div>
  );
};

export default CaseCard;
