import React from "react";
import Modal from "./index";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import {
  Asset,
  AssetType,
  Statuses,
  UpdateRepair,
} from "../../models/Repair/Statuses";
import { PremisesTypeId } from "../../models/Customer/Premises";
import Clear from "../../assets/img/Clear.png";
import Button from "../../storybook/Button";

const ReScheduleModelRepair: React.FC<ModalProps> = ({
  workOrderId,
  area,
  errorCode,
  props,
  setIsReScheduleModalShow,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={true}
        data-testid="CancelModel"
        cssClasses="modalPopUp"
      >
        <div>
          <div className="text-end pb-16"><img src={Clear} data-testid="CancelModelClose" className="cursor-pointer" alt="Cancel" onClick={() => setIsReScheduleModalShow(true)}  onKeyDown={() => setIsReScheduleModalShow(true)}/></div>
          <div
            id="exitmodal-div2"
          >
            <h1 className="header-size pt-0">{t("CR165")}</h1>
            <p className="modal-para">{t("CR166")}</p>
          </div>

          <div id="exitmodal-div3" className="modalPopup-footerbtn">
              <div className="col-md-12 marginTop-auto">
                <div className="row m-0">
                  <div className="col-lg-12 text-end bordertop-none">
                    <Button id="exitmodal-btn2" onClick={() => setIsReScheduleModalShow(true)} className="secondary-ghost-btn btn-padding">
                      {t("OK")}
                    </Button>
                </div>
              </div>
            </div>
          </div>
          </div>
      </Modal>
    </>
  );
};

export default ReScheduleModelRepair;
