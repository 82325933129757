import { RelatedPremises } from '../Property'
import Address from './Address'

export enum PremisesType {
  Default = 'All',
  Block = 'Block',
  DividedBlock = 'Divided Block',
  EstateArea = 'Estate Area',
  Estate = 'Estate',
  Property = 'Property',
  Scheme = 'Scheme',
}

export enum PremisesTypeId {
  Block = 4,
  DividedBlock = 5,
  EstateArea = 2,
  Estate = 3,
  Property = 6,
  Scheme = 1,
}

export enum ReferenceDataPremisesTypeId {
  'block' = 4,
  'divided-block' = 5,
  'estate-area' = 2,
  'estate' = 3,
  'property' = 6,
  'scheme' = 1,
}

export class PremisesDetails {
  public isTenancyActive: boolean = false
  public neighbourhoodManager: string = ''
  public uprn: string = ''
  public tenancyType: string | null = ''
  public paymentReference: string = ''
  public paymentMethod: string = ''
  public lastPaymentAmount: number = 0
  public accountBalance: number | undefined = 0
}

export class FullPremises extends PremisesDetails {
  public tenancyId: string = ''
  public tenancyStartDate: string = ''
  public tenancyEndDate: string = ''
  public premisesType: string = ''
  public premisesId: string = ''
  public premisesTypeId: string = ''
  public isResidential: boolean = false
  public premisesAddress: Address = new Address()
  public adminUnitIds: RelatedPremises[] = []
}
