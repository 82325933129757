import React, { useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import { getContractorsData } from "../../../ducks/redux/actions/historicRepair";
import { RepairStatusField } from "../../../models/Repair/Repair";
import { CustomerResponse } from "../../../models/Customer";
import Tenancies from "../../../models/Customer/Tenancies";
import Message from "../../../storybook/Message";
import {
  AssocitaedAssetsType,
  RelatedPremisesType,
} from "../../../models/Property/AdminUnits";
import { PremisesType } from "../../../models/Customer/Premises";
import { Offcanvas } from "react-bootstrap";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllAssetIdentifiers,
  showMenuTabs,
  userChosenPropertyId,
} from "../../../ducks/redux/actions/userChoices";

import "./index.scss";
import filtericon from "../../../assets/img/filtericon.png";
import Clear from "../../../assets/img/Clear.png";

import {
  MobilePagination,
  Pagination,
  Paginationbox,
  Search,
  WebPagination,
} from "../../HistoricRepairs/components";
import MyMessage from "./MyMessage";
import { formatDateTime } from "../../../utils/formatting";

interface MyMessagesHistoryProps extends RouteComponentProps {}

const MyMessagesHistory: React.FC<MyMessagesHistoryProps> = (props) => {
  let moment = require("moment");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation<any>();

  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [resultsPerPage, setresultsPerPage] = useState(10);
  const [Area, setArea] = useState(false);
  const [contractorsList, setContractorsList] = useState([]);
  const [CustResponse, setCustomerResponse] = useState<CustomerResponse>();
  const [defaultassocitaedAsset, setdefaultassocitaedAsset] = useState("");
  const [associtaedAsset, setassocitaedAsset] = useState("");
  const arrayArea: Array<{ key: string; value: string }> = [];
  const [premisesListArea, setpremisesListArea] = useState(arrayArea);
  const [associtaedAssetList, setassocitaedAssetsList] = useState(arrayArea);
  const [selectedArea, setselectedArea] = useState("All");
  const [filterMessageStatus, setFilterMessageStatus] = useState("All");
  const [searchedMessages, setSearchedMessages] = useState<any>([]);
  const [sortMessages, setSortMessages] = useState("Newest");
  const [allMyMessages, setAllMyMessages] = useState<any>();
  const [messages, setMessages] = useState<any>([]);
  const [allMessagesCount, setAllMessagesCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const [yellowAlertMessage, setYellowAlertMessage] = React.useState("");
  const [messageAlert, setMessageAlert] = React.useState(false);
  const [assetID, setAssetID] = useState<string>(defaultassocitaedAsset);
  const [activeTenancies, setActiveTenancies] = useState<any>();

  const defaultfromdate = formatDateTime(
    moment(new Date()).subtract(2, "years").toString()
  );

  const [fromDate, setFromDate] = React.useState(defaultfromdate);

  const defaulttodate = formatDateTime(moment(new Date()).toString());
  const [toDate, setToDate] = React.useState(defaulttodate);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let assetBlockPropertyIds: any = [];

  const customerID = useSelector((state: any) => state.addresses?.customerid);
  let PropertyId = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenPropertyId
  );
  const allMessageData = useSelector(
    (state: any) => state?.userChoicesReducer?.myMessageData
  );
  const allassetIndentifiers = useSelector(
    (state: any) => state?.userChoicesReducer?.allassetIdentifiers
  );

  const activeProperty = activeTenancies?.filter(
    (el: any) => el?.tenancySummary?.premisesIds[0] === assetID
  );
  const activeTenancyStartDate =
    activeProperty && activeProperty[0]?.tenancySummary?.tenancyStartDate;
  let formatedTenancyStartDate: any;

  const dateIsValid = (date: Date) => {
    return !Number.isNaN(new Date(date).getTime());
  };
  if (dateIsValid(activeTenancyStartDate)) {
    formatedTenancyStartDate = new Date(activeTenancyStartDate)
      .toISOString()
      .split("T")[0];
  }

  let associtaedAssets: any[] = [];
  const filterMessageList: string[] = ["All", "Read", "Unread"];

  const formatedToday = new Date().toISOString().split("T")[0];
  const twoYearsAgoFromNow = new Date();
  twoYearsAgoFromNow.setFullYear(twoYearsAgoFromNow.getFullYear() - 2);
  const formatedTwoYearsAgo = twoYearsAgoFromNow.toISOString().split("T")[0];

  interface RequestDataObject {
    fromDate: Date | string;
    toDate: Date | string;
    status: string;
    assetIdentifier: string | undefined;
    searchText: string;
    limit: number;
    skip: number;
    sort: string;
  }

  useEffect(() => {
    dispatch(showMenuTabs(true));
    const selectedAddress = associtaedAssetList.filter(
      (eachAdd) => eachAdd.key === PropertyId
    );
    setAssetID(selectedAddress[0]?.key);

    (() => {
      if (selectedAddress[0]?.key) {
        const requestData = {
          fromDate: formatedTwoYearsAgo,
          toDate: formatedToday,
          status: "",
          assetIdentifier: allassetIndentifiers,
          searchText: "",
          limit: 10,
          skip: 0,
          sort: "createdDate desc",
        };
        fetchMyMessageData(requestData);
        setresultsPerPage(10);
        setcurrentPage(0);
        setSortMessages("Newest");
        setFilterMessageStatus("All");
      }
    })();
  }, [associtaedAssetList, PropertyId, allassetIndentifiers]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const contractorResponse = await api.getContractors();
        setContractorsList(contractorResponse);
        dispatch(getContractorsData(contractorResponse));
        const customerResponse = await api.getCustomer(customerID);
        setCustomerResponse(customerResponse);
        const activeTenanciesSummaries =
          customerResponse.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );
        setActiveTenancies(activeTenanciesSummaries);
        const activeTenanciesPremisesIds: Tenancies[] = activeTenanciesSummaries
          .map((ts: any) => ts.tenancySummary.premisesIds)
          .flat();
        const promises = activeTenanciesPremisesIds.map((item: any) => {
          return api.getAssociatedAssets(item);
        });
        Promise.all(promises).then((results) => {
          //Filter results as per premisesid

          activeTenanciesPremisesIds?.map(async (assetid: any) => {
            results.forEach((element) => {
              const resultF = element.filter(
                (addressdata) => addressdata.id === assetid
              );

              if (resultF !== null) associtaedAssets.push(resultF);
            });
          });
          const associtaedAssetsList: AssocitaedAssetsType[] = [];

          associtaedAssets?.map((addressdata: any) => {
            addressdata?.map((address: any) => {
              const associtaedAssetsTypeEntry: AssocitaedAssetsType =
                new AssocitaedAssetsType();

              associtaedAssetsTypeEntry.key = address?.id;
              associtaedAssetsTypeEntry.value =
                address?.addresses?.postalAddresses[0]?.formattedAddress;
              associtaedAssetsList.push(associtaedAssetsTypeEntry);
            });
          });
          if (associtaedAssetsList?.length > 1) {
            setArea(true);
          } else {
            setArea(false);
          }
          if (PropertyId != "") {
            setdefaultassocitaedAsset(PropertyId);
            setassocitaedAsset(PropertyId);
          } else {
            PropertyId = associtaedAssetsList[0].key;
            setdefaultassocitaedAsset(associtaedAssetsList[0].key);
            setassocitaedAsset(associtaedAssetsList[0].key);
          }

          setassocitaedAssetsList(associtaedAssetsList);
        });

        const status =
          RepairStatusField.Appointed +
          "," +
          RepairStatusField.PendingApproval +
          "," +
          RepairStatusField.OnHold +
          "," +
          RepairStatusField.Cancelled +
          "," +
          RepairStatusField.Completed +
          "," +
          RepairStatusField.Unappointed +
          "," +
          RepairStatusField.InProgress +
          "," +
          RepairStatusField.InDiagnosis +
          "," +
          RepairStatusField.ReportedToEMA;
        const premisesList: RelatedPremisesType[] = [];
        const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
        let propertiesIds: string[] = [];
        let addressIds: string[] = [];
        premisesEntry.premisesType = PremisesType.Default;
        premisesEntry.premisesTypeId = PremisesType.Default;
        premisesList.push(premisesEntry);
        const addressesData = await api.getAssociatedAssets(PropertyId);
        addressesData.forEach((element) => {
          const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
          premisesEntry.premisesType = element.assetType as PremisesType;
          premisesEntry.premisesTypeId = element.id;
          premisesList.push(premisesEntry);
          propertiesIds.push(element.id);
        });
        const areaOptions = premisesList.map((f) => ({
          key: f.premisesType,
          value: f.premisesTypeId,
        }));

        setpremisesListArea(areaOptions);
        areaOptions.forEach((element) => {
          if (element.key === "Property" || element.key === "Block") {
            assetBlockPropertyIds.push(element.value);
          }
        });
        let assetIDsIntTxt = "assetIdentifiers=";
        let assetIDsRemTxt = assetBlockPropertyIds.join("&assetIdentifiers=");
        let assetIDsAll = assetIDsIntTxt.concat(assetIDsRemTxt);
        dispatch(getAllAssetIdentifiers(assetIDsAll));

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        props.history.push("/genericerror");
      }
    })();
  }, [PropertyId, allassetIndentifiers]);

  const reduceUnreadMessagesCount = () => {
    unreadMessagesCount >= 1 && setUnreadMessagesCount(unreadMessagesCount - 1);
  };


  const handleSubmit = (typedText: string): void => {
    let encodedTypedText = encodeURI(typedText);

    const data = {
      fromDate: fromDate ? fromDate : formatedTwoYearsAgo,
      toDate: toDate ? toDate : formatedToday,
      status: filterMessageStatus === "All" ? "" : filterMessageStatus,
      assetIdentifier: allassetIndentifiers,
      searchText: typedText ? encodedTypedText : "",
      limit: 10,
      skip: 0,
      sort:
        sortMessages === "Newest"
          ? "createdDate desc"
          : sortMessages === "Oldest"
          ? "createdDate asc"
          : "",
    };
    fetchMyMessageData(data);
    setcurrentPage(0);
  };
  const resetButtonHandler = (): void => {
    setcurrentPage(0);
    setSearchTerm("");
    setFromDate(defaultfromdate);
    setToDate(defaulttodate);
    setFilterMessageStatus("");
    setSearchText("");
    setSearchedMessages([]);
    setYellowAlertMessage("");
    setMessageAlert(false);
    setSortMessages("Newest");

    const requestData = {
      fromDate: formatedTwoYearsAgo,
      toDate: formatedToday,
      status: "",
      assetIdentifier: allassetIndentifiers,
      searchText: "",
      limit: 10,
      skip: 0,
      sort: "createdDate desc",
    };
    fetchMyMessageData(requestData);
  };

  const resetWithoutFetch = (): void => {
    setcurrentPage(0);
    setSearchTerm("");
    setFromDate("");
    setToDate("");
    setFilterMessageStatus("");
    setSearchText("");
    setSearchedMessages([]);
    setYellowAlertMessage("");
    setMessageAlert(false);
    setSortMessages("Newest");
  };

  const clearSearchText = () => {
    setSearchTerm("");
    resetButtonHandler();
  };

  const handleChangeAssocitae = (e: { target: { value: any } }) => {
    resetWithoutFetch();
    setdefaultassocitaedAsset(e.target.value);
    dispatch(userChosenPropertyId(e.target.value));
  };

  const fetchMyMessageData = (requestData: RequestDataObject) => {
    (async () => {
      setIsLoading(true);
      await api
        .getCommunicationMessages(customerID, requestData)
        .then((response) => {
          if (
            response?.["total-count"] === undefined ||
            (response?.["total-count"] === 0 && searchTerm)
          ) {
            setYellowAlertMessage("SSC017");
            setMessageAlert(true);
          } else if (
            response?.["total-count"] === undefined ||
            (response?.["total-count"] === 0 && fromDate && toDate)
          ) {
            setYellowAlertMessage("SSC017");
            setMessageAlert(true);
          } else if (
            response?.["total-count"] === undefined ||
            (response?.["total-count"] === 0 &&
              (filterMessageStatus === "Unread" ||
                filterMessageStatus === "Read"))
          ) {
            setYellowAlertMessage("SSC017");
            setMessageAlert(true);
          } else if (
            response?.["total-count"] === undefined ||
            response?.["total-count"] === 0
          ) {
            setYellowAlertMessage("SSC016");
            setMessageAlert(true);
          } else {
            setYellowAlertMessage("");
            setMessageAlert(false);
          }
          setAllMyMessages(response);

          setMessages(response?.communications);
          setUnreadMessagesCount(response?.["total-unread"]);
          setAllMessagesCount(response?.["total-count"]);
          setTotalCount(response?.["total-count"]);

          setIsLoading(false);
        })
        .catch((error) => {
          setYellowAlertMessage("SSC021");
          setMessageAlert(true);
        });
    })();
  };

  const fetchMobileViewData = () => {
    setresultsPerPage(100);
    const data = {
      fromDate: fromDate ? fromDate : formatedTwoYearsAgo,
      toDate: toDate ? toDate : formatedToday,
      status: filterMessageStatus === "All" ? "" : filterMessageStatus,
      assetIdentifier: allassetIndentifiers,
      searchText: searchTerm ? searchTerm : "",
      limit: 100,
      skip: 0,
      sort:
        sortMessages === "Newest"
          ? "createdDate desc"
          : sortMessages === "Oldest"
          ? "createdDate asc"
          : "",
    };
    fetchMyMessageData(data);
  };

  const onChangePage = (e: any, page: number) => handleChangePage(page);

  const handleChangePage = (page: number) => {
    const data = {
      fromDate: fromDate ? fromDate : formatedTwoYearsAgo,
      toDate: toDate ? toDate : formatedToday,
      status: filterMessageStatus === "All" ? "" : filterMessageStatus,
      assetIdentifier: allassetIndentifiers,
      searchText: searchTerm ? searchTerm : "",
      limit: 10,
      skip: resultsPerPage * page,
      sort:
        sortMessages === "Newest"
          ? "createdDate desc"
          : sortMessages === "Oldest"
          ? "createdDate asc"
          : "",
    };
    fetchMyMessageData(data);
    setcurrentPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
    setresultsPerPage((e.target as any).value);
    setcurrentPage(0);

    const data = {
      fromDate: fromDate ? fromDate : formatedTwoYearsAgo,
      toDate: toDate ? toDate : formatedToday,
      status: filterMessageStatus === "All" ? "" : filterMessageStatus,
      assetIdentifier: allassetIndentifiers,
      searchText: searchTerm ? searchTerm : "",
      limit: (e.target as any).value,
      skip: 0,
      sort:
        sortMessages === "Newest"
          ? "createdDate desc"
          : sortMessages === "Oldest"
          ? "createdDate asc"
          : "",
    };
    fetchMyMessageData(data);
  };

  const inputChangeHandler = (event: any) => {
    if (moment(event.target.value, "DD/MM/YYYY", true).isValid()) {
      setYellowAlertMessage("SSC020");
      setMessageAlert(true);
    } else {
      setYellowAlertMessage("");
      setMessageAlert(false);
    }

    if (event.target.id === "fromDate") {
      if (
        new Date(event.target.value).getTime() >
        new Date(formatedToday).getTime()
      ) {
        setYellowAlertMessage("SSC013");
        setMessageAlert(true);
      } else if (
        new Date(event.target.value).getTime() <
        new Date(formatedTenancyStartDate).getTime()
      ) {
        setYellowAlertMessage("SSC012");
        setMessageAlert(true);
      } else {
        setYellowAlertMessage("");
        setMessageAlert(false);
      }
      setFromDate(event.target.value);
    } else if (event.target.id === "toDate") {
      if (
        new Date(event.target.value).getTime() >
        new Date(formatedToday).getTime()
      ) {
        setYellowAlertMessage("SSC014");
        setMessageAlert(true);
      } else {
        setYellowAlertMessage("");
        setMessageAlert(false);
      }
      setToDate(event.target.value);
    }
  };

  const filterMessageSubmitHandler = () => {
    const data = {
      fromDate: fromDate ? fromDate : formatedTwoYearsAgo,
      toDate: toDate ? toDate : formatedToday,
      status: filterMessageStatus === "All" ? "" : filterMessageStatus,
      assetIdentifier: allassetIndentifiers,
      searchText: searchTerm ? searchTerm : "",
      limit: 10,
      skip: 0,
      sort:
        sortMessages === "Newest"
          ? "createdDate desc"
          : sortMessages === "Oldest"
          ? "createdDate asc"
          : "",
    };
    fetchMyMessageData(data);
    setresultsPerPage(10);
    setcurrentPage(0);

    handleClose();
  };

  const changeMessageListByStatusHandler = (e: { target: { value: any } }) => {
    setFilterMessageStatus(e.target.value);
  };

  const pageContents = (
    <>
      <div id="messageHistory">
        <h1 className="rephistory-heading">{t("My_Message_History")}</h1>
        <div className="property-messages" data-testid="property-address">
          {Area ? (
            <div className="pe-0 history-dd">
              <select
                data-testid="my-messages-property-address"
                value={defaultassocitaedAsset}
                id="my-messages-address"
                className="text-select repairs-search-address1 repair-dd desk-view"
                onChange={handleChangeAssocitae}
              >
                {associtaedAssetList.map((e, key) => {
                  return (
                    <option key={key} value={e.key}>
                      {" "}
                      {e.value}
                    </option>
                  );
                })}
              </select>
              {/* Mobile View */}
              <select
                data-testid="my-messages-property-address-mobile"
                value={defaultassocitaedAsset}
                id="my-messages-search-address"
                className="text-select repairs-search-address1 repair-dd mob-view"
                onChange={handleChangeAssocitae}
              >
                {associtaedAssetList.map((e, key) => {
                  return (
                    <option key={key} value={e.key}>
                      {" "}
                      {e?.value?.length > 84
                        ? e?.value?.slice(0, 84) + ".."
                        : e?.value}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <div
              data-testid="my-messages-search-address"
              id="my-messages-search-address"
            >
              {associtaedAssetList.map((e, key) => {
                return <div className="text-tenancy pt-16">{e.value}</div>;
              })}
            </div>
          )}
        </div>

        <div className="messages-filter-reset mt-30">
          <div className="mob-calendar-view filter-elements-margin-top">
            <input
              type="date"
              id="fromDate"
              name="fromDate"
              data-testid="fromDate"
              value={fromDate}
              defaultValue={fromDate}
              onChange={inputChangeHandler}
              className="calendar-min-height"
            />
            <input
              type="date"
              id="toDate"
              name="toDate"
              data-testid="toDate"
              value={toDate}
              defaultValue={toDate}
              onChange={inputChangeHandler}
              className="calendar-min-height ml-16"
            />
          </div>

          <div className="filter-elements-margin-top search-msg">
            <div id="custom-search-input">
              <div id="searchId">
                <label
                  id="searchLabel"
                  class="w-100"
                  data-testid="my-messages-search"
                >
                  <Search
                    className="search-input"
                    defaultValue={searchTerm}
                    data-testid="search"
                    onSubmit={handleSubmit}
                    placeholder="Search"
                    onClear={clearSearchText}
                    onChange={(searchStr: any) => setSearchTerm(searchStr)}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="messages-filters-mob-view filter-elements-margin-top">
            <button
              data-testid="my-message-filter-button"
              onClick={handleShow}
              onKeyDown={handleShow}
              className="filter-dd cursor-pointer msg-filter-cta"
            >
              {t("Filter")}
              <img
                src={filtericon}
                alt="Filter"
                className="repair-filtericon"
              />
            </button>

            <button
              onClick={resetButtonHandler}
              onKeyDown={resetButtonHandler}
              data-testid="transaction-search-Reset"
              className="filter-dd clear-filter cursor-pointer msg-filter-cta  msg-clear-filter-margin"
            >
              {t("Clear_filters")}
            </button>
          </div>
        </div>
    </div>

      <div className="mob-view mob-message-container-border mt-30">
        <tbody>
          {yellowAlertMessage && (
            <div>
              <Message className="error-msg">{t(yellowAlertMessage)}</Message>
            </div>
          )}
          {messages?.map((eachMsg: any, key: number) => {
            return (
              <MyMessage
                key={key}
                eachMsgData={eachMsg}
                callFrom360={
                  location?.state?.message.identifier == eachMsg.identifier
                    ? true
                    : false
                }
                fetchMyMessageData={fetchMyMessageData}
                assetID={assetID}
                reduceUnreadMessagesCount={reduceUnreadMessagesCount}
                {...props}
              />
            );
          })}
        </tbody>
      </div>

      <div className="mob-message-history row pt-24">
        <div className="col-md-12">
          {messageAlert ? (
            <div>
              <Message className="error-msg">{t(yellowAlertMessage)}</Message>
            </div>
          ) : (
            <div className="history-result">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" className="width-date">
                      {t("Messages_Date")}
                    </th>
                    <th scope="col" className="width-sender">
                      {t("Messages_Sender")}
                    </th>
                    <th scope="col" className="width-subject">
                      {t("Messages_Subject")}
                    </th>
                    <th scope="col" className="width-message">
                      {t("Messages_Message")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {messages?.map((eachMsg: any, key: number) => {
                    return (
                      <MyMessage
                        key={key}
                        eachMsgData={eachMsg}
                        callFrom360={
                          location?.state?.message.identifier ==
                          eachMsg.identifier
                            ? true
                            : false
                        }
                        fetchMyMessageData={fetchMyMessageData}
                        assetID={assetID}
                        reduceUnreadMessagesCount={reduceUnreadMessagesCount}
                        {...props}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Filter Modal */}
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <div id="mySidenav" className="filter-section">
          <Offcanvas.Header>
            <h4>
              {t("Filter")}
              <span>
                <img
                  src={Clear}
                  alt="Close"
                  className="cursor-pointer"
                  onClick={handleClose}
                  onKeyDown={handleClose}
                />
              </span>
            </h4>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <h6 className="pt-30">{t("Message_List")}</h6>
            <select
              data-testid="repairs-search-status1"
              value={filterMessageStatus}
              id="repairs-search-status1"
              className="mt-12 sorting-dd"
              onChange={changeMessageListByStatusHandler}
            >
              {filterMessageList.map((filterOption, key) => {
                return (
                  <option key={key} value={filterOption}>
                    {filterOption}
                  </option>
                );
              })}
            </select>

            <div className="pt-20 text-end">
              <button
                onClick={filterMessageSubmitHandler}
                data-testid="my-message-filter-button"
                className="primary-btn"
              >
                {t("Apply_Message_Filter")}
              </button>
            </div>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </>
  );

  return (
    <>
      <WebPagination>
        {isLoading ? (
          <div className="div-loader">
            <div className="SpinnerWrapper">
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          <>
            <div role="main" className="container-fluid p-0">
              <div className="padding-repair position-relative">
                {pageContents}

                {/* < -- Pagination view --> */}
                <div className="page-mar mb-8">
                  <Paginationbox>
                    <label>
                      <Pagination
                        className=" ml-4 "
                        colSpan={7}
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={resultsPerPage}
                        rowsPerPageOptions={[10, 20, 100]}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </label>
                  </Paginationbox>
                </div>
              </div>
            </div>
          </>
        )}
      </WebPagination>
      <MobilePagination>
        <div
          id="scrollableDiv"
          data-testid="scrollableDiv"
          className="mob-view-only padding-repair position-relative"
          style={{
            height: 800,
            overflowY: "scroll",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <InfiniteScroll
            dataLength={messages?.length}
            next={fetchMobileViewData}
            //  height={300}
            // onScroll={handleLoadMore}
            style={{ display: "flex", flexDirection: "column" }}
            hasMore={true}
            loader={<div></div>}
            scrollableTarget="scrollableDiv"
          >
            {isLoading ? (
              <div className="div-loader">
                <div className="SpinnerWrapper">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <>
                {/*Mobile Contents Resides Here.*/}
                {pageContents}
              </>
            )}
          </InfiniteScroll>
        </div>
      </MobilePagination>
    </>
  );
};

export default MyMessagesHistory;
