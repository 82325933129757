import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import {
  MobilePagination,
  Pagination,
  Paginationbox,
  Search,
  WebPagination,
} from "./components";
import moment from "moment";
import { RouteComponentProps } from "react-router-dom";
import {
  formatDateTime,
  formatDateTimeSlash,
  formatDateWithMonthInLetters,
} from "../../utils/formatting";

import filtericon from "../../assets/img/filtericon.png";

import LoadingSpinner from "../../storybook/LoadingSpinner";
import { Offcanvas } from "react-bootstrap";
import TransactionType from "../../models/Accounts/TransactionType";
import InfiniteScroll from "react-infinite-scroll-component";
import Message from "../../storybook/Message";
import Clear from "../../assets/img/Clear.png";

interface TransactionDetailsProps extends RouteComponentProps<any> {
  accountType: string | null;
  accountnumber: string;
  balance: string | null;
  balancetype: string | null;
}

interface WindowSize {
  width: number;
  isMobile: boolean;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  accountType,
  accountnumber,
  balance,
  balancetype,
}) => {
  const [totalCount, settotalCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [resultsPerPage, setresultsPerPage] = useState(10);
  const [resultsPerPageforMobile, setresultsPerPageMobile] = useState(100);
  const [skip, setskip] = useState(0);
  const { t } = useTranslation();

  const array: any = [];

  const userChosenPropertyAddress = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenPropertyAddress
  );
  const [transactions, setTransactionList] = useState(array);

  const accountid = accountnumber;

  const [isApiFail, setApiFailMessage] = useState(false);
  const [isNoResultsSearch, setNoresultSearchMsg] = useState(false);

  const defaultfromdate = formatDateTime(
    moment(new Date()).subtract(1, "years").toString()
  );
  const [fromDate, setFromDate] = useState(defaultfromdate);

  const defaulttodate = formatDateTime(moment(new Date()).toString());
  const [toDate, setToDate] = useState(defaulttodate);
  const [invalidDate, setDateErrorMessage] = useState(false);

  const [fromdateValue, setDateFrom] = useState(
    formatDateTime(defaultfromdate)
  );
  const [todateValue, setDateTo] = useState(defaulttodate);

  const [isvisible, setVisibility] = React.useState("block");
  const [errorclass, setDateClass] = React.useState(
    "border-grey w-28 md:w-full text-sm md:text-base p-2 md:p-4"
  );
  const [isErrorVisible, setErrorVisibility] = React.useState("none");

  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setsearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [filter, setfilter] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const transactionTypeOptions = TransactionType.map((f) => ({
    key: f.key,
    value: f.value,
  }));

  const [defaulttransactiontype, setdefaulttransactiontype] = useState("All");

  const handleChangeTransactionType = (e: { target: { value: any } }) => {
    setfilter(true);
    setdefaulttransactiontype(e.target.value);
  };

  const getWindowSize = (): WindowSize => {
    const width = window.innerWidth;
    const isMobile = width <= 820;
    return { width, isMobile };
  };
  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );

  useEffect(() => {
    const {width, isMobile } = getWindowSize();
    (async () => {
      try {
        setIsLoading(true);
        setApiFailMessage(false);
        if(isMobile){
          getDataFromDates(
            fromDate,
            toDate,
            resultsPerPageforMobile,
            skip,
            searchTerm,
            defaulttransactiontype
          );
        } else {
          getDataFromDates(
            fromDate,
            toDate,
            resultsPerPage,
            skip,
            searchTerm,
            defaulttransactiontype
          );
        }
      } catch (e) {
        setIsLoading(false);
        setApiFailMessage(true);
        setNoresultSearchMsg(false);
        setVisibility("none");
        console.error(e);
      }
    })();
  }, [accountnumber]);

  const handleReset = () => {
    setIsLoading(true);
    setDateFrom(defaultfromdate);
    setDateTo(defaulttodate);

    setFromDate(defaultfromdate);
    setToDate(defaulttodate);
    setresultsPerPage(10);
    setskip(0);
    setcurrentPage(0);
    setsearchTerm("");
    setdefaulttransactiontype("All");
    setDateErrorMessage(false);
    setVisibility("block");
    setDateClass("mt-1 ml-2 mr-6 block  border-grey");
    getDataFromDates(defaultfromdate, defaulttodate, 10, 0, "", "All");
    setIsLoading(false);
  };

  const handleSearch = (event: any): void => {
    setsearchTerm(event.target.value);
  };

  const applyFromDateFilter = (e: any) => {
    setFromDate(e);
    const todayDate = moment(new Date()).toString();
    if (!e) {
      setDateErrorMessage(true);
      setVisibility("none");
      setDateClass("mt-1 ml-2 mr-6 block  border-error");
    } else if (e > toDate) {
      setDateErrorMessage(true);
      setVisibility("none");
      setDateClass("mt-1 ml-2 mr-6 block  border-error");
    } else if (e > todayDate || toDate > todayDate) {
      setDateErrorMessage(true);
      setVisibility("none");
      setDateClass("mt-1 ml-2 mr-6 block  border-error");
    } else {
      setDateErrorMessage(false);
      setVisibility("block");
      setDateClass("mt-1 ml-2 mr-6 block  border-grey");
      getDataFromDates(
        e,
        toDate,
        resultsPerPage,
        skip,
        searchTerm,
        defaulttransactiontype
      );
    }
  };
  const onChangePage = (e: any, page: number) => handleChangePage(page);

  const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
    setresultsPerPage((e.target as any).value);
    setskip(0);
    setcurrentPage(0);
    getDataFromDates(
      fromDate,
      toDate,
      (e.target as any).value,
      0,
      searchTerm,
      defaulttransactiontype
    );
  };

  const handleChangePage = (page: number) => {
    setcurrentPage(page);
    setskip(resultsPerPage * page);
    getDataFromDates(
      fromDate,
      toDate,
      resultsPerPage,
      resultsPerPage * page,
      searchTerm,
      defaulttransactiontype
    );
  };

  const getDataFromDates = async (
    datefrom: any,
    dateto: any,
    resultsPerPage: number,
    skip: number,
    searchTerm: string,
    transactiontype: string
  ) => {
    try {
      const mmntFrom = moment(datefrom);
      const datefromValue = formatDateTimeSlash(mmntFrom.toString());
      const mmntTo = moment(dateto);
      const datetoValue = formatDateTimeSlash(mmntTo.toString());
      let sortedTransactions: any = [];
      if (mmntFrom > mmntTo) {
        setApiFailMessage(false);
        setDateErrorMessage(true);
        setVisibility("none");
        setDateClass("mt-1 ml-2 mr-6 block  border-error");
      } else {
        const transactionDetails = await api.getTransactionsSearch(
          accountid,
          resultsPerPage,
          skip,
          datefromValue,
          datetoValue,
          searchTerm,
          transactiontype
        );
        if (transactionDetails?.totalCount > 0) {
          setApiFailMessage(false);
          setIsLoading(false);
          let pendingTransactions: any = [];
          let nonPendingTransactions: any = [];

          transactionDetails.transactions.forEach((element: any) => {
            if (element.statuses["is-posted"]) {
              nonPendingTransactions.push(element);
            } else {
              pendingTransactions.push(element);
            }
          });
          pendingTransactions.forEach((elementPending: any) => {
            sortedTransactions.push(elementPending);
          });
          nonPendingTransactions.forEach((elementNonPending: any) => {
            sortedTransactions.push(elementNonPending);
          });
          setTransactionList(sortedTransactions);
          setVisibility("block");
          setErrorVisibility("none");
        } else {
          setIsLoading(false);
          setTransactionList(sortedTransactions);
          setVisibility("none");
          if (searchTerm != "") {
            setNoresultSearchMsg(true);
            setApiFailMessage(false);
          } else {
            if (filter != true) {
              setNoresultSearchMsg(false);
            } else {
              setNoresultSearchMsg(true);
              setApiFailMessage(false);
            }
          }
          setErrorVisibility("block");
        }
        settotalCount(transactionDetails?.totalCount);
        setfilter(false);
      }
    } catch (e) {
      setIsLoading(false);
      setApiFailMessage(true);
      setNoresultSearchMsg(false);
      setVisibility("none");
      console.error(e);
    }
  };
  const applyFromToFilter = (e: any) => {
    setToDate(e);
    const todayDate = formatDateTime(moment(new Date()).toString());
    if (!e) {
      setDateErrorMessage(true);
      setVisibility("none");
      setDateClass("mt-1 ml-2 mr-6 block  border-error");
    } else if (fromDate > e) {
      setDateErrorMessage(true);
      setVisibility("none");
      setDateClass("mt-1 ml-2 mr-6 block  border-error");
    } else if (fromDate > todayDate || e > todayDate) {
      setDateErrorMessage(true);
      setVisibility("none");
      setDateClass("mt-1 ml-2 mr-6 block  border-error");
    } else {
      setDateErrorMessage(false);
      setVisibility("block");
      setDateClass("mt-1 ml-2 mr-6 block  border-grey");
      getDataFromDates(
        fromDate,
        e,
        resultsPerPage,
        skip,
        searchTerm,
        defaulttransactiontype
      );
    }
  };

  const handleSubmit = (s: string): void => {
    if (!invalidDate) {
      setcurrentPage(0);
      setsearchTerm(s);
      getDataFromDates(
        fromDate,
        toDate,
        resultsPerPage,
        0,
        s,
        defaulttransactiontype
      );
    }
  };
  const handleResetSubmit = (s: string): void => {
    setcurrentPage(0);
    setsearchTerm("");
    getDataFromDates(
      fromDate,
      toDate,
      resultsPerPage,
      0,
      "",
      defaulttransactiontype
    );
  };

  const handleFilterSubmit = () => {
    if (!invalidDate) {
      setcurrentPage(0);
      getDataFromDates(
        fromDate,
        toDate,
        resultsPerPage,
        0,
        searchTerm,
        defaulttransactiontype
      );
    }
    setShow(false);
  };
  const fetchData = () => {
    setresultsPerPage(100);
    getDataFromDates(
      fromDate,
      toDate,
      totalCount,
      0,
      searchTerm,
      defaulttransactiontype
    );
  };
  const handleLoadMore = (e: MouseEvent) => {
    if (totalCount > resultsPerPage) {
      setresultsPerPage(100);
      getDataFromDates(
        fromDate,
        toDate,
        totalCount,
        0,
        searchTerm,
        defaulttransactiontype
      );
    }
  };
  return (
    <>
      <WebPagination>
        <div id="main_div_tx" role="main">
          {!isLoading ? (
            <div className="pb-12">
              <h1>{t("Account_transactions")}</h1>
              <div>
                <div className="row py-4">
                  <div className="col-md-4">
                    <input
                      data-testid="tx-fromdate"
                      id="fromdate"
                      value={fromdateValue}
                      type="date"
                      defaultValue={fromDate}
                      onBlur={(e) => applyFromDateFilter(e.target.value)}
                      onChange={(e) => setDateFrom(e.target.value)}
                      className="calendar-min-height"
                    ></input>
                    <input
                      data-testid="tx-todate"
                      id="todate"
                      type="date"
                      value={todateValue}
                      defaultValue={toDate}
                      onBlur={(e) => applyFromToFilter(e.target.value)}
                      onChange={(e) => setDateTo(e.target.value)}
                      className="calendar-min-height ml-16"
                    ></input>
                  </div>

                  <div className="col-md-3">
                    <label id="searchLabel" className="w-100">
                      <Search
                        defaultValue={searchTerm}
                        data-testid="transaction-search-box"
                        onSubmit={handleSubmit}
                        placeholder="Search"
                        onClear={handleResetSubmit}
                        className="search-input"
                      />
                    </label>
                  </div>

                  <div className="col-md-4 filter-tx">
                    <button
                      data-testid="transaction-search-filter"
                      onClick={handleShow}
                      onKeyDown={handleShow}
                      className="filter-dd cursor-pointer"
                    >
                      {t("Filter")}
                      <img
                        src={filtericon}
                        alt="Filter"
                        className="repair-filtericon"
                      />
                    </button>
                  </div>

                  <div className="col-md-1 filter-tx">
                    <button
                      onClick={handleReset}
                      onKeyDown={handleReset}
                      data-testid="transaction-search-Reset"
                      className="filter-dd clear-filter cursor-pointer"
                    >
                      {t("Clear_filters")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="my-4">
                {/* date validation messages */}

                {invalidDate && (
                  <div id="custDetails-div5" role="alert">
                    <Message className="warning-msg">
                      {" "}
                      {t("Tx_DateValidation_SSC004")}
                    </Message>
                  </div>
                )}
                {isApiFail && !invalidDate && (
                  <div id="div_apifail" role="alert">
                    <Message className="warning-msg">
                      {t("Tx_Service_Down_SSC001")}
                    </Message>
                  </div>
                )}
                {transactions.length == 0 &&
                !invalidDate &&
                !isNoResultsSearch ? (
                  <div id="div_errormsg" style={{ display: isErrorVisible }}>
                    <div id="repAddDet-div5" role="alert">
                      <Message className="warning-msg">
                        {t("Tx_Details_NotAvailable_SSC003")}
                      </Message>
                    </div>
                  </div>
                ) : (
                  transactions.length == 0 &&
                  !invalidDate &&
                  isNoResultsSearch && (
                    <div>
                      <div id="repAddDet-div5" role="alert">
                        <Message className="warning-msg">
                          {t("Tx_Details_NotAvailable_SSC006")}
                        </Message>
                      </div>
                    </div>
                  )
                )}

                <div
                  id={"div_main" + 1}
                  style={{ display: isvisible }}
                  className="my-4"
                  data-testid="main-div"
                >
                  {/* bind data start */}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="history-result">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">{t("statement_date")}</th>
                              <th scope="col" className="width-35">
                                {t("statement_description")}
                              </th>
                              <th scope="col" className="width-20">
                                {t("statement_credit")}
                              </th>
                              <th scope="col" className="width-25">
                                {t("statement_debit")}
                              </th>
                              <th scope="col" className="width-20">
                                {t("statement_balance")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions?.map((tx: any) => (
                              <tr>
                                <td>
                                  <div>
                                    {" "}
                                    {tx.statuses["is-posted"]
                                      ? formatDateWithMonthInLetters(
                                          tx.dates["transaction-date"]
                                        )
                                      : "-"}
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <strong>
                                      {tx["transaction-description"]}
                                    </strong>
                                  </div>
                                </td>
                                <td>
                                  {tx["payment-code"] == "CR" ? (
                                     Number((String(tx.amount.gross.value)).replace(/[^-.0-9]+/g,'')) > 0 ? (
                                      "£" + tx.amount.gross.value
                                    ) : (
                                      "-£" +
                                      Math.abs(
                                        tx.amount.gross.value
                                          .toString()
                                          .replace(/,/g, "")
                                      ).toFixed(2)
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td>
                                  <strong>
                                    {tx["payment-code"] != "CR" ? (
                                      Number((String(tx.amount.gross.value)).replace(/[^-.0-9]+/g,'')) < 0 ? (
                                        "-£" + Math.abs(
                                          tx.amount.gross.value
                                            .toString()
                                            .replace(/,/g, "")
                                        ).toFixed(2)
                                      ) : (
                                        "£" +
                                        Math.abs(
                                          tx.amount.gross.value
                                            .toString()
                                            .replace(/,/g, "")
                                        ).toFixed(2)
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </strong>
                                </td>
                                <td>
                                  <strong>
                                    {parseFloat(tx["running-balance"]?.value) >
                                    0 ? (
                                      <>
                                        <span className="color-black">
                                          £
                                          {tx["running-balance"]?.value +
                                            " " +
                                            ""}
                                        </span>
                                      </>
                                    ) : parseFloat(
                                        tx["running-balance"]?.value
                                      ) == 0 ? (
                                      "£" + tx["running-balance"]?.value
                                    ) : (
                                      <>
                                        <span className="error-color">
                                          {" "}
                                          {"£" +
                                            tx["running-balance"]?.value
                                              .toString()
                                              .replace("-", "") +
                                            ""}{" "}
                                        </span>{" "}
                                      </>
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="div_main"
                style={{ display: isvisible }}
                className=" main-div-pagination "
              >
                <div>
                  <Paginationbox>
                    <label>
                      <Pagination
                        data-testid="tx_pagination"
                        colSpan={7}
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={resultsPerPage}
                        rowsPerPageOptions={[10, 20, 100]}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </label>
                  </Paginationbox>
                </div>
              </div>
              <Offcanvas placement="end" show={show} onHide={handleClose}>
                <div id="mySidenav">
                  <a onClick={handleClose} onKeyDown={handleClose}>
                    &times;
                  </a>
                  <div>
                    <div>
                      <Offcanvas.Header>
                        <h2>{t("Filter")}</h2>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div>
                          <div>
                            <strong>{t("PaymentType")}</strong>
                          </div>
                          <select
                            data-testid="repairs-search-status"
                            value={defaulttransactiontype}
                            id="repairs-search-status"
                            onChange={handleChangeTransactionType}
                          >
                            {transactionTypeOptions.map((e, key) => {
                              return (
                                <option key={key} value={e.key}>
                                  {e.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div>
                          <button
                            onClick={handleFilterSubmit}
                            data-testid="repairs-search-button"
                            className="primary-btn"
                          >
                            {t("Search")}
                          </button>
                        </div>
                      </Offcanvas.Body>
                    </div>
                  </div>
                </div>
              </Offcanvas>
            </div>
          ) : (
            <div className="div-loader">
              <div className="SpinnerWrapper">
                <LoadingSpinner />
              </div>
            </div>
          )}
        </div>
      </WebPagination>
      <MobilePagination>
        <div
          id="scrollableDiv"
          data-testid="scrollableDiv"
          className="mob-view-only"
          style={{
            height: 800,
            overflowY: "scroll",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <InfiniteScroll
            dataLength={transactions?.length}
            next={fetchData}
            onScroll={handleLoadMore}
            style={{ display: "flex", flexDirection: "column" }}
            hasMore={true}
            loader={<h4></h4>}
            scrollableTarget="scrollableDiv"
          >
            <div
              id="main_div_tx_mobile"
              className=" bg-20% bg-fixed"
              role="main"
            >
              {!isLoading ? (
                <div className="pb-12 ">
                  <h1>{t("Account_transactions")}</h1>
                  <div className="my-4">
                    {/* date validation messages */}

                    {invalidDate && (
                      <Message className="warning-msg">
                        {t("Tx_DateValidation_SSC004")}
                      </Message>
                    )}
                    {isApiFail && !invalidDate && (
                      <Message className="warning-msg">
                        {t("Tx_Service_Down_SSC001")}
                      </Message>
                    )}
                    {transactions.length == 0 &&
                    !invalidDate &&
                    !isNoResultsSearch ? (
                      <div
                        id="div_errormsg"
                        style={{ display: isErrorVisible }}
                      >
                        <div
                          id="repAddDet-div5"
                          className="alertpayment alert-warningpayment error-reason mar-left"
                          role="alert"
                        >
                          {t("Tx_Details_NotAvailable_SSC003")}
                        </div>
                      </div>
                    ) : (
                      transactions.length == 0 &&
                      !invalidDate &&
                      isNoResultsSearch && (
                        <div>
                          <div
                            id="repAddDet-div5"
                            className="alertpayment alert-warningpayment error-reason mar-left"
                            role="alert"
                          >
                            {t("Tx_Details_NotAvailable_SSC006")}
                          </div>
                        </div>
                      )
                    )}

                    <div
                      id={"div_main" + 1}
                      style={{ display: isvisible }}
                      className="my-4"
                      data-testid="main-div"
                    >
                      {/* bind data start */}
                      {transactions?.map((tx: any) => (
                        <div className="border-b border-grey p-2">
                          <div className="flex justify-between">
                            <div>
                              {" "}
                              {tx.statuses["is-posted"]
                                ? formatDateWithMonthInLetters(
                                    tx.dates["transaction-date"]
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div>
                              <strong>{tx["transaction-description"]}</strong>
                            </div>
                            <div>
                              <strong>
                                {tx["payment-code"] == "CR"
                                  ? Number((String(tx.amount.gross.value)).replace(/[^-.0-9]+/g,'')) > 0
                                    ? "£" + tx.amount.gross.value
                                    : "-£" +
                                      Math.abs(
                                        tx.amount.gross.value
                                          .toString()
                                          .replace(/,/g, "")
                                      ).toFixed(2)
                                      : Number((String(tx.amount.gross.value)).replace(/[^-.0-9]+/g,'')) < 0
                                      ? "-£" + Math.abs(
                                        tx.amount.gross.value
                                          .toString()
                                          .replace(/,/g, "")
                                      ).toFixed(2)
                                  : "£" +
                                    Math.abs(
                                      tx.amount.gross.value
                                        .toString()
                                        .replace(/,/g, "")
                                    ).toFixed(2)}
                              </strong>
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <div>
                              <strong>{t("statement_balance")}</strong>
                            </div>
                            <div className="text-grey-dark text-xs">
                              {parseFloat(tx["running-balance"]?.value) > 0 ? (
                                <>
                                  <span className="color-black">
                                    £{tx["running-balance"]?.value + " " + ""}
                                  </span>
                                </>
                              ) : parseFloat(tx["running-balance"]?.value) ==
                                0 ? (
                                "£" + tx["running-balance"]?.value
                              ) : (
                                <>
                                  <span className="error-color">
                                    {"£" +
                                      tx["running-balance"]?.value
                                        .toString()
                                        .replace("-", "") +
                                      ""}{" "}
                                  </span>{" "}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Offcanvas placement="end" show={show} onHide={handleClose}>
                    <div id="mySidenav" className="filter-section">
                      <Offcanvas.Header>
                        <h4>
                          {t("Filter")}
                          <span>
                            <img
                              src={Clear}
                              alt="Close"
                              className="cursor-pointer"
                              onClick={handleClose}
                              onKeyDown={handleClose}
                            />
                          </span>
                        </h4>
                      </Offcanvas.Header>
                      <Offcanvas.Body>                         
                          <h6 className="pt-30">{t("PaymentType")}</h6>
                          <select
                            data-testid="repairs-search-status"
                            value={defaulttransactiontype}
                            id="repairs-search-status"
                            onChange={handleChangeTransactionType}
                            className="mt-12 sorting-dd"
                          >
                            {transactionTypeOptions.map((e, key) => {
                              return (
                                <option key={key} value={e.key}>
                                  {e.value}
                                </option>
                              );
                            })}
                          </select>
                      
                        <div className="pt-20 text-end">
                          <button
                            onClick={handleFilterSubmit}
                            data-testid="repairs-search-button"
                            className="primary-btn"
                          >
                            {t("Search")}
                          </button>
                        </div>
                      </Offcanvas.Body>
                    </div>
                  </Offcanvas>
                </div>
              ) : (
                <div className="div-loader">
                  <div className="SpinnerWrapper">
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </MobilePagination>
    </>
  );
};

export default TransactionDetails;
