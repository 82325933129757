export const RepairSelectOptions = [
  {
    key: "All",
    value: "All",
  },
  {
    key: "Active",
    value: "Active",
  },
  {
    key: "Assigned to contractor",
    value: "Unappointed",
  },
  {
    key: "Appointed",
    value: "Appointed",
  },
  {
    key: "On hold",
    value: "On Hold",
  },
  {
    key: "In progress",
    value: "In Progress",
  },

  {
    key: "Pending approval",
    value: "Pending Approval",
  },
  {
    key: "Cancelled",
    value: "Cancelled",
  },
  {
    key: "Completed",
    value: "Completed",
  },
  {
    key: "Draft",
    value: "In Diagnosis",
  },  
  {
    key: "Passed to Peabody",
    value: "Passed to Peabody",
  },
];
export default RepairSelectOptions;
