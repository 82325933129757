//import { recallRepairsAndGasRecallButtonEnabled } from '../../api/featureToggle'
//import { NoteProps } from '../../storybook/Notes'
import { Identifiers } from "./../WorksOrders/Identifier";
import { isWithinSixMonths } from '../../utils/formatting'
import { Address, CustomerName } from '../Customer'
import { EligibilityOutcome, PremisesDetails } from '../Property'
import DigitalAddress from '../shared/Address/DigitalAddress'
import { RaisedBy } from '../shared/RaisedBy'
import { Statuses } from '../shared/Statuses'
import { WorksOrder } from '../WorksOrders'
//import { Contact, ContractorV2 } from './ContractorV2'
import { CreatedRepair, RepairContact, RepairDetails } from './CreateRepair'
class CurrentStep {
  public value: number = 0
  public key: string = ''
  public displayName: string = ''
  public legacyKeys: any[] = []
}

export class Documents {
  public documentIdentifiers: string[] = [];
}

export class CompletedStep {
  public value: number = 0
  public key: string = ''
  public displayName: string = ''
  public legacyKeys: any[] = []
  constructor(step?: number) {
    this.value = step || 0
  }
}

export class ReportContactDetails {
  public name: any | null = null
  public contactNumber: string | null = null
  public organisation: string | null = null
  public reporterToBeContacted: boolean = false
}

export class CurrentRepair {
  public static canBeScheduled = (
    repair: Pick<CurrentRepair, 'status' | 'priority' | 'isRecall'>
  ): boolean => {
    const { status, priority, isRecall } = repair
    // if toggle is on, do not display appointment button for recalls
    //const displayAppointmentButtonForRecalledRepairs =
    //  recallRepairsAndGasRecallButtonEnabled() && isRecall ? false : true

    return (
      status === RepairStatusText.Unappointed &&
      priority === 'Next Available' 
      //&& displayAppointmentButtonForRecalledRepairs
    )
  }
  public static canBeRecalled = (
    repair: Pick<CurrentRepair, 'status' | 'priority' | 'completedDate' | 'sorCode' | 'isRecall'>
  ): boolean => {
    const GAS_SOR_CODES = ['PROGAS', 'COMGAS', 'PROGASLEAK', 'COMHIU', 'COMPLANT']

    // default true for non gas scenarios
    let displayRecallFeatureToggleCheck: boolean = true
    //if (GAS_SOR_CODES.includes(repair.sorCode)) {
      // check featureTogggle state to display for gas scenarios
     // displayRecallFeatureToggleCheck = recallRepairsAndGasRecallButtonEnabled()
   // }
    return (
      (repair.status === RepairStatusText.Completed || repair.status === RepairStatusText.Closed) &&
      repair.priority === 'Next Available' &&
      isWithinSixMonths(repair.completedDate) &&
      !repair.isRecall &&
      displayRecallFeatureToggleCheck
    )
  }

  public id: string
  public originalWorkOrderIdentifier?: string | undefined
  public originalWorkOrderJobId?: string | undefined
  public originalWorkOrderSorCode?: string | undefined
  public isDraft: boolean
  public isSupplementary?: boolean | null
  public isVandalism?: boolean | null
  public isCovidSelfIsolated?: boolean | null
  public premisesId: string
  public area: string = ''
  public premisesType: string = ''
  public premisesTypeId :string = ''
  public premisesAddress: Address | null = null
  public contractor: string = ''
  public contractorName?: string
  public contractorPhoneNumber?: string = ''
  public contractorBuilderName?: string = ''
  public contractorBuilderPhone?: string = ''
  public trade: string = ''
  public repairLocation: string = ''
  public repairLocationId: string = ''
  public isEmergency: boolean = false
  public isSubmitted: boolean = false
  public isOutOfHours: boolean = false
  public priority: string = ''
  public status: string = ''
  public raisedDate: string = ''
  public appointmentStartDate: any
  public lastAppointmentCreatedDate : any
  public appointmentEndDate: any
  public appointmentDates: object = {}
  public completedDate: any
  public description: string = ''
  public repairReason: string = ''
  public additionalInformation: string = ''
  public jobNo: string = ''
  public documents: Documents = new Documents();
  public repairAddress : string = ''
  public chargeCustomer: any
  public repairCause?: string = ''
  public repairCauseId?: number = 0
  public riskToHumanLife?: boolean = undefined
  public crimeReferenceNumber: string | null = null
  public reportedById?: number
  public reportedBy: string = ''
  public notifyAll: boolean
  public staffContactEmails: string[] = []
  public currentStep: CurrentStep = new CurrentStep()
  public completedStep: CompletedStep = new CompletedStep()
  public reportingMethodId: number = 1
  public reportingMethod?: string = ''
  public eTag: string = 'eTag'
  public reporterToBeContacted: boolean = true
  public reporterContactDetails: ReportContactDetails | null = new ReportContactDetails()
  //public notes: NoteProps[] = []
  public customerName: CustomerName | null = new CustomerName()
  public premisesUniqueReference: string = ''
  public sorCode: string = ''
  public raisedBy: RaisedBy = new RaisedBy()
  public staffContactDigitalAddresses: DigitalAddress[] = []
  public worksOrderIdentifier: string = ''
  public customerIdentifier: string = ''    
  public isRecall?: boolean
  public eligibilityOutcomes: EligibilityOutcome[] = []
  //public contractorContacts : ContractorV2 = new ContractorV2()
  public isEMA: boolean = false
  public isCatalyst: boolean = false
  public isInDefect: boolean | false
  
  public isPotentialDuplicate: boolean = false

  public isVulnerable?: boolean | null
  public VulnerableResponseText: any
  public assetIdentifier: string 
  public channel: string = ''
 
  constructor(worksOrder?: WorksOrder, premisesInfo?: PremisesDetails) {
    this.id = worksOrder?.identifiers?.worksOrderIdentifier || ''
    this.originalWorkOrderIdentifier =
      worksOrder?.identifiers?.originalWorkOrderIdentifier || undefined
    this.originalWorkOrderSorCode = worksOrder?.job?.sorCode || undefined
    this.isDraft = worksOrder?.statuses?.isDraft || false
    this.isSupplementary = worksOrder?.statuses?.isSupplementary || null
    if(worksOrder?.statuses?.isVandalism===false)
    {
      this.isVandalism=false;
    }
    else
    {
      this.isVandalism = worksOrder?.statuses?.isVandalism || null
    }   
    if(worksOrder?.statuses?.isCovidSelfIsolated===false)
    {
      this.isCovidSelfIsolated=false;
    }
    else
    {
      this.isCovidSelfIsolated = worksOrder?.statuses?.isCovidSelfIsolated || null
    }   
    this.premisesId = worksOrder?.identifiers?.asset?.assetIdentifier || ''
    this.premisesAddress = premisesInfo?.premises?.address || null
    this.premisesTypeId = premisesInfo?.premises?.premisesTypeId || ''
    this.premisesType = premisesInfo?.premises?.premisesType || ''
    this.area= worksOrder?.asset?.type?.assetTypeName|| ''
    this.contractor = worksOrder?.identifiers?.contractorSiteIdentifier || ''
    this.trade = worksOrder?.job?.trade || ''
    this.repairLocation = worksOrder?.job?.location?.location || ''
    this.repairLocationId = worksOrder?.job?.location?.locationId || ''
    this.repairReason = worksOrder?.job?.repairReason || ''
    this.isEmergency = worksOrder?.statuses?.isEmergency || false
    this.isSubmitted = worksOrder?.statuses?.isSubmitted || false
    this.isOutOfHours = worksOrder?.statuses?.isOutOfHours || false
    const calculatedPriority = this.retrievePriority(worksOrder?.statuses)
    this.priority = calculatedPriority || ''
    this.status = worksOrder?.statuses?.status || ''
    this.raisedDate = worksOrder?.raisedDate || ''
    this.isEMA = worksOrder?.statuses?.isEMA || false
    this.isCatalyst = worksOrder?.statuses?.isCatalyst || false
    this.isInDefect = worksOrder?.asset?.isInDefect || false
    this.isPotentialDuplicate = worksOrder?.statuses?.isPotentialDuplicate || false
    if(worksOrder?.customer?.vulnerability?.isVulnerable===false)
    {
      this.isVulnerable=false;
    }
    else
    {
      this.isVulnerable = worksOrder?.customer?.vulnerability?.isVulnerable || null
    } 
    //this.isVulnerable = worksOrder?.customer?.Vulnerability?.IsVulnerable || false
    this.VulnerableResponseText = worksOrder?.customer?.vulnerability?.vulnerableResponseText || null

    const appointmentStartDate =
      worksOrder?.appointments && worksOrder.appointments.length > 0
        ? worksOrder.appointments[0].appointmentStartDate
        : undefined
    this.appointmentStartDate = appointmentStartDate

    const appointmentEndDate =
      worksOrder?.appointments && worksOrder.appointments.length > 0
        ? worksOrder.appointments[0].appointmentEndDate
        : undefined
    this.appointmentEndDate = appointmentEndDate

    this.appointmentDates = this.appointmentDates = {
      appointmentStartDate,
      appointmentEndDate,
    }
    this.lastAppointmentCreatedDate = worksOrder?.appointments && worksOrder.appointments.length > 0
    ? worksOrder.appointments[0].createdDate
    : undefined  

    this.completedDate = worksOrder?.completedDate
    this.description = worksOrder?.job?.description || ''
    this.repairReason = worksOrder?.job?.repairReason || ''
    this.additionalInformation = worksOrder?.job?.additionalInformation || ''
    this.jobNo = worksOrder?.identifiers?.jobNumber || ''
    this.chargeCustomer = worksOrder?.customer?.person?.chargeCustomer || false
    this.repairCause = worksOrder?.job?.cause ? worksOrder.job.cause.cause : ''
    this.repairCauseId = worksOrder?.job?.cause ? worksOrder?.job.cause.causeId : undefined
    this.riskToHumanLife = worksOrder?.statuses?.isRiskToHumanLife || undefined
    this.crimeReferenceNumber = worksOrder?.identifiers?.crimeReferenceNumber || ''
    this.reportedById = worksOrder?.reporter?.reporterRelationship?.reportedById || undefined
    this.reportedBy = worksOrder?.reporter?.reporterRelationship?.reportedBy || ''
    this.notifyAll = worksOrder?.customer?.notification?.notifyAll || false
    this.documents.documentIdentifiers =
    worksOrder?.identifiers?.documents?.documentIdentifiers || [];
    this.staffContactEmails = this.getStaffContactEmailsFromDigitalAddress(
      worksOrder?.customer?.notification?.address?.digitalAddresses || []
    )
    this.currentStep = worksOrder ? this.calculateCurrentStep(worksOrder) : new CurrentStep()
    this.completedStep = worksOrder ? this.calculateCompletedStep(worksOrder) : new CompletedStep()
    this.reportingMethodId = worksOrder?.reporter?.reportingMethod?.reportingMethodId || 1
    this.reportingMethod = worksOrder?.reporter?.reportingMethod?.reportingMethod || ''
    this.eTag = worksOrder?.eTag || ''
    this.channel = worksOrder?.channel || ''
    this.reporterToBeContacted =
      worksOrder?.reporter?.reporterToBeContacted === true ? true : false
    this.reporterContactDetails = worksOrder?.reporter?.reporterToBeContacted
      ? {
          name:
            `${worksOrder?.reporter?.person?.name?.givenName || ''} ${worksOrder?.reporter?.person
              ?.name?.surname || ''}`,
          contactNumber: worksOrder?.reporter?.addresses?.telecomsAddresses?.[0].number || '',
          organisation: worksOrder?.reporter?.organisation || '',
          reporterToBeContacted: worksOrder?.reporter?.reporterToBeContacted,
        }
      : null

    //this.notes = []
    this.customerName = new CustomerName({
      title: worksOrder?.customer?.person?.name?.title || '',
      givenName: worksOrder?.customer?.person?.name?.givenName || '',
      surname: worksOrder?.customer?.person?.name?.surname || '',
    })
    this.premisesUniqueReference = worksOrder?.identifiers?.asset.premisesUniqueReference || ''
    this.sorCode = worksOrder?.job?.sorCode || ''
    this.raisedBy = worksOrder?.raisedBy || new RaisedBy()
    this.staffContactDigitalAddresses =
      worksOrder?.customer?.notification?.address?.digitalAddresses || []
    this.worksOrderIdentifier = worksOrder?.identifiers?.worksOrderIdentifier || ''
    this.customerIdentifier= worksOrder?.identifiers?.customerIdentifier || ''
    this.isRecall = worksOrder?.statuses?.isRecall || undefined
    this.eligibilityOutcomes = worksOrder?.eligibilityOutcomes ?? []
    this.assetIdentifier = worksOrder?.identifiers?.asset.assetIdentifier || ''
    this.documents.documentIdentifiers =
    worksOrder?.identifiers?.documents?.documentIdentifiers || [];
}


  public getStaffContactEmailsFromDigitalAddress = (digitalAddressEmails: DigitalAddress[]) => {
    const staffContactEmails: string[] = digitalAddressEmails.map(address => {
      return address.address || ''
    })
    return staffContactEmails
  }

  public retrievePriority = (statuses?: Statuses | null) => {
    if (statuses) {
      const { isEmergency, isOutOfHours, isRecall } = statuses
     // if (isRecall && recallRepairsAndGasRecallButtonEnabled()) {
     //   return 'Next Available (Recall)'
    //  }
      if (!isEmergency) {
        return 'Next Available'
      }
      if (isEmergency && !isOutOfHours) {
        return 'Emergency'
      }
      if (isEmergency && isOutOfHours) {
        return 'Emergency (Out of hours)'
      }
    }
    return ''
  }

  public calculateCompletedAndCurrentStep = (
    worksOrder: WorksOrder
  ): { currentStep: CurrentStep; completedStep: CompletedStep } => {
    if (
      worksOrder.statuses?.status !== 'Unappointed' &&
      worksOrder.statuses?.status !== 'In Diagnosis'
    ) {
      return {
        completedStep: { value: 5, key: '', displayName: '', legacyKeys: [] },
        currentStep: { value: 5, key: '', displayName: '', legacyKeys: [] },
      }
    }
    if (worksOrder.identifiers?.jobNumber) {
      return {
        completedStep: { value: 4, key: '', displayName: '', legacyKeys: [] },
        currentStep: { value: 5, key: '', displayName: '', legacyKeys: [] },
      }
    }
    if (worksOrder?.reporter && worksOrder.reporter.reportingMethod.reportingMethodId !== null) {
      return {
        completedStep: { value: 3, key: '', displayName: '', legacyKeys: [] },
        currentStep: { value: 4, key: '', displayName: '', legacyKeys: [] },
      }
    }
    if (
      worksOrder.customer?.person?.chargeCustomer !== null &&
      worksOrder.customer?.person?.chargeCustomer !== undefined
    ) {
      return {
        completedStep: { value: 2, key: '', displayName: '', legacyKeys: [] },
        currentStep: { value: 3, key: '', displayName: '', legacyKeys: [] },
      }
    }
    return {
      completedStep: { value: 1, key: '', displayName: '', legacyKeys: [] },
      currentStep: { value: 2, key: '', displayName: '', legacyKeys: [] },
    }
  }

  public calculateCurrentStep = (worksOrder: WorksOrder) => {
    const steps = this.calculateCompletedAndCurrentStep(worksOrder)
    return steps.currentStep
  }

  public calculateCompletedStep = (worksOrder: WorksOrder) => {
    const steps = this.calculateCompletedAndCurrentStep(worksOrder)
    return steps.completedStep
  }
}

export type OriginalRepair = {
  identifier?: string
  jobId?: string
  sorCode?: string
}

export type RepairEndPoint<T extends CreatedRepair | RepairDetails | RepairContact> = {
  id: string
  premisesId: string
} & T

export type RepairNote = {
  text: string
  noteTypeId: number
  noteType: string
}

export type RepairNotePayload = {
  text: string
  noteTypeId: number
}

export type RepairHistory = {
  type?: string
  identifier?: string
  statusTitle?: string
  comments?: string
  createdBy?: string
  createDateTime?: string
}

export enum RepairStatusText {
  Unappointed = 'Unappointed',
  Appointed = 'Appointed',
  InProgress = 'In progress',
  InDiagnosis = 'In Diagnosis',
  Completed = 'Completed',
  Closed = 'Closed',
  Cancelled = 'Cancelled',  
  ReportedToEMA = 'Reported to EMA',
}

export enum RepairReporterId {
  Customer = 1,
  "Friend or Family" = 2,
  MemberOfStaff = 3,
  SupportWorker = 4,
  Other = 5,
}

export enum RepairStatusField {
  Unknown="Unknown",
  InDiagnosis = 'In Diagnosis',
  Unappointed = 'Unappointed',
  Appointed = 'Appointed',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Cancelled = 'Cancelled', 
  OnHold="On Hold",
  PendingApproval = 'Pending Approval',
  ReportedToEMA = 'Reported to EMA',
  Assignedcontractor='Assigned to contractor',
  CatalystTier2Triage= 'Catalyst Tier 2 Triage',
  CatalystEMATriage= 'Catalyst Managing Agent Triage', 
  CatalystDefectTriage= 'Catalyst Defect Triage', 
  RepairStatusFieldCatalyst= 'Passed to Peabody'
}
export enum AssetRegion {
 hub ="HUB",
 hub1 = 'HUB1-REP',
 hub2 = 'HUB2-REP',
 hub3 = 'HUB3-REP',
 hub4 = 'HUB4-REP',
}

