import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import tooltip from "../../assets/icons/tooltip.png";
import "./index.css";

import Modal from "../Modal";
import Loader from "react-loader-spinner";

import Tooltip from "@mui/material/Tooltip";
import {
  storeBSRDocuments,
  storeFRADocuments,
  storeRESDocuments,
} from "../../ducks/redux/actions/historicRepair";
import { formatDate } from "../../utils/formatting";
import { googleAnalyticsTracking } from "../../utils/Analytics";
import Message from "../../storybook/Message";

interface HighRiseBuilding extends RouteComponentProps<any> {}

const HighRise: React.FC<HighRiseBuilding> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetId = useSelector(
    (state: any) => state.userChoicesReducer?.blockIdentifier
  );
  const FRAData = useSelector(
    (state: any) => state.historicRepairReducer?.FRADocuments
  );
  const BSRData = useSelector(
    (state: any) => state.historicRepairReducer?.BSRDocuments
  );
  const RESData = useSelector(
    (state: any) => state.historicRepairReducer?.RESDocuments
  );
  const [highRiseRes, setHighRiseRes] = useState<any>();
  const [popup, setPopup] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [openPAP, setOpenPAP] = useState(false);
  const [openBSM, setOpenBSM] = useState(false);
  const [openAP, setOpenAP] = useState(false);
  const [openRP, setOpenRP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHighRise, setIsHighRise] = useState(false);
  const [docToggle, setDocToggle] = useState("false");
  const docArr = [false, false];
  const [error, setError] = useState("");

  const [disablelink, setdisablelink] = useState<any>(docArr);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  const getFRAAssociateData = useCallback(async () => {
    try {
      const res = await api.getDocumentByBlockId(assetId, "FRA");
      dispatch(storeFRADocuments(res));
    } catch (e: any) {
      console.log(e);
    }
  }, []);

  const getBSRAssociateData = useCallback(async () => {
    try {
      const res = await api.getDocumentByBlockId(assetId, "BSR");
      dispatch(storeBSRDocuments(res));
      setIsHighRise(true);
    } catch (e: any) {
      console.log(e);
      
    }
  }, []);
  

  const getRESAssociateData = useCallback(async () => {
    try {
      const res = await api.getDocumentByBlockId(assetId, "RESIDENTENGAGEMENTSTRATEGY");
      dispatch(storeRESDocuments(res));
      setIsHighRise(true);
    } catch (e: any) {
      console.log(e);
      
    }
  }, []);

  const getAssociate = useCallback(async () => {
    try {
      const response = await api.GetHighRiseProperty(assetId);
      setHighRiseRes(response?.associates?.interestedParties);
    } catch (e: any) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getAssetByBlockId = async () => {
    setIsLoading(true);
    try {
      const response = await api.getAssetByCaseid(assetId);
      if (response?.statuses?.isHighRise) {
        setIsHighRise(true);
        getAssociate();
        getFRAAssociateData();
        getBSRAssociateData();
        getRESAssociateData();
      } else {
        setIsLoading(false);
      }
    } catch (e: any) {
      setIsLoading(false);
    }
  };

  const getDocumentToggleValue = async () => {
    try {
      const response = await api.GetAppConfigToggleValue(
        "mypeabodyDocumentToggle"
      );
      setDocToggle(response?.value);
    } catch (e: any) {}
  };
  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );

  useEffect(() => {
    googleAnalyticsTracking(customerIdentifier);
    getDocumentToggleValue();
    getAssetByBlockId();
  }, [assetId]);

  function toUpper(str: any) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word: any) {
        return word[0]?.toUpperCase() + word?.substr(1);
      })
      .join(" ");
  }

  const handlePAPTooltipOpen = () => {
    if (width <= 820) {
      setOpenPAP(true);
    }
  };

  const handleAPTooltipOpen = () => {
    if (width <= 820) {
      setOpenAP(true);
    }
  };

  const handleRPTooltipOpen = () => {
    if (width <= 820) {
      setOpenRP(true);
    }
  };

  const handleBSMTooltipOpen = (event: any) => {
    if (width <= 820) {
      setOpenBSM(true);
    }
  };

  const formatAddress = (str: any) => {
    const formattedText = str.split(",");
    return formattedText.map((data: any, i: number) => {
      if (i + 1 === formattedText.length) {
        return data.toUpperCase();
      }
      return data + ", ";
    });
  };

  const handleDocument = async (data: string, index: number) => {
let docId = "";
if(data==="FRA"){
  docId = FRAData?.documents[0]?.id
}
else if(data==="BSR"){
  docId = BSRData?.documents[0]?.id
}else{
  docId = RESData?.documents[0]?.id
}
    try {
      setError("");
      let newArr = [...disablelink];
      newArr[index] = true;
      setdisablelink(newArr);
      const res = await api.getBuildingSafetyDoc(assetId,docId);
      const binary = atob(res.media);
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (e: any) {
      console.log(e);
      setError("HR_BI009");
    }
  };

  return (
    <>
      <Modal open={popup} cssClasses="modalPopUp">
        <div className="py-4 px-4">
          <strong className="HighRiseText">{t("HR_BI006")}</strong>
          <strong
            className="close"
            onClick={() => setPopup(false)}
            onKeyPress={() => setPopup(false)}
          >
            X
          </strong>
          <p></p>
          <p>{t("HR_BI007")}</p>
          <p>
            {t("HR_BI007p")}{" "}
            <a
              href="https://www.peabody.org.uk/your-home/home-safety/fire-safety/high-rise-buildings/"
              target="_blank"
              rel="noopener"
            >
              {t("HR_BI007link")}
            </a>
          </p>
        </div>
      </Modal>
      {isLoading && (
        <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
      )}
      {isHighRise && !isLoading && (
        <div className="body-card row m-0 border-teal display-column">
          <h4 data-testid="highRise"> {t("HR_BI001")}</h4>
          <div className="col-md-12 p-0">
            <h5 className="pt-24 pb-20">{t("HR_BI001a")}</h5>
            {/* Yellow Alert */}

            {error?.trim() && (
              <Message className="warning-msg alert_warning ">
                {t(error)}
              </Message>
            )}

            {docToggle === "true" &&
              (BSRData?.documents?.length > 0 ||
                FRAData?.documents?.length > 0 || ((highRiseRes?.principleAccountablePerson?.person?.name?.givenName === "Peabody Trust") && RESData?.documents?.length >0)) && (
                <div
                  className="px-4 pd-top ml-HR border-tint global-txt "
                  data-testid="doc-link"
                >
                  <div className="w-100 sections__container inactive ml-HR px-3 mb-2">
                    <strong>{t("eDMS_Heading")} </strong>
                  </div>
                  {BSRData?.documents?.length > 0 && (
                    <div
                      className={
                        width > 1180 ? "flex justify-between px-3 " : "px-3 "
                      }
                    >
                      <strong>
                        <a
                          className={
                            disablelink[0]
                              ? "selectedLink a-style a-blue link-align linkSection_a"
                              : "a-style a-blue bsr-align"
                          }
                          onClick={() => handleDocument("BSR", 0)}
                          onKeyPress={() => handleDocument("BSR", 0)}
                          role="button"
                          id="BSR-Doc"
                        >
                          {t("eDMS_BSR")}
                        </a>
                      </strong>
                      <p
                        className={
                          width > 1180
                            ? "inactive linkSection_b py-1 date-align top-padding mt-0"
                            : "mt-0 devicePro document-link"
                        }
                      >
                        {t("Ins_Date")}
                        {formatDate(
                          BSRData?.documents[0]?.metadata.find(function (
                            o: any
                          ) {
                            return o.key === "inspection-date";
                          }).value || "-"
                        )}
                      </p>
                    </div>
                  )}

                  {FRAData?.documents?.length > 0 && (
                    <div
                      className={
                        width > 1180
                          ? " flex justify-between  px-3"
                          : "  px-3"
                      }
                    >
                      <strong>
                        <a
                          className={
                            disablelink[1]
                              ? "selectedLink a-style a-blue link-align linkSection_a"
                              : "a-style a-blue"
                          }
                          onClick={() => handleDocument("FRA", 1)}
                          onKeyPress={() => handleDocument("FRA", 1)}
                          role="button"
                          id="FRA-Doc"
                        >
                          {t("eDMS_FRA")}
                        </a>
                      </strong>
                      <p
                        className={
                          width > 1180
                            ? "inactive linkSection_b py-1 date-align top-padding mt-0"
                            : "mt-0 devicePro document-link"
                        }
                      >
                        {t("Ins_Date")}
                        {formatDate(
                          FRAData?.documents[0]?.metadata.find(function (
                            o: any
                          ) {
                            return o.key === "inspection-date";
                          }).value || "-"
                        )}
                      </p>
                    </div>
                  )}

                  {(highRiseRes?.principleAccountablePerson?.person?.name?.givenName === "Peabody Trust" && RESData?.documents?.length > 0) && (
                    <div
                      className={
                        width > 1180
                          ? " flex justify-between  px-3"
                          : "px-3"
                      }
                    >
                      <strong>
                        <a
                          className={
                            disablelink[1]
                              ? "selectedLink a-style a-blue linkSection_a"
                              : "a-style a-blue"
                          }
                          onClick={() => handleDocument("RES", 2)}
                          onKeyPress={() => handleDocument("RES", 2)}
                          role="button"
                          id="RES-Doc"
                        >
                          {t("eDMS_RES")}
                        </a>
                      </strong>
                      <p
                        className={
                          width > 1180
                            ? "inactive linkSection_b py-1 date-align top-padding mt-0"
                            : "mt-0 devicePro document-link"
                        }
                      >
                        {t("Res_Date")}
                        {formatDate(
                          RESData?.documents[0]?.metadata.find(function (
                            o: any
                          ) {
                            return o.key === "inspection-date";
                          }).value || "-"
                        )}
                      </p>
                    </div>
                  )}


                </div>
              )}
           
            <div className="px-4 py-2 mb-1 border-tint global-txt">
              {(highRiseRes?.principleAccountablePerson ||
                highRiseRes?.accountablePerson) && (
                <>
                  {highRiseRes?.principleAccountablePerson && (
                    <div
                      className={
                        " sections__container border-b border-grey py-1 mb-2 px-3"
                      }
                    >
                      <div className="sections__container__a inactive">
                        {" "}
                        <strong>{t("HR_BI002a")} </strong>
                      </div>

                      <div className=" sections__container__b active">
                        <Tooltip
                          title={t("HR_BI002")}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#E3F2FF",
                                color: "#000",
                                border: "1px solid #1551C1",
                                fontSize: "16px",
                                ["& .MuiTooltip-tooltip"]: {
                                  width: "500px",
                                },
                              },
                            },
                          }}
                          open={openPAP}
                          onClose={() => {
                            setOpenPAP(false);
                          }}
                        >
                          <img
                            src={tooltip}
                            alt="tooltip1"
                            className="toolInfo"
                            onClick={handlePAPTooltipOpen}
                            onKeyPress={handlePAPTooltipOpen}
                            style={{ display: "inline" }}
                            onMouseOver={() => setOpenPAP(true)}
                            onFocus={() => setOpenPAP(true)}
                            onMouseLeave={() => setOpenPAP(false)}
                          />
                        </Tooltip>
                      </div>

                      <div className="inactive sections__container__c">
                        <div className={"desc-pad"}>
                          <strong>
                            {highRiseRes?.principleAccountablePerson?.person
                              ?.name?.givenName || "-"}
                          </strong>
                        </div>
                        <div className={"desc-pad"}>
                          {highRiseRes?.principleAccountablePerson?.addresses
                            ?.digitalAddresses[0]?.postalAddresses
                            ?.formattedAddress
                            ? formatAddress(
                                toUpper(
                                  highRiseRes?.principleAccountablePerson
                                    ?.addresses?.digitalAddresses[0]
                                    ?.postalAddresses?.formattedAddress
                                )
                              )
                            : "-"}
                        </div>
                        <div className={"desc-pad"}>
                          {highRiseRes?.principleAccountablePerson?.addresses
                            ?.digitalAddresses[0]?.emailAddresses[0]?.value ||
                            "-"}
                        </div>
                      </div>
                    </div>
                  )}
                  {highRiseRes?.accountablePerson && (
                    <div
                      className={
                        "sections__container border-b border-grey py-1 mb-2 px-3"
                      }
                    >
                      <div className="sections__container__a">
                        <strong> {t("HR_BI003a")} </strong>
                      </div>

                      <div className="active sections__container__b">
                        <Tooltip
                          title={t("HR_BI003")}
                          open={openAP}
                          onClose={() => {
                            setOpenAP(false);
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#E3F2FF",
                                color: "#000",
                                border: "1px solid #1551C1",
                                fontSize: "16px",
                                ["& .MuiTooltip-tooltip"]: {
                                  width: "500px",
                                },
                              },
                            },
                          }}
                        >
                          <img
                            src={tooltip}
                            alt="tooltip2"
                            onClick={handleAPTooltipOpen}
                            onKeyPress={handleAPTooltipOpen}
                            className="toolInfo"
                            onMouseOver={() => setOpenAP(true)}
                            onFocus={() => setOpenAP(true)}
                            onMouseLeave={() => setOpenAP(false)}
                          />
                        </Tooltip>
                      </div>
                      <div className="inactive sections__container__c">
                        <div className={"desc-pad"}>
                          <strong>
                            {highRiseRes?.accountablePerson?.person?.name
                              ?.givenName || "-"}
                          </strong>
                        </div>
                        <div className={"desc-pad"}>
                          {highRiseRes?.accountablePerson?.addresses
                            ?.digitalAddresses[0]?.postalAddresses
                            ?.formattedAddress
                            ? formatAddress(
                                toUpper(
                                  highRiseRes?.accountablePerson?.addresses
                                    ?.digitalAddresses[0]?.postalAddresses
                                    ?.formattedAddress
                                )
                              )
                            : "-"}
                        </div>
                        <div className={"desc-pad"}>
                          {highRiseRes?.accountablePerson?.addresses
                            ?.digitalAddresses[0]?.emailAddresses[0]?.value ||
                            "-"}
                        </div>
                      </div>
                    </div>
                  )}
                  {highRiseRes?.buildingSafetyManager && (
                    <div
                      className={
                        "sections__container border-b border-grey py-1 mb-2 px-3"
                      }
                    >
                      <div className="sections__container__a">
                        <strong> {t("HR_BI004a")}</strong>{" "}
                      </div>

                      <div className="active sections__container__b">
                        <Tooltip
                          title={t("HR_BI004")}
                          open={openBSM}
                          onClose={() => {
                            setOpenBSM(false);
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#E3F2FF",
                                color: "#000",
                                border: "1px solid #1551C1",
                                fontSize: "16px",
                                ["& .MuiTooltip-tooltip"]: {
                                  width: "500px",
                                },
                              },
                            },
                          }}
                        >
                          <img
                            src={tooltip}
                            alt="tooltip3"
                            onClick={handleBSMTooltipOpen}
                            onKeyPress={handleBSMTooltipOpen}
                            className="toolInfo"
                            onMouseOver={() => setOpenBSM(true)}
                            onFocus={() => setOpenBSM(true)}
                            onMouseLeave={() => setOpenBSM(false)}
                          />
                        </Tooltip>
                      </div>
                      <div className="inactive sections__container__c">
                        <div className={"desc-pad"}>
                          <strong>
                            {highRiseRes?.buildingSafetyManager?.person?.name
                              ?.givenName || "-"}
                          </strong>
                        </div>
                        <div className={"desc-pad"}>
                          {highRiseRes?.buildingSafetyManager?.addresses
                            ?.digitalAddresses[0]?.postalAddresses
                            ?.formattedAddress
                            ? formatAddress(
                                toUpper(
                                  highRiseRes?.buildingSafetyManager?.addresses
                                    ?.digitalAddresses[0]?.postalAddresses
                                    ?.formattedAddress
                                )
                              )
                            : "-"}
                        </div>
                        <div className={"desc-pad"}>
                          {highRiseRes?.buildingSafetyManager?.addresses
                            ?.digitalAddresses[0]?.emailAddresses[0]?.value ||
                            "-"}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {highRiseRes?.responsiblePerson && (
                <div
                  className={
                    "sections__container border-b border-grey py-1 mb-2 px-3"
                  }
                >
                  <div className="sections__container__a">
                    <strong> {t("HR_BI005a")} </strong>{" "}
                  </div>

                  <div className="active sections__container__b">
                    <Tooltip
                      title={t("HR_BI005")}
                      open={openRP}
                      onClose={() => {
                        setOpenRP(false);
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E3F2FF",
                            color: "#000",
                            border: "1px solid #1551C1",
                            fontSize: "16px",
                            ["& .MuiTooltip-tooltip"]: {
                              width: "500px",
                            },
                          },
                        },
                      }}
                    >
                      <img
                        src={tooltip}
                        alt="tooltip4"
                        onClick={handleRPTooltipOpen}
                        onKeyPress={handleRPTooltipOpen}
                        className="toolInfo"
                        onMouseOver={() => setOpenRP(true)}
                        onFocus={() => setOpenRP(true)}
                        onMouseLeave={() => setOpenRP(false)}
                      />
                    </Tooltip>
                  </div>
                  <div className="inactive sections__container__c">
                    <div className={"desc-pad"}>
                      <strong>
                        {highRiseRes?.responsiblePerson?.person?.name
                          ?.givenName || "-"}
                      </strong>
                    </div>
                    <div className={"desc-pad"}>
                      {highRiseRes?.responsiblePerson?.addresses
                        ?.digitalAddresses[0]?.postalAddresses?.formattedAddress
                        ? formatAddress(
                            toUpper(
                              highRiseRes?.responsiblePerson?.addresses
                                ?.digitalAddresses[0]?.postalAddresses
                                ?.formattedAddress
                            )
                          )
                        : "-"}
                    </div>
                    <div className={"desc-pad"}>
                      {highRiseRes?.responsiblePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value || "-"}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="px-8 py-4 bottom-0">
            <div className="w-100">
              <a
                data-testid="repairs-Create"
                className="a-style a-blue"
                onClick={() => setPopup(true)}
                onKeyPress={() => setPopup(true)}
                id="Highrise-info"
              >
                {t("HR_BI006")}{" "}
              </a>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
      )}
    </>
  );
};

export default HighRise;
