import InputAdornment from '@material-ui/core/InputAdornment'
import React from 'react'
import { CloseIcon, CloseWrapper, SearchIcon } from '../components'

type SearchSectionProps = {
    currentValue?: string
    resetSearch?(e: React.MouseEvent): void 
    handleSearchClick?(e: React.MouseEvent): void
}

const SearchSection: React.FC<SearchSectionProps> = ({ currentValue, resetSearch, handleSearchClick }) => {
    return (
        <>
            <CloseWrapper>
                {currentValue && (
                    <InputAdornment data-bdd="close-icon" position="end" onClick={resetSearch}>
                        <CloseIcon />
                    </InputAdornment>
                )}
            </CloseWrapper>

            <InputAdornment data-bdd="search-icon" position="end" onClick={handleSearchClick}>
                <SearchIcon />
            </InputAdornment>
        </>
    )
}

export default SearchSection
