export const sortableFields = [
  {
    displayName: 'Newest to oldest',
    sortableKey: 'raisedDate desc',
  },
  {
    displayName: 'Oldest to newest',
    sortableKey: 'raisedDate asc',
  },
  
]
export default sortableFields
