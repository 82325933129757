import React, { useState, useEffect, useRef, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../assets/css/custom.scss";
import Chevronleft from "../../../assets/img/Chevronleft.png";
import mobileChevronleft from "../../../assets/img/mobileChevronleft.png";
import ListingDetailHeader from "../../../storybook/Header/ListingDetailHeader";
import { showMenuTabs } from "../../../ducks/redux/actions/userChoices";

interface CaseDetailsProps extends RouteComponentProps<any> {
    siteIdentifier?: string;
    associtaedAsset?: string;
}


const CaseDetails: React.FC<CaseDetailsProps> = (props) => {
    let moment = require("moment");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showMenuTabs(false));
      }, []);

    const caseListData = useSelector(
        (state: any) => state.historicRepairReducer?.repairHistoryData
    );

    const backButtonHandler = () => {
        dispatch(showMenuTabs(true));
        props.history.goBack();
      };

    return (
        <>
        <ListingDetailHeader name={"Listing"}></ListingDetailHeader>
        <div className="position-relative padding-repair mobile-view">
            <div className="mobile-repaircreate-yellow">
                <h2 className="rephistory-heading d-inline">
                    <span id="backmodal-p" className="back-page">
                        <a data-testid="btn_back_repair" className="back-btn" onClick={backButtonHandler} onKeyDown={backButtonHandler} >
                            <img src={Chevronleft} className="large-leftarrow" alt="Filter" />
                            <img src={mobileChevronleft} className="small-leftarrow detail-backarrow" alt="Filter" />
                        </a>
                    </span>
                    {caseListData.caseRefNo}
                </h2>
                <span className={caseListData.caseStatus === "Resolved" ? "status-style-green" : caseListData.caseStatus === "Cancelled" ? "status-style-red" : "status-style-yellow"}>
                    {caseListData.caseStatus}
                </span>
            </div>           
                <div className="row repair-details padding-repair">
                <div>
                    <h6 className="pb-6">Case owner</h6>
                                <p className="pb-12">{caseListData.caseOwner} </p>
                                <h6 className="pb-6">Date created</h6>
                                <p className="pb-12">{caseListData.dateCreated} </p>
                                <h6 className="pb-6">Request type</h6>
                                <p className="pb-12">{caseListData.requsetType} </p>                                
                    </div>
                    <div>
                        {caseListData?.caseDetailsData?.map((caseDescriptionItem) => {
                            var descriptionTxt = caseDescriptionItem?.split(' -');
                            var questionTxt = descriptionTxt[0];
                            var appendContact = "";
                            if (questionTxt === "Number") {
                                appendContact = "Contact ";
                            }
                            var ansTxt = descriptionTxt.slice(1).join(" -").trim();
                            return <> <h6 className="pb-6">{questionTxt}</h6>
                                <p className="pb-12">{ansTxt} </p></>;
                        })}
                    </div>
                    
                </div>   
        </div>
        </>
    );
};

export default CaseDetails;
