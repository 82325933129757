import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { clearReferralInformation, saveReferralAccountBalance } from "../../../ducks/redux/actions/FinancialReferral";
import Button from "../../../storybook/Button";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import { showFooter, showMenuTabs } from "../../../ducks/redux/actions/userChoices";


interface ReferralConfirmation extends RouteComponentProps<any> {

}

const EnquiryConfirmation: React.FC<ReferralConfirmation> = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.financialReferralReducer.financialReferralData)


  const buttonClickHandler = () => {
    dispatch(clearReferralInformation({}))
    props.history.push('/Customer360View');
  }
  useEffect(() => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
  }, [])



  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer p-0" id="noAppoint-container">
    <div className="padding-repair">
      <div id="noAppoint-main-div">
       
      <h1 id="noAppoint-h1" className="pt-40 header-size pb-16">{t("Financial_FI012")}</h1>
                  
       </div>
     
      <div
        id="noAppoint-div2" className="repair-reference"
      >
        <p id="noAppoint-span1" className="pb-8">
        {t("Financial_FI022")}<br /><h6>{(data?.ReferralReason)?.join(', ') ?? null}</h6>
        </p>
        <p id="noAppoint-span2" className="pb-8">
        {t("Financial_FI022a")}<br /><h6>{t('Financial Inclusion team')}</h6>
        </p>
      </div>
      </div>
      <div className="padding-repair happens-next">
            <h4>{t("What_Happen_Next")}</h4>
            <p className="pb-16">{t("Financial_FI022b")}</p>
            <p className="pb-16">{t("Financial_FI022c")}</p>
      </div>
    
    
    <div className="col-md-12 marginTop-auto" id="noAppoint-main-btn">
        <div className="row m-0 footer-actionbtn">
          <div className="col btn-top padding-repair mb-0">
         
          </div>
          <div className="col text-end pt-24 padding-repair">
          <Button
          id="btn_finish_noAppoint"
          data-testid="finshrepair"
          onClick={buttonClickHandler}
          className="primary-btn"
        >
          {t("Finish")}
        </Button>
          </div>
          </div>
          </div>
          </div>
    </>
  )
};
export default EnquiryConfirmation;