import {COMING_FROM_CASE, REFERRAL_ACCOUNT_BALANCE, CLEAR_REFERRAL_INFO, COMING_FROM_REFERRAL, REFERRAL_ADDITIONAL_DETAILS, REFERRAL_FOR, REFERRAL_INCLUSION_TEAM, REFERRAL_OTHER_DETAILS, REFERRAl_REASON, REASON_RESPONSE, IS_CASE_JOURNEY, IS_REPAIR_JOURNEY, SELECTED_SLOT, IS_TRIAGE_REPAIR } from "../actionTypes";

export const financialReferralFor = (financialReferral: string): any => {
  return { type: REFERRAL_FOR, payload: financialReferral };
};

export const FinancialReferralReasonRes = (referralReason:any[]): any => {
    return {type:REFERRAl_REASON, payload:referralReason}
}

export const FinancialInclusionTeam = (inclusionTeam:boolean):any => {
    return {type:REFERRAL_INCLUSION_TEAM, payload:inclusionTeam}
}


export const FinancialOtherDetails = (otherDetails:string):any => {
    return {type:REFERRAL_OTHER_DETAILS, payload:otherDetails}
}

export const clearReferralInformation = (data:any):any => {
    return {type: CLEAR_REFERRAL_INFO, payload:data}
}

export const comingFromReferralPage = (data:boolean):any => {
    return {type: COMING_FROM_REFERRAL, payload:data}
}

export const storeReferralAdditionalDetails = (data:any):any => {
    return {type:REFERRAL_ADDITIONAL_DETAILS, payload:data}
}

export const saveReferralAccountBalance = (data:string):any => {
    return {type:REFERRAL_ACCOUNT_BALANCE, payload:data}
} 

export const saveReasonResponse = (data:any): any => {
    return {type:REASON_RESPONSE, payload:data}
}

export const comingFromCasePage = (data:boolean):any => {
    return {type:COMING_FROM_CASE, payload:data}
}

export const comingFromCaseJourneySuccess = (data:boolean):any => {
    return {type:IS_CASE_JOURNEY, payload:data}
}

export const comingFromRepairJourneySuccess = (data:boolean):any => {
    return {type:IS_REPAIR_JOURNEY, payload:data}
}

export const setTriageRepair = (data:boolean):any => {
    return {type:IS_TRIAGE_REPAIR, payload:data}
}


export const saveSelectedSlotDate = (payload:any):any => {
    return {type:SELECTED_SLOT, payload}
}

