import React from "react";
import { ModalProps } from "react-bootstrap";
import ReactDOM from "react-dom";
import "./index.scss";

const Modal: React.FC<ModalProps> = ({ open, children, cssClasses }) => {
	if (!open) {
		return null;
	}
	return (
		<>
			<div className="overlayPopUp"></div>
			<div className={cssClasses}>
                {children}				
			</div>
		</>
	);
};

export default Modal;
