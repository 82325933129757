
import TablePagination from '@material-ui/core/TablePagination'
import styled from 'styled-components'
import SearchComp from '../../storybook/Search'
import 'bootstrap/dist/css/bootstrap.min.css';


export const Paginationbox = styled.div`
text-align: center;
float: right;
margin-top: 30px;
@media (max-width: 414px) {
    margin-right: -13px;
  }
`
export const Search = styled(SearchComp)`
height: 34px;
line-height: inherit;
padding-left: 10px;
`
export const WebPagination = styled.div`
@media (min-width: 920px) {
  display: inline;
  }
  display: none;
`
export const MobilePagination = styled.div`
@media (min-width: 920px) {
  display: none;
  }
  display: inline;
`

export const Pagination = TablePagination
