import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import ExitModalContent from "../../Modal/ExitModal";
import "./index.css";
import * as ReactBootstrap from "react-bootstrap";
import {  clearReferralInformation, comingFromReferralPage, saveReferralAccountBalance } from "../../../ducks/redux/actions/FinancialReferral";
import * as api from "../../../api";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "react-loader-spinner";
import { showFooter, showMenuTabs, userChosenAccountNumber } from "../../../ducks/redux/actions/userChoices";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import BackModal from "../../Modal/BackModal";
import ExitModal from "../../Modal/ExitModal";
import Button from "../../../storybook/Button";



interface ReviewrReferralProps extends RouteComponentProps<any> {}
const EnquiryReview: React.FC<ReviewrReferralProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(''); 
  const [contactType, setContactType] = useState("")
  

  const tableData = ['Who is the referral For?',  'Reason/s for financial support', 'Additional financial support details', "What is your preferred contact method?" ]
  const editableData = [ 'editable', 'Editable','Editable', 'Editable', 'Read Only']
  const data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)

  const referralDetail = useSelector((state:any) => state.financialReferralReducer.referralDetails)
  

  const assetId = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );

  const userProfileData = useSelector((state: any) => state?.userChoicesReducer?.userProfileData);


  const shareContactType = useSelector(
    (state: any) => state?.addresses?.customerPhoneNumber
  );

  const payRef = (useSelector(
    (state: any) => state.userChoicesReducer?.accountnumber)
  );

  const [buttondisable] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
    shareContactType?.forEach((data: any) => {
      if (data.number === contactNo) {
        setContactType(data.telecomsAddressType)
      }
    })
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    
    
  },[])

  
  
  const contactNo = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone || null
  );

  const contactName = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName || null
  );

  const contactRelation = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails || null
  );

  const referralReason = (data?.ReferralReason)?.join(', ')?? null;
  
  const contactInfo = (contactName && contactRelation) ? `${contactNo}, ${contactName}, ${contactRelation}`: contactNo

  const ansValue = [ data?.referralFor, referralReason, referralDetail, contactInfo]

  const bodyData = {
    "additionalDetails": referralDetail,
    "referralReason": data?.ReferralReason, 
    "referralFor": data?.referralFor,
    "assetIdentifier": assetId,
    "serviceRequestType": "financialinclusionenquiry",
    "preferredContact": {
      "personName": contactName,
      "relationship": contactRelation,
      "phoneNumber": contactNo,
      "phoneNumberType": contactType,
    }
  }

  const exitButtonHandler = () => {
    dispatch(clearReferralInformation({}))
    dispatch(saveReferralAccountBalance(""))
    dispatch(userChosenAccountNumber(""));
    props.history.push('/Customer360View');
  }
  const backButtonHandler = () => {
    dispatch(comingFromReferralPage(true))
    props.history.push('/ContactDetails');
  }
  const callPageHandler = async() => {
    // Call api to submit data
    try {
      setIsLoading(true)
      await api.submitFinancialInclusion(userProfileData?.customerResponse['customer-identifier'],payRef,bodyData)
      props.history.push('/enquiryconfirmation')
    }catch(e:any){
      setError('Financial_FI013d')
      
    }finally{
      setIsLoading(false)
    }
  }

  const onEditHandle = (index:number) => {
    if(index === 0){
      props.history.push('/enquiryconsent')
    }else if(index===1){
      props.history.push('/enquiryreason')
    }else if(index===2){
      props.history.push('/additionalenquirydetails')
    }else if(index===3){
      props.history.push('/ContactDetails')
    }
  }

  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer repair-request p-0" id="repAddDet-container">

      {isLoading &&<Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />}
      <div className="padding-repair">
      <div id="confirmation-main-div">
                <h1
                  data-testid="case-title"
                  className="header-size pb-30">
                  {t("Financial_FI013")}
                </h1>
                <p data-testid="case-title-t1" className="mb-4 color_black"></p>
                <p data-testid="case-title-t2" className="mb-4 color_black px-2">
                {t("Financial_FI014")}
                </p>
              </div>
                <div className="repair-text " id="confirmation-div-table">
                <ReactBootstrap.Table striped className="container-new">
                    <TableBody id="confirmation-table" className="w-full border border-grey">
                      <th className="hidden">{t("table_heading")}</th> 
                      {tableData.map((data:string, index:number)=> {
                    return (
                        <TableRow
                          id={"confirmation-tr1"}
                          className="flex items-center bg-white"
                          key={index}
                        >
                        <TableCell
                          id={'confirmation-tr1-td4'}
                          className="w-5/12 p-3 firstcol"
                          >
                          <div className="text-base padding-new color_black global-txt">
                            {data}
                          </div>
                        </TableCell>
                        <TableCell
                        id={'confirmation-tr1-td4'}
                        className="w-5/12 p-3 firstcol"
                        >
                        <div className="text-base padding-new color_black global-txt">
                          {ansValue[index]}
                        </div>
                      </TableCell>
                      <TableCell
                        id={'confirmation-tr1-td4'}
                        className="w-5/12 p-3 firstcol"
                        >
                        <div className="text-base padding-new color_black global-txt">
                          {editableData[index]==='Read Only'? '': 
                            <i
                            data-testid="question"
                            id={"confirmation-tr1-icon"}
                            className="fas fa-edit text-teal text-lg"
                            onClick={()=> onEditHandle(index)} onKeyDown={()=> onEditHandle(index)}
                          ></i>
                          }
                        </div>
                      </TableCell>
                      </TableRow>
                      )})}
                      
                    </TableBody>
                  </ReactBootstrap.Table>
                </div>
                </div>

             {/* Red Alert */}
              <div    
                data-testid="alert-warning"
                className="error px-2 py-2"
                role="alert"
                placeholder="alert-message"
              >
               {  error &&  <div className="w-full px-2 py-2 bg-red-20 flex items-center">
                    <div className="px-4 pr-8">
                      <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                    </div>
                    <div className="flex items-center">
                      <p
                        data-testid="locatioAlert_0"
                        className="w-full text-left my-2 font-AvantGardeGothic-Md"
                      >
                        {t(error)}
                      </p>
                      
                    </div>
                  </div>}
             </div> 
      {/* Exit and Continue to raise inclusion */}
      <div className="col-md-12 marginTop-auto">
        <div className="row m-0 footer-actionbtn case-footer">
          <div className="col btn-top padding-repair mb-0">
          <BackModal id="back-modal-backmodal" back={backButtonHandler} />
          <ExitModal id="repair-asset-exitmodal" exit={exitButtonHandler}/>
          </div>

          <div className="col text-end padding-repair">
          <Button
          id="btn_repairasset"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="primary-btn"
        >
          {t("continuen_button")}{" "}
        </Button>
          </div>
        </div>
      </div>    
    </div>
    </>
  );
};

export default EnquiryReview;
