import React, { ChangeEvent } from 'react';
import "./TextInputField.scss";

interface TextFieldInputProps {
  id: string;
  dataTestId: string;
  ariaLabel: string;
  type: string;
  disabled: boolean;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const TextFieldInput: React.FC<TextFieldInputProps> = ({
  id,
  dataTestId,
  ariaLabel,
  type,
  disabled,
  value,
  placeholder,
  onChange,
  style,
}) => {
  return (
    <input
      id={id}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      type={type}
      disabled={disabled}
      value={value}
      style={{ ...style, width: '100%' }}
      placeholder={placeholder}
      onChange={onChange}
      className="txtBox-Input"
    />
  );
};

export default TextFieldInput;
