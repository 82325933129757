import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classes from "./style.module.css";
import { RouteComponentProps } from 'react-router-dom';
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  loginStatus,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  selectedTenancyGroup
} from "../../ducks/redux/actions/userChoices";
import { selectedTenacyType } from "../../ducks/redux/actions/getAddress";

const b2cLoginUrl: string = process.env.REACT_APP_CSS_B2C_LOGIN_URL || "";
const b2cRegistrationUrl: string = process.env.REACT_APP_CSS_B2C_REGISTRATION_URL || "";

interface StartPageProps extends RouteComponentProps<any> {
}

const StartPage: React.FC<StartPageProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    ClearReduxStore()
    RedirectToIDAMLoginPage()
  }, []);

  const ClearReduxStore = () => {
    dispatch(loginStatus(false));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion("No"));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(selectedTenacyType(""));
    dispatch(selectedTenancyGroup(''))
    window.localStorage.clear();
  }

  const RedirectToIDAMLoginPage = () => {
    window.location.href = b2cLoginUrl;
  };
  const RedirectToIDAMRegistrationPage = () => {
    window.location.href = b2cRegistrationUrl;
  };

  return (
    <div className={classes.startPageBackground}>
      <div className={`container  ${classes.startPageContent}`} data-testid="startPgContent">
        <div className={classes.innerContainer}>
        <h1 className="text-white">{t("CR001")}</h1>
        <p className="max-w-md text-xl">{t("CR002a")}</p>
       <div className="max-w-md mb-4">
        <p className={classes.pgDetails}>{t("CP127")}</p>
        </div>
      <div className="w-48 mt-16 mt-4">
        <a onClick={RedirectToIDAMRegistrationPage}  className={`text-white border border-white p-2 pr-6 reg-btn flex justify-between w-44 items-center no-underline rounded-md ${classes.textButton}`}>
          {t("CR003")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </a>
        </div>
       
       <div className="ml-2 text-white">
        <div onClick={RedirectToIDAMLoginPage} className={classes.textLinks}>{t("CR004")}</div>
        <div className={classes.textLinks}>{t("CR005")}</div>
        </div>
      
        </div>
      </div>
    </div>
  );
};

export default StartPage;
