import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import {
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import {
  updateRouteHistory,
  userChosenSupplementaryQuestions,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  resumeDraftRepair
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as api from "../../api";
import { CurrentRepair } from "../../models/Repair/Repair";
import { PremisesTypeId } from "../../models/Customer/Premises";
import { Address, CustomerName } from "../../models/Customer";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";

import Button from "../../storybook/Button";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { resetUserChoicesWithNewChange } from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";

interface SupplementaryQuestionProps extends RouteComponentProps<any> { }
const SupplementaryQuestion: React.FC<SupplementaryQuestionProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState("");
  const [isDisabled, setButtonDisabled] = useState(false);
  const [HLRepair, setHLRepair] = useState("");
  const [subQuestion, setSubQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<any>();
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);

  const selectedRepairReasonObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject
  );

  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const userChosenSupplementaryQuestionsVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSupplementaryQuestions
  );

  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const assetId = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.customerID?.assetId
  );
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );
  const userChosenRepairLocationId = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );
  const userChosenUPRN = useSelector(
    (state: any) => state.userChoicesReducer.userChosenUPRN
  );
  const tenanciesData = useSelector(
    (state: any) => state.addresses.customertenancies
  );
  const customerDetails = useSelector(
    (state: any) => state.addresses.saveCustomerDetails
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );
  const resumeDraftRepair = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);

  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDataVal = useSelector((state: any) => state.userChoicesReducer.userResumeData);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);

  useEffect(() => {
    if(isUndefinedNullOrEmpty(loggedInStatus))
    {
      props.history.push("/startpage");
      return;
    }
    if (props.history.action === "POP") {
      setRadioValue(userChosenSupplementaryQuestionsVal);
      setButtonDisabled(false);
    }

    if (resumeDraftRepairVal) {
      if (userResumeDataVal?.resumeHLRepair) {
        setHLRepair(userResumeDataVal?.resumeHLRepair);
        setSubQuestion(userResumeDataVal?.resumeHLSubQuestion);
        userChosenSupplementaryQuestionsVal === 'Yes' ? setRadioValue('Yes') :
          userChosenSupplementaryQuestionsVal === 'No' ? setRadioValue('No') :
            setRadioValue('');
      } else {
        props.history.replace("/repairemergency");
      }
    } else {
      if (
        selectedRepairReasonObject[0]?.additionalData &&
        selectedRepairReasonObject[0]?.additionalData.length &&
        selectedRepairReasonObject[0]?.additionalData[0]?.description != ""
      ) {
        setHLRepair(selectedRepairReasonObject[0]?.description);
        setSubQuestion(
          selectedRepairReasonObject[0]?.additionalData[0]?.description
        );
        userChosenSupplementaryQuestionsVal === 'Yes' ? setRadioValue('Yes') :
          userChosenSupplementaryQuestionsVal === 'No' ? setRadioValue('No') :
            setRadioValue('');
      } else {
        props.history.replace("/repairemergency");
      }
    }
  }, []);

  let emergencyState = false;
  if (selectedproperty === "Property" && priority === "Emergency") {
    if (userChosenEmergencyQuestionVal === "Yes, I can provide access") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  } else {
    if (priority === "Emergency" || priority === "Emergency (OOH)") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  }

  const workOrderRepair = new CurrentRepair();

  const _premisesTypeId: any =
    PremisesTypeId[selectedproperty as keyof typeof PremisesTypeId];
  workOrderRepair.repairReason = selectedRepairReasonObject[0]?.description;
  workOrderRepair.description = selectedRepairReasonObject[0]?.description;

  workOrderRepair.premisesId = assetId;
  if (selectedproperty === "Divided Block") {
    workOrderRepair.premisesTypeId = "5";
  } else {
    workOrderRepair.premisesTypeId = _premisesTypeId;
  }

  workOrderRepair.isEmergency = emergencyState;
  workOrderRepair.premisesUniqueReference = userChosenUPRN;
  const _address = new Address();
  _address.addressLine1 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
  _address.addressLine2 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
  _address.addressLine3 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
  workOrderRepair.premisesAddress = _address;
  selectedRepairReasonObject[0]?.sors !== undefined
    ? (workOrderRepair.sorCode =
      selectedRepairReasonObject[0]?.sors[0]?.sorCode)
    : (workOrderRepair.sorCode = "");
  workOrderRepair.customerName = new CustomerName({
    title: customerDetails?.person?.name.title,
    givenName: customerDetails?.person?.name.givenName,
    surname: customerDetails?.person?.name.surname,
  });

  workOrderRepair.notifyAll = false;
  workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
  workOrderRepair.eligibilityOutcomes = [];
  workOrderRepair.assetIdentifier = tenanciesData?.premisesIds;
  workOrderRepair.repairLocationId = userChosenRepairLocationId;
  const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
  const woRequestParameters =
    _worksOrderRequest.createWorkOrderParameter(workOrderRepair);

  const CreateWorkOrder = async () => {
    try {
      setIsLoading(true);
      if (radioValue === "Yes") {
        woRequestParameters.isSupplementary = true;
        workOrderObject.isSupplementary = true;
      } else if (radioValue === "No") {
        woRequestParameters.isSupplementary = false;
        workOrderObject.isSupplementary = false;
      } 
      const apiResponse = await api.createWorkOrderRepair({
        ...woRequestParameters,
      });
      dispatch(workOrderRepairObject(workOrderRepair));
      if (apiResponse?.location.length > 0) {
        const workorderLocation = apiResponse?.location.split("/");
        workOrderRepair.id = workorderLocation[4];
        workOrderRepair.worksOrderIdentifier = workorderLocation[4];
        dispatch(workOrderRepairObject(workOrderRepair));
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
        setTimeout(() => {
            props.history.push("/genericerror");
        }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const UpdateWorkOrder = async () => {
    try {
      setIsLoading(true);
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.repairReason =
        selectedRepairReasonObject[0]?.description;
      woRequestParameters.description =
        selectedRepairReasonObject[0]?.description;
      if (radioValue === "Yes") {
        woRequestParameters.isSupplementary = true;
        workOrderObject.isSupplementary = true;
      } else if (radioValue === "No") {
        woRequestParameters.isSupplementary = false;
        workOrderObject.isSupplementary = false;
      }
      
      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      workOrderObject.repairReason = selectedRepairReasonObject[0]?.description;

      workOrderObject.description = selectedRepairReasonObject[0]?.description;
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
        setTimeout(() => {
            props.history.push("/genericerror");
        }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  const backButtonHandler = () => {
    props.history.push("/repairlocation");
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    if (radioValue === "") {
      props.history.replace("/customer360View");
    }

    setCloseAfterSaving(true);
    workOrderObject == null ? CreateWorkOrder() : UpdateWorkOrder();

    props.history.replace("/customer360View");
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { supplementaryQuestions } = userResumeDraftDataCache;
      if (supplementaryQuestions !== radioValue) {
        dispatch(userChosenEmergencyQuestion(""));
        dispatch(userChosenDuplicateQuestion(""));
        dispatch(userChosenAdditionalDescription(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (radioValue === "") {
      setErrorMessage(
        <div  className="pt-16">
        <Message className="error-msg">
        {t("SSR053")}
        </Message> 
        </div>     
      );
      return;
    }

    if (!resumeDraftRepairVal) {
      workOrderObject == null ? CreateWorkOrder() : UpdateWorkOrder();
    }
    props.history.push("/repairemergency");
  };

  const handleRadioChange = (event: any) => {
    setRadioValue(event.target.value);
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      // dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    }
    if (event.target.value === "No") {
      setErrorMessage(
        <div  className="pt-16">
        <Message className="error-msg">
        {t("SSR057")}
        </Message> 
        </div>    
       
      );
      setButtonDisabled(true);
    } else if (event.target.value === "Yes") {
      setErrorMessage("");
      setButtonDisabled(false);
    }
    dispatch(userChosenSupplementaryQuestions(event.target.value));
  };

  return (
    <><HeaderJourney></HeaderJourney>
    <Container id="supp-main-container" className="parent-footer repair-request" role="main">
     
      <div  className="padding-repair">
      <h1
          id="repair-asset-h1"
          className="pt-40 header-size"
        >
         { t(HLRepair)}
        </h1>
        
        {errorMessage}
      
       
        </div>
        
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={7000} //3 secs
        />
      ) : (
        <>
          <div
            id="supp-main-div"
            className="padding-repair"
          >
            <div id="supp-div1" className="pt-30 "> 
                  <p id="supp-p1" className="">
                    {subQuestion}
                  </p>               
                <RadioGroup
                  id="supp-radiogroup"
                  role="radiogroup"
                  name="radio-buttons-group"
                  defaultValue={radioValue}
                  className="pt-24"
                  value={radioValue}
                  data-testid="sureradioValue"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    className=""
                    value="Yes"
                    placeholder="Yes"
                    data-testid="yesRadio"
                    control={<Radio className="radio-yes" id="yes-radio" />}
                    label="Yes" />
                  <FormControlLabel
                    className=""
                    value="No"
                    placeholder="No"
                    data-testid="noRadio"
                    control={<Radio className="ml-0 radio-yes" id="no-radio" />}
                    label="No" />
                </RadioGroup>
              
            </div>
          
          </div>

          <div className="col-md-12 marginTop-auto" id="repAddDet-div5">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair">
              <BackModal id="custDetails-backmodal" back={backButtonHandler} />

              <ExitModalAndSave
              id="supp-exitmodal"
              exit={exitButtonHandler}
              saveDraftAndExit={saveDraftAndExit}
              closeAfterSaving={closeAfterSaving} />
            </div>
            <div className="col text-end pt-24 padding-repair">
              <Button
                id="supp-btn1"
                onClick={callPageHandler}
                disabled={isDisabled}
                className="primary-btn"
              >
                {t("continuen_button")}
              </Button>
            </div>
          </div>
        </div>         
        </>
      )}
     
    </Container></>
  );
};

export default SupplementaryQuestion;
