import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import "./index.css"
import { FinancialReferralReasonRes, clearReferralInformation, comingFromReferralPage, saveReasonResponse } from "../../../ducks/redux/actions/FinancialReferral";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FinancialReferralReason from "../../../models/FinancialInclusion/ReferralReason";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import BackModal from "../../Modal/BackModal";
import ExitModal from "../../Modal/ExitModal";
import Button from "../../../storybook/Button";
import { showFooter, showMenuTabs } from "../../../ducks/redux/actions/userChoices";
import Message from "../../../storybook/Message";


interface FinancialReason extends RouteComponentProps<any> {
  
}

const EnquiryReason: React.FC<FinancialReason> = (props:any) => {
   
  let tempReferralReasons:string[]=[];
  let [referralReasons, setReferralReasons] = useState(tempReferralReasons)
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [error, setError] = useState("");
  let data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)
  let [reasonChecked, setReasonChecked] = useState<string[]>(data?.ReferralReason);
  const [isLoader, setIsLoader] = useState(false);
  let keyValuePairs: any = "";
  let arrData : string[] = [];
  const apiResponse = useSelector((state:any) => state.financialReferralReducer.referralReasonResponse)
  const [buttondisable] = useState(false);
  useEffect(() => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    
    const fetchReferralReason = async () => {
      try{
        setIsLoader(true)
        const responseData = await api.getFinancialReferralReasonData()
        dispatch(saveReasonResponse(responseData))
           if (apiResponse) {
            keyValuePairs = responseData[0]?.namespace?.keyValues;
            for (const _data of Object.values(keyValuePairs)) {
              let obj=(_data as FinancialReferralReason);
              if ( obj.displayOnMyPeabody  == true ) {
                arrData.push(obj.referralReason)
              }
            }
            setReferralReasons(arrData)
            setReasonChecked(data?.ReferralReason)      
            setIsLoader(false)
          }    
      }catch(e:any){
        console.log("Error:", e)
      }
        
    };
    if(data?.referralFor){
      fetchReferralReason()
    }else {
      props.history.push('/enquiryconsent')
    }
  },[])
  const exitButtonHandler = () => {
    dispatch(clearReferralInformation(null))
    props.history.push('/Customer360View');
  }
  const backButtonHandler = () => {
    props.history.push('/enquiryconsent');
  }

  

  const callPageHandler = () => {
    if(reasonChecked?.length === 0){
      setError("Financial_FI008a")
    }else {
      dispatch(FinancialReferralReasonRes(reasonChecked))
      dispatch(comingFromReferralPage(true))
      props.history.push('/additionalenquirydetails')
    }
  }

 
 const handleChange = (event:any) => {
  setError("")
  if(event.target.checked){
    reasonChecked.push(event.target.name) 
    dispatch(FinancialReferralReasonRes(reasonChecked))
  }
  else{
    const index = reasonChecked.indexOf(event.target.name);
    if (index > -1) { 
      reasonChecked.splice(index, 1); 
    }
    dispatch(FinancialReferralReasonRes(reasonChecked))
  }  
 }

  
  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer repair-request p-0" id="repAddDet-container">
        {/* Main Page Content */}
        <div className="padding-repair">
            <div id="repreason-div1">
              <h1 className="header-size pb-30">
                 {t("Financial_FI020")} 
              </h1>
            </div> 

           {/* Yellow Alert */}
            {error && <div data-testid="alert-warning">
                      <Message className="warning-msg">{t(error)}</Message>    
            </div>}
        
        { isLoader?
           <div className="div-loader">
           <div className="SpinnerWrapper">
               <LoadingSpinner />
           </div>
           </div>:
          <Box>
            <FormControl sx={{ m: 1 }} variant="standard" >
            
              <FormGroup>
              {
                referralReasons?.slice(0,(Math.ceil(referralReasons.length/2))).map((item : string, index:number) => (
                  <FormControlLabel
                   data-testid="reasondata"
                    control={
                      <Checkbox checked={data?.ReferralReason?.includes(item)} onChange={handleChange} name={item} data-testid="checkbox-1234" />
                    }
                   label={item}
                   key={index}
                  />
                ))
              }
              </FormGroup>
            
            </FormControl>
            <FormControl
              sx={{ m: 1 }}
              variant="standard"
            >   
              <FormGroup className="MobileView">
              {
                referralReasons?.slice((Math.ceil(referralReasons.length/2))).map((item : string, index:number) => (
                  <FormControlLabel      
                    control={
                      <Checkbox checked={data?.ReferralReason?.includes(item)} onChange={handleChange} name={item} data-testid="checkbox-1234" />
                    }
                    label={item}
                    key={index}
                  />
                ))
              }
              </FormGroup>
              
            </FormControl>
          </Box>   
        }
       

           
         </div>
        {/* Exit and Continue SMS button */}
        <div className="col-md-12 marginTop-auto">
        <div className="row m-0 footer-actionbtn case-footer">
          <div className="col btn-top padding-repair mb-0">
          <BackModal id="back-modal-backmodal" back={backButtonHandler} />
          <ExitModal id="repair-asset-exitmodal" exit={exitButtonHandler}/>
          </div>

          <div className="col text-end padding-repair">
          <Button
          id="btn_repairasset"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="primary-btn"
        >
          {t("continuen_button")}{" "}
        </Button>
          </div>
        </div>
      </div>
    </div>
  </>
  )
};
export default EnquiryReason;