export enum EligibilityErrorCode {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
  E5 = 'E5',
  E8 = 'E8',
  E9 = 'E9',
  E20 = 'E20',
  E21 = 'E21',
  E22 = 'E22',
  E23 = 'E23',
  E24 = 'E24',
  E25 = 'E25',
  E28 = 'E28',
  E29 = 'E29',
  E30 = 'E30',
  E31 = 'E31',
  E32 = 'E32',
  SSR037='SSR037',
  SSR005 = 'SSR005',
  SSR069 = 'SSR069'
}
export class EligibilityOutcome {
  public eligibilityType: string = ''
  public isEligible: boolean = true
  public errorCode: EligibilityErrorCode | null = null
  public timestamp: string = ''
  public version: string = ''
}
export class CancelEligibilityOutcome {
  public eligibilityType: string = ''
  public isEligible: boolean = true
  public errorCode: EligibilityErrorCode | '' = ''
  public timestamp: string = ''
  public version: string = ''
}

export type EligibilityResponse = EligibilityOutcome[]
