import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch,useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import "./index.scss";
import Chevronleft from "../../../assets/img/Chevronleft.png";
import mobileChevronleft from "../../../assets/img/mobileChevronleft.png";
import { formatDateWithMonthInLetters } from "../../../utils/formatting";
import moment from "moment";
import { fetchChosenStatement, setUserSelectedMessageDate, setUserSelectedMessageDetails, setUserSelectedMessageDocumentIdentifier, setUserSelectedMessageNotificationType, setUserSelectedMessageSender, setUserSelectedMessageSubject } from "../../../ducks/redux/actions/userChoices";
import * as api from "../../../api";

interface MessageDetailsProps extends RouteComponentProps<any> {}

const MessageDetails: React.FC<MessageDetailsProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const userSelectedMessageDetails = useSelector(
    (state: any) => state?.userChoicesReducer?.messageDetails
  );

  const userSelectedMessageSubject = useSelector(
    (state: any) => state?.userChoicesReducer?.messageSubject
  );

  const userSelectedMessageDate = useSelector(
    (state: any) => state?.userChoicesReducer?.messageDate
  );

  const userSelectedMessageSender = useSelector(
    (state: any) => state?.userChoicesReducer?.messageSender
  );

  const userSelectedMessageNotificationType = useSelector(
    (state: any) => state?.userChoicesReducer?.messageNotificationType
  );

  const documentID = useSelector(
    (state: any) => state?.userChoicesReducer?.messageDocumentIdentifier
  );

  const userSelectedMessageStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.messageStatus
  );

  const [yellowAlertMessage, setYellowAlertMessage] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);

  const MonthYear = moment(userSelectedMessageDate).format('MMMM YYYY');


  const backButtonHandler = () => {
    dispatch(setUserSelectedMessageDetails(""));
    dispatch(setUserSelectedMessageSubject(""));
    dispatch(setUserSelectedMessageDate(""));
    dispatch(setUserSelectedMessageSender(""));
    dispatch(setUserSelectedMessageNotificationType(false));
    dispatch(setUserSelectedMessageDocumentIdentifier(""));
    props.history.push("/mymessageshistory");
  };

  const fetchStatement = () => {
    const data = {
        setYellowAlertMessage,
        setIsLoading,
        MonthYear,
        customerID,
        documentID
    }
    dispatch(fetchChosenStatement(data));
}

const customerIdentifier = useSelector(
  (state: any) => state.addresses?.customerid
);

useEffect(() => {
  if(userSelectedMessageStatus === "UNREAD"){
    (async () => {
      const status = {
        status: "READ",
      };
      const communicationID = documentID;
      await api
        .patchCommunicationMessages(customerID, communicationID, status)
        .then((response) => {
        })
        .catch((error) => {});

    })();
  }
}, []);


  return (
    <div className="position-relative padding-repair message-detail-heading">
      <h2 className="rephistory-heading d-inline">
        <span id="backmodal-p" className="back-page">
          <a
            data-testid="btn_back_repair"
            className="back-btn"
            onClick={backButtonHandler}
            onKeyDown={backButtonHandler}
          >
            <img src={Chevronleft} className="large-leftarrow" alt="Filter" />
            <img
              src={mobileChevronleft}
              className="small-leftarrow"
              alt="Filter"
            />
          </a>
        </span>
        <span>{userSelectedMessageSubject}</span>
      </h2>
      <div className="row detail-sender-date-margin">
        <div className="col-md-6 message-details-header">{userSelectedMessageSender}</div>
        <div className="col-md-6 detail-date-align message-details-header">
          {formatDateWithMonthInLetters(userSelectedMessageDate)}
        </div>
      </div>
      <div className="row detail-text-margin">
        <div className="col-md-12">{userSelectedMessageDetails}</div>
      </div>
      <div className="pt-30">
        {userSelectedMessageNotificationType && (
          <div
            className="secondary-ghost-btn"
            onClick={fetchStatement}
            onKeyDown={fetchStatement}
            data-testid="view-statement"
          >
            {t("View Statement")}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageDetails;
