export const sortableFields = [
    {
      displayName: 'Newest to oldest',
      sortableKey: 'createdDate desc',
    },
    {
      displayName: 'Oldest to newest',
      sortableKey: 'createdDate asc',
    },
    
  ]
  export default sortableFields
  