import React, { useEffect, useCallback, useState, lazy, Suspense } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackModal from "../../Modal/BackModal";
import {
  CASE_COUNTER,
  CASE_CREATION_QUESTIONS,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
} from "../../../ducks/redux/actionTypes";
import { RouteComponentProps } from "react-router-dom";
import {
  VANDALISM_CASE_QUESTION,
  REFERENCE_CASE_QUESTION_1,
  REFERENCE_CASE_QUESTION_2,
  TENANCY_AGREEMENT_CASE_QUESTION,
  HOUSEHOLD_OR_SUBLETTING_MEMBER,
  LEASEHOLD_OWNERSHIP_RADIO,
  COVID_CASE_QUESTION,
} from "./Question";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import Message from "../../../storybook/Message";

const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../storybook/Button"));
const ExitModal = lazy(() => import("../../Modal/ExitModal"));

interface radioProps extends RouteComponentProps<any> { }

const RadioTypeQuestion: React.FC<radioProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let errorCode = "";
  const dispatch = useDispatch();

  const [blockingIsVisible, setBlockingIsVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [showError, setShowError] = useState("");
  const [blockingAlert, setBlockingAlert] = useState("");
  const blockingArray: any[] = [];
  const [vandalism, setVandalism] = useState("false");
  let reportVandalism: any;
  let referenceCase: any;
  const [reference, setReference] = useState(0);
  let tenancyAgreement: any;
  let householdMemberOrSublettingCase: any;
  let leasehold: any;
  const [tenancyAgree, setTenancyAgree] = useState(false);
  const [sublettingCase, setSublettingCase] = useState(false);
  const [leaseholdOwnership, setLeaseholdOwnership] = useState("");

  const [isDisabled, setButtonDisabled] = useState(false);

  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );

  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );

  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );

  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );

  let count = useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );

  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationAdditionalInfo
  );

  if (questionInfoData && questionInfoData[count]?.blocking !== null) {
    blockingArray.push(questionInfoData[count]?.blocking);
    errorCode = questionInfoData[count]?.blocking?.errorCode;
  }

  // === Validating vandalism ===

  if (
    questionInfoData &&
    questionInfoData[count]?.label === VANDALISM_CASE_QUESTION
  ) {
    reportVandalism = "false";
  }

  //=== Validating reference ===

  if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_1
  ) {
    referenceCase = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_2
  ) {
    referenceCase = "2";
  }

  // Validating tenancy agreement question

  if (
    questionInfoData &&
    questionInfoData[count]?.label === TENANCY_AGREEMENT_CASE_QUESTION
  ) {
    tenancyAgreement = "1";
  }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_MEMBER
  ) {
    householdMemberOrSublettingCase = "1";
  }

  // Leasehold and ownership member

  if (
    questionInfoData &&
    questionInfoData[count]?.label === LEASEHOLD_OWNERSHIP_RADIO
  ) {
    leasehold = "1";
  }

  const assignedSelectedAnswersValue = useCallback(() => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        questionInfoData[index]?.options?.map((opt: any, id: number) => {
          if (opt.id === fetchAnswer[index]) {
            setRadioValue(opt.value);
          }
        });
      }
    });
  }, [count]);

  const assignedCounterComesFromEdit = () => {
    if (referenceCase === "1" && ansArray[count] === "1") {
      setReference(1);
    } else if (referenceCase === "1" && ansArray[count] === "2") {
      setReference(2);
    } else if (referenceCase === "2" && ansArray[count] === "1") {
      setReference(3);
    } else if (referenceCase === "2" && ansArray[count] === "2") {
      setReference(4);
    }
  };

  const assignVandalismComesFromEdit = () => {
    if (reportVandalism === "false" && ansArray[count] === "2") {
      setVandalism("true");
    } else if (reportVandalism === "false" && ansArray[count] === "1") {
      setVandalism("false");
    }
  };

  const assignSublettingCaseComesFromEdit = () => {
    if (householdMemberOrSublettingCase === "1" && ansArray[count] === "1") {
      setSublettingCase(true);
    } else {
      setSublettingCase(false);
    }
  };

  const assignLeaseholdOwnership = () => {
    if (leasehold === "1" && ansArray[count] === "1") {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && ansArray[count] === "2") {
      setLeaseholdOwnership("2");
    }
  };
  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
    assignedCounterComesFromEdit();
    assignSublettingCaseComesFromEdit();
    assignVandalismComesFromEdit();
    assignLeaseholdOwnership();
  }, [count]);

  const handleRadioChange = (e: any) => {
    setButtonDisabled(false);
    setRadioValue(e.target.value);
    setShowError("");

    if (e.target.value !== ansArray[count]) {
      //Removing further answers ans questions
      fetchQuestion.splice(count, fetchQuestion.length);
      fetchAnswer.splice(count, fetchAnswer.length);
      dispatch({ type: CASE_EDIT, payload: true });
    }
    setRadioValue(e.target.value);
    setAnsArray([...ansArray, e.target.name]);
    setShowError("");
    // ==== If user is changing the answer for the same question, need to get index and replace the value ===
    let newArr = [...ansArray]; // copying the old datas array
    newArr[count] = e.target.name; // replace e.target.value with new value
    setAnsArray(newArr);

    // ==== Validate not to display next question, if user select dependent options ====

    if (reportVandalism === "false" && e.target.name === "2") {
      setVandalism("true");
    } else if (reportVandalism === "false" && e.target.name === "1") {
      setVandalism("false");
    }

    // === Validating reference case condition here ===

    if (referenceCase === "1" && e.target.name === "1") {
      setReference(1);
    } else if (referenceCase === "1" && e.target.name === "2") {
      setReference(2);
    } else if (referenceCase === "2" && e.target.name === "1") {
      setReference(3);
    } else if (referenceCase === "2" && e.target.name === "2") {
      setReference(4);
    }

    // Validating tenancy agreement

    if (tenancyAgreement === "1" && e.target.name === "2") {
      setTenancyAgree(true);
    }

    // Validating Subletting house member

    if (householdMemberOrSublettingCase === "1" && e.target.name === "1") {
      setSublettingCase(true);
    } else {
      setSublettingCase(false);
    }

    // Validating leasehold question

    if (leasehold === "1" && e.target.name === "1") {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && e.target.name === "2") {
      setLeaseholdOwnership("2");
    }

    if (blockingArray.length > 0) {
      if (blockingArray[0].dependsOn[0].responseId === e.target.name) {
        setBlockingAlert(t(errorCode));
        setBlockingIsVisible(false);
      } else {
        setBlockingAlert("");
      }
    }
  };

  const onContinueHandler = () => {
    if (
      questionInfoData &&
      questionInfoData[count]?.label === COVID_CASE_QUESTION &&
      radioValue === ""
    ) {
      setShowError(t("Case_Covid_CC007"));
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
      // Validating question count according to user selection
      if (
        vandalism === "true" ||
        reference === 2 ||
        reference === 3 ||
        tenancyAgree === true ||
        leaseholdOwnership === "1"
      ) {
        count = count + 2;
      } else if (reference === 4) {
        count = count + 3;
      } else if (
        sublettingCase === false &&
        householdMemberOrSublettingCase === "1"
      ) {
        count = questionInfoData.length;
      } else if (leaseholdOwnership === "2") {
        count = questionInfoData.length;
      } else {
        count = count + 1;
      }

      if (!blockingAlert) {
        if (radioValue === "") {
          setShowError(t("Case_Question_Error"));
        } else {
          dispatch({
            type: CASE_COUNTER,
            payload: count,
          });
          dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
          dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });
          setRadioValue("");
          history.push("/caseQuestionnaire");
        }
      } else {
        setBlockingIsVisible(true);
      }
    }
  };

  const backButtonHandler = () => {
    if (count >= 1) {
      if (leasehold === "1") {
        count = count - 2;
      } else if (householdMemberOrSublettingCase === "1") {
        count = count - 4;
      } else {
        count = count - 1;
      }
      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });
      history.push("/caseQuestionnaire");
    } else {
      history.push("/caseType");
    }
  };

  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    history.push("/customer360View");
  };

  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div id="reploc-container" className="container-fluid parent-footer p-0" data-testid="case-div1">
          <Suspense
          fallback={
            <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
          }
        >
          {!COVID_CASE_QUESTION && (
            <div className="padding-repair" id="repreason-div1">
              <h1
                data-testid="title"
                className="header-size"
              >
                {descData}
              </h1>
            </div>
          )}

          <div className="padding-repair" key={questionInfoData && questionInfoData[count]?.questionId}>
            {COVID_CASE_QUESTION ? (
              <>
              <h1
                id="repreason-div1"
                className="header-size"
                data-testid="label"
              >
                {t(questionInfoData && questionInfoData[count]?.label)}
              </h1>
               {showError && (
                <div className="question-error">
                <Message className="error-msg" data-testid="locatioAlert_0">
                   {t(showError)}
              </Message>
              </div>
                )}
            </>
            ) : (
              <h1 data-testid="case-q1" className="header-size">
                {t(questionInfoData && questionInfoData[count]?.label)}
              </h1>
            )}
            <RadioGroup
              data-testid="radioGroup"
              key={questionInfoData && questionInfoData[count]?.questionId}
              defaultValue={radioValue}
              className="pt-44"
              value={radioValue}
              onChange={handleRadioChange}
            >
              {questionInfoData &&
                questionInfoData[count]?.options?.map((e: any) => {
                  return (
                    <FormControlLabel
                      id={e.id}
                      key={e.id}
                      value={e.value}
                      data-testid="formControl"
                      control={
                        <Radio
                          id={`covidQues-radio1`}
                          placeholder="radioLabel"
                          name={e.id}
                        />
                      }
                      label={e.value}
                    />
                  );
                })}
            </RadioGroup>
          </div>
          {/* ==== Blocking alert ===== */}

          {blockingAlert && (
            <div
              data-testid="alert-warning"
              className={
                blockingIsVisible
                  ? "warning mb-8 unblockingMessage"
                  : "blockingMessage padding-repair pt-16"
              }
              role="alert"
              placeholder="alertNo"
            >
              <p>
                {t(blockingAlert)}
              </p>
            </div>
          )}
          {/* ==== End blocking alert ==== */}

          <div className="col-md-12 marginTop-auto" id="repreason-main-btn">
            <div className="row m-0 footer-actionbtn case-footer">
              <div className="col btn-top padding-repair mb-0">
                <BackModal
                  id="reploc-backmodal"
                  back={backButtonHandler}
                  btn_msg="return_case_button"
                />
                <ExitModal
                  data-testid="case-exitmodal"
                  exit={exitButtonHandler}
                  btn_msg="return_case_button"
                />
              </div>
              <div className="col text-end padding-repair">
                <Button
                  onClick={onContinueHandler}
                  data-testid="btn_caseQ"
                  className="primary-btn"
                  disabled={isDisabled}
                >
                  {t("continue_button")}
                </Button>
              </div>
            </div>
          </div>
          <div>
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default RadioTypeQuestion;
