import { AnyAction } from "redux";
import { COMING_FROM_CASE, REFERRAL_FOR, REFERRAL_INCLUSION_TEAM, REFERRAl_REASON, REFERRAL_OTHER_DETAILS, CLEAR_REFERRAL_INFO, COMING_FROM_REFERRAL, REFERRAL_ADDITIONAL_DETAILS, REFERRAL_ACCOUNT_BALANCE, REASON_RESPONSE, IS_CASE_JOURNEY, IS_REPAIR_JOURNEY, SELECTED_SLOT, IS_TRIAGE_REPAIR } from "../actionTypes";

const initialState = {
    financialReferralData: {
        referralFor: "",
        FinancialInclusionTeam:"",
        ReferralReason: [],
        ReferralOtherDetails:"",
    },
    isComingFromReferral:false,
    referralDetails:"",
    referralAccountBalance:"",
    referralReasonResponse: [],
    isComingFromCase: false,
    isCaseJourneyMessage: false,
    isRepairJourneyMessage: false,
    
    
}


export const FinancialReferralReducer = (state: any = initialState, action: AnyAction) => {
    switch (action.type) {
        case REFERRAL_FOR:
            return {
                ...state,
                financialReferralData: {
                    ...state.financialReferralData,
                    referralFor: action.payload
                  }
            };
        case REFERRAL_INCLUSION_TEAM:
            return {
                ...state,
                financialReferralData: {
                    ...state.financialReferralData,
                    FinancialInclusionTeam:action.payload
                }
            }
        case REFERRAl_REASON:
            return {
                ...state,
                financialReferralData: {
                    ...state.financialReferralData,
                    ReferralReason:action.payload
                }
            } 
        case REFERRAL_OTHER_DETAILS:
            return {
                ...state,
                financialReferralData: {
                    ...state.financialReferralData,
                    ReferralOtherDetails:action.payload
                }
            } 
        case CLEAR_REFERRAL_INFO:
            return {
                ...state,
                financialReferralData: {
                    referralFor: "",
                    FinancialInclusionTeam:"",
                    ReferralReason: [],
                    ReferralOtherDetails:"",
                    

                },
                isComingFromReferral:false,
                referralDetails:"",
                referralReasonResponse: [],
                isComingFromCase:false
            } 
            
        case COMING_FROM_REFERRAL:
            return {
                ...state,
                isComingFromReferral: action.payload
            } 
        case REFERRAL_ADDITIONAL_DETAILS:
            return {
                ...state,
                referralDetails: action.payload
            }   
        case REFERRAL_ACCOUNT_BALANCE:
            return {
                ...state,
                referralAccountBalance:action.payload
            } 
        case REASON_RESPONSE:
            return {
                ...state,
                referralReasonResponse:action.payload
            } 
        case COMING_FROM_CASE: 
            return {
                ...state,
                isComingFromCase:action.payload
            } 
            case IS_CASE_JOURNEY:
            return {
                ...state,
                isCaseJourneyMessage: action.payload
            }    
        case IS_REPAIR_JOURNEY:
                return {
                    ...state,
                    isRepairJourneyMessage: action.payload
                }  
        case SELECTED_SLOT:
            return {
                ...state,
                selectedDateSlot:action.payload
            } 
            case IS_TRIAGE_REPAIR:
                return {
                    ...state,
                    isTriageRepair:action.payload
                }                                              
        default:
            return state;
    }

};