import React, { useState, useEffect, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  RepairReporterId,
  ReportContactDetails,
} from "../../../models/Repair/Repair";

import { isUndefinedNullOrEmpty } from "../../../utils/translation/helper";
import {
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenReporterId,
  showMenuTabs,
  showFooter,
} from "../../../ducks/redux/actions/userChoices";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CLEAR_CASE_STATE,
} from "../../../ducks/redux/actionTypes";
import { CaseReporterId } from "../../../models/Case/Case";
import { RouteComponentProps, useHistory } from "react-router-dom";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import TextInputField from "../../../storybook/TextInputField/TextInputField";
import Dropdown from "../../../storybook/Dropdown";
import Message from "../../../storybook/Message";
import BackModal from "../../Modal/BackModal";
import ExitModal from "../../Modal/ExitModal";
import { isNullOrUndefined } from "../../../utils/formatting";
import { clearReferralInformation } from "../../../ducks/redux/actions/FinancialReferral";


const Loader = React.lazy(() => import("react-loader-spinner"));
const Button = React.lazy(() => import("../../../storybook/Button"));

interface ContactDetailsProps extends RouteComponentProps<any> {}
const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
  const { t } = useTranslation();
  const contactDetailsOptions = [
    { id: "custDetails-op1", label: t("Customer_Option") },
    { id: "custDetails-opt2", label: t("Friend_Option") },
    { id: "custDetails-opt3", label: t("Other_Option") },
  ];
  const customerPhone = useSelector(
    (state: any) => state?.addresses?.customerPhoneNumber
  );

  const [inputState, setInputState] = useState<any>(t("Customer_Option"));
  let indexFound = 0;
  const dispatch = useDispatch();
  const history = useHistory();
  const [userInput, setUserInput] = useState("");
  var errorArray: any[] = [];
  const [errorArrayState, seterrorArrayState] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberInput, setNumberInput] = useState("");
  const [radiovalue, setRadioValue] = useState(
    customerPhone[0]?.number
      ? `${customerPhone[0].number}@0`
      : t("Case_Provide_Number")
  );
  const radioText = t("Case_Provide_Number");
  const isinputdisabled = radiovalue != radioText;
  const userChosenContactDetailsBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactDetails
  );
  
  const userChosenContactNameBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );
  const userChosenContactPhoneBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone
  );

  const userChosenRelationSelect = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails
  );

  const userChosenIsReporter = useSelector(
    (state: any) => state.userChoicesReducer.userChosenIsReporter
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );

  const isEdit = useSelector(
    (state: any) => state?.caseCreationReducer?.isCaseEdit
  );

  const selectedadditionalInfo = useSelector(
    (state: any) => state?.caseCreationReducer?.caseCreationAdditionalInfo
  );

  const selectedAnswers = useSelector(
    (state: any) => state?.caseCreationReducer?.caseSelectedAnswers
  );

  const referral = useSelector( (state: any) => state?.financialReferralReducer?.isComingFromReferral)
  const caseFlag = useSelector( (state: any) => state?.financialReferralReducer?.isComingFromCase)
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  
  useEffect(() => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
  }, [])

  useEffect(() => {
    if(!referral && !caseFlag){
      props.history.push('/Customer360View')
    }
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage");
      return;
    }
    if (props.history.action === "POP" || props.history.action === "REPLACE" || props.history.action === "PUSH") {
      if(userChosenContactDetailsBackButton){
        
        if(customerPhone[0]?.number === userChosenContactDetailsBackButton ){
          setRadioValue(`${customerPhone[0].number}@0`)
        }else {
          setRadioValue(userChosenContactDetailsBackButton);  
        } 
      }
        setUserInput(userChosenContactNameBackButton);
    }
    if (!userChosenRelationSelect) {
      dispatch(userChosenRelationDetails(t("Customer_Option")));
    }
    if (
      userChosenContactNameBackButton?.trim().length > 0 &&
      isEdit === false && referral === false
    ) {
      setRadioValue(t("Case_Provide_Number"));
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
    } else if (userChosenIsReporter) {
      setRadioValue(t("Case_Provide_Number"));
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
      if (userChosenRelationSelect === "Friend or family") {
        dispatch(userChosenRelationDetails("Friend or Family"));
      } else {
        dispatch(userChosenRelationDetails(userChosenRelationSelect));
      }
    } else if (
      !userChosenContactDetailsBackButton &&
      !userChosenContactPhoneBackButton && !isNullOrUndefined(customerPhone)
    ) {
      if (customerPhone !== null && customerPhone !== undefined) {
        dispatch(userChosenContactDetails(`${customerPhone[0]?.number}`));
      }
      else {
        dispatch(userChosenContactDetails(t("Case_Provide_Number")));
      }
      dispatch(userChosenContactDetails(`${customerPhone[0]?.number}`));
      dispatch(userChosenRelationDetails(""));
    } else if (userChosenContactNameBackButton && isEdit === false) {
      setRadioValue(t("Case_Provide_Number"));
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
    } else {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
    }

    if (userChosenRelationSelect) {
      setInputState(userChosenRelationSelect);
    }
  }, []);

  const updateSelection = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value != t("Case_Provide_Number")) {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      setNumberInput("");
      setUserInput("");
      seterrorArrayState([]);
      errorArray = [];
    } else if (e.target.value == t("Case_Provide_Number")) {
      dispatch(userChosenRelationDetails("Customer"));
    }
    setRadioValue(e.target.value);
    dispatch(userChosenContactPhone(e.target.value));
    dispatch(userChosenContactDetails(e.target.value));
  };
  const inputChangeHandler = (event: any) => {
    const re = /^[+0-9]+$/;
    // if value is not blank, then test the regex
    seterrorArrayState([]);
    errorArray = [];
    if (event.target.value === "" || re.test(event.target.value)) {
      //  @ts-ignore
      setNumberInput(event.target.value.toString().slice(0, 11));
      dispatch(
        userChosenContactPhone(event.target.value.toString().slice(0, 11))
      );
    }
  };

  const numberValidate = () => {
    if (isinputdisabled) {
      if (radiovalue != t("Case_Provide_Number")) {
        dispatch(
          userChosenContactPhone(
            radiovalue.toString().slice(0, 11)
          )
        );
      } else {
        dispatch(
          userChosenContactPhone(numberInput?.length > 0 ? numberInput : customerPhone[0]?.number)
        );
      }
      // Adding referral condition
      if(referral){
        const radioUpdatedValue = radiovalue.split("@")
        dispatch(userChosenContactPhone(radioUpdatedValue[0]));
        props.history.push("/enquiryreview");
      }else {
        props.history.push("/casereview");
      }

      if (radiovalue != t("Case_Provide_Number")) {
        dispatch(userChosenContactName(""));
        dispatch(userChosenRelationDetails(""));
        dispatch(userChosenContactName(""));
        dispatch(userChosenReporterId(""));
      }
      return;
    }
    const phoneEmpty =  referral? t('Financial_FI016c') : t("Case_CC010");
    const phoneInvalid = t("SSR002");

    const ukCountryCode = "0";
    if (!numberInput) {
      errorArray.push(phoneEmpty);
      seterrorArrayState(errorArray);
      textvalidate();
      return;
    }
    if (numberInput.slice(0, 1) != ukCountryCode || numberInput.length < 11) {
      errorArray.push(phoneInvalid);
      seterrorArrayState(errorArray);
      textvalidate();
      return;
    }
    textvalidate();
    errorArray = [];
  };
  const nameError = t("Case_CC011");
  const textvalidate = () => {
    if (!userInput || (userInput && userInput.length <= 0)) {
      errorArray.push(nameError);
      seterrorArrayState(errorArray);
      return;
    }
    if (errorArray.length == 0) {
      if (inputState === t("Customer_Option")) {
        dispatch(
          userChosenReporterId(
            CaseReporterId["Customer" as keyof typeof CaseReporterId]
          )
        );
      }
      dispatch({ type: CASE_EDIT, payload: false });
      if (!userChosenRelationSelect) {
        dispatch(userChosenRelationDetails(t("Customer_Option")));
      }
      if(referral){
        props.history.push("/enquiryreview");
      }else {
        props.history.push("/caseReview");
      }
    }
  };

  const clearCaseDataFromRedux = () => {
    dispatch({ type: CLEAR_CASE_STATE });
  };

  const backButtonHandler = () => {
    if(referral){
      dispatch(userChosenContactPhone(radiovalue));
      props.history.push("/additionalenquirydetails");
    }else {
      dispatch({ type: CASE_COUNTER, payload: selectedAnswers?.length - 1 });
      props.history.push("/caseQuestionnaire");
    }
  };

  const onExitHandler = () => {
    clearCaseDataFromRedux();
    dispatch(clearReferralInformation({}))
    props.history.push("/customer360View");
  };

  const nameInputChangeHandler = (e: any) => {
    const re = /^[-A-Za-z' \b]+$/;
    seterrorArrayState([]);
    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      setUserInput(e.target.value);
      dispatch(userChosenContactName(e.target.value));
    }
  };

  const onContinueHandler = () => {
    seterrorArrayState([]);
    numberValidate();
  };

  const handleChange = (e: { target: { value: any } }) => {
    if (inputState != e.target.value) {
      const state: string = RepairReporterId[e.target.value];
    }
    setInputState(e.target.value);
    dispatch(userChosenRelationDetails(e.target.value));
    dispatch(
      userChosenReporterId(
        CaseReporterId[e.target.value as keyof typeof CaseReporterId]
      )
    );
  };

  let errorfieldsArray: any[] = [];
  errorArrayState?.forEach((err: any) => {
    errorfieldsArray.push(
      <Message className="warning-msg">{err}</Message>  
    );
  });
  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div
        className="container-fluid parent-footer"
        id="custDetails-container"
        role="main"
      ><h1 id="custDetails-h1"
      className=" text-purple-100 text-2xl global-txt">
      { referral? t('Financial_FI016') :  t("Case_CC008")}
      </h1>
        <Suspense
          fallback={
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={50}
              width={50}
              timeout={5000}
            />
          }
        >
          <div
            id="custDetails-div5"
            role="alert"
            placeholder="alert"
            className="detail-msg">
            {errorfieldsArray?.length > 0 && (
             errorfieldsArray
            )}
            {isLoading ? (
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={50}
                width={50}
                timeout={5000}
              />
            ) : (
              <>
                <div className="pt-44" id="options">
                  <RadioGroup
                    id="custDetails-radiogroup"
                    data-testid="radioGroup"
                    onChange={updateSelection}
                    value={radiovalue}
                  >
                    {customerPhone.map((number: any, index: number) => {
                      return (
                        number.number && (
                          <FormControlLabel
                            id="custDetails-label1"
                            key={"customerPhoneNumber_" + index}
                            control={
                              <Radio
                                id="custDetails-radio1"
                                key="checkboxPhoneNumber"
                                name="checkboxPhoneNumber"
                                data-testid="checkboxPhoneNumber"
                              />
                            }
                            label={number.number}
                            value={`${number.number}@${index}`}
                          />
                        )
                      );
                    })}
                    <FormControlLabel
                      id="custDetails-label2"
                      control={
                        <Radio
                          id="custDetails-radio2"
                          key="phoneNumberforRepair"
                          name="phoneNumberforRepair"
                          data-testid="phoneNumberforRepair"
                        />
                      }
                      label={t("Case_Provide_Number")}
                      value={t("Case_Provide_Number")}
                    />{" "}
                  </RadioGroup>
                </div>

                <div id="custDetails-div1" className="parent-div">
                  <div id="custDetails-div2" className="pt-2 pt-lg-0">
                    <h6 className="pb-12">Phone number</h6>
                    <TextInputField
                      id="custDetails-text1"
                      dataTestId="inputPhoneNumber"
                      ariaLabel="phone number"
                      type="text"
                      disabled={isinputdisabled}
                      value={numberInput}
                      placeholder="Please enter a contact phone number"
                      onChange={(e) => inputChangeHandler(e)}
                    ></TextInputField>
                  </div>
                  <div id="custDetails-div3" className="pt-24">
                    <h6 className="pb-12">Name</h6>
                    <TextInputField
                      id="custDetails-text2"
                      dataTestId="customerName"
                      ariaLabel="customer name"
                      type="text"
                      disabled={isinputdisabled}
                      value={userInput}
                      placeholder="Name"
                      onChange={nameInputChangeHandler}
                    ></TextInputField>
                  </div>

                  <div id="custDetails-div4" className="pt-24">
                    <h6 className="pb-12">
                      {t("Repair_Contact_Details_Selection_Query")}
                    </h6>
                    <Dropdown                  
                      options={contactDetailsOptions}
                      selectedOption={userChosenRelationSelect ?? inputState}
                      disabled={isinputdisabled}
                      handleChange={handleChange}
                      dataTestId= {"inputstate"}
                    ></Dropdown>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-md-12 marginTop-auto" id="case-contacts-btn">
            <div className="row m-0 footer-actionbtn case-footer">
              <div className="col mb-0">
                <BackModal
                  id="custDetails-backmodal"
                  back={backButtonHandler}
                  btn_msg="return_case_button"
                />

                <ExitModal
                  id="case-exitmodal"
                  exit={onExitHandler}
                  btn_msg= {referral? 'Cancel' : "return_case_button"}
                />
              </div>
              <div className="col text-end">
                <Button
                  id="btn_case"
                  type="button"
                  data-testid="btncontinue"
                  name="btncontinue"
                  onClick={onContinueHandler}
                  placeholder="btncontinue"
                  value="Continue"
                  className="primary-btn"
                >
                  {t("continue_button")}
                </Button>
              </div>
            </div>
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default ContactDetails;
