import React, { useEffect, useState, Suspense, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_ADDITIONAL_CASE_FIELD,
  CASE_ASSET_TYPE,
  CASE_COUNTER,
  CASE_CREATION_ADDITINALINFO,
  CASE_CREATION_ID,
  CASE_OWNING_TEAM,
  CASE_SUBTYPE,
  CASE_SUBTYPE_CATEGORY,
} from "../../../ducks/redux/actionTypes";

const Loader = React.lazy(() => import("react-loader-spinner"));

const TEXT = "Text";
const RADIO = "Radio";
const LIST = "List";

interface QuestionTypeProps extends RouteComponentProps<any> {}

const CaseTypeQuestion: React.FC<QuestionTypeProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location: any = useLocation();
  const [editCounter, setEditCounter] = useState(parseInt(location?.state?.id));

  const getAllData = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationMasterData
  );

  let count =
    useSelector((state: any) => state.caseCreationReducer.counter) || 0;

  const descData = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationDescription
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);

  useEffect(() => {
    getAllData?.map((data: any) => {
      if (data.description === descData) {
        dispatch({
          type: CASE_CREATION_ADDITINALINFO,
          payload: data.additionalInfo,
        });
        dispatch({ type: CASE_CREATION_ID, payload: data.id });
        dispatch({ type: CASE_SUBTYPE, payload: data.subType });
        dispatch({ type: CASE_ASSET_TYPE, payload: data.assetType });
        dispatch({
          type: CASE_SUBTYPE_CATEGORY,
          payload: data.subTypeCategory,
        });

        if (
          data.additionalCaseCreationFields !== null &&
          data.additionalCaseCreationFields &&
          data.additionalCaseCreationFields[0]?.fieldName === "pea_casecategory"
        ) {
          dispatch({
            type: CASE_ADDITIONAL_CASE_FIELD,
            payload: data.additionalCaseCreationFields,
          });
        }
        loadQuestionScreen(data.additionalInfo);
      }
    });
  }, []);

  const loadQuestionScreen = useCallback(
    (questionData: any) => {
      if (editCounter >= 0) {
        count = editCounter;
      }

      if (
        questionData &&
        questionData.length > count &&
        questionData[count]?.isEnabled === "true"
      ) {
        dispatch({ type: CASE_COUNTER, payload: count });
        if (questionData[count].type === RADIO) {
          history.push("/caseQuestionnaireRadio");
        } else if (questionData[count].type === LIST) {
          history.push("/caseQuestionnaireDropdown");
        } else if (questionData[count].type === TEXT) {
          history.push("/caseQuestionnaireText");
        }
      } else {
        history.push("/contactdetails");
      }
    },
    [count]
  );

  const backButtonHandler = () => {
    history.push("/caseType");
  };

  return (
    <Container id="reploc-container" role="main">
      <Suspense
        fallback={
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={2000} //3 secs
          />
        }
      >
        {/* <BackModalContent
          id="reploc-backmodal"
          back={backButtonHandler}
          btn_msg="return_case_button"
        /> */}
        <div className="h5" id="repreason-div1">
          <p
            className="text-purple-100 text-2xl mb-0 global-txt"
            data-testid="title"
          >
            {descData}
          </p>
        </div>
        <br />

        {/* === Displaying question here === */}

        {/* === End question */}
      </Suspense>
    </Container>
  );
};

export default CaseTypeQuestion;
