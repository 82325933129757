export default class Address {
  public id?: string = ''
  public addressLine1: string = ''
  public addressLine2: string | null = null
  public addressLine3: string | null = null
  public addressLine4: string | null = null
  public addressLine5: string | null = null
  public formattedAddress?: string = ''
  public postcode: string = ''
  public postalAddressType: string = ''
}
