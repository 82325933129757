import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_CREATION_QUESTIONS,
} from "../../../ducks/redux/actionTypes";

import { RouteComponentProps } from "react-router-dom";
import BackModal from "../../Modal/BackModal";
import {
  HOUSEHOLD_OR_SUBLETTING_GUEST,
  HOUSEHOLD_OR_SUBLETTING_LETTING,
  HOUSEHOLD_OR_SUBLETTING_LOADGER,
  REFERENCE_CASE_QUESTION_3,
  REFERENCE_CASE_QUESTION_4,
  LEASEHOLD_OWNERSHIP_TEXT1,
  LEASEHOLD_OWNERSHIP_TEXT2,
} from "./Question";

import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import Message from "../../../storybook/Message";

const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../storybook/Button"));
const ExitModal = lazy(() => import("../../Modal/ExitModal"));

interface TextTypeProps extends RouteComponentProps<any> { }

const TextTypeQuestion: React.FC<TextTypeProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let referenceCase: any = "0";
  let leasehold: any;
  const format = /^(?!.*--)[^><&*!]*$/;
  
  const dispatch = useDispatch();

  const [textValue, setTextValue] = useState("");
  const [showError, setShowError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showStaticAlert, setShowStaticAlert] = useState("");
  let householdMemberOrSublettingCase: any;

  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );

  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );

  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );

  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );

  let count = useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );

  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  const caseTypeId = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationId
  );

  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationAdditionalInfo
  );

  if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_3
  ) {
    referenceCase = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_4 &&
    caseTypeId === "14"
  ) {
    referenceCase = "2";
  }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_GUEST
  ) {
    householdMemberOrSublettingCase = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_LOADGER
  ) {
    householdMemberOrSublettingCase = "2";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_LETTING
  ) {
    householdMemberOrSublettingCase = "3";
  }

  // Validating leasehold ownership
  if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_4 &&
    caseTypeId === "16"
  ) {
    leasehold = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === LEASEHOLD_OWNERSHIP_TEXT1
  ) {
    leasehold = "2";
  }

  const assignedSelectedAnswersValue = () => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        setTextValue(fetchAnswer[index]);
      }
    });
  };

  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
  }, [count]);

  const backButtonHandler = () => {
    if (count >= 1) {
      if (
        referenceCase === "1" ||
        referenceCase === "2" ||
        householdMemberOrSublettingCase === "2" ||
        leasehold === "1"
      ) {
        count = count - 2;
      } else if (householdMemberOrSublettingCase === "3") {
        count = count - 3;
      } else {
        count = count - 1;
      }
      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });

      history.push("/caseQuestionnaire");
    } else {
      history.push("/caseType");
    }
  };

  const onContinueHandler = () => {
    setShowStaticAlert("");

    if (textValue === "") {
      setShowError(t("Case_Question_Error"));
    } else {
      if (referenceCase === "1") {
        count = count + 2;
      } else if (
        householdMemberOrSublettingCase === "1" ||
        householdMemberOrSublettingCase === "2" ||
        householdMemberOrSublettingCase === "3" ||
        leasehold === "2"
      ) {
        count = questionInfoData.length;
      } else {
        count = count + 1;
      }
      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
      dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });

      setTextValue("");
      history.push("/caseQuestionnaire");
    }
  };

  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    history.push("/customer360View");
  };

  useEffect(() => {
    if (
      questionInfoData &&
      questionInfoData[count]?.alertMessageCode !== null
    ) {
      setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.code);
    }
  }, [questionInfoData]);

  const blurInputHandler = (e: any) => {
    let txtInput = e.target.value.replace(/\n/g, " ");
      if (txtInput !== ansArray[count]) {
        //Removing further answers ans questions
        fetchQuestion && fetchQuestion.splice(count, fetchQuestion.length);
        fetchAnswer && fetchAnswer.splice(count, fetchAnswer.length);
        dispatch({ type: CASE_EDIT, payload: true });
      }
      setTextValue(txtInput);
      setAnsArray([...ansArray, txtInput]);
      // ==== If user is changing the answer for the same question, need to get index and replace the value ===
      let newArr = [...ansArray];
      newArr[count] = txtInput;
      setAnsArray(newArr);
  };

  const onChange = (e: any) => {
    if (format.test(e.target.value)) {
      setShowError("");
      setTextValue(e.target.value);
    } else {
      setShowError( t("Case_Input_Error"));
    }
  }

  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer p-0" id="reploc-container">
      <Suspense
        fallback={<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />}
      >
        <div id="repreason-div1" className="padding-repair">
          <h1
            data-testid="title"
            className="header-size"
          >
            {descData}
          </h1>
          {showError && (
            <div className="question-error">
            <Message className="error-msg" data-testid="locatioAlert_0">
               {t(showError)}
          </Message>
          </div>
        )}
        </div>

        <div
        className="padding-repair pt-30"
          key={questionInfoData && questionInfoData[count]?.questionId}
        >
          <h6 id="case-q1" className="pb-12">
              {t(questionInfoData && questionInfoData[count]?.label)}
            </h6>

            <div className="vulner-textarea mb-30" id="repAddDet-div3" data-testid="repairdetails">
              <textarea 
              data-testid="Case_question_details"
              id="outlined-basic"
              placeholder=""
              value={textValue}
              style={{ marginLeft: 0 }}
              onChange={(e) => onChange(e)}
              onBlur={blurInputHandler}
              className="text-area"></textarea>
              </div>
        </div>

        {/* ==== Display purple alert warning === */}
        {showStaticAlert && (
          <div
            data-testid="alert-warning"
            role="alert"
            placeholder="alert-message"
            className="padding-repair"
          >
              {t(showStaticAlert)}
          </div>
        )}
        {/* === Close purple warning === */}

        <div
        className="col-md-12 marginTop-auto"
          id="repreason-main-btn"
        >
          <div className="row m-0 footer-actionbtn case-footer">
              <div className="col btn-top padding-repair mb-0">
              <BackModal
          id="reploc-backmodal"
          back={backButtonHandler}
          btn_msg="return_case_button" />
          <ExitModal
            data-testid="case-exitmodal"
            exit={exitButtonHandler}
            btn_msg="return_case_button" />
              </div>
              <div className="col text-end padding-repair">
          
          <Button
            onClick={onContinueHandler}
            data-testid="btn_caseQ"
            className="primary-btn"
          >
            {t("continue_button")}
          </Button>
          </div>
        </div>
        </div>
      </Suspense>
    </div>
    </>
  );
};

export default TextTypeQuestion;
