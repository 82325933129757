import React from "react";
import "./Message.scss";

const Message = (props: any) => (
  <div className="pt-16 pb-16" id="messageText" data-testid={props.messageId} >
    <h5      
      className={props.className}  >
      {props.children}
    </h5>    
  </div>
);

export default Message;
