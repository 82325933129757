import React, { useState, useEffect, Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as api from "../../../api";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_IDENTIFIER,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_ELIGIBILITY_RESPONSE,
  CASE_COUNTER,
  CASE_OWNER,
  CASE_ASSET_IDENTIFIER,
} from "../../../ducks/redux/actionTypes";
import CaseData from "../../../models/Case/Case";
import CaseDrool from "../../../models/Case/CaseDrool";
import { COVID_CASE_QUESTION } from "../QuestionType/Question";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import Message from "../../../storybook/Message";
import ExitModal from "../../Modal/ExitModal";
import BackModal from "../../Modal/BackModal";
import { comingFromCaseJourneySuccess } from "../../../ducks/redux/actions/FinancialReferral";

const TableCell = React.lazy(() => import("@material-ui/core/TableCell"));
const TableRow = React.lazy(() => import("@material-ui/core/TableRow"));
const TableBody = React.lazy(() => import("@material-ui/core/TableBody"));
const Loader = React.lazy(() => import("react-loader-spinner"));
const Button = React.lazy(() => import("../../../storybook/Button"));


interface ReviewCaseProps extends RouteComponentProps<any> { }
const CaseReview: React.FC<ReviewCaseProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState("");
  const [redAlert, setRedAlert] = useState("");
  const [isSingleTenancy, setIsSingleTenancy] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  enum AssetTypeId {
    Block = 1,
    Property = 2
  };

  enum userTenancyCount {
    Single = 1
  };

  enum AssetTypeName {
    Block = "Block",
    Property = "Property"
  };

  let tenancyIdentifier: any = "";
  let tenancyType: string;
  let landlordName: string = "null";
  let caseCategory: string;

  let assetIdentifier = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );
  const [assigneeName, setAssigneeName] = useState("");
  const [assigneeType, setAssigneeType] = useState("");

  const caseTypeDescription = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationDescription
  );

  const assetType = useSelector(
    (state: any) => state.caseCreationReducer.caseAssetType
  );

  const caseSubType = useSelector(
    (state: any) => state.caseCreationReducer.caseSubType
  );

  const caseSubTypeCategory = useSelector(
    (state: any) => state.caseCreationReducer.caseSubTypeCategory
  );

  const caseTypeId = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationId
  );

  const selectedTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );

  const addresses = useSelector(
    (state: any) => state.addresses?.customerassociatedassets || []
  );

  let rowNumber = 0;

  addresses[0]?.map((address: any) => {
    if (address.assetType === assetType) {
      assetIdentifier = address.id;
    }
    dispatch({ type: CASE_ASSET_IDENTIFIER, payload: assetIdentifier });
  });

  const selectedPropertyAddress = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyAddress
  );

  const userChosenContactPhone = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone
  );

  const userChosenContactName = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );

  const userChosenRelationDetails = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails
  );

  const userChosenRelationId = useSelector(
    (state: any) => state.userChoicesReducer.userChosenReporterId
  );

  const caseCategoryAdditionalFields = useSelector(
    (state: any) => state.caseCreationReducer.caseAdditionalFields
  );

  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );

  const userTenancy =
    useSelector((state: any) => state?.addresses?.customertenancies) || [];

  userTenancy.map((data: any, index: number) => {
    if (data?.tenancySummary?.premisesIds[0] === selectedTenancy) {
      tenancyType = data?.tenancySummary.tenancyType;
      return (tenancyIdentifier = data?.tenancySummary?.tenancyId);
    }
  });

  const selectedQuestions =
    useSelector(
      (state: any) => state?.caseCreationReducer?.caseCreationAdditionalInfo
    ) || [];

  let AnsValueArr: any = [];

  let isCovidInFlow = false;

  const selectedAnswers = useSelector(
    (state: any) => state?.caseCreationReducer?.caseSelectedAnswers
  );

  const selectedQues = useSelector(
    (state: any) => state?.caseCreationReducer?.caseSelectedQuestions
  );


  AnsValueArr = [...selectedAnswers];

  selectedQuestions.map((q: any, index: number) => {
    if (q.label === COVID_CASE_QUESTION && q.isEnabled === "true") {
      isCovidInFlow = true;
    }
    q?.options?.map((data: any) => {
      if (data.id === selectedAnswers[index]) {
        return (AnsValueArr[index] = data.value);
      }
    });
  });

  //  === Need to validate with solid logic principle ===
  let storeIndex: any = [];

  selectedAnswers.map((data: any, index: number) => {
    storeIndex.push(index);
  });

  storeIndex.map((_: any, index: number) => {
    if (index !== storeIndex[index]) {
      selectedAnswers[index] = undefined;
    }
  });

  if (
    caseCategoryAdditionalFields &&
    caseCategoryAdditionalFields[0]?.valueFrom
  ) {
    caseCategory = AnsValueArr[0];
  }

  const callCaseDroolAPIS = async () => {
    setIsLoading(true);
    const droolRequest = new CaseDrool();
    droolRequest.caseSubType = caseSubType;
    droolRequest.caseSubTypeCategory = caseSubTypeCategory;
    droolRequest.caseCategory = caseCategory || "NA";
    droolRequest.landLord = landlordName;
    droolRequest.tenancyType = tenancyType;
    droolRequest.channel = "CustomerSelfService";

    try {
      const response = await api.caseDroolAPI(droolRequest);
      if (
        response?.value?.["com.myspace.pb_team_determination.ActionItem"]
          ?.owningTeam != null
      ) {
        setAssigneeName(
          response?.value?.["com.myspace.pb_team_determination.ActionItem"]
            ?.owningTeam
        );
        setAssigneeType(
          response?.value?.["com.myspace.pb_team_determination.ActionItem"]
            ?.owningTeamCategory
        );
      } else {
        props.history.push("/genericerror");
        dispatch({ type: CLEAR_CASE_STATE });
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
      }
    } catch (e: any) {
      props.history.push("/genericerror");
      dispatch({ type: CLEAR_CASE_STATE });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGetCaseAssetAssociateData = async () => {
    try {
      setIsLoading(true);
      const response = await api.getCaseAssetAssociate(assetIdentifier);
      if (response) {
        dispatch({ type: CASE_ELIGIBILITY_RESPONSE, payload: response });
      }
      landlordName =
        response?.associates?.landLord?.organisation?.name || "UNKNOWN";
      if (landlordName) {
        callCaseDroolAPIS();
      }
    } catch (e: any) {
      props.history.push("/genericerror");
      dispatch({ type: CLEAR_CASE_STATE });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    }
  };

  let timerId: any;
  useEffect(() => {
    fetchGetCaseAssetAssociateData();
    if (userTenancy.length === userTenancyCount.Single) {
      setIsSingleTenancy(true);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const clearCaseDataFromRedux = () => {
    dispatch({ type: CLEAR_CASE_STATE });
  };

  const backButtonHandler = () => {
    props.history.push("/contactdetails");
  };

  const onExitHandler = () => {
    clearCaseDataFromRedux();
    props.history.push("/customer360View");
  };

  const getAssetDetails = () => {
    let assetTypeID;
    let assetTypeName;
    let missingBlockID = true;
    if (assetType === AssetTypeName.Block) {
      addresses[0]?.map((address: any) => {
        if (address.assetType === AssetTypeName.Block) {
          missingBlockID = false;
        }
      });
      if (!missingBlockID) {
        assetTypeID = AssetTypeId.Block;
        assetTypeName = AssetTypeName.Block;
      } else {
        assetTypeID = AssetTypeId.Property;
        assetTypeName = AssetTypeName.Property;
      }

    } else if (assetType === AssetTypeName.Property) {
      assetTypeID = AssetTypeId.Property;
      assetTypeName = AssetTypeName.Property;
    }
    return [assetTypeID, assetTypeName];
  }

  const onContinueHandler = async () => {
    setIsLoading(true);
    const [assetID, assetName] = getAssetDetails();
    const caseRequest = new CaseData();
    caseRequest.caseTypeId = parseInt(caseTypeId);
    caseRequest.caseTypeDescription = caseTypeDescription;
    caseRequest.asset = {
      type: {
        assetTypeId: assetID,
        assetTypeName: assetName,
      },
    };

    //add desc in case of covid
    caseRequest.caseTypeDescription =
      isCovidInFlow == true ? caseTypeDescription : caseTypeDescription;
    caseRequest.assignee = assigneeName;
    caseRequest.assigneeType = assigneeType;
    caseRequest.identifiers = {
      assetIdentifier: assetIdentifier,
      customerIdentifier: customerIdentifier,
      tenancyIdentifier: tenancyIdentifier,
    };
    caseRequest.contactDetails = {
      phoneNumber: userChosenContactPhone,
      name: userChosenContactName || null,
      relationId: parseInt(userChosenRelationId) || null,
      relation: userChosenRelationDetails || null,
    };

    // Removing blank value from question and answers

    do {
      selectedAnswers.filter((data: any, index: number) => {
        if (data == undefined) {
          selectedAnswers.splice(index, 1);
          selectedQuestions.splice(index, 1);
        }
      });
    } while (selectedAnswers.includes(undefined));

    caseRequest.additionalInfo = selectedAnswers?.map(
      (ans: any, index: number) => {
        return {
          questionId: selectedQuestions[index]?.questionId,
          responseId:
            selectedQuestions[index]?.type === "Radio" ||
              selectedQuestions[index]?.type === "List"
              ? parseInt(ans)
              : null,

          responseText: selectedQuestions[index]?.type === "Text" ? ans : null,
        };
      }
    );

    try {
      timerId = setTimeout(function () {
        setShowError("Case_CC022");
      }, 1000);

      const response = await api.createCaseData(caseRequest);
      dispatch({ type: CASE_IDENTIFIER, payload: response?.caseIdentifier });
      dispatch({ type: CASE_OWNER, payload: assigneeName });
      dispatch(comingFromCaseJourneySuccess(true))
     props.history.push("/Customer360View");
    } catch (e) {
      setRedAlert("Case_CC023");
    } finally {
      setIsLoading(false);
      setShowError("");
    }
  };

  const displayCustomerContactDetails = () => {
    return (
      <div className={`${rowNumber % 2 === 0 ? 'confirm-bg-grey' : ''} row `}>
        <div className="col-11"
          id={"confirmation-tr1"}
        >
          <h6 className="confirm-span1 padding-1"
            id="confirmation-tr1-td1">

            {t("Case_Customer_Details_Text_Two")}

          </h6>
          <p
            id={"confirmation-tr1-div2"}
            className="confirm-span2 padding-1"
          >
            {userChosenContactPhone} <br></br>{userChosenContactName}<br></br>
            {userChosenRelationDetails}
          </p>
        </div>
        <div id={"confirmation-tr1-div3"} className="col-1">
          <span
            id={"confirmation-tr1-div3"}
            className="text-center"
          >
            <i
              data-testid="contact-details"
              id={"confirmation-tr1-icon"}
              className="fas fa-edit text-teal"
              onClick={() => {
                props.history.push("/contactdetails");
              }}
            ></i></span>
        </div>
      </div>
    );
  };

  const displayCaseType = () => {
    return (
      <div className="row">
        <div className="col-11">
          <h6 className="confirm-span1 padding-1">
            {t("Case_Type_Title")}
          </h6>
          <p className="confirm-span2 padding-1" >
            {caseTypeDescription}
          </p>
        </div>
        <div className="col-1"
          id={"confirmation-tr1-td3"}
        >
          <span
            id={"confirmation-tr1-div3"}
            className="text-center"
          >
            <i
              data-testid="caseType"
              id={"confirmation-tr1-icon"}
              className="fas fa-edit text-teal"
              onClick={() => {
                dispatch({ type: CASE_COUNTER, payload: 0 });
                props.history.push("/caseType");
              }}
            ></i>
          </span>

        </div>
      </div>
    );
  };

  const displaySelectedProperty = () => {
    return (

      <div className="confirm-bg-grey row">
        <div className="col-11">
          <h6 className="confirm-span1 padding-1">
            {t("Case_Property")}
          </h6>

          <p className="confirm-span2 padding-1"
            id={"confirmation-tr1-div2"}
          >       {selectedPropertyAddress}        </p>
        </div>

        <div className="col-1"
          id={"confirmation-tr1-td3"}
        >
          <span
            id={"confirmation-tr1-div3"}
            className="text-right"
          >
            {!isSingleTenancy &&
              <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new">
                <i
                  data-testid="tenancy"
                  id={"confirmation-tr1-icon"}
                  className="fas fa-edit text-teal"
                  onClick={() => {
                    props.history.push("/selectTenancy");
                  }}
                ></i>
              </div>}
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer repair-request p-0" id="confirmation-container">
      <div id="confirmation-main-div ">
        <div id="confirmation-main-div" className="padding-repair">
          <h1 className="header-size" id="confirmation-h1">
            {t("Case_Review_Title")}
          </h1>
          <Message className="info-msg">
            {t("Case_Details_Text2")}
          </Message>
        </div>
        {redAlert && (
          <div className='padding-repair payment-error pt-16'>
            <Message className="error-msg">{t(redAlert)}
            </Message>    </div>
        )}
        {showError && (
          <div className='padding-repair payment-error pt-16'>
            <Message className="warning-msg">{t(showError)}
            </Message>    </div>

        )}
        {isLoading ? (
          <>
            <Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />
          </>
        ) : (

          <div id="confirmation-div-table">
            {displaySelectedProperty()}
            {displayCaseType()}
            {selectedQuestions
              .slice(0, selectedAnswers.length)
              .map((q: any, id: number) => {
                if (AnsValueArr[id] ) {
                  rowNumber++
                }

                return (
                  <>
                    <div className={AnsValueArr[id] === undefined ||
                      AnsValueArr[id] === null
                      ? "hideQuestion"
                      : `${rowNumber % 2 !== 0 ? 'confirm-bg-grey' : ''} row `}  >
                      <div className="col-11" id={"confirmation-tr1" + id}  >
                        <h6 id={"confirmation-tr1-td1" + id}
                          className={AnsValueArr[id] === undefined ||
                            AnsValueArr[id] === null
                            ? "hideQuestion"
                            : "confirm-span1 padding-1"}
                        >
                          {q.label} 

                        </h6>

                        <p className="confirm-span2 padding-1"
                          id={"confirmation-tr1-div2"}
                        >
                          {AnsValueArr[id]}
                        </p>   </div>
                      <div className="col-1">
                        <span
                          id={"confirmation-tr1-div3" + id}
                          className="text-center"
                        >
                          <i
                            data-testid="question"
                            id={"confirmation-tr1-icon"}
                            className="fas fa-edit text-teal"
                            onClick={() => {
                              props.history.push({
                                pathname: "/caseQuestionnaire",
                                state: { id: `${id}` },
                              });
                            }}
                          ></i>
                        </span>
                      </div>

                    </div>
                  </>
                );
              })}
            {/* === Display contact details ===      */}
            {displayCustomerContactDetails()}

          </div >
        )}

        
      </div >
      <div className="col-md-12 marginTop-auto" id="case-btn">
          <div className="row m-0 footer-actionbtn case-footer">
            <div className="col btn-top padding-repair mb-0">
              <BackModal
                id="reploc-backmodal"
                back={backButtonHandler}
                btn_msg="return_case_button"
              />
              <ExitModal
                id="case-exitmodal"
                exit={onExitHandler}
                btn_msg="return_case_button"
              />
            </div>
            <div className="col text-end padding-repair">
              <Button
                id="btn_case"
                type="button"
                data-testid="btncontinue"
                name="btncontinue"
                onClick={onContinueHandler}
                placeholder="btncontinue"
                value="Continue"
                className="primary-btn"
              >
                {t("continue_button")}
              </Button>
            </div>
          </div>
        </div>
    </div ></>

  );
};

export default CaseReview;
