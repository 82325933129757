import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import "./index.scss";
import { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as api from "../../api";
import { formatDate } from "../../utils/formatting";
import { catalystEditProfileToggleValue, showMenuTabs } from "../../ducks/redux/actions/userChoices";

const b2cResetPasswordUrl: string =
  process.env.REACT_APP_CSS_B2C_RESET_PASSWORD_URL || "";

interface ManageProfileProps extends RouteComponentProps<any> {}
const ManageProfile: React.FC<ManageProfileProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCustInfo, setIsCustInfo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [catalystProfileToggle, setCatalystCaseCardToggle] = useState("false");
  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const userProfileData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfileData
  );

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );

  const getCatalystProfileToggle = async () => {
    try {
      const response = await api.GetAppConfigToggleValue(
        "mypeabodycatalystprofileupdate"
      );
      setCatalystCaseCardToggle(response?.value);
      dispatch(catalystEditProfileToggleValue(response?.value));
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(showMenuTabs(true));
    if (props.history.action === "POP") {
      props.history.push("/customer360View");
    }
    // catalyst profile update toggle api call
    getCatalystProfileToggle();
  }, []);

  const backButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const editButtonHandler = () => {
    props.history.push("/editprofile");
  };

  const redirectToIDAMResetPassword = () => {
    window.location.href = b2cResetPasswordUrl;
  };

  return (
    <>
      {isLoading ? (
        <div className="padding-repair container-fluid mb-30">
          <div className="manageprofile-container">
            <h1> {t("Customer_Profile")} </h1>
            <div className="manageprofile-buttons">
              <a
                className="secondary-ghost-btn"
                onClick={redirectToIDAMResetPassword}
                onKeyDown={redirectToIDAMResetPassword}
                data-testid="change_password_button"
              >
                <span>{t("change_password")}</span>
              </a>
              <span
                className="secondary-ghost-btn"
                onClick={editButtonHandler}
                onKeyDown={editButtonHandler}
                role="button"
                aria-label="Edit Profile"
                data-testid="edit_button"
              >
                {t("Edit_your_deatils")}
              </span>
            </div>
          </div>

            <div className="customer-padding">
              <h6 className="label-padding"> {t("Customer_Profile_Name")} </h6>
              <p className="textfield-padding" data-hj-suppress="">
                {userProfileData?.customerResponse?.person.name.title}{" "}
                {userProfileData?.customerResponse?.person.name.givenName}{" "}
                {userProfileData?.customerResponse?.person.name.surname}
              </p>

              <h6 className="label-padding"> {t("Customer_Profile_DOB")}</h6>
              <p className="textfield-padding">
                {userProfileData?.customerResponse?.person.dateOfBirth &&
                  formatDate(
                    userProfileData?.customerResponse?.person.dateOfBirth
                  )}
              </p>

              <h6 className="label-padding">{t("Customer_Profile_Email")}</h6>
              <p className="textfield-padding">
                {userProfileData?.customerContactParamsEntry?.email
                  ? userProfileData?.customerContactParamsEntry?.email
                  : "-"}
              </p>

              <h6 className="label-padding">
                {" "}
                {t("Customer_Profile_Mobile")}
              </h6>
              <p className="textfield-padding">
                {userProfileData?.customerContactParamsEntry?.mobilePhone
                  ? userProfileData?.customerContactParamsEntry?.mobilePhone
                  : "-"}
              </p>

              <h6 className="label-padding">{t("Customer_Profile_Home")}</h6>
              <p className="textfield-padding">
                {userProfileData?.customerContactParamsEntry?.homePhone
                  ? userProfileData?.customerContactParamsEntry?.homePhone
                  : "-"}
              </p>

              <h6 className="label-padding">
                {" "}
                {t("Customer_Profile_Business")}
              </h6>
              <p className="textfield-padding">
                {userProfileData?.customerContactParamsEntry?.workPhone
                  ? userProfileData?.customerContactParamsEntry?.workPhone
                  : "-"}
              </p>

          <div>
            {!isCustInfo ? (
              <div></div>
            ) : (
              <div className="">
                <div className="">
                  <p className="">
                    {t("missing_information")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="repair-card-btns">
              {/**The code below will be enabled once the change username story is developed.**/
              /* <p className="text-teal a-mar">
                      <a className="a-style man-pad man-pro" data-testid="change_username_button">
                        <span>{t("change_username")}</span>
                      </a>
                    </p> */}
            </div>
          </div>
        </div>
        </div>
      ) : (
        <div className="SpinnerWrapper">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default ManageProfile;
