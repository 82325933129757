import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userProfileETag } from "../../ducks/redux/actions/userChoices";

import { formatDate } from "../../utils/formatting";
import * as api from "../../api";
import { USER_PROFILE_DATA } from "../../ducks/redux/actionTypes";
import Customer from "../../models/Customer";
import Message from "../../storybook/Message";
import Button from "../../storybook/Button";

const b2cResetPasswordUrl: string =
  process.env.REACT_APP_CSS_B2C_RESET_PASSWORD_URL || "";

interface EditProfileProps extends RouteComponentProps<any> {}

const EditProfile: React.FC<EditProfileProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const customerId = useSelector((state: any) => state.addresses?.customerid);
  const eTag = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfileETag
  );
  const userProfileData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfileData
  );
  const { name, dateOfBirth } = userProfileData?.customerResponse?.person;
  const { email, mobilePhone, homePhone, workPhone } =
    userProfileData?.customerContactParamsEntry || {};
  const [eMail, setEMail] = useState(email || "");
  const [mobileNo, setMobileNo] = useState(mobilePhone || "");
  const [homePhoneNo, setHomePhoneNo] = useState(homePhone || "");
  const [businessPhone, setBusinessPhone] = useState(workPhone || "");
  const [updateStatusMsg, setUpdateStatusMsg] = useState("");
  const [disabledSaveButton, setSaveButtonDisabled] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedMobile, setUpdatedMobile] = useState("");
  const [updatedHome, setUpdatedHome] = useState("");
  const [updatedBusiness, setUpdatedBusiness] = useState("");

  const catalystProfileToggle = useSelector(
    (state: any) => state.userChoicesReducer?.isEditProfileToggle
  );

  const [isProfileUpdated, setIsProfileUpdated] = useState(false);

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );

  let errorArray: any[] = [];
  const emailRegrex =
    /^(([^<>()\[\]\\.,;:\s$*£`%+?{}\=|/¬&!#^~@"]+(\.[^<>()\[\]\\.,;:\s$*£`%+?{}\=|/¬&!#^~@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  const phoneNoRegrex = /^[+0-9]+$/;

  const backButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const redirectToIDAMResetPassword = () => {
    window.location.href = b2cResetPasswordUrl;
  };

  const numValidator = (
    errorCode: string,
    typedValue: string,
    errorArray: string[]
  ) => {
    if (
      typedValue.slice(0, 1) != "0" ||
      typedValue.length < 11 ||
      typedValue == ""
    ) {
      errorArray.push(errorCode);
      let uniqueErrors = Array.from(new Set(errorArray));
      setErrorMessage(uniqueErrors);
    } else if (errorArray.includes(errorCode)) {
      errorArray.splice(errorArray.indexOf(errorCode), 1);
      setErrorMessage(errorArray);
    }
  };

  const inputBlurHandler = (event: any) => {
    const inputValue = event.target.value;
    if (event.target.id === "emailId") {
      if (userProfileData?.customerContactParamsEntry?.email !== inputValue) {
        setIsProfileUpdated(true);
        setUpdatedEmail(inputValue);
      }
    } else if (event.target.id === "mobileId") {
      if (
        userProfileData?.customerContactParamsEntry?.mobilePhone !== inputValue
      ) {
        setIsProfileUpdated(true);
        setUpdatedMobile(inputValue);
      }
    } else if (event.target.id === "homePhoneId") {
      if (
        userProfileData?.customerContactParamsEntry?.homePhone !== inputValue
      ) {
        setIsProfileUpdated(true);
        setUpdatedHome(inputValue);
      }
    } else if (event.target.id === "businessPhoneId") {
      if (
        userProfileData?.customerContactParamsEntry?.workPhone !== inputValue
      ) {
        setIsProfileUpdated(true);
        setUpdatedBusiness(inputValue);
      }
    }
  };

  const inputChangeHandler = (event: any) => {
    setUpdateStatusMsg("");
    const typedValue = event.target.value;

    errorArray = errorMessage;
    if (event.target.id === "emailId") {
      const splitedEmail = typedValue.split("@");
      if (
        !typedValue ||
        emailRegrex.test(typedValue) === false ||
        splitedEmail[0].length > 64 ||
        splitedEmail[1].length > 48 ||
        typedValue.length > 100 ||
        typedValue.indexOf("-@") > -1
      ) {
        errorArray.push("SSCP002");
        let uniqueErrors = Array.from(new Set(errorArray));
        setErrorMessage(uniqueErrors);
      } else if (errorArray.includes("SSCP002")) {
        errorArray.splice(errorArray.indexOf("SSCP002"), 1);
        setErrorMessage(errorArray);
      }
      setEMail(typedValue);
    } else if (event.target.id === "mobileId") {
      if (!typedValue || phoneNoRegrex.test(typedValue)) {
        numValidator("SSCP003a", typedValue, errorArray);
        setMobileNo(typedValue.toString().slice(0, 11));
      }
    } else if (event.target.id === "homePhoneId") {
      if (typedValue == "") {
        setHomePhoneNo(typedValue.toString());
        setErrorMessage(
          errorMessage.filter((errorcode) => errorcode !== "SSCP003b")
        );
      } else if (!typedValue || phoneNoRegrex.test(typedValue)) {
        numValidator("SSCP003b", typedValue, errorArray);
        setHomePhoneNo(typedValue.toString().slice(0, 11));
      }
    } else if (event.target.id === "businessPhoneId") {
      if (typedValue == "") {
        setBusinessPhone(typedValue.toString());
        setErrorMessage(
          errorMessage.filter((errorcode) => errorcode !== "SSCP003c")
        );
      } else if (!typedValue || phoneNoRegrex.test(typedValue)) {
        numValidator("SSCP003c", typedValue, errorArray);
        setBusinessPhone(typedValue.toString().slice(0, 11));
      }
    }
  };

  const sendEmailNotification = async (
    updatedEmail: any,
    updatedMobile: any,
    updatedHome: any,
    updatedBusiness: any
  ) => {
    const today = new Date();
    const minutes = today.getMinutes().toString().padStart(2, "0");
    const hour = today.getHours().toString().padStart(2, "0");
    const dd = today.getDate().toString().padStart(2, "0");
    const mm = (today.getMonth() + 1).toString().padStart(2, "0");
    const yyyy = today.getFullYear();
    const profileDate = `${dd}/${mm}/${yyyy}`;
    const editedTime = `${hour}:${minutes}`;

    const emailNotification = {
      sender: {
        addresses: {
          digitalAddresses: [
            {
              emailAddresses: [
                {
                  value: "test@peabody.org.uk",
                  id: "email",
                },
              ],
              telecomsAddresses: [
                {
                  number: "",
                  deviceId: "",
                  dialingCode: "",
                  countryCode: "",
                  telecomsAddressType: "mobile",
                },
              ],
            },
          ],
        },
      },
      communicationTemplateIdentifier:
        "pb-catalystcustomerprofileupdate-template",
      communicationType: "Email",
      content: [
        {
          identifier: "insert_date",
          value: profileDate,
        },
        {
          identifier: "insert_time",
          value: editedTime,
        },
        {
          identifier: "email_address",
          value: updatedEmail,
        },
        {
          identifier: "mobile_phone",
          value: updatedMobile,
        },
        {
          identifier: "home_phone",
          value: updatedHome,
        },
        {
          identifier: "business_phone",
          value: updatedBusiness,
        },
      ],
    };
    try {
      await api.postRequestCatalystCustomerEmail(customerId, emailNotification);
    } catch (e: any) {
      console.log("Error:", e);
    }
  };

  const submitButtonHandler = async () => {
    setUpdateStatusMsg("");
    errorArray = errorMessage;
    const splitedEmail = eMail.split("@");
    if (
      !eMail ||
      emailRegrex.test(eMail) === false ||
      splitedEmail[0].length > 64 ||
      splitedEmail[1].length > 48 ||
      eMail.length > 100 ||
      eMail.indexOf("-@") > -1
    ) {
      errorArray.push("SSCP002");
      let uniqueErrors = Array.from(new Set(errorArray));
      setErrorMessage(uniqueErrors);
    }
    if (
      mobileNo === "" ||
      mobileNo.slice(0, 1) != "0" ||
      mobileNo.length < 11
    ) {
      errorArray.push("SSCP003a");
      let uniqueErrors = Array.from(new Set(errorArray));
      setErrorMessage(uniqueErrors);
    } else if (
      homePhoneNo.length > 0 &&
      (homePhoneNo.slice(0, 1) != "0" || homePhoneNo.length < 11)
    ) {
      errorArray.push("SSCP003b");
      let uniqueErrors = Array.from(new Set(errorArray));
      setErrorMessage(uniqueErrors);
    } else if (
      businessPhone.length > 0 &&
      (businessPhone.slice(0, 1) != "0" || businessPhone.length < 11)
    ) {
      errorArray.push("SSCP003c");
      let uniqueErrors = Array.from(new Set(errorArray));
      setErrorMessage(uniqueErrors);
    } else if (errorArray.length === 0) {
      errorArray = [];
      setErrorMessage(errorArray);

      const allData = {
        emailAddress: eMail,
        mobilePhone: mobileNo,
        homePhone: homePhoneNo,
        workPhone: businessPhone,
      };

      const customerContactParamsEntry: Customer = new Customer();
      customerContactParamsEntry.email = eMail;
      customerContactParamsEntry.mobilePhone = mobileNo;
      customerContactParamsEntry.homePhone = homePhoneNo;
      customerContactParamsEntry.workPhone = businessPhone;

      setIsLoading(true);
      setSaveButtonDisabled("pointer-events-none"); // disable save to prevent double click

      try {
        await api.putEditProfile(customerId, allData, eTag);

        if (
          isProfileUpdated &&
          isCatalyst &&
          catalystProfileToggle === "true"
        ) {
          setUpdateStatusMsg("SSCP004a");
          sendEmailNotification(
            updatedEmail,
            updatedMobile,
            updatedHome,
            updatedBusiness
          );
        } else if (
          !isProfileUpdated &&
          isCatalyst &&
          catalystProfileToggle === "true"
        ) {
          setUpdateStatusMsg("SSCP005");
        } else if (
          !isProfileUpdated &&
          isCatalyst &&
          catalystProfileToggle === "false"
        ) {
          setUpdateStatusMsg("profile_update_success");
        } else {
          setUpdateStatusMsg("profile_update_success");
        }

        const customerResponse = await api.getCustomer(customerId);
        dispatch(userProfileETag(customerResponse?.eTag));
        dispatch({
          type: USER_PROFILE_DATA,
          payload: { customerResponse, customerContactParamsEntry },
        });
        setIsLoading(false);
        setSaveButtonDisabled("");
        setIsProfileUpdated(false);
        setTimeout(async () => {
          setIsLoading(false);
          props.history.push("/manageprofile");
        }, 3000);
      } catch (e: any) {
        setIsLoading(false);
        setUpdateStatusMsg("profile_update_failed");
      }
    }
  };
  const cancelButtonHandler = () => {
    props.history.push("/manageprofile");
  };

  const nameVal = `${name.title} ${name.givenName} ${name.surname}`;
  const DOBVal = formatDate(dateOfBirth);

  const elementsObj = [
    {
      label: "Customer_Profile_Name",
      divId: "name-div",
      inputId: "nameId",
      dataTestId: "nameInput",
      type: "text",
      placeholder: "Customer name",
      value: nameVal,
      disabledStatus: true,
    },
    {
      label: "Customer_Profile_DOB",
      divId: "DOB-div",
      inputId: "DOBId",
      dataTestId: "DOBInput",
      type: "text",
      placeholder: "Date of birth",
      value: DOBVal,
      disabledStatus: true,
    },
    {
      label: "Customer_Profile_Email",
      divId: "email-div",
      inputId: "emailId",
      dataTestId: "emailInput",
      type: "text",
      placeholder: "E-Mail address",
      value: eMail,
      disabledStatus: false,
    },
    {
      label: "Customer_Profile_Mobile",
      divId: "mobile-div",
      inputId: "mobileId",
      dataTestId: "mobileNumberInput",
      type: "text",
      placeholder: "Mobile phone number",
      value: mobileNo,
      disabledStatus: false,
    },
    {
      label: "Customer_Profile_Home",
      divId: "homePhone-div",
      inputId: "homePhoneId",
      dataTestId: "homePhoneNumberInput",
      type: "text",
      placeholder: "Home phone number",
      value: homePhoneNo,
      disabledStatus: false,
    },
    {
      label: "Customer_Profile_Business",
      divId: "businessPhone-div",
      inputId: "businessPhoneId",
      dataTestId: "businessPhoneNumberInput",
      type: "text",
      placeholder: "Business phone number",
      value: businessPhone,
      disabledStatus: false,
    },
  ];

  return (
    <>
      <div className="padding-repair container-fluid">
      <div className="editprofile-container">
          <h1> {t("Customer_Profile")} </h1>

            {errorMessage?.map((error: any, key: any) => {
              return (
                <div
                  data-testid="alert-warning"
                  className="mb-8"
                  role="alert"
                  placeholder="alert"
                  key={key}
                >
                  <Message className="warning-msg">{t(`${error}`)}</Message>
                </div>
              );
            })}

            {updateStatusMsg && (
              <div
                data-testid="info-message"
                className="mb-8"
                role="alert"
                placeholder="alert"
              >
                <Message className={updateStatusMsg === "profile_update_failed" ? "error-msg" : "success-msg"}>{t(updateStatusMsg)}</Message>
              </div>
            )}

            <div className="customer-padding">
              {elementsObj.map((eachElem, key) => {
                return (
                  <div
                    key={key}
                  >

                    <h6 className="label-inputpadding"> {t(eachElem.label)}</h6>
                    <p
                      id={eachElem.divId}
                      className="textfield-padding"
                    >
                      <input
                        id={eachElem.inputId}
                        data-testid={eachElem.dataTestId}
                        type={eachElem.type}
                        placeholder={eachElem.placeholder}
                        value={eachElem.value}
                        className={
                          eachElem.disabledStatus
                            ? "disabledElement"
                            : "w-512"
                        }
                        onChange={inputChangeHandler}
                        disabled={eachElem.disabledStatus}
                        onBlur={inputBlurHandler}
                      />
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="col-md-12 marginTop-auto">
              <div className="row m-0 footer-actionbtn">
                <div className="col btn-top">
                  <span
                    className="secondary-ghost-btn"
                    onClick={cancelButtonHandler}
                    onKeyDown={cancelButtonHandler}
                    role="button"
                    aria-label="Cancel"
                    data-testid="cancel_button"
                  >
                    {t("CR005")}
                  </span>
                </div>
                <div className="col text-end pt-24">
                  <Button
                    className={`${disabledSaveButton} primary-btn`}
                    onClick={submitButtonHandler}
                    onKeyDown={submitButtonHandler}
                    role="button"
                    aria-label="Save"
                    data-testid="save_button"
                  >
                    {t("Save_changes")}
                  </Button>
                </div>
              </div>
            </div>
        </div>
      </div>

      {isLoading && (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={5000}
        />
      )}
    </>
  );
};

export default EditProfile;
