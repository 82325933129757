import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../storybook/Button";
import "./index.css"
import { clearReferralInformation, comingFromReferralPage, storeReferralAdditionalDetails } from "../../../ducks/redux/actions/FinancialReferral";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import ExitModal from "../../Modal/ExitModal";
import BackModal from "../../Modal/BackModal";
import { showFooter, showMenuTabs } from "../../../ducks/redux/actions/userChoices";
import Message from "../../../storybook/Message";



interface AssetDetailCardProps extends RouteComponentProps<any> {
  
}

const AdditionalEnquiryDetails: React.FC<AssetDetailCardProps> = (props:any) => {
  let specials = /[&*%!<>]/;
  const { t } = useTranslation();
  
  const dispatch = useDispatch()
  const [error, setError] = useState("");
  const data = useSelector((state:any) => state.financialReferralReducer?.referralDetails)
  const reasonData = useSelector((state:any) => state.financialReferralReducer.financialReferralData)

  let [message, setMessage] =  useState(data || "");
  const [countCharacter, setCountCharacter] = useState(data?.length || 0);

  

  useEffect(()=>{
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    if(reasonData?.ReferralReason.length === 0){
      props.history.push('/enquiryreason')
    }
  },[])

  const backButtonHandler = () => {
    props.history.push('/enquiryreason');   
  }

 
  const exitButtonHandler = () => {
    dispatch(clearReferralInformation({}))
    props.history.push('/Customer360View');
  }

  const validateComposedSMS = (e:any) => {
    if(e.target.value.trim() !== ""){
        setMessage(e.target.value)
    }
  }

  const onTextChangeHandler = (e:any) => { 
    setError("")
    let  test = e.target.value.replace(/((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/g,'');
  if (specials.test(test)) {
      setError("Financial_BC009")
      e.target.value = e.target.value.replace(/[&*%!<>]/g,'');
    }else {
        setCountCharacter(e.target.value.length)
        setTimeout(()=>{
            setMessage(e.target.value)
        }, 1000)
    }
  }

  const callPageHandler = async() => {
    if(message !== "" ) {
        if(countCharacter<=1500) {        
          dispatch(storeReferralAdditionalDetails(message))
          dispatch(comingFromReferralPage(true))
          props.history.push("/ContactDetails")   
        }  
    }else {
        setError("Financial_FI005")
    }

    
  }
  
  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer repair-request p-0" id="repAddDet-container">
    
        {/* Main Page Content */}
        <div className="padding-repair">
            <div id="repreason-div1">
              <h1 className="header-size pb-30">
              {t("Financial_Additional_Referral")}
              </h1>
            </div>
            {/* Yellow Alert */}
            { (error !== "" || countCharacter >=1500) && 
                <div >
                      { countCharacter>= 1500 &&<Message className="warning-msg">  {t("Financial_FI009")}</Message>}
                      {error && <Message className="warning-msg"> {t(`${error}`)}</Message>}
                </div>}
        <p className="py-2 flex justify-between mt-2 mb-2 px-2">
            {t("Financial_Additional_Point1")}
         </p>
         <p className="py-2 flex justify-between mt-2 mb-2 px-2">
            {t("Financial_Additional_Point2")}
         </p>
      
      
        <div className="contentPosition px-2">
            <textarea name="postSMS" rows={8} cols={40} spellCheck="true"
             data-testid='textarea'
             className="w-alert text-sm md:text-base h-28" aria-label="composeSMS" 
             maxLength={1500} placeholder={t("Financial_FI003")}
             defaultValue={message? message :""}
             onChange={onTextChangeHandler } onBlur={validateComposedSMS} />      
        </div>
    
        <p className="characterCount mt-2 px-2">{`${countCharacter}/1500`}</p>
         
         </div>
        {/* Exit and Send SMS button */}
        <div className="col-md-12 marginTop-auto">
        <div className="row m-0 footer-actionbtn case-footer">
          <div className="col btn-top padding-repair mb-0">
          <BackModal id="back-modal-backmodal" back={backButtonHandler} />
          <ExitModal id="repair-asset-exitmodal" exit={exitButtonHandler}/>
          </div>

          <div className="col text-end padding-repair">
          <Button
          id="btn_repairasset"
          onClick={callPageHandler}
          className="primary-btn"
        >
          {t("continuen_button")}{" "}
        </Button>
          </div>
        </div>
      </div>     
    </div>
 
</>
  )
};
export default AdditionalEnquiryDetails;