import React, { useEffect, useState } from 'react'
import "./index.scss";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from 'react-router-dom';
import * as api from "../../api";
import { AllpayConformationMessage, showMenuTabs, showFooter } from '../../ducks/redux/actions/userChoices';
import Button from '../../storybook/Button';
import mobileChevronleft from "../../assets/img/mobileChevronleft.png";
import HeaderJourney from "../../storybook/Header/HeaderJourney";

const allPayUrl: string = process.env.REACT_APP_ALLPAY_BASE_URL || '';

interface AllPayGatewayProps extends RouteComponentProps<any> {
}
const Allpayforminput = (key: string, Value: string): HTMLInputElement => {
    var hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = key;
    hiddenField.value = Value;
    return hiddenField
};

const AllPayGateway: React.FC<AllPayGatewayProps> = (props) => {
    const dispatch = useDispatch();
    const customerID = useSelector((state: any) => state.addresses?.customerid);
    const balance = Number(useSelector(
        (state: any) => state.userChoicesReducer?.OtherBalance)
    );

    const accountnumber = (useSelector(
        (state: any) => state.userChoicesReducer?.accountnumber)
    );
    const { t } = useTranslation()
    useEffect(() => {
        (async () => {
            try {
                const Payment = Number(balance.toString().replace(/£/g, ''))
                const liveBalancevalue = Payment.toFixed(2);
                const allPayTaken = await api.allPayTaken(customerID);
                window.addEventListener("message", onCompleteEvent);
                var form = document.createElement("form");
                form.method = "post";
                form.action = allPayUrl;
                form.target = "allpay";
                form.appendChild(Allpayforminput("token", allPayTaken['bearer-token']));
                form.appendChild(Allpayforminput("customerReference", accountnumber));
                form.appendChild(Allpayforminput("amount", liveBalancevalue));
                form.appendChild(Allpayforminput("clientCode", allPayTaken['client-code']));
                //form.appendChild(Allpayforminput("clientCode", "NEWS"));
                form.appendChild(Allpayforminput("paymentReason", "Rent"));


                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            }
            catch (e) {
                console.error(e);
                props.history.push("/genericerror");
            }
        })()
    }, [])

    useEffect(()=>{
        dispatch(showMenuTabs(false));
        dispatch(showFooter(false));
    },[])

    const backButtonHandler = () => {
        props.history.push("/paymentDetails");
    };
    const conformationButtonHandler = () => {
        props.history.push("/allPayGatewayConformation");
    };
    function onCompleteEvent(event: any) {
        if (event.data.resultCode === 0) {
            dispatch(AllpayConformationMessage(t("Success_Message")));
        }
        else {
            dispatch(AllpayConformationMessage(t("Fail_Message")));
        }
        conformationButtonHandler();
    }
    return (
        <>
            <HeaderJourney></HeaderJourney>
            <div id="iframe-allpay" className="container-fluid parent-footer p-0">
                <div className="padding-repair">
                    <div className="mt-24 allpay-scroll">
                        <iframe id="allpay" className="iframe-width" name="allpay" height="1200px" title="peabody-iframe" scrolling="no"></iframe>
                    </div>
                </div>
                <div className="col-md-12 marginTop-auto payment-bordernone">
                    <div className="row m-0 footer-actionbtn">
                        <div className="col btn-top padding-repair mb-24">
                        <span className="payment-backbtn">
                            <Button
                                data-testid="back-button"
                                onClick={backButtonHandler}
                                className="secondary-ghost-btn"
                            >
                                {t("Back")}
                            </Button>
                            </span>
                            <span className="payment-backarrow"><img src={mobileChevronleft} onClick={backButtonHandler} onKeyDown={backButtonHandler} alt="Back" /></span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AllPayGateway;


