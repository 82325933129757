import {
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "../../compositions/VandalismQuestion/index.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../storybook/Button";
import Loader from "react-loader-spinner";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import BackModal from "../Modal/BackModal";
import {
  resetUserChoicesWithNewChange,
  updateRouteHistory,
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  isEditState,
  resumeDraftRepair
} from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import HeaderJourney from "../../storybook/Header/HeaderJourney";

interface VandalismQuestionProps extends RouteComponentProps<any> { }
const VandalismQuestion: React.FC<VandalismQuestionProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [eligible, setEligible] = useState("none");
  const [radioValue, setRadioValue] = useState("No");
  const [crimeRefValue, setCrimeRefValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttondisable, setButtonDisable] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);

  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const format = /[$^&*_\{};<>/]+/;
  const history = useHistory();
  const userChosenVandalismQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenVandalismAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );
  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);
  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );
  useEffect(() => {
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage");
      return;
    }
    if (props.history.action === "POP") {
      setRadioValue(userChosenVandalismQuestionBackButton);
      setCrimeRefValue(userChosenVandalismAdditionalInformationBackButton);

      if (
        userChosenVandalismQuestionBackButton === "Yes" &&
        userChosenVandalismAdditionalInformationBackButton
      ) {
        setEligible("block");
      }
    }
  }, []);

  useEffect(() => {
    if (userChosenVandalismQuestionBackButton) {
      setRadioValue(userChosenVandalismQuestionBackButton);
      setCrimeRefValue(userChosenVandalismAdditionalInformationBackButton);

      if (
        userChosenVandalismQuestionBackButton === "Yes" &&
        userChosenVandalismAdditionalInformationBackButton
      ) {
        setEligible("block");
      }
    }
  }, []);
  const onChange = (e: any) => {

    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    if (format.test(e.target.value)) {
      return true;
    } else {
      e.preventDefault();
    }
    setCrimeRefValue(e.target.value);
    dispatch(userChosenVandalismAdditionalInformation(e.target.value));
  };

  // update work order
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const submitForm = async () => {
    try {
      setIsLoading(true);
      setButtonDisable(true);
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

      if (radioValue == "Yes") {
        woRequestParameters.isVandalism = true;
        workOrderObject.isVandalism = true;
        woRequestParameters.crimeReferenceNumber =
          userChosenVandalismAdditionalInformationBackButton;
        woRequestParameters.repairCause = "Vandalism or Damage";
        woRequestParameters.repairCauseId =
          RepairCauseId[
          woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      } else if (radioValue == "No") {
        woRequestParameters.crimeReferenceNumber = "";
        woRequestParameters.isVandalism = false;
        workOrderObject.isVandalism = false;
        woRequestParameters.repairCause = "General Wear and Tear";
        woRequestParameters.repairCauseId =
          RepairCauseId[
          woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      }
      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setButtonDisable(false);
    }
  };
  const backButtonHandler = () => {
    dispatch(userChosenVandalismAdditionalInformation("No"));
    dispatch(userChosenVandalismQuestion("No"));
    props.history.push("/repairadditionaldetails");
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    //Implement Save Draft and Exit functionalities here...
    submitForm();
    setCloseAfterSaving(true);
    props.history.replace("/customer360View");
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { vandalismQuestion, crimeReferenceNumber } = userResumeDraftDataCache;
      if (vandalismQuestion !== radioValue || crimeReferenceNumber !== crimeRefValue) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    submitForm();
    const timerId = setTimeout(() => {
      // console.log("Will be called after 2 seconds");
      props.history.push("/vulnerabilityquestion");
    }, 1000);
  };
  const handleRadioChange = (event: any) => {
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    setRadioValue(event.target.value);
    dispatch(userChosenVandalismQuestion(event.target.value));
    if (event.target.value === "Yes") {
      setEligible("block");
    } else {
      setEligible("none");
      setCrimeRefValue("");
      dispatch(userChosenVandalismAdditionalInformation("No"));
    }
  };

  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer repair-request p-0" id="vanQues-container">
        <div className="padding-repair" id="vanQues-main-div">
          <div id="vanQues-div1">
          <h1 className="pt-40 header-size" id="vanQues-h1">
          {t("VandalismQuestion")}
          </h1>
          <div className="mt-30">
            <RadioGroup
              id="vanQues-radiogroup"
              role="radiogroup"
              name="radio-buttons-group"
              defaultValue={radioValue}
              data-testid="crimereradioValue"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                id="vanQues-label1"
                value="Yes"
                placeholder="Yes"
                data-testid="yesRadio"
                control={<Radio id="vanQues-radio1" />}
                label="Yes"
              />
              <div
                id="vanQues-div2"
                style={{ display: eligible }}
              >
                <input
                  id="vanQues-text"
                  type="text"
                  value={crimeRefValue}
                  data-testid="crimereferencebox"
                  name="crimereferencebox"
                  className="txtBox-Input"
                  maxLength={20}
                  placeholder="Crime reference number (optional)"
                  onChange={(e) => onChange(e)}
                  aria-label="Crime reference number (optional)"
                ></input>
                {" "}
              </div>
              <FormControlLabel
                id="vanQues-label2"
                value="No"
                data-testid="noRadio"
                control={<Radio id="vanQues-radio2" />}
                label="No"
                placeholder="No"
              />
            </RadioGroup>
            </div>
          </div>
        </div>
        <div className="col-md-12 marginTop-auto" id="vanQues-main-btn">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair">
              <BackModal id="vanques-backmodal" back={backButtonHandler} />
              <ExitModalAndSave
                id="vanQues-exitmodal"
                exit={exitButtonHandler}
                saveDraftAndExit={saveDraftAndExit}
                closeAfterSaving={closeAfterSaving}
              />
            </div>
            <div className="col text-end pt-24 padding-repair">
              <Button
                id="btn_vandalism"
                disabled={buttondisable}
                onClick={callPageHandler}
                className="primary-btn"
              >
                {t("continuen_button")}

              </Button>
            </div>
          </div>
        </div>


        <span>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (
            <>
              <div></div>
            </>
          )}
        </span>
      </div>
    </>
  );
};

export default VandalismQuestion;
