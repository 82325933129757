import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import Loader from "react-loader-spinner";

import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  resumeDraftRepair
} from "../../ducks/redux/actions/userChoices";

import Button from "../../storybook/Button";
import ExitModalAndSave from "../Modal/ExitModalAndSave";

import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import * as api from "../../api";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";

interface CovidQuestionProps extends RouteComponentProps<any> { }
const CovidQuestion: React.FC<CovidQuestionProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [radioValue, setRadioValue] = React.useState("");
  const [isError, setIsError] = React.useState("none");
  const [isError2, setIsError2] = React.useState("none");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
      
  const handleRadioChange = (event: any) => {
    if (EditState === true) {
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    setRadioValue(event.target.value);
    dispatch(userChosenCovidQuestion(event.target.value));
  };

  const userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenVulnerabilityAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );

  const userChosenPropertyType = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );

  const userChosenCovidQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenCovidQuestion
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );

const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );
  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);

  const covidConfigValue = useSelector(
    (state: any) => state?.addresses?.covidtoggleValue
  );


  useEffect(() => {
    (async () => {
      if (isUndefinedNullOrEmpty(loggedInStatus)) {
        props.history.push("/startpage");
        return;
      }

      if (props.history.action === "POP") {
        setRadioValue(userChosenCovidQuestionBackButton);
      }
      if (userChosenPropertyType != "Property" || covidConfigValue === "false") {
        props.history.replace("/customerdetails");
        return;
      }

      if (userChosenCovidQuestionBackButton) {
        setRadioValue(userChosenCovidQuestionBackButton);
      }
    })();
  }, []);

  useEffect(() => {
    if (radioValue) {
      setIsError("none");
    }
    if (radioValue === "Yes" && userChosenVulnerabilityQuestion === "No" && priority !== "Emergency"
    ) {
      setIsError2("block");
      setIsDisabled(true);
    } else {
      setIsError2("none");
      setIsDisabled(false);
    }
  }, [radioValue, userChosenVulnerabilityQuestion]);

  const backButtonHandler = () => {
    dispatch(userChosenCovidQuestion(""));
    if (priority != "Emergency") {
      props.history.replace("/vulnerabilityquestion");
    } else {
      if(eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true)
      {
      props.history.replace("/vandalismquestion");
      }
      else
      props.history.push("/repairadditionaldetails")
    }
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    if (radioValue === "") {
      props.history.replace("/customer360View");
    } else {
      if (radioValue === "Yes") {
        props.history.replace("/customer360View");
      }
      else {
        submitForm();
        setCloseAfterSaving(true);
        props.history.replace("/customer360View");
      }

    }
  }

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { covidQuestion } = userResumeDraftDataCache;
      if (covidQuestion !== radioValue) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (radioValue === "") {
      setIsError("block");
    } else {
      submitForm();
      const timerId = setTimeout(() => {
        props.history.push("/customerdetails");
      }, 1000);
    }
  };


  // update work order
  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const userChosenVandalismQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenVandalismAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const submitForm = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

      if (radioValue === "Yes") {
        woRequestParameters.isCovidSelfIsolated = true;
        workOrderObject.isCovidSelfIsolated = true;
      } else if (radioValue === "No") {
        woRequestParameters.isCovidSelfIsolated = false;
        workOrderObject.isCovidSelfIsolated = false;
      }

      userChosenVulnerabilityQuestion != "" &&
        userChosenVulnerabilityQuestion != null &&
        userChosenVulnerabilityQuestion != "No"
        ? (woRequestParameters.isVulnerable = true)
        : (woRequestParameters.isVulnerable = false);
      userChosenVulnerabilityAdditionalInformation != ""
        ? (woRequestParameters.VulnerableResponseText =
          userChosenVulnerabilityAdditionalInformation)
        : (woRequestParameters.VulnerableResponseText = null);

      if (userChosenVandalismQuestion == "Yes") {
        woRequestParameters.crimeReferenceNumber =
          userChosenVandalismAdditionalInformation;
        woRequestParameters.repairCause = "Vandalism or Damage";
        woRequestParameters.repairCauseId =
          RepairCauseId[
          woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        woRequestParameters.crimeReferenceNumber = "";
        woRequestParameters.repairCause = "General Wear and Tear";
        woRequestParameters.repairCauseId =
          RepairCauseId[
          woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      }

      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      dispatch(workOrderRepairObject(workOrderObject));

    } catch (e) {
      console.error(e);
      setIsLoading(false);
      const timerId = setTimeout(() => {
        props.history.push("/genericerror");
      }, 1000);

    } finally {
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <>
      <Container id="covidQues-container" role="main">
        {/* <BackModalContent id="covidQues-backmodal" back={backButtonHandler} /> */}
        <div
          id="covidQues-main-div"
          className="ml-sm-5 pl-sm-5 repair-text"
        >
          <div id="covidQues-div1" className="h5 my-2">
            <div className="mb-8">
              <p id="covidQues-h1" className="text-purple-100 text-2xl mb-2 global-txt"> {t("CovidQuestion_label")}</p>
            </div>

            <RadioGroup
              id="covidQues-radiogroup"
              name="radio-buttons-group"
              defaultValue={radioValue}
              className="question-btn mb-4"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                id="covidQues-label1"
                value="Yes"
                className="h-8 w-8 global-txt mb-2 margin-lt"
                control={
                  <Radio id="covidQues-radio1" placeholder="Yes" name={"yes"} />
                }
                label="Yes"
              />
              <FormControlLabel
                id="covidQues-label2"
                value="No"
                className="mt-3 h-8 w-8 global-txt margin-lt"
                control={
                  <Radio id="covidQues-radio2" placeholder="No" name={"no"} />
                }
                label="No"
              />
            </RadioGroup>
          </div>

          <div className="error mb-8" id="covidQues-div21"
            style={{ display: isError }} placeholder="firtsAlert">
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("SSR012")}</p>
              </div>
            </div>
          </div>
          <div id="covidQues-div2"
            style={{ display: isError2 }}
            className="warning mb-8" placeholder="secondAlert">
            <div className="w-full px-2 py-2 bg-red-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("SSR048")}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="covidQues-main-btn"
          className="flex justify-between items-center pb-4 md:pb-8"
        >
          <ExitModalAndSave className="ml_btn" id="covidQues-exitmodal" exit={exitButtonHandler} saveDraftAndExit={saveDraftAndExit} closeAfterSaving={closeAfterSaving} />
          <Button
            id="btn_covidquestion"
            onClick={callPageHandler}
            className="button-class"
            disabled={isDisabled}
          >
            {t("continuen_button")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
        <span>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (
            <>
              <div></div>
            </>
          )}
        </span>

      </Container>
    </>
  );
};

export default CovidQuestion;
