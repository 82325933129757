export const TransactionType = [
    {
        key: "All",
        value: "All",
    },
    {
        key: "transactionType=PAY",
        value: "Payments",
    },
    {
        key: "transactionType=DRS",
        value: "Charges",
    },
    {
        key: "transactionType=ADJ&transactionType=DRA",
        value: "Adjustments",
    }  

];
export default TransactionType;


