import React, { useEffect, useState } from "react";
import Modal from "./index";
import Button from "../../storybook/Button";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import Clear from "../../assets/img/Clear.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	userChosenPropertyId,
	userChosenPropertyType,
	userChosenPropertyAddress,
	userChosenRepairLocationId,
	userChosenRepairLocation,
	userChosenHighLevelDescription,
	userChosenSupplementaryQuestions,
	userChosenEmergencyQuestion,
	userChosenDuplicateQuestion,
	userChosenAdditionalDescription,
	userChosenVandalismQuestion,
	userChosenVandalismAdditionalInformation,
	userChosenCovidQuestion,
	userChosenVulnerabilityQuestion,
	userChosenVulnerabilityAdditionalInformation,
	userChosenContactDetails,
	userChosenUPRN,
	userChosenSOR,
	userChosenSTATUS,
	userChosenEligibilityErrorCode,
	userChosenContactPhone,
	userChosenContactName,
	userChosenRelationDetails
} from "../../ducks/redux/actions/userChoices";

const ExitModalAndSave: React.FC<ModalProps> = ({ exit, saveDraftAndExit, closeAfterSaving }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);
	const [disableSaveLeaveBtn, setDisableSaveLeaveBtn] = useState(true);
	const workOrderObject = useSelector(
		(state: any) => state.addresses.workOrderRepair
	);

	const userChosenSORValue = useSelector(
	(state: any) => state.userChoicesReducer.userChosenSOR
	);

	const clearUserStates = async () => {
		dispatch(userChosenPropertyId(""));
		dispatch(userChosenPropertyType(""));
		dispatch(userChosenPropertyAddress(""));
		dispatch(userChosenRepairLocationId(0));
		dispatch(userChosenRepairLocation(""));
		dispatch(userChosenHighLevelDescription(""));
		dispatch(userChosenSupplementaryQuestions(""));
		dispatch(userChosenEmergencyQuestion(""));
		dispatch(userChosenDuplicateQuestion(""));
		dispatch(userChosenAdditionalDescription(""));
		dispatch(userChosenVandalismQuestion("No"));
		dispatch(userChosenVandalismAdditionalInformation(""));
		dispatch(userChosenCovidQuestion(""));
		dispatch(userChosenVulnerabilityQuestion(""));
		dispatch(userChosenVulnerabilityAdditionalInformation(""));
		dispatch(userChosenContactDetails(""));
		dispatch(userChosenUPRN(""));
		dispatch(userChosenSOR(""));
		dispatch(userChosenSTATUS(""));
		dispatch(userChosenEligibilityErrorCode(""));
		dispatch(userChosenContactPhone(""));
		dispatch(userChosenContactName(""));
		dispatch(userChosenRelationDetails(""));
		exit();
		if (workOrderObject) {
			const getWorkOrderResult: any = await api.getRepair(workOrderObject.id);
			const deleteWorkOrderResponse: any = await api.deleteWorkOrder(workOrderObject.id, getWorkOrderResult?.eTag?.toString());
		}
	}

	if (closeAfterSaving) {
		setIsOpen(false)
	}

	useEffect(() => {
		const urlRepairLoc = window.location.pathname.slice(1,window.location.pathname.length);
		setDisableSaveLeaveBtn(userChosenSORValue === "" && urlRepairLoc === "repairlocation")
	})

	return (
		<>
			<a id="exitmodal-btn1" onClick={() => setIsOpen(true)} onKeyDown={() => setIsOpen(true)}>
			<span className="exit-arrowbtn">
				<img src={Clear}  alt="Exit" />
				</span>
			</a>
			<Modal open={isOpen} cssClasses="modalPopUp">

				<div id="exitmodal-div1">
					<div className="text-end pb-16"><img src={Clear} className="cursor-pointer" alt="Cancel" onClick={() => setIsOpen(false)} onKeyDown={() => setIsOpen(false)} /></div>
					<h1 id="exitmodal-div2" className="header-size pt-0">
						{t("SSR030ai")}
					</h1>
					<p className="modal-para">{t("SSR030aii")}</p>
					<div id="exitmodal-div3" className="modalPopup-footerbtn">
						<div className="col-lg-12 marginTop-auto">
							<div className="row m-0">
								<div className="col-md-3 btn-top bordertop-none cancel-exitBtn">
									<Button id="exitmodal-btn2" className="secondary-ghost-btn" onClick={() => setIsOpen(false)}>{t("return_repair_button")}
									</Button>
								</div>
								<div className="col-md-9 text-end bordertop-none exit-save">
									<Button id="exitmodal-btn3" onClick={clearUserStates} className="secondary-ghost-btn btn-padding">{t("leave_button")}
									</Button>
									<Button id="exitmodal-btn4" disabled={disableSaveLeaveBtn} onClick={saveDraftAndExit} className="primary-btn btn-padding">{t("save_and_exit_button")}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ExitModalAndSave;
