const imgSize = process.env.REACT_APP_IMG_SIZE as string;
const videoSize = process.env.REACT_APP_VIDEO_SIZE as string;
const pdfSize = process.env.REACT_APP_DOC_SIZE as string;

export const validateMediaSize = (mediaType: any) => {
  let size: number = +imgSize;
  mediaType = mediaType.toString();
  if (mediaType.split("/", 1) == "video") {
    size = +videoSize;
  } else if (mediaType.split("/", 1) == "pdf") {
    size = +pdfSize;
  } else {
    size = +imgSize;
  }

  return size;
};
