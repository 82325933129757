export interface NameDetails {
  title?: string | null
  givenName?: string | null
  surname?: string | null
}

export default class CustomerName {
  public title: string | null = ''
  public givenName: string | null = ''
  public surname: string | null = ''

  constructor(args?: NameDetails | null) {
    if (args) {
      const { title = '', givenName = '', surname = '' } = args
      this.title = title
      this.givenName = givenName
      this.surname = surname
    }
  }

  public getFullName(): string {
    return this.renderWithoutNullValues([this.title, this.givenName, this.surname])
  }
  public isEmpty() {
    return !this.title && !this.givenName && !this.surname
  }

  public getWithoutTitle(): string {
    return this.renderWithoutNullValues([this.givenName, this.surname])
  }

  private renderWithoutNullValues(fields: Array<string | null>): string {
    return fields.filter(x => x !== null).join(' ')
  }
}
