
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import Message from "../../storybook/Message";
import Button from "../../Button/Button";
import "../../assets/css/custom.scss"
import "./index.css";


const GenericError: React.FC<any> = (props) => {
  const isError = useSelector(
    (state: any) => state?.userChoicesReducer?.userIsError
  );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loggedInStatus = useSelector(
        (state: any) => state?.userChoicesReducer?.loginStatus
    );

    const customerID = useSelector((state: any) => state.addresses?.customerid);

  const exitButtonHandler = () => {    
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage")
    }
    else {
      props.history.push("/customer360View")
    }
  };
  
 

  return (
    <div className="gen_font gen_err">
            {isError && (
               <Message className="error-msg container-fluid parent-footer p-0">                           
               {t("IDEM_Error")}
           </Message>             
            ) }
        
          {!isError && <div className="mt-24-gen-head"> 
              <h1>Whoops!</h1>
             <p className="mt-gen-err gen_font"> {t("Generic_Error")}
              {t("Generic_Error1")}
             {t("Generic_Error2")}</p>
          </div>}
          
         <Button
          onClick={exitButtonHandler}
          className="primary-btn mt-gen-err">
          {t("Generic_Error_button")}
          </Button>   
      
    </div>

  )

}
export default GenericError;