import React, { useEffect, useState } from "react";
import "./index.scss";
import peabody from "../../assets/img/peabody-logo-teal.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Hotjar from '@hotjar/browser'
import { loginStatus, loginTime, selectedTabValue, userChosenAdditionalDescription, userChosenContactDetails, userChosenContactName, userChosenContactPhone, userChosenCovidQuestion, userChosenDuplicateQuestion, userChosenEligibilityErrorCode, userChosenHighLevelDescription, userChosenPropertyAddress, userChosenPropertyId, userChosenPropertyType, userChosenRelationDetails, userChosenRepairLocation, userChosenSOR, userChosenSTATUS, userChosenSupplementaryQuestions, userChosenUPRN, userChosenVandalismAdditionalInformation, userChosenVandalismQuestion, userChosenVulnerabilityAdditionalInformation, userChosenVulnerabilityQuestion, userProfileData } from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { getCustomerId } from "../../ducks/redux/actions/getAddress";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import HomeModal from "../../compositions/Modal/HomeModal";
import {
    CASE_SELECTED_ANSWERS,
    CLEAR_CASE_STATE,
} from "../../ducks/redux/actionTypes";


const b2cLoginUrl: string = process.env.REACT_APP_CSS_B2C_LOGIN_URL || "";
const siteId = process.env.REACT_APP_SITE_ID;
const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;


const HeaderJourney = ( props:any ) => {
   
  
    const peabodyWebsite = () => {
        window.open(
            "https://www.peabody.org.uk/",
            "_blank",
            "noopener"
        );
    }
   
    return (       
                <header className="repair-header container-fluid">
                    <div className="row justify-content-start">
                        <div className="col header-logo">
                        <a data-testid="peabody_logo" onClick={peabodyWebsite} onKeyDown={peabodyWebsite}>
                        <img src={peabody} className="logo mtb-16" alt="Peabody Logo" />
                    </a>
                        </div>
                    </div>              
                </header>

    );
};
export default HeaderJourney;