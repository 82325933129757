
import {
  GET_REPAIR_ID,
  REPAIRS_SEARCH_SUCCESS,
  REPAIR_HISTORY_DATA,
  GET_CONTACTOR_DATA,
  REPAIR_HISTORY_CURRENT_PAGE,
  REPAIR_HISTORY_CURRENT_RESULTS_PER_PAGE,
  REPAIR_DEFAULT_ASSOCIATED_ASSET,
  REPAIR_SEARCH_TERM ,
  DEFAULT_SEARCH_REPAIR_STATUS,
  DEFAULT_SEARCH_AREA ,
  DEFAULT_SEARCH_SORT_BY_ORDER,
  FRA_DOCUMENTS,
  BSR_DOCUMENTS,
  RES_DOCUMENTS

} from "../actionTypes";

export const searchForRepairs = (payload: any) => ({
  type: REPAIRS_SEARCH_SUCCESS,
  payload
});
export const getRepairID = (payload: any) => ({
  type: GET_REPAIR_ID,
  payload
});

export const repairHistoryData = (payload: any) => ({
  type: REPAIR_HISTORY_DATA,
  payload
});

export const getContractorsData = (payload: any) => ({
  type: GET_CONTACTOR_DATA,
  payload
});
export const repairHistoryCurrentPage = (payload: any) => ({
  type: REPAIR_HISTORY_CURRENT_PAGE,
  payload
});
export const repairHistoryCurrentResultsPerPage = (payload: any) => ({
  type: REPAIR_HISTORY_CURRENT_RESULTS_PER_PAGE,
  payload
});
export const repairDefaultAssocitaedAsset = (payload: any) => ({
  type: REPAIR_DEFAULT_ASSOCIATED_ASSET,
  payload
});

export const repairSearchTerm = (payload: any) => ({
  type: REPAIR_SEARCH_TERM,
  payload
});
export const defaultSearchRepairStatus = (payload: any) => ({
  type: DEFAULT_SEARCH_REPAIR_STATUS,
  payload
});
export const defaultSearchArea = (payload: any) => ({
  type: DEFAULT_SEARCH_AREA,
  payload
});
export const defaultSearchSortByOrder = (payload: any) => ({
  type: DEFAULT_SEARCH_SORT_BY_ORDER,
  payload
});
export const storeFRADocuments = (payload:any) => ({
  type: FRA_DOCUMENTS,
  payload
})
 
export const storeBSRDocuments = (payload:any) => ({
  type: BSR_DOCUMENTS,
  payload
})

export const storeRESDocuments = (payload:any) => ({
  type: RES_DOCUMENTS,
  payload
})

