import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, } from "react-router-dom";
import { clearAllState, loginStatus, loginTime } from '../../ducks/redux/actions/userChoices';
import { getCustomerId, showSessionTimeOutMessage } from '../../ducks/redux/actions/getAddress';
import IdleTimer from "react-idle-timer"
import { useTranslation } from "react-i18next";
import Button from "../../storybook/Button";
import Modal from 'react-modal';
import { isUndefinedNullOrEmpty } from '../../utils/translation/helper';
import Clear from "../../assets/img/Clear.png";
import "./index.scss";

const STATUS = {
    STARTED: 'Started',
    STOPPED: 'Stopped',
}

const INITIAL_COUNT = 300
if (process.env.NODE_ENV !== 'test') Modal.setAppElement("#root")
export default function AutoLogout() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
    const [status, setStatus] = useState(STATUS.STOPPED);
    const idleTimerRef = useRef(null);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [showSignoutMessageState, setShowSignoutMessageState] = React.useState(false);
    const [showSignoutMessage, setShowSignoutMessage] = React.useState(false);
    const secondsToDisplay = secondsRemaining % 60
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
    const minutesToDisplay = minutesRemaining % 60

    const loggedinStatus = useSelector(
        (state: any) =>
            state?.userChoicesReducer?.loginStatus
    );

    useInterval(
        () => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining - 1)
            } else {
                setStatus(STATUS.STOPPED)
            }
        },
        status === STATUS.STARTED ? 1000 : 0,
        setIsOpen,
        setShowSignoutMessage,
        showSignoutMessageState,
        setShowSignoutMessageState
    )

    useEffect(() => {
        if (isUndefinedNullOrEmpty(loggedinStatus)) {
            setIsOpen(false);
            setStatus(STATUS.STOPPED);
        }
        else {
            setStatus(STATUS.STARTED);
        }
    })

    const onIdle = () => {
        setSecondsRemaining(INITIAL_COUNT);
        if (secondsRemaining != 0) {
            setTimeout(() => {
                setStatus(STATUS.STOPPED);
                setShowSignoutMessageState(true);
            }, 300 * 1000);
            setIsOpen(true);
            setShowSignoutMessageState(true);
        }
    }

    const onContinue = () => {
        setIsOpen(false)
        setShowSignoutMessage(false);
        setShowSignoutMessageState(false);
    }

    const onShowSignoutMessage = () => {
        setShowSignoutMessage(false);
        setShowSignoutMessageState(false);
    }

    const onRedirectLoginPage = () => {
        dispatch(loginTime(''));
        dispatch(loginStatus(false));
        dispatch(getCustomerId(''));
        dispatch(clearAllState({}));
        setIsOpen(false);
        setShowSignoutMessage(false);
        setShowSignoutMessageState(false);
        history.push("/startpage")
    };

    return (
        <><Modal isOpen={modalIsOpen} id="Modal-div" className="modalPopUp">

            <div id="timermodal-div">
            <div className="text-end pb-16"><img src={Clear} alt="Cancel" onKeyDown={onContinue}  onClick={onContinue} className="cursor-pointer" /></div>
                <h1 id="backmodal-div3">
                    {t("AutoLogoutMessage")}
                </h1>
                <p className='pt-24'>{t("AutoLogoutText")}</p>
                <p className='pt-16 pb-16'>
                    <span className='fw-bold pe-1'>{twoDigits(minutesToDisplay)}:
                        {twoDigits(secondsToDisplay)}</span>
                    {t("EMID024")}
                </p>
                <div id="backmodal-div3" className="modalPopup-footerbtn">
                    <div className="col-md-12 marginTop-auto">
                        <div className="row m-0 autolog-modal">
                            <div className="col-md-3 btn-top bordertop-none">
                                <Button id="backmodal-btn2" onClick={onRedirectLoginPage} className="secondary-ghost-btn">
                                    {t("logout_button")}
                                </Button>
                            </div>
                            <div className="col-md-9 text-end bordertop-none">
                                <Button id="backmodal-btn1"
                                    className="primary-btn btn-padding"
                                    onClick={onContinue}
                                >
                                    {t("Stay_logged_in")}

                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
            <Modal isOpen={showSignoutMessage} id="Modal-div" className="modalPopUp">
                <div id="timermodal-div">
                <div className="text-end pb-16"><img src={Clear} alt="Cancel" onKeyDown={onShowSignoutMessage} onClick={onShowSignoutMessage} className="cursor-pointer" /></div>
                    <h1 id="backmodal-div3">
                        {t("EMID025")}
                    </h1>
                    <div id="backmodal-div3" className="modalPopup-footerbtn">
                    <div className="col-md-12 marginTop-auto">
                        <div className="row m-0 autolog-modal">
                            <div className="col-lg-12 text-end bordertop-none">
                        <Button id="backmodal-btn2" className="secondary-ghost-btn mt-3" onClick={onShowSignoutMessage}>
                            {t("ok_button")}
                        </Button>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <IdleTimer ref={() => idleTimerRef} timeout={1500 * 1000} onIdle={onIdle}></IdleTimer>
        </>

    )
}

function useInterval(callback: any, delay: number, setIsOpen: any, setShowSignoutMessage: any, showSignoutMessageState: boolean, setShowSignoutMessageState: any) {
    const dispatch = useDispatch();
    const history = useHistory();
    const savedCallback = useRef<any>()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay != 0) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }

        if (delay === 0) {
            RedirectLoginPage();
        }
    }, [delay])

    const RedirectLoginPage = () => {
        setIsOpen(false);
        if (showSignoutMessageState) {
            dispatch(loginTime(''));
            dispatch(loginStatus(false));
            dispatch(getCustomerId(''));
            setShowSignoutMessage(true);
            setShowSignoutMessageState(false);
            history.push("/startpage")
        }
    };
}

const twoDigits = (num: number) => String(num).padStart(2, '0')