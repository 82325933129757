import { AnyAction } from "redux";
import {
  REPAIRS_SEARCH_SUCCESS,
  GET_REPAIR_ID,
  REPAIR_HISTORY_DATA,
  REPAIR_HISTORY_CURRENT_PAGE,
  REPAIR_HISTORY_CURRENT_RESULTS_PER_PAGE,
  REPAIR_DEFAULT_ASSOCIATED_ASSET,
  REPAIR_SEARCH_TERM,
  DEFAULT_SEARCH_REPAIR_STATUS,
  DEFAULT_SEARCH_AREA,
  DEFAULT_SEARCH_SORT_BY_ORDER,
  FRA_DOCUMENTS,
  BSR_DOCUMENTS,
  RES_DOCUMENTS
} from "../actionTypes";

const initialState = {
  data: "",
  repairID: "",
  repairHistoryData: {},
  repairHistoryCurrentPage:0,
  repairHistoryCurrentResultsPerPage:10,
  repairDefaultAssocitaedAsset:"",
  repairSearchTerm:"",
  defaultSearchRepairStatus:"",
  defaultSearchArea:"",
  defaultSearchSortByOrder:"",
  
};
export const historicRepairReducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_REPAIR_ID: {
      return {
        ...state,
        repairID: action.payload
      }
    }
    case REPAIRS_SEARCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.payload.models,
        totalCount: action.payload.totalCount,
      }
    }
    case REPAIR_HISTORY_DATA:
      return {
        ...state,
        repairHistoryData: action.payload,        
      };
      case REPAIR_HISTORY_CURRENT_PAGE:
      return {
        ...state,
        repairHistoryCurrentPage: action.payload,        
      };
      case REPAIR_HISTORY_CURRENT_RESULTS_PER_PAGE:
      return {
        ...state,
        repairHistoryCurrentResultsPerPage: action.payload,        
      };
      case REPAIR_DEFAULT_ASSOCIATED_ASSET:
      return {
        ...state,
        repairDefaultAssocitaedAsset: action.payload,        
      };
      case REPAIR_SEARCH_TERM:
      return {
        ...state,
        repairSearchTerm: action.payload,        
      };
      case DEFAULT_SEARCH_REPAIR_STATUS:
      return {
        ...state,
        defaultSearchRepairStatus: action.payload,        
      };
      case DEFAULT_SEARCH_AREA:
      return {
        ...state,
        defaultSearchArea: action.payload,        
      };
      case DEFAULT_SEARCH_SORT_BY_ORDER:
      return {
        ...state,
        defaultSearchSortByOrder: action.payload,        
      };
      case FRA_DOCUMENTS:
        return {
          ...state,
          FRADocuments:action.payload
        }
      case BSR_DOCUMENTS:
          return {
            ...state,
            BSRDocuments:action.payload
          }
          case RES_DOCUMENTS:
            return {
              ...state,
              RESDocuments:action.payload
            }
    default:
      return state
  }
};
export default historicRepairReducer;