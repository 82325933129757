import React, { useEffect, useState } from "react";
import Modal from "./index";
import Button from "../../storybook/Button";
import Clear from "../../assets/img/Clear.png";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import mobileChevronleft from "../../assets/img/mobileChevronleft.png";

const BackModal: React.FC<ModalProps> = ({ back, btn_msg }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isMobView, setIsMobView] = useState(false);


    useEffect(() => {
        if (window.innerWidth <= 414) {
            setIsMobView(true);
        }
    }, [])

    // flex-row for desktop view and flex-row for mobile view

    const webViewModal = () => {
        return (
            <Modal open={isOpen} cssClasses="modalPopUp">
                <div id="backmodal-div2">
                    <div className="text-end pb-16"><img src={Clear} alt="Cancel" className="cursor-pointer" onClick={() => setIsOpen(false)} onKeyDown={() => setIsOpen(false)} /></div>
                    <h1
                        id="backmodal-div3"
                        className="header-size pt-0"
                    >
                        {t("SSR043")}
                    </h1>
                    <div
                        id="backmodal-div3"
                        className="modalPopup-footerbtn"
                    >
                        <div className="col-md-12 marginTop-auto">
                            <div className="row m-0">
                                <div className="col-md-3 btn-top bordertop-none cancel-exitBtn">
                                    <Button
                                        id="backmodal-btn1"
                                        className="secondary-ghost-btn"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {t(btn_msg || "return_repair_button")}
                                    </Button>
                                </div>
                                <div className="col-md-9 text-end bordertop-none">
                                    <Button id="backmodal-btn2" onClick={back} className="secondary-ghost-btn btn-padding">
                                        {t("go_back_button")}
                                    </Button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Modal>
        );
    }

    const mobViewModal = () => {
        return (
            <Modal open={isOpen} cssClasses="modalPopUp">
                <div id="backmodal-div2">
                    <div className="text-end pb-16"><img src={Clear} alt="Cancel" className="cursor-pointer" onClick={() => setIsOpen(false)} onKeyDown={() => setIsOpen(false)} /></div>
                    <h1
                        id="backmodal-div3"
                        className="header-size pt-0"
                    >
                        {t("SSR043")}
                    </h1>
                    <div
                        id="backmodal-div3"
                        className="modalPopup-footerbtn"
                    >
                        <div className="col-md-12 marginTop-auto">
                            <div className="row m-0">
                                <div className="col-md-3 btn-top bordertop-none cancel-exitBtn">
                                    <Button
                                        id="backmodal-btn1"
                                        className="secondary-ghost-btn"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {t(btn_msg || "return_repair_button")}
                                    </Button>
                                </div>
                                <div className="col-md-9 text-end bordertop-none">
                                    <Button id="backmodal-btn2" onClick={back} className="secondary-ghost-btn btn-padding">
                                        {t("go_back_button")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }

    return (
        <>
            <span className="back-arrow"><img src={mobileChevronleft} onClick={() => setIsOpen(true)} onKeyDown={() => setIsOpen(true)} alt="Back" /></span>
            <a className="back-arrowbtn secondary-ghost-btn" onClick={() => setIsOpen(true)} onKeyDown={() => setIsOpen(true)}>Back
            </a>
            {isMobView ?

                mobViewModal()
                :
                webViewModal()
            }
        </>
    );
};

export default BackModal;
