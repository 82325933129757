import React, { useState, useEffect, Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    Container,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import {
    saveAssociatedAssests,
    saveCustomerTenacies,   
    saveCustomerDetails,
    saveCustomerphoneNumber,
    getCustomerId,    
} from "../../../ducks/redux/actions/getAddress";
import { showMenuTabs, singleTenancy, userChosenPropertyAddress, showFooter, userNavigatedFrom } from "../../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../../utils/translation/helper";
import {   
    CASE_EDIT,
    CASE_PROPERTY_TENANCY_GROUP,
    CASE_SELECTED_ANSWERS,
    CLEAR_CASE_STATE,
    USERCHOSEN_PROPERTY_ID,
} from "../../../ducks/redux/actionTypes";

import Button from "../../../storybook/Button";
import Message from "../../../storybook/Message";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import mobileChevronleft from "../../../assets/img/mobileChevronleft.png"
import Clear from "../../../assets/img/Clear.png"
import ExitModal from "../../Modal/ExitModal";
import BackModal from "../../Modal/BackModal";

const Loader = React.lazy(() => import("react-loader-spinner"));


interface SelectTenencyProps extends RouteComponentProps<any> { }
const SelectTenency: React.FC<SelectTenencyProps> = (props) => {
    const dispatch = useDispatch();
 
    const userChosenPropertyId = useSelector(
        (state: any) => state.userChoicesReducer?.userChosenPropertyId
    );   
   
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const array: any[] = [];
    const [AllAsset, SetAllAssetData] = useState(array);

    const [isError, setError] = useState("none");
    const [buttondisable, setsetButtonDisable] = useState(false);

    const [getTenancyGroup, setTenancyGroup] = useState("");

    const radiovalue = useSelector(
        (state: any) => state.userChoicesReducer.userChosenPropertyAddress
    );

    const customerID = useSelector((state: any) => state.addresses?.customerid);
   
    const [activeTenacyPremisesIds, setActiveTenacyPremisesIds] = useState<any>(
        []
    );

    useEffect(() => {
        (async function () {
            try {
                setIsLoading(true);
                let residentialProperties: {
                    premisesid: string;
                    tenancygroup: string;
                    startdate: string | null;
                }[] = [{ premisesid: "", tenancygroup: "", startdate: "" }];

                let nonResidentialProperties: {
                    premisesid: string;
                    tenancygroup: string;
                    startdate: string | null;
                }[] = [{ premisesid: "", tenancygroup: "", startdate: "" }];
                // set work order to null for create new work order
                const customerResponse = api.getCustomer(customerID);
                const tenancyResponse = api.getTenancies(customerID);
                Promise.all([customerResponse, tenancyResponse]).then(
                    async (results) => {
                        dispatch(
                            saveCustomerphoneNumber(results[0].addresses.telecomsAddresses)
                        );
                        dispatch(getCustomerId(customerID));
                        const tenancySummries = results[0].tenanciesSummaries;

                        const activeTenanciesSummaries = tenancySummries.filter(
                            (ts) =>
                                ts.tenancySummary.statuses.find(
                                    (s) => s.type === "isTenancyActive"
                                )?.value === true
                        );
                       
                        dispatch(saveCustomerTenacies(activeTenanciesSummaries));

                        dispatch(saveCustomerDetails(results[0]));

                        if (results[1]?.length > 0) {
                            results[1].map((itemTenancy) => {
                                activeTenanciesSummaries.map((itemActiveTenancy) => {
                                    if (
                                        itemTenancy.tenancyIdentifier ==
                                        itemActiveTenancy.tenancySummary.tenancyId
                                    ) {
                                        const premiseid =
                                            itemActiveTenancy?.tenancySummary?.premisesIds[0];

                                        const startdateValue =
                                            itemActiveTenancy?.tenancySummary.tenancyStartDate;

                                        const groupTenancy = itemTenancy.tenancyGroup;

                                        if (
                                            itemTenancy.tenancyGroup == "Non-residential" ||
                                            itemTenancy.tenancyGroup == "Commercial"
                                        ) {
                                            nonResidentialProperties.push({
                                                premisesid: premiseid,
                                                tenancygroup: groupTenancy,
                                                startdate: startdateValue,
                                            });
                                        } else {
                                            residentialProperties.push({
                                                premisesid: premiseid,
                                                tenancygroup: groupTenancy,
                                                startdate: startdateValue,
                                            });
                                        }
                                    }
                                });
                            });
                        }
                        const resAddAssestsArray: any = [];
                        const promisesResidential = residentialProperties.map(
                            async (itemdata) => {
                                if (itemdata.premisesid !== "") {
                                    const responseResidential: any =
                                        await api.getAssociatedAssets(itemdata.premisesid);
                                    resAddAssestsArray.push(responseResidential);

                                    return responseResidential
                                        .filter((data: any) => {
                                            return data.id === itemdata.premisesid;
                                        })

                                        .map((eData: any) => {
                                            return {
                                                ...itemdata,
                                                residential: true,
                                                formattedAddress:
                                                    eData.addresses.postalAddresses[0].formattedAddress,
                                            };
                                        });
                                }
                            }
                        );
                        const promisesNonResidential = nonResidentialProperties.map(
                            async (itemdata) => {
                                if (itemdata.premisesid !== "") {
                                    const responseNonResidential: any =
                                        await api.getAssociatedAssets(itemdata.premisesid);
                                    resAddAssestsArray.push(responseNonResidential);

                                    return responseNonResidential
                                        .filter((data: any) => {
                                            return data.id === itemdata.premisesid;
                                        })

                                        .map((eData: any) => {
                                            return {
                                                ...itemdata,
                                                residential: false,
                                                formattedAddress:
                                                    eData.addresses.postalAddresses[0].formattedAddress,
                                            };
                                        });
                                }
                            }
                        );

                        SetAllAssetData(resAddAssestsArray);

                        const parsedData = await Promise.all([
                            ...promisesResidential,
                            ...promisesNonResidential,
                        ]);

                        const resData = parsedData
                            .filter((e: any) => (e && e[0] ? true : false))
                            .flat()
                            .sort(function (a: any, b: any) {
                                const dateA: any = Date.parse(a.startdate);
                                const dateB: any = Date.parse(b.startdate);
                                return dateB - dateA;
                            });

                        setActiveTenacyPremisesIds(resData);

                        // ======  getting case tenancy group value ========

                        resData.map((tenancy: any) => {
                            if (tenancy.formattedAddress === radiovalue) {
                                setTenancyGroup(tenancy.tenancygroup);
                            }
                        });

                        // ============== end ======================

                        const residentialBool = resData.filter((e: any) => {
                            return e.residential;
                        });

                        if (activeTenacyPremisesIds?.length === 1) {
                            dispatch(saveAssociatedAssests(results));
                        }

                        const chosenAdd = resData.filter((eachData: any) => {
                            return eachData.premisesid === userChosenPropertyId;
                        });

                        const defaultData = chosenAdd[0];

                        if (!isUndefinedNullOrEmpty(radiovalue)) {
                            const selectedtData = resData.filter((data: any) => {
                                return data.formattedAddress === radiovalue;
                            });
                            callSelection(
                                selectedtData[0]?.formattedAddress,
                                selectedtData[0]?.premisesid,
                                resAddAssestsArray,
                                selectedtData[0]?.tenancygroup
                            );
                        } else {
                            callSelection(
                                defaultData?.formattedAddress,
                                defaultData?.premisesid,
                                resAddAssestsArray,
                                defaultData?.tenancygroup
                            );
                        }

                        //redirect to next page if single tenancy
                        if (resData.length === 1) {
                            dispatch({
                                type: CASE_PROPERTY_TENANCY_GROUP,
                                payload: resData[0].tenancygroup,
                            });
                            setIsLoading(false);
                            dispatch(showMenuTabs(false));
                            dispatch(showFooter(false));
                            dispatch(singleTenancy(true))
                            props.history.push("/caseType");
                        }

                        setIsLoading(false);
                    }
                );
                setError("none");
                setsetButtonDisable(false);
            } catch (e) {
                setError("block");
                setsetButtonDisable(true);
                props.history.push("/genericerror");
            }
        })();
        dispatch(userNavigatedFrom(""));
    }, []);

    const backButtonHandler = () => {
        dispatch(userChosenPropertyAddress(""));
        props.history.push("/customer360View");
    };

    const exitButtonHandler = () => {
        props.history.push("/customer360View");
    };

    const onContinueHandler = () => {
        dispatch({ type: CASE_PROPERTY_TENANCY_GROUP, payload: getTenancyGroup });
        dispatch(showMenuTabs(false));
        dispatch(showFooter(false));  
        props.history.push("/caseType");
    };

    const updateSelection = (
        value: string,
        premiseId: any,
        tenancygroup: any,
        arr: any = AllAsset
    ) => {
        setTenancyGroup(tenancygroup);

        let associatedAssets: any[] = [];
        arr.forEach((element: any) => {
            const resultF = element.filter((data: any) => data.id === premiseId);
            if (resultF !== null && resultF.length > 0) {
                associatedAssets.push(element);

                dispatch(saveAssociatedAssests(associatedAssets));
            }
        });
        dispatch(userChosenPropertyAddress(value));
        dispatch({ type: USERCHOSEN_PROPERTY_ID, payload: premiseId });
        // ==== If user is updating the property address, remove all the case related data from store ====
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
        dispatch({ type: CLEAR_CASE_STATE });
        dispatch({ type: CASE_EDIT, payload: true });
    };
    const callSelection = (
        value: string,
        premiseId: any,
        arr: any = AllAsset,
        tenancygroup: any
    ) => {
        let associatedAssets: any[] = [];
        arr.forEach((element: any) => {
            const resultF = element.filter((data: any) => data.id === premiseId);
            if (resultF !== null && resultF.length > 0) {
                associatedAssets.push(element);
                dispatch(saveAssociatedAssests(associatedAssets));
            }
        });
        dispatch(userChosenPropertyAddress(value));
        setTenancyGroup(tenancygroup);
    };

    return (
        <><HeaderJourney></HeaderJourney>
            <div className="container-fluid parent-footer repair-request p-0" data-testid="case-container" role="main">
                <div className="padding-repair">
                    <h1
                        id="repair-asset-h1"
                        className="header-size"                                    >
                        {t("Case_CC003")}
                    </h1>
                    {isLoading ? (
                        <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
                    ) : (

                        <><div
                            style={{ display: isError }}
                        >
                            <Message className="error-msg">
                                {t("cases_servicedown")}
                            </Message>
                        </div><div className="pt-44">

                                <RadioGroup
                                    data-testid="case-radiogroup"
                                    name="radio-buttons-group"
                                    value={radiovalue}
                                    className="question-btn mx-0 px-0 max-w-4xl my-2 "
                                >
                                    {activeTenacyPremisesIds
                                        .filter((e: any) => {
                                            return e.residential;
                                        })
                                        .map((e: any) => {
                                            return (
                                                <FormControlLabel
                                                    value={e.formattedAddress}
                                                    key={e.premisesid}
                                                    className="mb-4 global-txt card-text margin-lt"
                                                    control={<Radio
                                                        key={e.premisesid}
                                                        id={"radio-id1"}
                                                        data-testid="button"
                                                        name={e.formattedAddress}
                                                        className="global-txt"
                                                        onChange={(
                                                            v: React.ChangeEvent<HTMLInputElement>
                                                        ): void => {
                                                            updateSelection(
                                                                v.target.value,
                                                                e.premisesid,
                                                                e.tenancygroup
                                                            );
                                                        }} />}
                                                    label={e.formattedAddress} />
                                            );
                                        })}
                                    {activeTenacyPremisesIds
                                        .filter((e: any) => {
                                            return !e.residential;
                                        })
                                        .map((e: any) => {
                                            return (
                                                <FormControlLabel
                                                    data-testid="formControl"
                                                    value={e.formattedAddress}
                                                    key={e.premisesid}
                                                    className="mb-4 global-txt card-text margin-lt"
                                                    control={<Radio
                                                        key={e.premisesid}
                                                        className=""
                                                        id="radio-id"
                                                        name={e.formattedAddress}
                                                        onChange={(
                                                            v: React.ChangeEvent<HTMLInputElement>
                                                        ): void => {
                                                            updateSelection(
                                                                v.target.value,
                                                                e.premisesid,
                                                                e.tenancygroup
                                                            );
                                                        }} />}
                                                    label={e.formattedAddress} />
                                            );
                                        })}
                                </RadioGroup>
                            </div></>
                    )}
                </div >

                <div className="col-md-12 marginTop-auto" id="case-btn">
              <div className="row m-0 footer-actionbtn case-footer">
                <div className="col btn-top padding-repair mb-0">              
                            {/* <BackModal
                                back={backButtonHandler}
                                data-testid="back-modal"
                                btn_msg="return_case_button" />                        */}
                                
                 
                  <ExitModal
                    id="case-exitmodal"
                    exit={exitButtonHandler}
                    btn_msg="return_case_button"
                  />
                        </div>
                        <div className="col text-end padding-repair">
                            <Button
                                onClick={onContinueHandler}
                                className="primary-btn"
                                disabled={buttondisable}
                                data-testid="btn_caseproperty"
                            >
                                {t("continue_button")}
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectTenency;
