import React from "react";

const Button = (props: any) => (
    <button
      disabled={props.disabled}
      className={props.className}
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </button>
);

export default Button;
