import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { RouteComponentProps } from 'react-router-dom'
import { selectedTabValue, showMenuTabs, showFooter, userChosenOtherBalance } from '../../ducks/redux/actions/userChoices';
import Button from "../../storybook/Button";
import mobileChevronleft from "../../assets/img/mobileChevronleft.png";
import Message from "../../storybook/Message";
import HeaderJourney from '../../storybook/Header/HeaderJourney';
import { Radio } from '@mui/material';

interface PaymentDetailsProps extends RouteComponentProps<any> {
}
const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {

    const [radioValue, setradioValue] = useState(true);
    const [errorcode, seterrorcode] = useState(false);
    const [otherRadio, setotherRadio] = useState(false);
    const [paymentValue, setpaymentValue] = useState("");
    const [buttondisabled, setdisabled] = useState(false);
    const [liveBalanceValue, setliveBalance] = useState("");
    const dispatch = useDispatch();

    const accountnumber = (useSelector(
        (state: any) => state.userChoicesReducer?.accountnumber)
    );
    const balance = Number(useSelector(
        (state: any) => state.userChoicesReducer?.balance)
    )
    const accountType = useSelector(
        (state: any) => state.userChoicesReducer?.accountType
    );
    const customerID = useSelector((state: any) => state.addresses?.customerid);
    const { t } = useTranslation()

    useEffect(() => {
        if (customerID === null || customerID === undefined || customerID === "") {
            props.history.push("/startpage");
            return;
        }
        (async () => {
            try {
                setliveBalance("£" + balance.toFixed(2))
                if (balance > 0) {
                    setradioValue(true);
                    setotherRadio(false);
                }
                else {
                    setotherRadio(true);
                    setradioValue(false);
                }
            }
            catch (e) {
                console.error(e);
            }
        })()
    }, [])

    useEffect(() => {
        dispatch(showMenuTabs(false));
        dispatch(showFooter(false));
    }, [])

    const backButtonHandler = () => {
        props.history.push("/viewaccount");
        dispatch(showMenuTabs(true));
        dispatch(showFooter(true));
        dispatch(selectedTabValue("viewaccount"))
    };
    const callPageHandler = () => {
        if (radioValue) {
            const liveBalance = Number(balance);
            if (liveBalance >= 1 && liveBalance <= 5000) {
                seterrorcode(false);
                setdisabled(false);
                dispatch(userChosenOtherBalance(liveBalance));
                props.history.push("/allPayGateway");
            }
            else {
                seterrorcode(true);
                setdisabled(true);

            }
        }
        else {
            let Payment = paymentValue.toString().replace(/£/g, '')
            const liveBalance = Number(Payment);
            if (liveBalance >= 1 && liveBalance <= 5000) {
                seterrorcode(false);
                setdisabled(false);
                dispatch(userChosenOtherBalance(liveBalance));
                props.history.push("/allPayGateway");
            }
            else {
                seterrorcode(true);
                setdisabled(true);
            }
        }

    };
    const updateSelection = (
        value: number
    ) => {
        if (value == 1) {
            seterrorcode(false);
            setdisabled(false);
            setradioValue(true);
            setotherRadio(false);
        }
        else {
            setradioValue(false);
            setotherRadio(true);
        }
    }
    const handlePaymentValue = (event: any): void => {
        if (otherRadio) {
            let PaymentValue = event.target.value.toString().replace(/£/g, '')
            if (Number(PaymentValue) || PaymentValue == "") {
                if (PaymentValue == "") {
                    setpaymentValue("");
                }
                else {
                    setpaymentValue(PaymentValue);
                }

                const Paymentvalue = Number(PaymentValue);
                if (Paymentvalue >= 1 && Paymentvalue <= 5000) {

                    seterrorcode(false);
                    setdisabled(false);
                }
                else {
                    seterrorcode(true);
                    setdisabled(true);
                }
            }
        }
    };


    return (
        <>
            <HeaderJourney></HeaderJourney>
            <div className="container-fluid parent-footer p-0">
                <h1 className="header-size padding-repair payment-heading">{t("Payment_details")}</h1>
                {errorcode ? (
                        <div className='padding-repair payment-error'>
                            <Message id="div_apilength" className="error-msg">
                                {t("SSC008")}
                            </Message>
                        </div>
                    ) :
                        (
                            <div> </div>
                        )}
                <div className='acnt-type padding-repair'>
                    <table>
                        <thead>
                            <tr>
                                <th className='w-50'>{t("Account_type")}</th>
                                <th className='w-50'>{t("Payment_ref")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{accountType}</td>
                                <td>{accountnumber}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div id="form-exceptional-health-issue padding-repair" className='form-payment'>
                    {balance > 0 ?
                        (
                            <div className='padding-repair'>
                                <label>
                                    <Radio onChange={(
                                    ): void => {
                                        updateSelection(1);
                                    }} id="radio-select-number1" checked={radioValue} name="radio-select-number1" value={liveBalanceValue} />
                                    {t("Outstanding_balance")} - ({liveBalanceValue})
                                </label>
                            </div>
                        ) : (
                            <div className='padding-repair'>
                                <label>
                                    <Radio checked={radioValue} disabled={true} id="radio-select-number2" name="radio-select-number2
                                        " value={liveBalanceValue} />
                                    {t("Outstanding_balance")} - ({liveBalanceValue})
                                </label>
                            </div>
                        )}
                    <div className='other-amt'>
                        <label className='padding-repair'>
                            <Radio onChange={(): void => {
                                updateSelection(2);
                            }} checked={otherRadio} data-testid="payment-radio" value={balance} id="radio-select-number3" name="radio-select-number3" />
                            {t("Other_amount")}
                        </label>
                        {otherRadio && (
                            <div className='amt-grey'>
                                <label className='w-100'>
                                    <div className='d-flex'>
                                        <span className='amount-sign'>£</span>
                                        <input type="text" data-testid="payment-test" value={paymentValue} placeholder='Amounts between £1 - £5000' onChange={handlePaymentValue} className='amount-input' />
                                    </div>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-12 marginTop-auto">
                    <div className="row m-0 footer-actionbtn">
                        <div className="col btn-top padding-repair mb-0">
                            <span className="payment-backbtn"><Button
                                data-testid="back-button"
                                onClick={backButtonHandler}
                                className="secondary-ghost-btn"
                            >
                                {t("back_button")}
                            </Button>
                            </span>
                            <span className="payment-backarrow"><img src={mobileChevronleft} onClick={backButtonHandler} onKeyDown={backButtonHandler} alt="Back" /></span>
                        </div>
                        <div className="col text-end padding-repair">
                            <Button
                                onClick={callPageHandler}
                                className="primary-btn"
                                disabled={buttondisabled}
                                id="btn_paymentdetails"
                            >
                                {t("continuen_button")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentDetails;


