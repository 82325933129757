import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as api from "../../../api";
import MyMessageCardRow from './MyMessageCardRow';
import Button from "../../../storybook/Button";
import { selectedTabValue, userNavigatedFrom } from '../../../ducks/redux/actions/userChoices';
import { useDispatch } from "react-redux";

interface MyMessagesCardProps extends RouteComponentProps { associatedAssetList: any }

const MyMessagesCard: React.FC<MyMessagesCardProps> = (props) => {

    const { t } = useTranslation();

    const customerID = useSelector((state: any) => state.addresses?.customerid);
    const PropertyId = useSelector((state: any) => state.userChoicesReducer?.userChosenPropertyId);
    const allassetIndentifiers = useSelector((state: any) => state?.userChoicesReducer?.allassetIdentifiers)

    const [allMyMessages, setAllMyMessages] = React.useState<any>();
    const [yellowAlertMessage, setYellowAlertMessage] = React.useState('');

    const formatedToday = new Date().toISOString().split("T")[0];
    const twoYearsAgoFromNow = new Date();
    twoYearsAgoFromNow.setFullYear(twoYearsAgoFromNow.getFullYear() - 2);
    const formatedTwoYearsAgo = twoYearsAgoFromNow.toISOString().split("T")[0];

    const sessionId = localStorage.getItem("sessionId");
    const corelationId = localStorage.getItem("corelationId");
    const customerId = localStorage.getItem("customerId");
    const dispatch = useDispatch();
    useEffect(() => {
        if (sessionId !== null && corelationId !== null && customerId !== null) {

            (async () => {
                const requestData = {
                    fromDate: formatedTwoYearsAgo,
                    toDate: formatedToday,
                    status: '',
                    assetIdentifier: allassetIndentifiers,
                    searchText: '',
                    limit: 2,
                    skip: 0,
                    sort: ''
                }

                await api.getCommunicationMessages(customerID, requestData)
                    .then((response) => {
                        if (response?.["total-count"] === undefined || response?.["total-count"] === 0) {
                            setYellowAlertMessage('SSC016');
                        } else {
                            setYellowAlertMessage('');
                        }
                        setAllMyMessages(response);
                    })
                    .catch((error) => {
                        setYellowAlertMessage('SSC021');
                    })
            })()
        }
    }, [sessionId, corelationId, customerId, customerID, props.associatedAssetList, PropertyId, allassetIndentifiers]);

    const callMyMessageHistory = () => {
        dispatch(selectedTabValue("mymessageshistory"));
        dispatch(userNavigatedFrom(""));
        props.history.push("/mymessageshistory");
    };

    return (
        <div className="body-card row m-0 border-green display-column">
            <h4 data-testid="My_Messages">
                {" "}
                {t("My_Messages")}{" "}
            </h4>
            <div className="col-md-12 p-0">
                <h5 className="pt-24 pb-20">{t("My_Messages_Sub")}</h5>

                {allMyMessages?.communications?.length > 0 &&
                    <div className='p-0'>
                        <div className="flex justify-start">
                            <div className="pe-2">All ({allMyMessages?.["total-count"]})</div>
                            <div>Unread ({allMyMessages?.["total-unread"]})</div>
                        </div>
                    </div>
                }

                {yellowAlertMessage ?
                    <div>

                        <div>
                        </div>
                        <p data-testid="yellow_Notification">{t(yellowAlertMessage)}</p>

                    </div>
                    :
                    allMyMessages?.communications?.slice(0, 2).map((eachMsg: any, key: number) => {
                        return (
                            <MyMessageCardRow
                                key={key}
                                eachMsgData={eachMsg}
                                msgArrayNum={key}
                                {...props}
                            />
                        );
                    })
                }
            </div>

            <div className="col-md-12 marginTop-auto">
                <div className="row pe-0 repair-btn">
                    <div className="col ps-0 pt-30">

                        {allMyMessages?.communications?.length > 0 &&
                            <div>
                                <Button onClick={callMyMessageHistory} className="secondary-ghost-btn">View all messages</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyMessagesCard;