import React, { useEffect, useState, useRef } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDateToSlash } from "../../../utils/formatting";
import * as api from "../../../api";
import {
  fetchChosenStatement,
  setUserSelectedMessageDetails,
  setUserSelectedMessageSubject,
  setUserSelectedMessageDate,
  setUserSelectedMessageSender,
  setUserSelectedMessageNotificationType,
  setUserSelectedMessageDocumentIdentifier,
} from "../../../ducks/redux/actions/userChoices";

interface MyMessageProps extends RouteComponentProps {
  eachMsgData: any;
  callFrom360: boolean;
  fetchMyMessageData: (requestData: any) => void;
  assetID: string;
  reduceUnreadMessagesCount: () => void;
}

const MyMessage: React.FC<MyMessageProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let moment = require("moment");
  const location = useLocation<any>();

  const [isActive, setIsActive] = useState(false);
  const [messageRead, setMessageRead] = useState(false);
  const [viewedMessages, setViewedMessages] = useState<string[]>([]);
  const [yellowAlertMessage, setYellowAlertMessage] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCasesMsgNotification, setisCasesMsgNotification] = useState(true);

  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const documentID = props.eachMsgData.message.content[2]?.value;

  const { title, givenName, surname } =
    props.eachMsgData.message.sender.organisation?.personName || ({} as any);
  const titleVal = title ? title : "";
  const givenNameVal = givenName ? givenName : "";
  const surnameVal = surname ? surname : "";
  const messageSender = titleVal + " " + givenNameVal + " " + surnameVal;

  const {
    title: recieverTitle,
    givenName: recieverGivenName,
    surname: recieverSurname,
  } = props.eachMsgData.message.recievers[0].person?.personName || ({} as any);
  const recieverTitleVal = recieverTitle ? recieverTitle : "";
  const recieverGivenNameVal = recieverGivenName ? recieverGivenName : "";
  const recieverSurnameVal = recieverSurname ? recieverSurname : "";
  let reciever = `${recieverTitleVal} ${recieverGivenNameVal} ${recieverSurnameVal}`;

  // fetching cutomer Name from store in-case cutomer/asset API fails in case creation listner
  const userProfileData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfileData
  );
  const customerName =
    userProfileData?.customerResponse?.person?.name?.title +
    " " +
    userProfileData?.customerResponse?.person?.name?.givenName +
    " " +
    userProfileData?.customerResponse?.person?.name?.surname;

  const splitedMsgContent =
    props.eachMsgData.message.content[1].value.split("\n");

  const MonthYear = moment(props.eachMsgData.createdDate).format("MMMM YYYY");

  const twoYearsAgoFromNow = new Date();
  twoYearsAgoFromNow.setFullYear(twoYearsAgoFromNow.getFullYear() - 2);

  const viewedMessagesArray: string[] = [];

  enum CommunicationCategoryType {
    CaseCreation = "CaseCreation",
  }

  useEffect(() => {
    checkCasesMsgNotification(props.eachMsgData.communicationCategoryType);
    if (
      props.callFrom360 &&
      location?.state?.message.identifier == props.eachMsgData.identifier
    ) {
      updateUNREADMessage();
      setIsActive(true);
      scrollToCard();
    } else {
      setIsActive(false);
    }
  }, [props.eachMsgData]);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToCard = () => {
    const { innerWidth, innerHeight } = window;
    if (innerWidth >= 314 && innerWidth <= 912) {
      messagesEndRef?.current?.offsetTop &&
        document?.getElementById("scrollableDiv")?.scrollTo({
          top: messagesEndRef?.current?.offsetTop - 125,
          behavior: "smooth",
        });
    } else {
      messagesEndRef?.current?.offsetTop &&
        window.scrollTo({
          top: messagesEndRef?.current?.offsetTop - 21,
          behavior: "smooth",
        });
    }
  };

  const updateUNREADMessage = async () => {
    if (
      props.eachMsgData?.status === "UNREAD" &&
      viewedMessages.includes(props.eachMsgData.identifier) === false
    ) {
      const status = {
        status: "READ",
      };
      const communicationID = props.eachMsgData.identifier;
      await api
        .patchCommunicationMessages(customerID, communicationID, status)
        .then((response) => {
          setMessageRead(true);
          viewedMessagesArray.push(props.eachMsgData.identifier);
          setViewedMessages(viewedMessagesArray);
          props.reduceUnreadMessagesCount();
        })
        .catch((error) => {});
    }
  };
  const showDetailsHandler = () => {
    updateUNREADMessage();
    setIsActive(!isActive);
  };

  const fetchStatement = () => {
    const data = {
      setYellowAlertMessage,
      setIsLoading,
      MonthYear,
      customerID,
      documentID,
    };
    dispatch(fetchChosenStatement(data));
  };

  const checkCasesMsgNotification = (communicationCategoryType: any) => {
    if (communicationCategoryType === CommunicationCategoryType.CaseCreation) {
      setisCasesMsgNotification(false);
    }
  };

  const messageDetailsClickHandler = (
    messagedetails: string,
    messageSubject: string,
    messageDate: string,
    messageSender: string,
    messageNotificationType: boolean,
    messageDocumentIdentifier: string
  ) => {
    updateUNREADMessage();
    dispatch(setUserSelectedMessageDetails(messagedetails));
    dispatch(setUserSelectedMessageSubject(messageSubject));
    dispatch(setUserSelectedMessageDate(messageDate));
    dispatch(setUserSelectedMessageSender(messageSender));
    dispatch(setUserSelectedMessageNotificationType(messageNotificationType));
    dispatch(
      setUserSelectedMessageDocumentIdentifier(messageDocumentIdentifier)
    );
    props.history.push("/messagedetails");
  };

  return (
    <>
      <tr
        onClick={() =>
          messageDetailsClickHandler(
            props.eachMsgData.message.content[1].value,
            props.eachMsgData.message.content[0].value,
            props.eachMsgData.createdDate,
            messageSender,
            isCasesMsgNotification,
            props.eachMsgData.message.content[2]?.value
          )
        }
        data-testid="message-details-row"
      >
        <td>
          <div
            className={
              messageRead || props.eachMsgData?.status === "READ"
                ? `mob-message-history message-read`
                : `mob-message-history message-unread`
            }
          >
            {formatDateToSlash(props.eachMsgData.createdDate)}
          </div>
        </td>
        <td>
          <div
            className={
              messageRead || props.eachMsgData?.status === "READ"
                ? `mob-message-history message-read`
                : `mob-message-history message-unread`
            }
          >{`${titleVal} ${givenNameVal} ${surnameVal}`}</div>
        </td>
        <td>
          <div
            className={
              messageRead || props.eachMsgData?.status === "READ"
                ? `message-subject-overflow  mob-message-history message-read`
                : `message-subject-overflow  mob-message-history message-unread`
            }
          >
            {props.eachMsgData.message.content[0].value}
          </div>
        </td>
        <td>
          <div
            className={
              messageRead || props.eachMsgData?.status === "READ"
                ? `message-details-overflow mob-message-history message-read`
                : `message-details-overflow  mob-message-history message-unread`
            }
          >
            {props.eachMsgData.message.content[1].value}
          </div>
        </td>
      </tr>

      <div className="mob-view">
        <div
          className={
            messageRead || props.eachMsgData?.status === "READ"
              ? `message-read message-details-view pt-30 mob-message-row`
              : `message-unread message-details-view pt-30 mob-message-row`
          }
          onClick={() =>
            messageDetailsClickHandler(
              props.eachMsgData.message.content[1].value,
              props.eachMsgData.message.content[0].value,
              props.eachMsgData.createdDate,
              messageSender,
              isCasesMsgNotification,
              props.eachMsgData.message.content[2]?.value
            )
          }
          onKeyDown={() =>
            messageDetailsClickHandler(
              props.eachMsgData.message.content[1].value,
              props.eachMsgData.message.content[0].value,
              props.eachMsgData.createdDate,
              messageSender,
              isCasesMsgNotification,
              props.eachMsgData.message.content[2]?.value
            )
          }
        >
          <div>
            {formatDateToSlash(props.eachMsgData.createdDate)}
          </div>
          <div
          >{`${titleVal} ${givenNameVal} ${surnameVal}`}</div>
          <div>
            {props.eachMsgData.message.content[0].value}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyMessage;
