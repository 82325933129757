import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AutoLogout from "../AutoLogout";
import classes from "./index.module.css";
import { isUndefinedNullOrEmpty } from '../../utils/translation/helper';
import { getCustomerId } from "../../ducks/redux/actions/getAddress";
import {
	userChosenPropertyId,
	userChosenPropertyType,
	userChosenPropertyAddress,
	userChosenRepairLocationId,
	userChosenRepairLocation,
	userChosenHighLevelDescription,
	userChosenSupplementaryQuestions,
	userChosenDuplicateQuestion,
	userChosenAdditionalDescription,
	userChosenVandalismQuestion,
	userChosenVandalismAdditionalInformation,
	userChosenCovidQuestion,
	userChosenVulnerabilityQuestion,
	userChosenVulnerabilityAdditionalInformation,
	userChosenContactDetails,
	userChosenUPRN,
	userChosenSOR,
	userChosenSTATUS,
	userChosenEligibilityErrorCode,
	userChosenContactPhone,
	userChosenContactName,
	userChosenRelationDetails
} from "../../ducks/redux/actions/userChoices";
import { trackEvent } from "../../appInsights/TelemetryService";

type PropertyDetailsFields = {
};


const Dashboard: React.FC<PropertyDetailsFields> = (props) => {
	const customerID = useSelector((state: any) => state.addresses?.customerid);
	const history = useHistory();
	const [isVisiableSignIn, setIsVisiableSignIn] = useState("block");
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();

	const grant_type = process.env.REACT_APP_GRANT_TYPE || ""
	const loggedinStatus = useSelector(
		(state: any) =>
			state?.userChoicesReducer?.loginStatus
	);
	const loggedInTime = useSelector(
		(state: any) =>
			state?.userChoicesReducer?.loginTime
	);

	const initialLoggedInTime = useSelector(
		(state: any) =>
			state?.userChoicesReducer?.initialLoginTime
	);

	const refershedToken = useSelector(
		(state: any) =>
			state?.userChoicesReducer?.refershToken
	);

	const accessTokenExpireTime = useSelector(
		(state: any) =>
			state?.userChoicesReducer?.accessTokenExpirationTime
	);

	const refreshTokenExpireTime = useSelector(
		(state: any) =>
			state?.userChoicesReducer?.refreshTokenExpirationTime
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	useEffect(() => {
		(async function () {
			try {
				const _channel = process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
				const _customerId = localStorage.getItem('customerId') || "";
				const _corelationId = localStorage.getItem('corelationId') || "";
				trackEvent(window.location.pathname, _channel, _customerId, _corelationId);
			} catch (e) {
				console.error(e);
			}
		})();
	}, [location]);

	useEffect(() => {
		if (isUndefinedNullOrEmpty(loggedinStatus)) {
			setIsVisiableSignIn("none");
		} else {
			setIsVisiableSignIn("block");
		}
	}, [loggedinStatus]);

	return (
		<div id="dashboard-main-div" data-testid="dashboard-layout">
			<AutoLogout></AutoLogout>
			<div>
				<div className={history.location && history.location.pathname === '/' || history.location && history.location.pathname === '/startpage' ? ` ` : `${classes.contentDiv}`} id="dashboard-div1">
					{props.children}
				</div>
			</div>
		</div>

	);
};

export default Dashboard;
