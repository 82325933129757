
import { PremisesType } from '../Customer'
import { CurrentRepair } from '../Repair/Repair'
import { RaisedBy } from '../shared/RaisedBy'
import { WorksOrder, WorksOrdersResponse } from '../WorksOrders'

export default class Repair {
  public id: string = ''
  public area: PremisesType = PremisesType.Property
  public jobNo: string | null = ''
  public contractor: string | null = ''
  public trade: string | null = ''
  public priority: string | null = ''
  public status: string | null = ''
  public sorCode: string | null = ''
  public raisedDate: string = ''
  public appointmentDate: string | null = ''
  public completedDate: string | null = ''
  public description: string | null = ''
  public raisedBy?: RaisedBy
  public isIntegrated?: boolean = false
  public repairLocation: string | null = ''
  public siteIndentifire: string | null = ''
  public channel: string | null = ''
  
}

export class RepairsResponse {
  public models: Repair[] = []
  public totalCount: number = 0
  public workOrder : CurrentRepair | undefined;

  constructor(worksOrders: WorksOrdersResponse) {
    this.models = this.convertWorksOrdersToRepairs(worksOrders)
    this.totalCount = this.totalRepairs(worksOrders)
  }

  public convertWorksOrdersToRepairs = (worksOrders: WorksOrdersResponse): Repair[] => {
    const worksOrdersArray: WorksOrder[] = !!worksOrders ? worksOrders.worksOrders : []

    const repairs = worksOrdersArray.map((worksOrder: WorksOrder) => {
      const appointments = worksOrder.appointments
      return {
        id: worksOrder.identifiers?.worksOrderIdentifier,
        area: worksOrder.asset?.type?.assetTypeName,
        jobNo: worksOrder.identifiers?.jobNumber,
        contractor: worksOrder.contractor?.name,
        trade: worksOrder.job?.trade,
        priority: this.retrievePriority(
          worksOrder.statuses?.isEmergency,
          worksOrder.statuses?.isOutOfHours,
          worksOrder.statuses?.isRecall
        ),
        status: worksOrder.statuses?.status,
        sorCode: worksOrder.job?.sorCode,
        raisedDate: worksOrder.raisedDate,
        appointmentDate: !!appointments ? appointments[0]?.appointmentStartDate : null,
        completedDate: worksOrder.completedDate,
        description: worksOrder.job?.description,
        raisedBy: worksOrder.raisedBy,
        repairLocation:worksOrder.job?.location?.location,
        siteIndentifire : worksOrder.identifiers?.contractorSiteIdentifier,
        channel : worksOrder.channel
      } as Repair
    })

    return repairs
  }

  public retrievePriority = (
    isEmergency: boolean | undefined,
    isOutOfHours: boolean | undefined,
    isRecall: boolean | null | undefined
  ) => {
    if (isRecall ) {
      return 'Next Available (Recall)'
    }
    if (!isEmergency) {
      return 'Next Available'
    }
    if (isEmergency && !isOutOfHours) {
      return 'Emergency'
    }
    if (isEmergency && isOutOfHours) {
      return 'Emergency (Out of hours)'
    }
    return ''
  }

  public totalRepairs = (worksOrders: WorksOrdersResponse): number => {
    return worksOrders.totalCount
  }
}

export class RepairStatus {
  public name: string = ''
  public subCategoryNames: string[] | null = null

  constructor(name: string) {
    this.name = name
    this.subCategoryNames = null
  }
}

export interface ConflictedRepairTypes {
  id: string
  area: string
  jobNo: string
  contractor: string
  trade: string
  priority: string
  status: string
  sorCode: string
  raisedDate: string
  appointmentDate: string
  completedDate: string
  description: string | null
  isIntegrated?: boolean
  raisedBy?: RaisedBy
  isRecall?: boolean 
}
