import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_CREATION_QUESTIONS,
} from "../../../ducks/redux/actionTypes";
import { RouteComponentProps } from "react-router-dom";
import BackModal from "../../Modal/BackModal";
import {
  HOUSEHOLD_OR_SUBLETTING_TITLE,
  LEASEHOLD_OWNERSHIP,
  RENT_CARD,
} from "./Question";
import { Container } from "@material-ui/core";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import Message from "../../../storybook/Message";
 
const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../storybook/Button"));
const ExitModal = lazy(() => import("../../Modal/ExitModal"));
 
interface DropdownProps extends RouteComponentProps<any> {}
 
const DropdownQuestion: React.FC<DropdownProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let errorCode = "";
  const [optionKeyValue, setOptionKeyValue] = useState("");
  const [showError, setShowError] = useState("");
  let householdMemberOrSubletting: any;
  let rentCard: any;
  let leasehold: any;
  let tenancyType: string;
 
  enum DependsOnAttributes {
    TenancyType = "TenancyType"
  }
 
  const [isLoading, setIsLoading] = useState(false);
  const [blockingIsVisible, setBlockingIsVisible] = useState(false);
  const [blockingAlert, setBlockingAlert] = useState("");
  const blockingArray: any[] = [];
  const [disabled, setdisabled] = useState(false);
  const [sublettingSelection, setSublettingSelection] = useState(0);
  const [rent, setRent] = useState(false);
  const [leaseholdOwnership, setLeaseholdOwnership] = useState("");
 
  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );
 
  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );
 
  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );
 
  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );
 
  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );
 
  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationAdditionalInfo
  );
 
  let count = useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );
 
 
  const selectedTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );
 
  const userTenancy =
    useSelector((state: any) => state?.addresses?.customertenancies) || [];
 
  userTenancy.map((data: any, index: number) => {
    if (data?.tenancySummary?.premisesIds[0] === selectedTenancy) {
      tenancyType = data?.tenancySummary.tenancyType;
    }
  });
 
 
  if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_TITLE
  ) {
    householdMemberOrSubletting = "1";
  }
 
  if (questionInfoData && questionInfoData[count]?.label === RENT_CARD) {
    rentCard = "1";
  }
 
  if (
    questionInfoData &&
    questionInfoData[count]?.label === LEASEHOLD_OWNERSHIP
  ) {
    leasehold = "1";
  }
 
  const onContinueHandler = () => {
    // validating count according to use selection
    if (sublettingSelection === 2) {
      count = count + 4;
    } else if (
      sublettingSelection === 3 ||
      rent === true ||
      leaseholdOwnership === "2"
    ) {
      count = count + 2;
    } else if (sublettingSelection === 4 || leaseholdOwnership === "3") {
      count = count + 3;
    } else {
      count = count + 1;
    }
    if (!blockingAlert) {
      if (optionKeyValue === "") {
        setShowError(t("Case_Question_Error"));
      } else {
        dispatch({ type: CASE_COUNTER, payload: count });
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });
 
        setOptionKeyValue("");
        history.push("/caseQuestionnaire");
      }
    } else {
      setBlockingIsVisible(true);
    }
  };
 
  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    history.push("/customer360View");
  };
 
  if (questionInfoData && questionInfoData[count]?.blocking !== null) {
    blockingArray.push(questionInfoData[count]?.blocking);
    errorCode = questionInfoData[count]?.blocking?.errorCode;
  }
 
  const assignedSelectedAnswersValue = useCallback(() => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        questionInfoData[index]?.options?.map((listOpt: any, id: number) => {
          if (listOpt.id === fetchAnswer[index]) {
            setOptionKeyValue(listOpt.id);
            setdisabled(true);
          }
        });
      }
    });
  }, [count]);
 
  const assignRentCardComesFromEdit = () => {
    if (rentCard === "1" && ansArray[count] === "1") {
      setRent(true);
    }
  };
 
  const assignedCounterComesFromEdit = () => {
    if (householdMemberOrSubletting === "1" && ansArray[count] === "1") {
      setSublettingSelection(1);
    } else if (householdMemberOrSubletting === "1" && ansArray[count] === "2") {
      setSublettingSelection(2);
    } else if (householdMemberOrSubletting === "1" && ansArray[count] === "3") {
      setSublettingSelection(3);
    } else if (householdMemberOrSubletting === "1" && ansArray[count] === "4") {
      setSublettingSelection(4);
    }
  };
 
  const assignLeaseholdOwnership = () => {
    if (
      leasehold === "1" &&
      (ansArray[count] === "1" || ansArray[count] === "2")
    ) {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && ansArray[count] === "3") {
      setLeaseholdOwnership("2");
    } else if (
      leasehold === "1" &&
      (ansArray[count] === "4" || ansArray[count] === "5")
    ) {
      setLeaseholdOwnership("3");
    }
  };
 
  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
    assignedCounterComesFromEdit();
    assignRentCardComesFromEdit();
    assignLeaseholdOwnership();
 
  }, [count]);
 
  const dropDownHandler = (e: any) => {
    setdisabled(true);
    setBlockingIsVisible(false);
    if (e.target.value == "") {
      setShowError(t("Case_Question_Error"));
    } else {
      if (e.target.value !== ansArray[count]) {
        //Removing further answers ans questions
        fetchQuestion.splice(count, fetchQuestion.length);
        fetchAnswer.splice(count, fetchAnswer.length);
        dispatch({ type: CASE_EDIT, payload: true });
      }
      setAnsArray([...ansArray, e.target.value]);
      setOptionKeyValue(e.target.value);
      setShowError(t(""));
 
      // ==== If user is changing the answer for the same question, need to get index and replace the value ===
      let newArr = [...ansArray];
      newArr[count] = e.target.value;
      setAnsArray(newArr);
    }
 
    // Validating household and subletting case
    if (householdMemberOrSubletting === "1" && e.target.value === "1") {
      setSublettingSelection(1);
    } else if (householdMemberOrSubletting === "1" && e.target.value === "2") {
      setSublettingSelection(2);
    } else if (householdMemberOrSubletting === "1" && e.target.value === "3") {
      setSublettingSelection(3);
    } else if (householdMemberOrSubletting === "1" && e.target.value === "4") {
      setSublettingSelection(4);
    }
 
    if (rentCard === "1" && e.target.value === "1") {
      setRent(true);
    } else {
      setRent(false);
    }
 
    if (
      leasehold === "1" &&
      (e.target.value === "1" || e.target.value === "2")
    ) {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && e.target.value === "3") {
      setLeaseholdOwnership("2");
    } else if (
      leasehold === "1" &&
      (e.target.value === "4" || e.target.value === "5")
    ) {
      setLeaseholdOwnership("3");
    }
 
    if (blockingArray.length > 0) {
      if (blockingArray[0]?.dependsOn[0]?.responseId === e.target.value) {
        setBlockingAlert(t(errorCode));
        setBlockingIsVisible(false);
      } else {
        setBlockingAlert("");
      }
    }
  };
 
  const backButtonHandler = () => {
    if (count >= 1) {
      dispatch({ type: CASE_COUNTER, payload: count - 1 });
      history.push("/caseQuestionnaire");
    } else {
      history.push("/caseType");
    }
  };
 
  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer p-0" id="reploc-container" data-testid="case-div1">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        ) : (
          <>
          <div className="padding-repair" id="repreason-div1">
              <h1
                data-testid="title"
                className="header-size"
              >
                {descData}
              </h1>
              {/* ==== Showing error ==== */}
            {showError && (
              <div className="question-error">
              <Message className="error-msg" data-testid="locatioAlert_0">
                 {t(showError)}
            </Message>
            </div>
            )}
            {/* === End error ==== */}
            </div>
            <div className="padding-repair pt-30" key={questionInfoData && questionInfoData[count]?.questionId}>
              <h6 data-testid="case-q1" className="pb-12">
                {t(questionInfoData && questionInfoData[count]?.label)}
              </h6>
              
                <select
                  data-testid="case-key-selection"
                  value={optionKeyValue}
                  key={questionInfoData && questionInfoData[count]?.questionId}
                  onChange={dropDownHandler}
                  className="input-text-select height-44"
                >
                  <option
                    value="Select"
                    disabled={disabled}
                  >
                    Select
                  </option>
                  {questionInfoData &&
                    questionInfoData[count]?.options?.map((option: any) => {
                      if (option?.dependsOnAttributes?.length > 0) {
                        return option?.dependsOnAttributes?.map((dependsOnAttr: any) => {
                          if (dependsOnAttr.key === DependsOnAttributes.TenancyType) {
                            if (dependsOnAttr.value === tenancyType) {
                              return (
                                <option
                                  key={option.id}
                                  id={option.id}
                                  value={option.id}
                                >
                                  {option.value}
                                </option>
                              );
                            }
                          }
                        });
                      } else {
                        return (
                          <option
                            key={option.id}
                            id={option.id}
                            value={option.id}
                          >
                            {option.value}
                          </option>
                        );
                      }
                    })}
                </select>
            </div>
 
            {/* ==== Display purple alert warning === */}
 
            {/* ==== Blocking alert ===== */}
 
            {blockingAlert && (
              <div
                data-testid="alert-warning"
                className={
                  blockingIsVisible
                    ? "unblockingMessage padding-repair pt-16"
                    : "blockingMessage padding-repair pt-16"
                }
                role="alert"
                placeholder="alertNo"
              >
                      {blockingAlert}
              </div>
            )}
            {/* ==== End blocking alert ==== */}
 
            <div
            className="col-md-12 marginTop-auto" 
              id="repreason-main-btn"
            >
              <div className="row m-0 footer-actionbtn case-footer">
              <div className="col btn-top padding-repair mb-0">
              <BackModal
              id="reploc-backmodal"
              back={backButtonHandler}
              btn_msg="return_case_button"
            />
              <ExitModal
                data-testid="case-exitmodal"
                exit={exitButtonHandler}
                btn_msg="return_case_button"
              />
              </div>
              <div className="col text-end padding-repair">
              <Button
                onClick={onContinueHandler}
                data-testid="btn_caseQ"
                disabled={blockingIsVisible}
                className="primary-btn"
              >
                {t("continue_button")}
              </Button>
              </div>
            </div>
            </div>
          </>
        )}
      </Suspense>
    </div>
    </>
  );
};
 
export default DropdownQuestion;
