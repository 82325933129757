import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import mobileChevronleft from "../../assets/img/mobileChevronleft.png";
import Button from "../../storybook/Button";
import Message from "../../storybook/Message";
import HeaderJourney from '../../storybook/Header/HeaderJourney';
import { selectedTabValue, showMenuTabs, showFooter } from '../../ducks/redux/actions/userChoices';
interface AllPayGatewayConformationProps extends RouteComponentProps<any> {
}

const AllPayGatewayConformation: React.FC<AllPayGatewayConformationProps> = (props) => {
    const customerID = useSelector((state: any) => state.addresses?.customerid);
    const allPayMessage = useSelector(
        (state: any) => state.userChoicesReducer?.conformationMessage)
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const backButtonHandler = () => {
        props.history.push("/viewaccount");
        dispatch(showMenuTabs(true));
        dispatch(showFooter(true));
        dispatch(selectedTabValue("viewaccount"))
    };
    useEffect(() => {
        dispatch(showMenuTabs(false));
        if (customerID === null || customerID === undefined || customerID === "") {
            props.history.push("/startpage");
            return;
        }
    }, []);
    return (
        <>
            <HeaderJourney></HeaderJourney>
            <div className="container-fluid parent-footer p-0">
                <div className='padding-repair payment-error pt-16'>
                    {allPayMessage === t("Success_Message") ? (
                        <Message className="success-msg">
                            {allPayMessage} 
                        </Message>
                    ) : (
                        <Message className="error-msg">                           
                            {allPayMessage} 
                        </Message>
                    )
                    }
                </div>
                <div className="col-md-12 marginTop-auto payment-bordernone">
                    <div className="row m-0 footer-actionbtn action-btn">
                        <div className="col btn-top padding-repair mb-12">
                        <span className="payment-backbtn">
                            <Button
                                data-testid="back-button"
                                onClick={backButtonHandler}
                                className="secondary-ghost-btn"
                            >
                                {t("Back")}
                            </Button>
                            </span>
                            <span className="payment-backarrow"><img src={mobileChevronleft} onClick={backButtonHandler} onKeyDown={backButtonHandler} alt="Back" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllPayGatewayConformation;


