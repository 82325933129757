import React, { Suspense } from "react";
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
    RouteComponentProps,
} from "react-router-dom";
import "./utils/translation";
import Header from "./storybook/Header";
import Footer from "./storybook/Footer";
import "./App.scss";
import "./assets/css/custom.scss";
import Loader from "react-loader-spinner";

import Dashboard from "./compositions/DashBoard/index";
import StartPage from "./compositions/RepairProperty/StartPage";

import RepairProperty from "./compositions/RepairProperty";
import RepairAsset from "./compositions/ReapirAsset";
import RepairLocation from "./compositions/RepairLocation";
import SupplementaryQuestion from "./compositions/SupplementaryQuestion";
import VandalismQuestion from "./compositions/VandalismQuestion";
import RepairAdditionalDetails from "./compositions/RepairAdditionalDetails";
import VulnerabilityQuestion from "./compositions/VulnerabilityQuestion";
import CovidQuestion from "./compositions/CovidQuestion";
import CustomerDetails from "./compositions/CustomerDetails";
import ConfirmationDetails from "./compositions/ConfirmationPage";
import DuplicateRepair from "./compositions/DuplicateRepair";
import RepairEmergency from "./compositions/RepairEmergency";
import HistoricRepairs from "./compositions/HistoricRepairs";
import RepairHistoryDetails from "./compositions/RepairHistoryDetails";
import AppointmentScheduler from "./compositions/Calender";
import GenericError from "./compositions/GenericError";
import Customer360View from "./compositions/Customer360View";
import { setUpAppInsights } from "./appInsights/TelemetryService";

import TransactionDetails from "./compositions/TransactionDetails"
import ViewStatement from "./compositions/ViewStatement"
import ViewAccount from "./compositions/ViewAccount";
import ManageProfile from "./compositions/ManageProfile";
import EditProfile from "./compositions/EditProfile";
import PaymentDetails from "./compositions/PaymentDetails";
import AllPayGateway from "./compositions/AllPayGateway";
import AllPayGatewayConformation from "./compositions/AllPayGatewayConformation";
import SelectTenancy from "./compositions/Cases/SelectTenancy";
import CaseType from "./compositions/Cases/CaseType";
import CaseTypeQuestion from "./compositions/Cases/QuestionType";
import MyMessagesHistory from "./compositions/MyMessages/MyMessagesHistory";
import MessageDetails  from "./compositions/MyMessages/MessageDetails";
import ContactDetails from "./compositions/Cases/ContactDetails";
import CaseReview from "./compositions/Cases/CaseReview";
import CaseSearch from "./compositions/Cases/CaseSearch";
import RadioTypeQuestion from "./compositions/Cases/QuestionType/RadioTypeQuestion";
import TextTypeQuestion from "./compositions/Cases/QuestionType/TextTypeQuestion";
import DropdownTypeQuestion from "./compositions/Cases/QuestionType/DropdownTypeQuestion";
import { useSelector } from "react-redux";
import UploadMedia from "./compositions/UploadMedia";
import ViewMedia from "./compositions/ViewMedia";
import { FeatureFlagsProvider } from "./compositions/contexts/FeatureFlags";
import CaseDetails from "./compositions/Cases/CaseDetails";
import EnquiryConsent from "./compositions/FinancialEnquiry/EnquiryConsent";
import EnquiryReason from "./compositions/FinancialEnquiry/EnquiryReason";
import AdditionalEnquiryDetails from "./compositions/FinancialEnquiry/AdditionalEnquiryDetails";
import EnquiryReview from "./compositions/FinancialEnquiry/EnquiryReview"
import EnquiryConfirmation from "./compositions/FinancialEnquiry/EnquiryConfirmation";

setUpAppInsights();

const App: React.FC<{}> = (props) => {

    const loggedinStatus = useSelector(
        (state: any) => state?.userChoicesReducer?.loginStatus
    );

    const isCatalyst = useSelector(
        (state: any) => state.userChoicesReducer?.isCatalystCustomer
    );
    const isCatalystDisabledArea = useSelector(
        (state: any) => state.userChoicesReducer?.isCatalystDisabledArea
    );
    const workOrderToggle = useSelector(
        (state: any) => state.userChoicesReducer?.isWorkOrderToggle
    );

    const accessRepairFunctionality = !isCatalyst || !isCatalystDisabledArea ||  workOrderToggle === "true" 



    let financialRoutes = (
        <Switch>
          <Route path='/enquiryconsent' render={(props: RouteComponentProps<any>) => <EnquiryConsent {...props} />} />
            <Route path='/enquiryreason' render={(props: RouteComponentProps<any>) => <EnquiryReason {...props} />} />
            <Route path='/additionalenquirydetails' render={(props: RouteComponentProps<any>) => <AdditionalEnquiryDetails {...props} />} />
            <Route path= '/enquiryreview' render={(props: RouteComponentProps<any>) => <EnquiryReview {...props} /> } />
            <Route path= '/enquiryconfirmation' render={(props: RouteComponentProps<any>) => <EnquiryConfirmation {...props} /> } />  

             {accessRepairFunctionality && (
                <Route
                    path="/repairproperty"
                    render={(props: RouteComponentProps<any>) => (
                        <RepairProperty {...props} />
                    )}
                />
            )}
            <Route
                path="/repairasset"
                render={(props: RouteComponentProps<any>) => <RepairAsset {...props} />}
            />
             <Route
                path="/supplementaryquestion"
                render={(props: RouteComponentProps<any>) => (
                    <SupplementaryQuestion {...props} />
                )}
            />
             <Route
                path="/vandalismquestion"
                render={(props: RouteComponentProps<any>) => (
                    <VandalismQuestion {...props} />
                )}
            />
              <Route
                path="/covidquestion"
                render={(props: RouteComponentProps<any>) => (
                    <CovidQuestion {...props} />
                )}
            />
             <Route
                path="/customerdetails"
                render={(props: RouteComponentProps<any>) => (
                    <CustomerDetails {...props} />
                )}
            />  
                    
            <Route
                path="/contactdetails"
                render={(props: RouteComponentProps<any>) => (
                    <ContactDetails {...props} />
                )}
            />
            <Route
                path="/repairemergency"
                render={(props: RouteComponentProps<any>) => (
                    <RepairEmergency {...props} />
                )}
            />
             {/* <Route
                path="/repairfinalconfirmation"
                render={(props: RouteComponentProps<any>) => (
                    <RepairFinalConfirmation {...props} />
                )}
            /> */}
             <Route
                path="/startpage"
                render={(props: RouteComponentProps<any>) => <StartPage {...props} />}
            />
            <Route
                path="/appointmentscheduler"
                render={(props: RouteComponentProps<any>) => (
                    <AppointmentScheduler {...props} />
                )}
            />
             <Route
                path="/genericerror"
                render={(props: RouteComponentProps<any>) => (
                    <GenericError {...props} />
                )}
            />
                  
            <Route
                path="/repairasset"
                render={(props: RouteComponentProps<any>) => <RepairAsset {...props} />}
            />
                      
            
            <Route
                path="/contactdetails"
                render={(props: RouteComponentProps<any>) => (
                    <ContactDetails {...props} />
                )}
            />
            <Route
                path="/caseReview"
                render={(props: RouteComponentProps<any>) => <CaseReview {...props} />}
            />
            <Route
                path="/casesearch"
                render={(props: RouteComponentProps<any>) => <CaseSearch {...props} />}
            />

            <Route
                path="/caseQuestionnaireRadio"
                render={(props: RouteComponentProps<any>) => (
                    <RadioTypeQuestion {...props} />
                )}
            />
            <Route
                path="/caseQuestionnaireText"
                render={(props: RouteComponentProps<any>) => (
                    <TextTypeQuestion {...props} />
                )}
            />
            <Route
                path="/caseQuestionnaireDropdown"
                render={(props: RouteComponentProps<any>) => (
                    <DropdownTypeQuestion {...props} />
                )}
            />

             <Route
                path="/casedetails"
                render={(props: RouteComponentProps<any>) => <CaseDetails {...props} />}
            />         

            <Route
                path="/transactionDetails"
                render={(props: RouteComponentProps<any>) => (
                    <TransactionDetails {...props} />
                )}
            />
            <Route
                path="/viewstatement"
                render={(props: RouteComponentProps<any>) => (
                    <ViewStatement {...props} />
                )}
            />
            <Route
                path="/viewaccount"
                render={(props: RouteComponentProps<any>) => <ViewAccount {...props} />}
            />
            <Route
                path="/manageprofile"
                render={(props: RouteComponentProps<any>) => (
                    <ManageProfile {...props} />
                )}
            />
            
                <Route
                    path="/editprofile"
                    render={(props: RouteComponentProps<any>) => (
                        <EditProfile {...props} />
                    )}
                />
            <Route
                path="/paymentDetails"
                render={(props: RouteComponentProps<any>) => (
                    <PaymentDetails {...props} />
                )}
            />
            <Route
                path="/allPayGateway"
                render={(props: RouteComponentProps<any>) => (
                    <AllPayGateway {...props} />
                )}
            />
            <Route
                path="/allPayGatewayConformation"
                render={(props: RouteComponentProps<any>) => (
                    <AllPayGatewayConformation {...props} />
                )}
            />

            <Route
                path="/selectTenancy"
                render={(props: RouteComponentProps<any>) => (
                    <SelectTenancy {...props} />
                )}
            />

            <Route
                path="/caseType"
                render={(props: RouteComponentProps<any>) => <CaseType {...props} />}
            />

            <Route
                path="/caseReview"
                render={(props: RouteComponentProps<any>) => <CaseReview {...props} />}
            />

            <Route
                path="/casesearch"
                render={(props: RouteComponentProps<any>) => <CaseSearch {...props} />}
            />

            <Route
                path="/caseQuestionnaire"
                render={(props: RouteComponentProps<any>) => (
                    <CaseTypeQuestion {...props} />
                )}
            />

            <Route
                path="/caseQuestionnaireRadio"
                render={(props: RouteComponentProps<any>) => (
                    <RadioTypeQuestion {...props} />
                )}
            />

            <Route
                path="/caseQuestionnaireText"
                render={(props: RouteComponentProps<any>) => (
                    <TextTypeQuestion {...props} />
                )}
            />

            <Route
                path="/caseQuestionnaireDropdown"
                render={(props: RouteComponentProps<any>) => (
                    <DropdownTypeQuestion {...props} />
                )}
            />

            <Route
                path="/caseQuestionnaire"
                render={(props: RouteComponentProps<any>) => (
                    <CaseTypeQuestion {...props} />
                )}
            />

            <Route
                path="/mymessageshistory"
                render={(props: RouteComponentProps<any>) => <MyMessagesHistory {...props} />}
            />
            {/* <Route
                path="/casefinalconfirmation"
                render={(props: RouteComponentProps<any>) => <CaseFinalConfirmation {...props} />}
            />  */}
           <FeatureFlagsProvider>
            <Route
                path="/repairlocation"
                render={(props: RouteComponentProps<any>) => (
                    <RepairLocation {...props} />
                )}
            />
             <Route
                path="/confirmationdetails"
                render={(props: RouteComponentProps<any>) => (
                    <ConfirmationDetails {...props} />
                )}
            />
            <Route
                path="/duplicaterepair"
                render={(props: RouteComponentProps<any>) => (
                    <DuplicateRepair {...props} />
                )}
            />
           
            <Route
                path="/repairadditionaldetails"
                render={(props: RouteComponentProps<any>) => (
                    <RepairAdditionalDetails {...props} />
                )}
            />
            <Route
                path="/vulnerabilityquestion"
                render={(props: RouteComponentProps<any>) => (
                    <VulnerabilityQuestion {...props} />
                )}
            />
          
           
            {accessRepairFunctionality && (
                <Route
                    path="/historicRepairs"
                    render={(props: RouteComponentProps<any>) => (
                        <HistoricRepairs {...props} />
                    )}
                />
            )}

            <Route
                path="/repairhistorydetails"
                render={(props: RouteComponentProps<any>) => (
                    <RepairHistoryDetails {...props} />
                )}
            />

            <Route
                path="/uploadmedia"
                render={(props: RouteComponentProps<any>) => (
                <UploadMedia {...props} />
            )}
            />

            <Route
                path="/messagedetails"
                render={(props: RouteComponentProps<any>) => (
                <MessageDetails {...props} />
            )}
            />

            <Route
            path="/viewmedia"
            render={(props: RouteComponentProps<any>) => <ViewMedia {...props} />}
            />
            </FeatureFlagsProvider>
            </Switch>
    );

    let routes = (
        <Switch>
             <Route
                path="/customer360View"
                render={(props: RouteComponentProps<any>) => (
                    <Customer360View {...props} />
                )}
            />
            <Route path="/" exact component={StartPage} />
            {loggedinStatus && financialRoutes}    
            <Redirect to="/" />
        </Switch>
    );

    return (
        <BrowserRouter>
            <Header name="Amit" />
            <Dashboard>
                <Suspense fallback={Loader}>{routes}</Suspense>
            </Dashboard>
            <Footer name="User" />
        </BrowserRouter>
    );
};

export default App;
