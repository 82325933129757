import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import * as api from "../../../api";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import {
  CASE_COUNTER,
  CASE_CREATION_DESCRIPTION,
  CASE_CREATION_MASTER_DATA,
  CASE_CREATION_QUESTIONS,
  CASE_DESCRIPTION_DATA_INFO,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
} from "../../../ducks/redux/actionTypes";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderJourney from "../../../storybook/Header/HeaderJourney";
import ExitModal from "../../Modal/ExitModal";
import BackModal from "../../Modal/BackModal";
import Message from "../../../storybook/Message";

const Loader = React.lazy(() => import("react-loader-spinner"));
const Button = React.lazy(() => import("../../../storybook/Button"));
const TextField = React.lazy(() => import("@mui/material/TextField"));
const Autocomplete = React.lazy(() => import("@mui/material/Autocomplete"));

interface CaseTypeProps extends RouteComponentProps<any> { }

const CaseType: React.FC<CaseTypeProps> = (props) => {
  const dispatch = useDispatch();
  const tenancies = (state: any) => state?.addresses?.customertenancies;
  const tenanciesData = useSelector(tenancies);

  const { t } = useTranslation();
  const caseTypeData: { description: string; keywords: [] }[] = [];

  let caseDescriptionArray: any[] = [];
  let timerId: any;
  let actualRefData: any = [];

  const [isLoading, setIsLoading] = useState(true);
  let suggesstionArray;
  let keyValuePairs: any = "";

  const [inputValue, setInputValue] = useState("");
  const [warningAlert, setWarningAlert] = useState("");
  let keywordPosArr: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const [userInput, setUserInput] = useState("");
  const [mappingData, setMappingData] = useState(caseTypeData);
  const [showStaticAlert, setShowStaticAlert] = useState("");

  let getDescriptionArr: any[] = [];
  const defaultSelection = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationDescription
  );

  const getDescriptionData = useSelector(
    (state: any) => state.caseCreationReducer.caseDescriptionDataInfo
  );
  if (getDescriptionData) {
    getDescriptionArr = [...getDescriptionData];
  }

  const getTenancyGroup = useSelector(
    (state: any) => state.caseCreationReducer.caseTenancyGroup
  );

  const singleTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.singleTenancy
);

  const getAPIMasterData = async () => {
    try {
      const responseData = await api.getQuestionnaireMasterData();
      if (responseData) {
        keyValuePairs = responseData[0]?.namespace?.keyValues;

        for (const data of Object.entries(keyValuePairs)) {
          const [key, value] = data;
          if (typeof value === "object") {
            actualRefData.push(value);
          }
        }
      }
      // Pushing description and keywords in an array and binding in autocomplete filed
      actualRefData?.map((data: any) => {
        //Validating with case tenancy group;
        if (
          data.tenancyGroups.length === 1 &&
          (data.tenancyGroups[0] === getTenancyGroup ||
            data.tenancyGroups[0] === "All") &&
          data.isVisibleForSelfServe === true
        ) {
          caseTypeData.push({
            description: data.description,
            keywords: data.keywords,
          });
          caseDescriptionArray.push(data.description);
        } else if (
          (data.tenancyGroups[0] === getTenancyGroup ||
            data.tenancyGroups[1] === getTenancyGroup ||
            data.tenancyGroups[0] === "All") &&
          data.isVisibleForSelfServe === true
        ) {
          caseTypeData.push({
            description: data.description,
            keywords: data.keywords,
          });
          caseDescriptionArray.push(data.description);
        }
      });
      setMappingData(caseTypeData);

      dispatch({ type: CASE_CREATION_MASTER_DATA, payload: actualRefData });
      dispatch({
        type: CASE_DESCRIPTION_DATA_INFO,
        payload: caseDescriptionArray,
      });
    } catch (e) {
      props.history.push("/genericerror");
    }
    setIsLoading(false);
    setUserInput(defaultSelection);
    setInputValue(defaultSelection);
  };

  const customerID = useSelector((state: any) => state.addresses?.customerid);
  useEffect(() => {
    if (defaultSelection) {
      setUserInput(defaultSelection);
      setInputValue(defaultSelection);
    }
    setShowStaticAlert("CC049_Case_warning_message");
    getAPIMasterData();

    return () => clearTimeout(timerId);
  }, []);

  const [fetchData, setFetchData] = useState<any>([]);

  const clearCaseDataFromRedux = () => {
    dispatch({ type: CLEAR_CASE_STATE });
  };

  const backButtonHandler = () => {
    // Clearning all data from state
    clearCaseDataFromRedux();
    if (tenanciesData?.length > 1) {
      props.history.push("/selectTenancy");
    } else {
      props.history.push("/customer360View");
    }
  };

  const exitButtonHandler = () => {
    clearCaseDataFromRedux();
    props.history.push("/customer360View");
  };

  const onContinueHandler = () => {
    if (inputValue === "") {
      setWarningAlert("Case_Warning");
    } else {
      dispatch({ type: CASE_COUNTER, payload: 0 });
      props.history.push("/caseQuestionnaire");
    }
  };

  const suggestionClickHandler = (e: any, value: any) => {
    if (value) {
      if (value.indexOf(" ") !== 0) {
        getDescriptionArr.filter((s) => {
          const matchingVal =
            s.includes(value.charAt(0).toUpperCase().trim() + value.slice(1)) ||
            s.includes(value.trim()) ||
            s.toLowerCase().includes(value.toLowerCase().trim()) ||
            s.includes(value.charAt(0).toLowerCase().trim() + value.slice(1));
          if (matchingVal) {
            caseDescriptionArray.push(s);
            let MatcherVal = caseDescriptionArray.sort().filter((c, index) => {
              return caseDescriptionArray.indexOf(c) === index;
            });
            setFetchData(MatcherVal);
            setWarningAlert("");
          } else if (caseDescriptionArray.length <= 0) {
            setFetchData([]);
            setWarningAlert("Case_Type_Error");
          }
        });
      } else {
        setWarningAlert("Case_Type_Error");
      }

      setUserInput(value);

      // === Validating keywords here ====
      mappingData?.map((data, index: number) => {
        data.keywords?.map((keywords: string) => {
          keywords?.match(/\b\w+?\b/g)?.map((keyword: any) => {
            if (value.indexOf(" ") !== 0) {
              suggesstionArray = value.split(" ");
              for (const matchValue of suggesstionArray) {
                if (matchValue.replace(/\s/g, '').length !== 0) {
                  if (keyword.trim().toLowerCase().includes(matchValue.trim().toLowerCase()) || keyword.trim() === matchValue.trim()) {
                    keywordPosArr[index] = keywordPosArr[index] + 1;
                    if (keywordPosArr[index] > 2) {
                      caseDescriptionArray.splice(1, 0, data.description);
                    }
                    if (keywordPosArr[index] > 1) {
                      caseDescriptionArray.unshift(data.description);
                    } else {
                      caseDescriptionArray.push(data.description);
                    }

                    let uniqueMatcher = caseDescriptionArray.filter(
                      (c, index) => {
                        return caseDescriptionArray.indexOf(c) === index;
                      }
                    );

                    if (uniqueMatcher) {
                      setFetchData(uniqueMatcher);
                      setWarningAlert("");
                    } else {
                      setFetchData([]);
                      setWarningAlert("Case_Warning");
                    }

                  }
                }
              }
            }
          });
        });
      });
    } else {
      setUserInput("");
      setWarningAlert("");
      setFetchData([]);
    }
    setInputValue("");
  };

  const inputTextChangeHandler = (event: any, newValue: any) => {
    if (newValue) {
      if (newValue !== defaultSelection) {
        dispatch({ type: CASE_CREATION_DESCRIPTION, payload: newValue });
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: [] });
        dispatch({ type: CASE_EDIT, payload: true });
        dispatch({ type: CASE_COUNTER, payload: 0 });
      }
      setWarningAlert("");
      setInputValue(newValue);
    } else {
      setWarningAlert("Case_Warning");
    }
  };

  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer p-0" id="reploc-container">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >

        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        ) : (
          <>
            <div id="repreason-div1" className="padding-repair">
              <h1 className="pb-30 header-size">
                {t("Case_Type_Title")}
              </h1>
              {/* ==== Showing alert ==== */}
              {warningAlert && userInput ? (
                <div data-testid="alert-warning" className="case-error">
                <Message className="error-msg" data-testid="locatioAlert_0">
                {t(warningAlert)}
                <a style={{ textDecoration: 'none' }} href="https://www.peabody.org.uk/contact-us" target="_blank" rel="noopener noreferrer">{" "} here</a>
              </Message>
              </div>
              ):(
                warningAlert  && (
                  <div data-testid="alert-warning" className="case-error">
                  <Message className="error-msg" data-testid="locatioAlert_0">
                  {t(warningAlert)}
                </Message>
                </div>
              ))
            }
              {showStaticAlert && (
                <div role="alert" className="additional-infomsg">
                <Message className="info-msg">
                {t(showStaticAlert)}
              </Message>
              </div>
              )}
              <p id="repreason-p" className="pt-16 pb-16">
                {t("Case_Hint")}
              </p>
              <div className="amt-grey ms-0 me-0">
              <label className="w-100">
                <div className="d-flex case-txtbox">
              <Autocomplete
                data-testid="high-level-case"
                value={userInput}
                onChange={inputTextChangeHandler}
                inputValue={userInput}
                onInputChange={suggestionClickHandler}
                id="high-level-case"
                freeSolo
                disableClearable
                filterOptions={(options) => fetchData}
                options={fetchData}
                size="small"
                className="amount-input"
                renderInput={(params) => (
                  <label>
                    {window.innerWidth <= 820 ? <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                        },
                      }}
                      multiline={true}
                      rows={1}
                      size="small"
                      margin="normal"
                      id="case-text"
                      placeholder={t("Case_Type_Placeholder")}
                      data-testid="autocomplete-input"
                    /> : <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                        },
                      }}

                      margin="normal"
                      id="case-text"
                      placeholder={t("Case_Type_Placeholder")}
                      data-testid="autocomplete-input"
                    />}
                  </label>
                )}
              />
              </div>
              </label>
              </div>
              
              {/* === End alert ==== */}
              
            </div>

            <div className="col-md-12 marginTop-auto" id="case-btn">
              <div className="row m-0 footer-actionbtn case-footer">
                <div className="col btn-top padding-repair mb-0">
                  {!singleTenancy && (
                  <BackModal
                    id="reploc-backmodal"
                    back={backButtonHandler}
                    btn_msg="return_case_button"
                  />   
                  )}           
                  
                  <ExitModal
                    id="case-exitmodal"
                    exit={exitButtonHandler}
                    btn_msg="return_case_button"
                  />
                </div>
                <div className="col text-end padding-repair">
                  <Button
                    id="btn_case"
                    type="button"
                    data-testid="btncontinue"
                    name="btncontinue"
                    onClick={onContinueHandler}
                    placeholder="btncontinue"
                    value="Continue"
                    className="primary-btn"
                  >
                    {t("continue_button")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </Suspense>
    </div>
    </>
  );
};

export default CaseType;
