import moment from 'moment'

import { Address, PostalAddress } from '../../models/Customer'

// Dates received in the format 1952-03-15T00:00:00Z
export const formatDate = (date: string | null): string => {
  if (!date) return 'None'
  return date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('-')
}

// Dates received in the format 1952-03-15T00:00:00Z
export const formatDateToSlash = (date: string | null): string => {
  if (!date) return 'None'
  return date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('/')
}

export const isWithinSixMonths = (date: string): boolean => {
  const today = new Date()
  return moment(new Date(today)).diff(new Date(date), 'months', true) < 6
}

export const formatDateWithMonthInLetters = (dateToFormat: string| null): string => {
  if (!dateToFormat) return '-'
  const date = moment(dateToFormat).format('DD MMM YYYY')
  return `${date}`
}
export const formatDateWithFullMonthInLetters = (dateToFormat: string| null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('DD MMMM YYYY')
  return `${date}`
}

export const formatDateWithFullMonthWeekInLetters = (dateToFormat: string| null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('dddd DD MMMM YYYY')
  return `${date}`
}

export const isNullOrUndefined = (obj : any): boolean => {
  let result = false;
  if ( obj === undefined || obj === null ||  obj === '' || obj === "") {  
    result = true; 
  } 
  return result;  
}

// Dates received in the format 1952-03-15T00:00:00Z
export const formatDateWithTime = (date: string, date2?: string): string => {
  if (!date && !date2) {
    return '-'
  }
  
  /*
  // TODO: Once we have tests, see if we can refactor into the following
  const time1 = moment.utc(moment(date)).format('DD-MM-YYYY, h.mma')
  const time2 = moment.utc(moment(date2)).format('-h.mma')
  return time2 ? `${time1}${time2}` : time1
  */

  const mmnt = moment(date)

  const d = mmnt.format('DD-MM-YYYY')
  const h = mmnt.hour()
  const m = mmnt.minute()

  let hour = h
  const minute = doubleDigit(m)
  let periodOfDay = 'am'

  if (hour >= 12) {
    hour = hour - 12
    periodOfDay = 'pm'
  }

  let formattedNewDate: string = ''
  if (!date2) {
    formattedNewDate = `${d}, ${hour}.${minute}${periodOfDay}`
  } else {
    let h2 = moment(date2).hour()
    const m2 = moment(date2).minute()
    let periodOfDay2 = 'am'
    if (h2 >= 12) {
      h2 = h2 - 12
      periodOfDay2 = 'pm'
    }
    formattedNewDate = `${d}, ${hour}.${minute}${periodOfDay}-${h2}.${m2}${periodOfDay2}`
  }

  return formattedNewDate
}

export const doubleDigit = (value: number): number | string => {
  const newValue = value >= 10 ? value : `0${value}`
  return newValue
}

export function addressToString(address: Address | PostalAddress | null): string {
  if (!address) return ''
  const { addressLine1, addressLine2, addressLine3, postcode } = address

  const addressLine4 = (address as Address).addressLine4 || ''
  const addressLine5 = (address as Address).addressLine5 || ''
  const formattedAddress = (address as Address).formattedAddress || ''
  const town = (address as PostalAddress).town || ''

  if (formattedAddress) return formattedAddress

  const allLines = [
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    town,
    postcode,
  ]

  const addField = (input: string, field: string | null) => {
    if (!field) return input
    if (!input) return field
    return `${input}, ${field}`
  }

  return (
    formattedAddress ||
    allLines.reduce((addr: string, line) => {
      return addField(addr, line)
    }, '')
  )
}

export function csvToMultiline(s: string): string {
  if (!s || typeof s !== 'string') return s

  return s
    .split(',')
    .map(str => str.trim())
    .join('\n')
}

export const stringToHTML = (s: string) => s.split(',')

export function valueOrPlaceholder(val: string | null) {
  const EMPTY_PLACEHOLDER = '-'
  return val || EMPTY_PLACEHOLDER
}

export const priorityType = (text: any) => {
  if (text === undefined || text === null) return ''

  const priority = text?.includes('(') ? text?.slice(0, text.indexOf('(')) : text
  return priority
}

export const bracketMessage = (text: any) => {
  if (text === undefined || text === null) return ''

  const messageInBracket = text?.includes('(')
    ? text?.slice(text.indexOf('('), text.indexOf(')') + 1)
    : ''
  return messageInBracket
}

export const textPreview = (text: string) => {
  const textLimit = 30
  const previewText = text.length > textLimit ? `${text.substring(0, textLimit)}...` : text

  return previewText
}

export const formatDateWithFromCalendar = (date: string): string => {
  if (!date) {
    return '-'
  }

  // TODO - Adding one day as calendar's date is not working well with moment.js
  const calendarDate = moment(date).format('DD-MM-YYYY')

  return calendarDate
}

export const dateToHour = (date: string): string => date.split('T')[1]?.split(':')[0]


export const formatDateTime = (dateToFormat: string| null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('YYYY-MM-DD')
  return `${date}`
}

export const formatDateTimeSlash = (dateToFormat: string| null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('DD/MMM/YYYY')
  return `${date}`
}

export function capitalizeName(name: string | undefined | null): string {
  if (!name) {
    return '';
  }

  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}