import "./index.scss";
import React, { useEffect } from "react";
import accessibility from "../../assets/img/peabody-logo-white.png";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
type FooterProps = {
  name: string;
};

const Footer: React.FC<FooterProps> = ({ name }) => {
  const { t } = useTranslation();

  const showFooter = useSelector(
    (state: any) => state?.userChoicesReducer?.showFooter
);

useEffect(() => {  
}, [showFooter]);

  const termsLink = () => {
    window.open("https://www.peabody.org.uk/terms-conditions",
      "", "width=auto, height=auto");
  }

  const AccessibilityLink = () => {
    window.open("https://www.peabody.org.uk/accessibility-translations",
      "", "width=auto, height=auto");
  }
  const privacyLink = () => {
    window.open("https://www.peabody.org.uk/terms-conditions/privacy",
      "", "width=auto, height=auto");
  }
  const cookiesLink = () => {
    window.open("https://www.peabody.org.uk/terms-conditions/cookies",
      "", "width=auto, height=auto");
  }
  return (
    <>
      {showFooter && (
        <footer className="footer-main">
          <div className="row">
            <div className="col-md-3 footer-copyright">
              {t("Peabody_2023")}
            </div>
            <div className="col-md-6 list-footer">
                  <ul className="footer-navigation">
                    <li>
                      <a onClick={termsLink} rel="noopener">{t("Terms_And_Conditions")}</a>
                    </li>
                    <li>
                      <a onClick={AccessibilityLink} rel="noopener">{t("Accessibility")}</a>
                    </li>
                    <li>
                      <a onClick={privacyLink} rel="noopener">{t("Privacy")}</a>
                    </li>
                    <li>
                      <a onClick={cookiesLink} rel="noopener">{t("Cookies")}</a>
                    </li>
                  </ul>
            </div>
            <div className="col-md-3 footer-logo-align">
              <img src={accessibility} alt="Peabody Logo - Footer" className="footer-logoimage"></img>
            </div>

          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
