import { CHANGE_REPAIR_PROPERTY_TRACK, UPDATE_FORM, FETCH_REPAIR_REASONS, USER_TYPED_REPAIR_REASONS, FETCH_REPAIR_REASONS_SELECT, SELECTED_REPAIR_REASON_OBJECT,ELIGIBILITY_RESPONSE,RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY,SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION} from "../actionTypes";

export const updateDashboardForm = (payload: any) => ({
  type: UPDATE_FORM,
  payload,
});

export const changeComponentTrack = (payload: any) => ({
  type: CHANGE_REPAIR_PROPERTY_TRACK,
  payload,
});


export const fetchRepairReasons = (payload: any) => ({
  type: FETCH_REPAIR_REASONS,
  payload,
});

export const fetchRepairReasonsSelect = (payload: any) => ({
  type: FETCH_REPAIR_REASONS_SELECT,
  payload,
});


export const userTypedRepairReasons = (payload: any) => ({
  type: USER_TYPED_REPAIR_REASONS,
  payload,
});

/* export const userChosenRepairReason = (payload: any) => ({
  type: USER_CHOSEN_REPAIR_REASON,
  payload,
}); */

export const selectedRepairReasonObject = (payload: any) => ({
  type: SELECTED_REPAIR_REASON_OBJECT,
  payload,
});
export const selectedRepairReasonPriorityDetermination = (payload: any) => ({
  type: SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION,
  payload,
});
export const resumeRepairReasonPriorityUpdatedToEmergency = (payload: any) => ({
  type: RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY,
  payload,
});

export const eligibilityResponseObject = (payload: any) => ({
  type: ELIGIBILITY_RESPONSE,
  payload,
});
