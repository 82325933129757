import React, { ChangeEvent } from 'react';
import "../../App.scss";
 
interface DropdownProps {
  options: { id: string; label: string }[]; 
  selectedOption: string; 
  disabled?: boolean; 
  handleChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  dataTestId?: string;
}
 
const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedOption,
  disabled,
  handleChange,
  dataTestId,
}) => {
  return (
    <select
      data-testid="inputstate"
      style={{ height: 44}}
      disabled={disabled}
      className="input-text-select"
      value={selectedOption}
      onChange={handleChange}
      aria-label="Input State"
    >
      {options.map((option) => (
        <option key={option.id} value={option.label}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
 
export default Dropdown;
 