import { RelatedPremises } from '../Property'
import PostalAddress from '../shared/Address/PostalAddress'
import Address from './Address'
import AssistanceRequirement from './AssistanceRequirement'
import CustomerName from './CustomerName'
import CustomerResponse from './CustomerResponse'
import { CustomerForProperty } from './CustomersForProperty'
import { CustomerContactErrors, CustomerErrors } from './Errors'
import { NotesType } from './Notes'
import { NotesTypeText } from './NotesTypes'
import Preference from './Preference'
import { FullPremises, PremisesDetails, PremisesType } from './Premises'
import TenancySummary from './TenancySummary'

export default class Customer {
  public id: string = ''
  public eTag: string = ''
  public givenName: string = ''
  public surname: string = ''
  public dateOfBirth: string = ''
  public email: string = ''
  public homePhone: string = ''
  public workPhone: string = ''
  public mobilePhone: string = ''
}

export type CustomerContactParams = {
  MobilePhone: string
  WorkPhone: string
  HomePhone: string
  EmailAddress: string
}

export type CustomersForPropertyResponse = CustomerForProperty[]

export {
  Address,
  AssistanceRequirement,
  CustomerContactErrors,
  CustomerErrors,
  CustomerForProperty,
  CustomerName,
  CustomerResponse,
  RelatedPremises,
  FullPremises,
  PostalAddress,
  Preference,
  PremisesDetails,
  PremisesType,
  NotesType,
  NotesTypeText,
  TenancySummary,
}
