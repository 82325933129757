import React, { useState } from "react";
import Modal from "./index";
import Button from "../../storybook/Button";
import Clear from "../../assets/img/Clear.png";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
} from "../../ducks/redux/actions/userChoices";
import { propTypes } from "react-bootstrap/esm/Image";


const ExitModal: React.FC<ModalProps> = ({ exit, btn_msg, referral_msg}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const id = "id";
  const clearUserStates = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));

    exit();
  };

  return (
    <>
      <a
        id={id}
        // className="secondary-ghost-btn ml-16"
        onClick={() => setIsOpen(true)}
        onKeyDown={() => setIsOpen(true)}
      >
        {/* {t("exit_button")} */}
        <span className="exit-arrowbtn">
                    <img src={Clear}  alt="Exit" />
                    </span>
      </a>
      <Modal open={isOpen} cssClasses="modalPopUp">
        <div id="exitmodal-div1">
          <div className="text-end pb-16"><img src={Clear} alt="Cancel" className="cursor-pointer" onClick={() => setIsOpen(false)} onKeyDown={() => setIsOpen(false)}/></div>
          <h1
            id="exitmodal-div2"
            className="pb-256 header-size pt-0"
          >
            {btn_msg ? t("SSR030_Case") : t("SSR030")}
          </h1>
          <div
            id="exitmodal-div3"
            className="modalPopup-footerbtn"
          >
            <div className="row m-0">
              <div className="col-md-3 btn-top bordertop-none cancel-exitBtn">
                <Button
                  id="exitmodal-btn2"
                  className="secondary-ghost-btn"
                  onClick={() => setIsOpen(false)}
                >
                  {t(btn_msg ||referral_msg || "return_repair_button")}
                </Button>
              </div>
              <div className="col-md-9 text-end bordertop-none">
                <Button
                  id="exitmodal-btn3"
                  onClick={clearUserStates}
                  className="secondary-ghost-btn btn-padding"
                >
                  {t("leave_button")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExitModal;
