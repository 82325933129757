export default class CaseSearchData {
    cases: Case[] = [];
    "total-count": number = 0;
    skip: number = 0;
    limit: number = 0;
    _links: Links = new Links();
}

export class Case {
    caseTypeDescription: string = "";
    caseTypeId: string = "";
    description: string = "";
    caseReferenceNumber: string = "";
    status: string = "";
    caseSubType: string = "";
    caseSubTypeCategory: string = "";
    assignee: string = "";
    priority: string = ""
    caseNotes: CaseNote[] = [];
    createdDate: string = "";
    modifiedDate: string = "";
    identifiers: Identifiers = new Identifiers();
    _links: Links=new Links();
}

export class CaseNote {
    noteType: string = "";
    noteDescription: string = "";
}

export class Customer {
    href: string = "";
    method: string = "";
}

export class Identifiers {
    caseIdentifier: string = "";
    assetIdentifier: string = "";
    customerIdentifier: string = "";
}

export class Asset {
    href: string = "";
    method: string = "";
}

export class Links {
    self: Self = new Self();
    asset: Asset = new Asset();
    customer: Customer = new Customer();
    next: Next = new Next();
    previous: Previous = new Previous();
}

export class Next {
    href: string = "";
    method: string = "";
}

export class Previous {
    href: string = "";
    method: string = "";
}

export class Self {
    href: string = "";
    method: string = "";
}