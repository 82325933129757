import React from 'react'
import { Loader } from './components'

// Pure CSS loading spinner courtesy of https://loading.io/css/
const LoadingSpinner = () => (
  <Loader data-bdd="loading-spinner">
    <div id="loading-spinner-1" />
    <div id="loading-spinner-2" />
    <div id="loading-spinner-3" />
    <div id="loading-spinner-4" />
  </Loader>
)

export default LoadingSpinner
