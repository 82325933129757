import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps } from 'react-bootstrap';
import Modal from '../Modal/index';
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import classes from "./style.module.css";
import Countdown from 'react-countdown';
import { requestCustomStatement } from '../../ducks/redux/actions/userChoices';

const RequestStatement: React.FC<ModalProps> = (props) => {
    const dispatch = useDispatch();

    const customerID = useSelector((state: any) => state.addresses?.customerid);

    let moment = require("moment");

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const [fromDate, setFromDate] = React.useState('');
    const [toDate, setToDate] = React.useState('');
    const [radioValue, setRadioValue] = React.useState('');
    const [otherEmailVal, setOtherEmailVal] = React.useState('');
    const [showOtherEmail, setShowOtherEmail] = React.useState(false);
    const [yellowAlertMessage, setYellowAlertMessage] = React.useState('');
    const [purpleAlertMessage, setPurpleAlertMessage] = React.useState('');

    
    let formatedDate: any;
    const date = props.tenancyStartDate;
    const formatedToday = new Date().toISOString().split("T")[0];

    const dateIsValid = (date: Date) => {
        return !Number.isNaN(new Date(date).getTime());
    }

    if (dateIsValid(date)) {
        formatedDate = new Date(date).toISOString().split("T")[0];
    }

    let errorArray: any[] = [];
    const emailRegrex = /^(([^<>()\[\]\\.,;:\s$*£`%+?{}\=|/¬&!#^~@"]+(\.[^<>()\[\]\\.,;:\s$*£`%+?{}\=|/¬&!#^~@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    const validateEmail = (typedValue: string) => {
        const splitedEmail = typedValue.split('@');
        if (!typedValue || emailRegrex.test(typedValue) === false || splitedEmail[0].length > 64 || splitedEmail[1].length > 48 || typedValue.length > 100 || typedValue.indexOf('-@') > -1) {
            setYellowAlertMessage('SSC011');
            return 'Invalid Email';
        } else {
            setYellowAlertMessage('');
            return 'Valid Email';
        }
    }

    const otherEmailChangeHandler = (event: any) => {
        const typedValue = event.target.value;
        setYellowAlertMessage('');
        setOtherEmailVal(typedValue);
    }

    const handleRadioChange = (event: any) => {
        if (event.target.id === 'covidQues-radio2') {
            setShowOtherEmail(true);
        } else {
            setYellowAlertMessage('');
            setShowOtherEmail(false);
        }
        setRadioValue(event.target.value);
    }

    const inputChangeHandler = (event: any) => {
        if (moment(event.target.value, 'DD/MM/YYYY', true).isValid()) {
            setPurpleAlertMessage('');
            setYellowAlertMessage('SSC020');
        } else {
            setYellowAlertMessage('');
        }

        if (event.target.id === 'fromDate') {
            if (new Date(event.target.value).getTime() > new Date(formatedToday).getTime()) {
                setPurpleAlertMessage('');
                setYellowAlertMessage('SSC013');
            } else if (new Date(event.target.value).getTime() < new Date(formatedDate).getTime()) {
                setPurpleAlertMessage('');
                setYellowAlertMessage('SSC012');
            } else {
                setYellowAlertMessage('');
            }
            setFromDate(event.target.value);

        } else if (event.target.id === 'toDate') {
            if (new Date(event.target.value).getTime() > new Date(formatedToday).getTime()) {
                setPurpleAlertMessage('');
                setYellowAlertMessage('SSC014');
            } else {
                setYellowAlertMessage('');
            }
            setToDate(event.target.value);
        }
    }

    const otherData = {
        setFromDate,
        setToDate,
        setRadioValue,
        setOtherEmailVal,
        setShowOtherEmail,
        setYellowAlertMessage,
        setPurpleAlertMessage,
        setIsOpen
    }
    
    const closeModal = () => {
        setIsOpen(false);
        setFromDate('');
        setToDate('');
        setRadioValue('');
        setOtherEmailVal('');
        setShowOtherEmail(false);
        setYellowAlertMessage('');
        setPurpleAlertMessage('');
    }

    const requestStatementHandler = () => {
        if (yellowAlertMessage) {
            return
        }

        if (!fromDate || !toDate || !radioValue || (radioValue === 'Other Email Address' && otherEmailVal === '')) {
            setPurpleAlertMessage('');
            setYellowAlertMessage('SSC009');
        } else if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
            setPurpleAlertMessage('');
            setYellowAlertMessage('SSC009');
        } else if (new Date(fromDate).getTime() < new Date(formatedDate).getTime()) {
            setPurpleAlertMessage('');
            setYellowAlertMessage('SSC012');
        } else if (new Date(fromDate).getTime() > new Date(formatedToday).getTime()) {
            setPurpleAlertMessage('');
            setYellowAlertMessage('SSC013');
        } else if (new Date(toDate).getTime() > new Date(formatedToday).getTime()) {
            setPurpleAlertMessage('');
            setYellowAlertMessage('SSC014');
        } else if (otherEmailVal && validateEmail(otherEmailVal) === 'Invalid Email') {
            validateEmail(otherEmailVal);
        } else {
            let customerEmail;
            if (radioValue == 'Other Email Address') {
                customerEmail = otherEmailVal;
            } else {
                customerEmail = radioValue;
            }

            const customerName = `${props.customerName?.title} ${props.customerName?.givenName} ${props.customerName?.surname}`

            const statementRequestData = {
                "sender": {
                    "addresses":
                    {
                        "digitalAddresses": [
                            {
                                "emailAddresses": [
                                    {
                                        "value": "mypeabody@peabody.org.uk",
                                        "id": "email"
                                    }
                                ],
                                "telecomsAddresses": [
                                    {
                                        "number": "",
                                        "deviceId": "",
                                        "dialingCode": "",
                                        "countryCode": "",
                                        "telecomsAddressType": "mobile"
                                    }
                                ]
                            }
                        ]
                    }
                },
                "communicationTemplateIdentifier": "pb-requestcustomstatement-template",
                "communicationType": "Email",
                "content": [
                    {
                        "identifier": "payment_ref",
                        "value": props.accountnumber
                    },
                    {
                        "identifier": "customer_name",
                        "value": customerName
                    },
                    {
                        "identifier": "statement_startdate",
                        "value": fromDate
                    },
                    {
                        "identifier": "statement_enddate",
                        "value": toDate
                    },
                    {
                        "identifier": "customer_email",
                        "value": customerEmail
                    }
                ]
            }

            dispatch(requestCustomStatement(customerID, statementRequestData, props.eTag, otherData));
        }
    }

    return (
        <>
            <div>
                {props.isDisabled ?
                    <a id="exitmodal-btn1" className="cursor-pointer-none text-muted no-underline">{t("VS_RCS") + ' '}
                        <i className="fas fa-file-alt"></i>
                    </a>
                    :
                    <a id="exitmodal-btn1" data-testid="Request_Statement_Button" className="cursor-pointer text-teal no-underline hover:underline" onClick={() => setIsOpen(true)}>{t("VS_RCS") + ' '}
                        <i className="fas fa-file-alt"></i>
                    </a>
                }
            </div>
            <Modal open={isOpen} cssClasses="requestStatement">
                {!purpleAlertMessage &&
                    <div className="absolute top-2 flex items-center justify-center text-teal w-6 h-6 p-1  z-50 cursor-pointer">
                        <i onClick={closeModal} className="far fa-times-circle w-4 h-4"></i>
                    </div>
                }
                <div className="w-full ">
                    <div className="flex flex-wrap">
                        <div className="w-full p-4 ">
                            <h2 data-testid="RCS_Request_Statement" className="my-1 text-black py-2 border-b border-grey">{t("RCS_Request_Statement")}</h2>

                            {purpleAlertMessage ?
                                <>                                    
                                    <div className={`${classes.purpleAlertMessage}`}>
                                        <div className="w-full px-4 py-2 bg-purple-20 flex items-center">
                                            <div className={`${classes.alertMessageContainer}`}>
                                                <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
                                            </div>
                                            <div className="flex items-center">
                                                <p data-testid="purple_Notification" className={`${classes.alertMessage} w-full text-left my-2 font-AvantGardeGothic-Md`}>{t(purpleAlertMessage)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <p className="p-0 mt-2 m-0">{t("RCS_Message")}</p>

                                    <div className=" py-2 mt-4 border-b border-grey">
                                        <div className={`${classes.accountDatesContainer} flex justify-between py-1 mb-2 border-b border-grey`}>
                                            <div>Account type</div>
                                            <div>{props.accountType}</div>
                                        </div>

                                        <div className={`${classes.accountDatesContainer} flex justify-between py-2 border-b border-grey`}>
                                            <label className="pt-2" htmlFor="fromDate">From date</label>
                                            {/* <input type="text" id="fromDate" name="fromDate" min={formatedDate} value={fromDate} onChange={inputChangeHandler} onFocus={(e) => (e.target.type = "date")} className={classes.dateCSS} placeholder="dd/mm/yyyy" /> */}
                                            <input type="date" id="fromDate" name="fromDate" min={formatedDate} value={fromDate} onChange={inputChangeHandler} className={classes.dateCSS} placeholder="dd/mm/yyyy" />
                                        </div>

                                        <div className={`${classes.accountDatesContainer} flex justify-between py-2 mb-2 border-b border-grey`}>
                                            <label className="pt-2" htmlFor="toDate">To date</label>
                                            {/* <input type="text" id="toDate" name="toDate" max={formatedToday} value={toDate} onChange={inputChangeHandler} onFocus={(e) => (e.target.type = "date")} className={classes.dateCSS} placeholder="dd/mm/yyyy" />  */}
                                            <input type="date" id="toDate" name="toDate" max={formatedToday} value={toDate} onChange={inputChangeHandler} className={classes.dateCSS} placeholder="dd/mm/yyyy" />
                                        </div> 

                                        <RadioGroup
                                            id="covidQues-radiogroup"
                                            name="radio-buttons-group"
                                            defaultValue={radioValue}
                                            className="question-btn mb-4 mt-4"
                                            value={radioValue}
                                            onChange={handleRadioChange}
                                        >
                                            {props.customerAddress &&
                                                <FormControlLabel
                                                    id="covidQues-label1"
                                                    data-testid="Existing_Email_Address"
                                                    value={props.customerAddress}
                                                    className="h-8 global-txt mb-2 margin-lt"
                                                    control={
                                                        <Radio id="covidQues-radio1" placeholder={props.customerAddress} name={"currentEmail"} />
                                                    }
                                                    label={props.customerAddress}
                                                />
                                            }
                                            <FormControlLabel
                                                id="covidQues-label2"
                                                data-testid="Other_Email_Address"
                                                value="Other Email Address"
                                                className="mt-3 h-8 global-txt margin-lt"
                                                control={
                                                    <Radio id="covidQues-radio2" placeholder="Other Email Address" name={"otherEmail"} />
                                                }
                                                label="Other Email Address"
                                            />
                                        </RadioGroup>

                                        {showOtherEmail &&
                                            <input
                                                type="text"
                                                value={otherEmailVal}
                                                className={`${classes.otherEmail}`}
                                                placeholder="Enter other email"
                                                onChange={otherEmailChangeHandler}
                                            />
                                        }

                                        {yellowAlertMessage &&
                                            < div className={`${classes.alertMessageMainContainer} alert mb-8`}>
                                                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                                    <div className={`${classes.alertMessageContainer}`}>
                                                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p data-testid="yellow_Notification" className={`${classes.alertMessage} w-full text-left my-2 font-AvantGardeGothic-Md`}>{t(yellowAlertMessage)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="md:flex md:justify-between pt-6">
                                        <div className="mb-2 md:mb-0 py-3"><a onClick={closeModal} className="cursor-pointer text-teal no-underline hover:underline">{t("RCS_Cancel")}</a></div>
                                        <Button
                                            id="-btn1"
                                            onClick={requestStatementHandler}
                                            className="button-class"
                                        >
                                            {t("continuen_button")}
                                            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default RequestStatement;