import ReactGA from 'react-ga4'	  
		 
const ga4_tracking_id = process.env.REACT_APP_GA4_TRACKING_ID ?? ''; 

export const googleAnalyticsTracking = (uid:any) => {
    ReactGA.initialize([
        {
          trackingId: ga4_tracking_id,
          gaOptions: {name: 'tracker1',
          userId: uid}, 
        },
        {
          trackingId: ga4_tracking_id,
        },
      ]);
      ReactGA.set({ userId: uid });
      ReactGA.send({ hitType: "pageview", page: window.location.pathname});

 };

 