export enum AccountType {
  REN = "Rent Account",
  FD5 = "FTA Debit for 5 year fixed tenancy",
  MJW = "Major Works Account",
  GRD = "Ground Rent Account",
  DEP = "Deposit Account",
  HOM = "Catalyst Historical Home Account",
}

export enum AccountTypeList {
  REN = "REN",
  FD5 = "FD5",
  MJW = "MJW",
  GRD = "GRD",
  DEP = "DEP",
  HOM = "HOM",
}
export enum TenancyType {
  GARAGE = "GARAGE",
  STORELIC = "STORELIC",
  LEASE100 = "LEASE100",
  LEASECTH = "LEASECTH",
  LEASEHB5 = "LEASEHB5",
  LEASESEC = "LEASESEC",
  LEASESHR = "LEASESHR",
  FREEHOLD = "FREEHOLD",
  INTERMED = "INTERMED",
  AST09 = "AST09",
  FIXED5YR = "FIXED5YR",
  FIXED6YR = "FIXED6YR",
  AFF6YR = "AFF6YR",
  AFFSTART = "AFFSTART",
  ASSPROT = "ASSPROT",
  LONDONLR = "LONDONLR",
  STARTER = "STARTER",
  STUDENT = "STUDENT",
  ASS06 = "ASS06",
  LICGAR = "LICGAR",
  AST_KEY = "AST_KEY",
  COM2 = "COM2",
  LIC02 = "LIC02",
  FIXED2YR = "FIXED2YR",
  FIXED3YR = "FIXED3YR",
  FIXED4YR = "FIXED4YR",
  AFF1YR = "AFF1YR",
  AFF2YR = "AFF2YR",
  AFF3YR = "AFF3YR",
  AFF4YR = "AFF4YR"
}
export enum TenancyTypeRen {
  GARAGE = "Garage Account",
  STORELIC = "Storeroom Account",
  LEASE100 = "Service Charge Account",
  LEASECTH = "Service Charge Account",
  LEASEHB5 = "Service Charge Account",
  LEASESEC = "Service Charge Account",
  LEASESHR = "Rent and Service Charge Account",
  FREEHOLD = "Estate Charge Account",
  LICGAR = "Garage Account",

}
export enum TenancyTypeFD5 {
  GARAGE = "Garage Account",
  STORELIC = "Storeroom Account",
  LEASE100 = "Service Charge Account",
  LEASECTH = "Service Charge Account",
  LEASEHB5 = "Service Charge Account",
  LEASESEC = "Service Charge Account",
  LEASESHR = "Service Charge Account",
  FREEHOLD = "Estate Charge Account",
  INTERMED = "Service Charge Account",
  LICGAR = "Garage Account"
}

export const getAccountType = (
  accountType: string,
  tenancyType: string
): any => {
  let AccountTypeValue = "";
  if (accountType == AccountTypeList.REN) {
    if (tenancyType == TenancyType.GARAGE) {
      AccountTypeValue = TenancyTypeRen.GARAGE;
    } else if (tenancyType == TenancyType.STORELIC) {
      AccountTypeValue = TenancyTypeRen.STORELIC;
    } else if (tenancyType == TenancyType.LEASE100) {
      AccountTypeValue = TenancyTypeRen.LEASE100;
    } else if (tenancyType == TenancyType.LEASECTH) {
      AccountTypeValue = TenancyTypeRen.LEASECTH;
    } else if (tenancyType == TenancyType.LEASEHB5) {
      AccountTypeValue = TenancyTypeRen.LEASEHB5;
    } else if (tenancyType == TenancyType.LEASESEC) {
      AccountTypeValue = TenancyTypeRen.LEASESEC;
    } else if (tenancyType == TenancyType.LEASESHR) {
      AccountTypeValue = TenancyTypeRen.LEASESHR;
    } else if (tenancyType == TenancyType.FREEHOLD) {
      AccountTypeValue = TenancyTypeRen.FREEHOLD;
    } else if (tenancyType == TenancyType.LICGAR) {
      AccountTypeValue = TenancyTypeRen.LICGAR;
    }else {
      AccountTypeValue = AccountType.REN;
    }
  } else if (accountType == AccountTypeList.FD5) {
    if (tenancyType == TenancyType.GARAGE) {
      AccountTypeValue = TenancyTypeFD5.GARAGE;
    } else if (tenancyType == TenancyType.STORELIC) {
      AccountTypeValue = TenancyTypeFD5.STORELIC;
    } else if (tenancyType == TenancyType.LEASE100) {
      AccountTypeValue = TenancyTypeFD5.LEASE100;
    } else if (tenancyType == TenancyType.LEASECTH) {
      AccountTypeValue = TenancyTypeFD5.LEASECTH;
    } else if (tenancyType == TenancyType.LEASEHB5) {
      AccountTypeValue = TenancyTypeFD5.LEASEHB5;
    } else if (tenancyType == TenancyType.LEASESEC) {
      AccountTypeValue = TenancyTypeFD5.LEASESEC;
    } else if (tenancyType == TenancyType.LEASESHR) {
      AccountTypeValue = TenancyTypeFD5.LEASESHR;
    } else if (tenancyType == TenancyType.FREEHOLD) {
      AccountTypeValue = TenancyTypeFD5.FREEHOLD;
    } else if (tenancyType == TenancyType.INTERMED) {
      AccountTypeValue = TenancyTypeFD5.INTERMED;
    } else if (tenancyType == TenancyType.LICGAR) {
      AccountTypeValue = TenancyTypeRen.LICGAR;
    } else {
      AccountTypeValue = AccountType.REN;
    }
  } else if (accountType == AccountTypeList.MJW) {
    AccountTypeValue = AccountType.MJW;
  } else if (accountType == AccountTypeList.GRD) {
    AccountTypeValue = AccountType.GRD;
  } else if (accountType == AccountTypeList.DEP) {
    if (tenancyType == TenancyType.FREEHOLD) {
      AccountTypeValue = AccountType.GRD;
    } else {
      AccountTypeValue = AccountType.DEP;
    }
  } else if (accountType == AccountTypeList.HOM) {
    AccountTypeValue = AccountType.HOM;
  }
  return AccountTypeValue;
};
