import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import "./index.scss";
import * as api from "../../api";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import Message from "../../storybook/Message";
import MonthYearName from "../../models/MonthYearName/MonthYearName";
import Dropdown from "../../storybook/Dropdown";
import downloadicon from "../../assets/img/download-icon.png";

interface ViewStatementDetailsProps extends RouteComponentProps<any> {
  name: string;
  monthYear: MonthYearName[];
  swtichOffAlert: any;
  accountnumber: string;
  payRef: string;
}


const ViewStatementDetails: React.FC<ViewStatementDetailsProps> = ({
  name,
  swtichOffAlert,
  accountnumber,
  payRef,
  monthYear,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const [isDisplay, setIsDisplay] = useState("none");

  /* istanbul ignore next */
  monthYear.forEach((obj, index) => {
    obj.id = index.toString();
    obj.label = obj.name;
  });

  const [inputState, setInputState] = useState<any>(monthYear[0]?.label);

  /* istanbul ignore next */
  const handleChange = (e: { target: { value: any } }) => {
    if (inputState != e.target.value) {
      setInputState(e.target.value);
      setIsDisplay("none");
    }
  };

  let monthNumber: string;
  let prevMonthNumber: string;

  useEffect(() => {
    swtichOffAlert.current = turnOffAlert;
  }, [swtichOffAlert.current]);

  useEffect(() => {
    setInputState(monthYear[0]?.label);
    setIsDisplay("none");
  }, [payRef])

  const turnOffAlert = () => {
    setIsDisplay("none");
  };

  /* istanbul ignore next */
  const fetchChosenStatement = (name: string) => {
    setIsLoading(true);
    const monthYearName = name?.trim();
    const monthYear_array = monthYearName?.split(" ");
    const chosenMonth = monthYear_array?.[0]??null;
    const chosenYear = monthYear_array?.[1]??null;
    
    let PrevChosenYear = chosenYear;

    if (chosenMonth === "January") {
      monthNumber = "01";
      prevMonthNumber = "12";
      PrevChosenYear = (Number(chosenYear) - 1).toString();
    } else if (chosenMonth === "February") {
      monthNumber = "02";
      prevMonthNumber = "01";
    } else if (chosenMonth === "March") {
      monthNumber = "03";
      prevMonthNumber = "02";
    } else if (chosenMonth === "April") {
      monthNumber = "04";
      prevMonthNumber = "03";
    } else if (chosenMonth === "May") {
      monthNumber = "05";
      prevMonthNumber = "04";
    } else if (chosenMonth === "June") {
      monthNumber = "06";
      prevMonthNumber = "05";
    } else if (chosenMonth === "July") {
      monthNumber = "07";
      prevMonthNumber = "06";
    } else if (chosenMonth === "August") {
      monthNumber = "08";
      prevMonthNumber = "07";
    } else if (chosenMonth === "September") {
      monthNumber = "09";
      prevMonthNumber = "08";
    } else if (chosenMonth === "October") {
      monthNumber = "10";
      prevMonthNumber = "09";
    } else if (chosenMonth === "November") {
      monthNumber = "11";
      prevMonthNumber = "10";
    } else if (chosenMonth === "December") {
      monthNumber = "12";
      prevMonthNumber = "11";
    }

    (async () => {
      try {
        const data = {
          // yearMonthFrom: `${PrevChosenYear}/${prevMonthNumber}`,
          yearMonthFrom: `${chosenYear}/${monthNumber}`,
          yearMonthTo: `${chosenYear}/${monthNumber}`,
        };
        const statementsResponse = await api.getDocumentsByCustomer(
          customerID,
          data
        );

        Promise.all([statementsResponse]).then((results) => {

          var documentsForAccount = results[0]?.documents.some(doc => doc.metadata.find(metaKey => metaKey.key == "account-reference" && metaKey.value == payRef))
          
          if (results[0].documents.length == 0 || !documentsForAccount) {
            setIsDisplay("block");
            setIsLoading(false);

            return;
          }
          let documentPDFTitle = `Statement ${chosenMonth} ${chosenYear}`;
          results.map((allDocs) => {
            allDocs.documents.filter(async (el: any) => {
              el.metadata.filter(async (key: any) => {
                if (
                  key.value === payRef &&
                  key.key === "account-reference"
                ) {
                  const documentResponse = await api.getDocumentByIdentifier(
                    customerID,
                    el?.id
                  );
                  if (!documentResponse.data) {
                    setIsLoading(false);
                    setIsDisplay("block");
                    return;
                  } else {
                    const file = new Blob([documentResponse.data], {
                      type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    const newWindow: any = window.open(fileURL, "_blank");

                    if (newWindow?.document) {
                      setTimeout(
                        () => (newWindow.document.title = documentPDFTitle),
                        1000
                      );
                    }

                    setIsLoading(false);
                  }
                  return;
                }
              });
            });
          });
        });
      } catch (error) {
        setIsLoading(false);
        setIsDisplay("block");
      }
    })();
  };
  return (
    <div>
      {!isLoading ? (
        <div>
          <div id="statement-download" className="accounts-grey col-md-12 mt-8">
            <Dropdown
              options={monthYear}
              selectedOption={inputState}
              handleChange={handleChange}
              dataTestId="inputState"
            ></Dropdown>
            <button
              className="secondary-ghost-btn ml-16"
              onClick={(event) => fetchChosenStatement(inputState)}
              id="downLoadStatement"
              data-testid="downLoadStatement"
            >
              {t("Download")}
            <img
              src={downloadicon}
              alt="Filter"
              className="repair-filtericon"
              />
            </button>
         
          </div>
          <div className="mb-8" data-testid="errorMessage1" style={{ display: isDisplay }}>
            <Message className="warning-msg">
              {t("VS_Failure_To_Retrieve_Statement")}
            </Message>
          </div>
        </div>
      ) : (
        <div className="div-loader">
          <div className="SpinnerWrapper">
            <LoadingSpinner />
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewStatementDetails;
